import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { FileReq } from 'src/app/models/dbModels/fileReq';
import { Request } from 'src/app/models/dbModels/request';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';
import { User } from 'src/app/models/dbModels/user';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { RequestMessage } from 'src/app/models/logicModels/requestMessage';
import { AuthService } from 'src/app/services/auth.service';
import { Functions } from 'src/app/services/functions';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-request-chat',
  templateUrl: './request-chat.component.html',
  styleUrls: ['./request-chat.component.css']
})

export class RequestChatComponent implements OnInit {

  @Input() requestData: Request;
  @Input() isAcudiente: boolean;
  @Input() acudienteData: User;
  @Output() closeRequestData = new EventEmitter<boolean>();
  @Output() updateData = new EventEmitter<boolean>();
  public nameBucketRequestInfo = environment.nameBucketRequestInfo;
  public requestID: number;

  public viewType?: number | null;
  public userDocument: string | undefined;
  public requestHistoryCreation: RequestHistory;
  public requestHistoriesList: RequestHistory[];
  public filesReqListToUpload: FileReq[] = [];
  public fileReqTemp: FileReq;

  public fileToUpload: File | undefined;
  public uploadArchiveText: string = 'Elija el archivo';
  public isArchiveError: boolean = false;

  public allRequestMessages: RequestMessage[] | undefined;

  public activeDocumentsSelector: boolean = false;

  constructor (
    private _requestService: RequestService,
    private _authService: AuthService,
    private _functions: Functions,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this.viewType = 3
    this.requestHistoryCreation = {
      publicComment: '',
      updateDate: new Date(Date.now()),
      stageReqID: 1,
      stateReqID: 1,
      requestID: this.requestID,
      userID: Number(this._authService.getCurrentUserID()),
    }
    this.fileReqTemp = {
      descript: '',
      userID: Number(this._authService.getCurrentUserID()),
      nameFile: '',
      updateDate: new Date(Date.now()),
      isAdmin: false,
      isDocumentValid: false,
      urlFile: ''
    }
  }

  ngOnInit(): void {
    this.dataChatMsgsOrder();
    this.calculateDataToPayment();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if (!changes['firstChange']) {
      this.dataChatMsgsOrder();
      this.calculateDataToPayment();
    }
  }

  onFileInput(event: any) {
    console.log(event.target.files[0].name);
    this.fileToUpload = <File>event.target.files[0];
    if (this.fileToUpload.size > 5000000) {//5M
      this.uploadArchiveText = 'Error de Archivo'
      this.isArchiveError = true;
    }
    else {
      this.uploadArchiveText = 'Cambiar el archivo';
      this.isArchiveError = false;
    }
  }

  // addAttachmentToList() {
  //   this.fileReqTemp.updateDate = new Date(Date.now());
  //   this.filesReqListToUpload.push(this.fileReqTemp);
  //   // this.fileReqTemp.nameFile = '';
  //   // this.fileReqTemp.descript = '';
  //   console.log(this.filesReqListToUpload);
  // }

  addAttachmentToList = async () =>  {
    let newFile = JSON.parse(JSON.stringify(this.fileReqTemp));
    newFile.updateDate = new Date(Date.now());
    this.filesReqListToUpload.push(newFile);
    this.fileReqTemp.nameFile = '';
    this.fileReqTemp.descript = '';
    this.fileToUpload = undefined;
  }

  deleteArrayListItem(e){
    this.filesReqListToUpload = this.filesReqListToUpload.filter(item => item.updateDate !== e.updateDate)
  }

  dataChatMsgsOrder() {  

    this.userDocument = this.requestData.requestUserRequester?.UserDocuments! ? this.requestData.requestUserRequester?.UserDocuments[0]?.Document : '';


    let documentMessages: RequestMessage[] | undefined;
    let textMessages: RequestMessage[] | undefined;

    textMessages = this.requestData.requestHistories?.map(history => {
      let historyItem: RequestMessage = {
        userName: history.requestHistoryUser?.Name1 + ' ' + history.requestHistoryUser?.LastName1,
        userEmail: history.requestHistoryUser?.email,
        message: history.publicComment,
        messageDate: history.updateDate,
        userImg: history.requestHistoryUser?.Photo,
        isReceived: history.userID?.toString() === this._authService.getCurrentUserID(),
      }
      return ( historyItem )
    })
    
    documentMessages = this.requestData.filesReq?.map(file => {
      let FileItem: RequestMessage = {
        userName: file.filesUserID?.Name1 + ' ' + file.filesUserID?.LastName1,
        // userPosition: ,
        userEmail: file.filesUserID?.email,
        documents: [file],
        messageDate: file.updateDate,
        userImg: file.filesUserID?.Photo,
        isReceived: file.filesUserID?.id?.toString() === this._authService.getCurrentUserID(),
      }
      return ( FileItem )
    })

    if (documentMessages) {
      this.allDocumentMessagesPlaceholders = documentMessages?.filter(fileToFilter => fileToFilter.documents?.[0]?.isPlaceholderFile);
      this.allDocumentMessagesPlaceholders?.sort( function ( a, b ): number {
        const nameA = a?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
        const nameB = b?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
        const numA = parseInt(nameA, 10);
        const numB = parseInt(nameB, 10);
        if (!isNaN(numA) && !isNaN(numB)) return numA - numB;
        return nameA.localeCompare(nameB);
      } );
      this.allDocumentMessages = documentMessages?.filter(fileToFilter => !fileToFilter.documents?.[0]?.isPlaceholderFile);
      this.allDocumentMessages?.sort( function ( a, b ): number {
        const nameA = a?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
        const nameB = b?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
        const numA = parseInt(nameA, 10);
        const numB = parseInt(nameB, 10);
        if (!isNaN(numA) && !isNaN(numB)) return numA - numB;
        return nameA.localeCompare(nameB);
      } );
    }
    
    this.allRequestMessages = textMessages?.concat(documentMessages ?? []);

    this.allRequestMessages?.sort(function(a,b) {
      return new Date(a.messageDate!).getTime() - new Date(b.messageDate!).getTime();
    });    
  }

  allDocumentMessages: RequestMessage[];
  allDocumentMessagesPlaceholders: RequestMessage[];

  createRequestHistory() {
    this.requestHistoryCreation.requestID = this.requestID;
    this.requestHistoryCreation.requestID = this.requestData.id;
    this.requestHistoryCreation.updateDate = new Date(Date.now());
    if(this.requestHistoryCreation.requestID) {
      this.requestHistoryCreation.updateDate = new Date(Date.now())
      this._requestService.createRequestHistory(this.requestHistoryCreation).pipe(take(1)).subscribe(
        data => {
          console.log('createRequestHistory success', data);
          this.requestHistoryCreation.publicComment = '';
          this.requestData?.requestHistories?.push(data);
          this.dataChatMsgsOrder();
          this.updateDataSaved();
        },
        err => {
          console.log('fail', err);
        }
      );
    }
  }

  closeRequestDataBox() {
    this.closeRequestData.emit(false);
    this.closeFloatingBoxUserStudent();
  }

  getDataFromUploadFileComponent(data: InfoFileData): void {
    this.fileReqTemp.urlFile = data.urlFile;
    this.fileReqTemp.nameFile = data.nameFile;
    this.fileReqTemp.descript = data.descript;
    this.fileReqTemp.requestID = this.requestData.id;
    this.fileReqTemp.updateDate = new Date(Date.now());
    this.createFileReq(this.fileReqTemp);
  }
  closeUploadFileComponent(): void {
    this.activeDocumentsSelector = false;
  }

  createFileReq(fileReq: FileReq): void {
    this._requestService.createFileReq(fileReq).pipe(take(1)).subscribe(
      data => {
        console.log('createFileReq success', data);
        this.updateDataSaved();
        this.requestData?.filesReq?.push(data);
        this.dataChatMsgsOrder();
        this.activeDocumentsSelector = false;
      },
      err => {
        console.log('createFileReq fail', err);
      }
    );
  }

  updateDataSaved(): void {
    this.updateData.emit(true);
  }

  dataPayment = {
    requestWithPayment: false,
    showPaymentBtn: false,
    classPaymentColor: '',
    textPaymentState: 'SIN COSTO',
    btnLink: '',
    paymentHistory: [{}]
  };
  platformName = environment.principalAppName;
  calculateDataToPayment(): void {
    this.showBtnToGenerateNewDispatch = false;
    this.dataPayment = {
      requestWithPayment: this.requestData.dispatchOrders!.length > 0,
      showPaymentBtn: false,
      classPaymentColor: '',
      textPaymentState: (this.requestData?.TotalPayment && this.requestData.TotalPayment > 0 && this.requestData.dispatchOrders!.length == 0) ? 'SIN ASIGNAR' : 'SIN COSTO',
      btnLink: '',
      paymentHistory: []
    }
    if (this.requestData.dispatchOrders!.length == 0) return;
    if (this.requestData.dispatchOrders![0].sessionData == null) return;
    this.getMyIp();
    const btnLink = JSON.parse(this.requestData.dispatchOrders![0].sessionData);
    this.dataPayment.btnLink = btnLink.processUrl;
    const paymentState = this.requestData.dispatchOrders![0].PaymentState;
    const realStatus = this.requestData.dispatchOrders![0].realStatus;
    console.log('**************');
    console.log('realStatus: ',realStatus);
    if ( !paymentState && realStatus == "PENDING" ) {
      this.dataPayment.showPaymentBtn = true;
      this.dataPayment.textPaymentState = 'PENDIENTE';
      // this.validateTimeToGenerateNewDispatchOrder(false);
    }
    // if ( paymentState && realStatus == "APPROVED" ) {
    if ( realStatus == "APPROVED" ) {
      this.dataPayment.textPaymentState = 'PAGADO';
    }
    // if ( !paymentState && realStatus == "PROCESS" ) {
    if ( realStatus == "PROCESS" ) {
      if (this.platformName == 'cedoc') this.dataPayment.showPaymentBtn = true;
      this.dataPayment.textPaymentState = 'EN PROCESO';
      // this.validateTimeToGenerateNewDispatchOrder(false);
    }
    // if ( !paymentState && realStatus == "CANCELLED" ) {
    if ( realStatus == "CANCELLED" || realStatus == "REJECTED" ) {
      this.dataPayment.textPaymentState = 'RECHAZADO';
      // this.validateTimeToGenerateNewDispatchOrder(true);
      this.showBtnToGenerateNewDispatch = true;
    }
    this.calculatePaymentHistory();
    // if (this.platformName.toLowerCase() == "CEDOC".toLowerCase()) {
    //   // if ( !paymentState && realStatus == "PENDING" ) this.dataPayment.showPaymentBtn = true;
    //   // if ( paymentState && realStatus == "APPROVED" ) {
    //   //   this.dataPayment.showPaymentBtn = false;
    //   //   this.dataPayment.textPaymentState = 'PAGADO';
    //   // }
    //   // if ( !paymentState && realStatus == "PROCESS" ) {
    //   //   this.dataPayment.showPaymentBtn = false;
    //   //   this.dataPayment.textPaymentState = 'EN PROCESO';
    //   // }
    // } else {
    //   // if ( !paymentState && realStatus == "PENDING" ) this.dataPayment.showPaymentBtn = true;
    //   // if ( paymentState && realStatus == "APPROVED" ) {
    //   //   this.dataPayment.showPaymentBtn = false;
    //   //   this.dataPayment.textPaymentState = 'PAGADO';
    //   // }
    //   // if ( !paymentState && realStatus == "PROCESS" ) {
    //   //   this.dataPayment.showPaymentBtn = false;
    //   //   this.dataPayment.textPaymentState = 'EN PROCESO';
    //   // }
    //   // if ( !paymentState && realStatus == "CANCELLED" ) {
    //   //   this.dataPayment.showPaymentBtn = false;
    //   //   this.dataPayment.textPaymentState = 'RECHAZADO';
    //   // }
    // }
  }

  calculatePaymentHistory(): void {
    let paymentHistory = {
      id: '',
      date: '',
      state: '',
      gatewayData: false
    }
    this.requestData.dispatchOrders!.forEach((dispatch, index) => {
      paymentHistory = {
        id: dispatch.id,
        date: dispatch.issueDate,
        state: '',
        gatewayData: !!dispatch.gatewayData
      }
      const realStatus = dispatch.realStatus;
      switch (realStatus) {
        case 'PENDING':
          paymentHistory.state = 'PENDIENTE';
          break;
        case 'APPROVED':
          paymentHistory.state = 'PAGADO';
          break;
        case 'PROCESS':
          paymentHistory.state = 'EN PROCESO';
          break;
        case 'CANCELLED':
        case 'REJECTED':
          paymentHistory.state = 'RECHAZADO';
          break;
      }
      this.dataPayment.paymentHistory.push(paymentHistory);
    });
  }

  generateNewDispatchOrder(): void {
    let navigatorName = navigator.userAgent;
    let lastDispatchOrderInfo = this.requestData.dispatchOrders![0];
    this.showBtnToGenerateNewDispatch = false;

    let todayDate = new Date();
    todayDate.setDate(new Date().getDate() + 30);
    let monthDate = todayDate.toISOString();

    const data: DispatchOrderI = {
      id: Number(lastDispatchOrderInfo.UserId.toString() + new Date().valueOf().toString()).toString(),
      DueDate: monthDate,
      issueDate: new Date().toISOString(),
      DispatchCode: lastDispatchOrderInfo.DispatchCode,
      Code: lastDispatchOrderInfo.Code,
      document: lastDispatchOrderInfo.document,
      Address: lastDispatchOrderInfo.Address,
      UserId: lastDispatchOrderInfo.UserId,
      Telephone: lastDispatchOrderInfo.Telephone,
      firsEmail: lastDispatchOrderInfo.firsEmail,
      lastEmail: lastDispatchOrderInfo.lastEmail,
      FirstName: lastDispatchOrderInfo.FirstName,
      lasName: lastDispatchOrderInfo.lasName,
      AssetsAndServicesCatalog: lastDispatchOrderInfo.AssetsAndServicesCatalog,
      Description: lastDispatchOrderInfo.Description,
      EcollectValue: lastDispatchOrderInfo.EcollectValue,
      PaymentState: false,
      paymentDate1: lastDispatchOrderInfo.paymentDate1,
      // paymentDate2: lastDispatchOrderInfo.TotalPayment!,
      fileAdminName: lastDispatchOrderInfo.fileAdminName,
      realStatus: "PENDING",
      gatewayName: lastDispatchOrderInfo.gatewayName,
      requestID: lastDispatchOrderInfo.requestID,
      userAgent: navigatorName,
      ipAddress: this.myIP,
      paidTo: `["${lastDispatchOrderInfo.document!}"]`,
    }
    console.log('DispatchOrderI',data);

    this._requestService.setDispatchOrders( data ).pipe( take(1) ).subscribe({
      next: resultDispatchOrder => {
        console.log( 'generateNewDispatchOrder success', resultDispatchOrder );
        this.updateDataSaved();
      },
      error: (err) => {
        console.log( 'generateNewDispatchOrder error', err );
        this.showBtnToGenerateNewDispatch = true;
      }
    })

    console.log('generateNewDispatchOrder',data);
  }

  myIP = '';
  getMyIp(): void {
    this._functions.getIP().pipe(take(1)).subscribe({
      next: data => {
        console.log('this.myIP', this.myIP);
        console.log('getMyIp success', data);
        let ipList = data.clientIp.split(',');
        console.log('ipList', ipList);
        if (ipList[0].includes(':')) this.myIP = ipList[1].trim();
        else this.myIP = ipList[0].trim();
        console.log('this.myIP', this.myIP);
      },
      error: err => {
        console.log('getMyIp fail', err);
      }
    });
  }

  showBtnToGenerateNewDispatch = false;
  showInfoModal = false;
  urlToPaymentEntityImage = environment.URL_IMAGEN_PASARELA_DE_PAGOS;
  validateTimeToGenerateNewDispatchOrder(isRejected: boolean): void {
    let lastDispatchOrderInfo = this.requestData.dispatchOrders![0];
    const lastDate = new Date(lastDispatchOrderInfo.issueDate);
    const fechaActual = new Date();
    const diferenciaEnMilisegundos = fechaActual.getTime() - lastDate.getTime();
    const diferenciaEnMinutos = Math.floor(diferenciaEnMilisegundos / (1000 * 60));
    if (diferenciaEnMinutos > this.TIME_IN_MINUTES_TO_GENERATE_NEW_PAYMENT_LINK || isRejected) this.showBtnToGenerateNewDispatch = true;
  }

  TIME_IN_MINUTES_TO_GENERATE_NEW_PAYMENT_LINK = environment.TIME_IN_MINUTES_TO_GENERATE_NEW_PAYMENT_LINK;
  showBtnToPayment = true;

  closeModal(): void {
    this.showInfoModal = false;
  }

  procesPayment(): void {
    let dispatchOrderID = this.requestData.dispatchOrders![0].id;
    const data = { realStatus: "PROCESS" }
    this._requestService.patchDispatchOrdersByID( dispatchOrderID, data ).pipe( take(1) ).subscribe({
      next: resultDispatchOrder => {
        console.log( 'procesPayment success', resultDispatchOrder );
        if (this.platformName == 'cedoc') window.open(this.dataPayment.btnLink, '_blank');
        else window.open(this.dataPayment.btnLink, '_self');
      },
      error: (err) => {
        console.log( 'procesPayment error', err );
        this.showBtnToPayment = true;
      }
    });
  }

  /** ****************************** New Code ****************************** */

  closeFloatingBoxUserStudent(): void {
    this._requestWatcherUserService.closeFloatingBoxUserStudent();
  }
}
