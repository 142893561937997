import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { ShowRequestFloatingBox } from 'src/app/models/requestWatcher/showRequestFloatingBox';

@Injectable({
  providedIn: 'root'
})
export class RequestWatcherUserService {

  constructor() { }

  /** ***** ************** ***** */
  /** ***** ************** ***** */
  /** ***** Implementation ***** */
  /** ***** ************** ***** */
  /** ***** ************** ***** */

  // private subscriptionTypeView: Subscription;
  //
  // this.subscriptionTypeView = this.sharedService.sharedtypeViewCards.subscribe(data => {
  //   if (data.typeView)
  //     this.typeView = data.typeView
  //   else {
  //     let tempTypeView = this.sharedService.getTypeView()
  //     if (tempTypeView) {
  //       this.typeView = tempTypeView
  //     }
  //   }
  // });
  //
  // ngOnDestroy() {
  //   this.subscriptionTypeView.unsubscribe();
  // }

  /** ***** *************** ***** */
  /** ***** *************** ***** */
  /** ***** _USER AND ADMIN ***** */
  /** ***** *************** ***** */
  /** ***** *************** ***** */

  /** ***** Logica para volver a cargar toda la info de solicitudes ***** */
  private defaultValueUpdateDataLoaded: boolean = false;
  private updateDataLoadedToShow = new BehaviorSubject( this.defaultValueUpdateDataLoaded );
  updateDataLoadedToShowObservable = this.updateDataLoadedToShow.asObservable();
  manageUpdateDataLoadedToShow(value: boolean) {
    this.updateDataLoadedToShow.next(value);
  }
  closeUpdateDataLoadedToShow() {
    this.updateDataLoadedToShow.next(this.defaultValueUpdateDataLoaded);
  }

  /** ***** ***** ***** */
  /** ***** ***** ***** */
  /** ***** _USER ***** */
  /** ***** ***** ***** */
  /** ***** ***** ***** */

  /** ***** Logica para mostrar 'modal' para crear o visualizar detalles de una solicitud de estudiante ***** */
  private defaultValueShowFloatingBoxUserStudent: ShowRequestFloatingBox = { showBox: false, createView: false, infoView: false };
  private showFloatingBoxUserStudent = new BehaviorSubject( this.defaultValueShowFloatingBoxUserStudent );
  userStudentShowFloatingBox = this.showFloatingBoxUserStudent.asObservable();
  manageFloatingBoxUserStudent(value: ShowRequestFloatingBox) {
    this.showFloatingBoxUserStudent.next(value);
  }
  closeFloatingBoxUserStudent() {
    this.showFloatingBoxUserStudent.next(this.defaultValueShowFloatingBoxUserStudent);
  }

  /** ***** ***** ***** */
  /** ***** ***** ***** */
  /** ***** ADMIN ***** */
  /** ***** ***** ***** */
  /** ***** ***** ***** */

  /** ***** Logica para mostrar 'modal' para crear o visualizar detalles de una solicitud de estudiante ***** */
  private defaultValueShowFloatingBoxAdminStudent: ShowRequestFloatingBox = { showBox: false, createView: false, infoView: false, isMassive: false };
  private showFloatingBoxAdminStudent = new BehaviorSubject( this.defaultValueShowFloatingBoxAdminStudent );
  adminStudentShowFloatingBox = this.showFloatingBoxAdminStudent.asObservable();
  manageFloatingBoxAdmin(value: ShowRequestFloatingBox) {
    this.showFloatingBoxAdminStudent.next(value);
  }
  closeFloatingBoxAdmin() {
    this.showFloatingBoxAdminStudent.next(this.defaultValueShowFloatingBoxAdminStudent);
  }

  /** ***** Logica para cargar las solicitudes del usuario o las de las escuelas ***** */
  private defaultValueLoadRequestToShow: number = 0; // 0 Usuario - 1 Escuela
  private loadRequestToShow = new BehaviorSubject( this.defaultValueLoadRequestToShow );
  loadRequestToShowObservable = this.loadRequestToShow.asObservable();
  updateLoadRequestToShowObservable(value: number) {
    this.loadRequestToShow.next(value);
  }

  /** ***** Logica para guardar todos los typeRequest ***** */
  private defaultValueAllTypeRequest: TypeRequest[] = [];
  private ALL_TYPE_REQUEST = new BehaviorSubject( this.defaultValueAllTypeRequest );
  ALL_TYPE_REQUEST_OBSERVABLE = this.ALL_TYPE_REQUEST.asObservable();
  updateAllTypeRequestObservable(value: TypeRequest[]) {
    this.ALL_TYPE_REQUEST.next(value);
  }

  /** ***** Logica para guardar todos los subTypeRequest ***** */
  private defaultValueAllSubTypeRequest: SubTypeRequest[] = [];
  private ALL_SUB_TYPE_REQUEST = new BehaviorSubject( this.defaultValueAllSubTypeRequest );
  ALL_SUB_TYPE_REQUEST_OBSERVABLE = this.ALL_SUB_TYPE_REQUEST.asObservable();
  updateAllSubTypeRequestObservable(value: SubTypeRequest[]) {
    this.ALL_SUB_TYPE_REQUEST.next(value);
  }

  /** ***** Logica para guardar todas las Schools ***** */
  private defaultValueAllSchools: School[] = [];
  private ALL_SCHOOLS = new BehaviorSubject( this.defaultValueAllSchools );
  ALL_SCHOOLS_OBSERVABLE = this.ALL_SCHOOLS.asObservable();
  updateAllSchoolsObservable(value: School[]) {
    this.ALL_SCHOOLS.next(value);
  }

  /** ***** Logica para guardar todas las Massive Req List ***** */
  private defaultValueAllMasiveReqSelected: Request[] = [];
  private ALL_MASIVE_REQS = new BehaviorSubject( this.defaultValueAllMasiveReqSelected );
  ALL_MASIVE_REQS_OBSERVABLE = this.ALL_MASIVE_REQS.asObservable();
  updateAllMasiveReqObservable(value: Request[]) {
    this.ALL_MASIVE_REQS.next(value);
  }

  /** ***** Logica para actualizar toda la lista de Reqs ***** */
  // private defaultValueAllMasiveReqSelected: Request[] = [];
  // private ALL_MASIVE_REQS = new BehaviorSubject( this.defaultValueAllMasiveReqSelected );
  // ALL_MASIVE_REQS_OBSERVABLE = this.ALL_MASIVE_REQS.asObservable();
  // updateAllMasiveReqObservable(value: Request[]) {
  //   this.ALL_MASIVE_REQS.next(value);
  // }

  /** ***** Logica para actualizar la vista de req admin (Individual, massivo) ***** */
  private defaultValueSectionAdminViewSelected: number = 1;
  private SECTION_ADMIN_TO_VIEW = new BehaviorSubject( this.defaultValueSectionAdminViewSelected );
  SECTION_ADMIN_TO_VIEW_OBSERVABLE = this.SECTION_ADMIN_TO_VIEW.asObservable();
  updateSectionAdminToViewObservable(value: number) {
    this.SECTION_ADMIN_TO_VIEW.next(value);
  }

}
