import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { MultiReq } from 'src/app/models/dbModels/multi-req';
import { CloudFunctions } from 'src/app/services/cloud-functions';
import { RequestService } from 'src/app/services/request.service';
import { XlsxService } from 'src/app/services/xlsx.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'request-bar-masive-list-admin',
  templateUrl: './bar-masive-list-admin.component.html',
  styleUrls: ['./bar-masive-list-admin.component.css']
})
export class BarMasiveListAdminComponent implements OnInit {

  @Input() isListSaved: boolean = false;
  @Input() allDataList: MultiReq = new MultiReq();
  @Output() createMasiveRequest = new EventEmitter<boolean>();
  @Output() showModalReqMembers = new EventEmitter<boolean>();

  public nameBucketCertificateinfo = environment.nameBucketCertificateinfo;

  constructor(
    private _xlsxService: XlsxService,
    private _cloudFunctions: CloudFunctions,
    private _requestService: RequestService,
  ) { }

  ngOnInit(): void {
    /** */
  }

  createRequest() {
    this.createMasiveRequest.emit(true);
  }

  modalReqMembers() {
    this.showModalReqMembers.emit(true);
  }

  exportUserList(){
    let dataToExport: {
      Nombre: string | undefined,
      Fecha: string | undefined,
      "Nombre Curso": string | undefined,
      Pago: string | undefined,
      Estado: string | undefined,
      // Documento: req.requestUserRequester?.Name1,
      "Email Cedoc": string | undefined,
      "Aprobado": string | undefined,
      "Hoja de Vida": string | undefined
    }[] | undefined = []
    console.log(this.allDataList);



    dataToExport = this.allDataList.requests?.map(req => {
      let item = {
        Ticket: req.id,
        Nombre: req.requestUserRequester?.Name1 + ' ' + req.requestUserRequester?.Name2 + ' ' + req.requestUserRequester?.LastName1 + ' ' + req.requestUserRequester?.LastName2,
        Fecha: req.updateDate!.toString().split('T')[0],
        "Nombre Curso": this.allDataList.courseOfer?.NameCourseOfer,
        Pago: req.isPaid ? "Si":"No",
        Estado: req.requestStateReq?.nameState,
        // Documento: req.requestUserRequester?.Name1,
        "Email Cedoc": req.requestUserRequester?.CedocEmail,
        "Aprobado": "",
        "Hoja de Vida": `https://cedoc.edu.co/Cv-Public/${req.requestUserRequester?.CedocEmail}`
      }
      return ( item )
    })
    this.allDataList.requests!.forEach(element => {
      
    });
    console.log(dataToExport);
    this._xlsxService.exportRawToFile('Estudiantes.xlsx', null,  dataToExport!);
  }

  public fileToUpload: File;
  onFileInput(event: any) {
    console.log(event.target.files[0].name);
    this.fileToUpload = <File>event.target.files[0];
    this.fileReader(event);
  }
  public archiveTableHeader: any[];
  public archiveTableData: any[];
  fileReader(event: any) {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const data = <any[]>this._xlsxService.importFromFile(bstr);
      this.archiveTableHeader = data[0];
      this.archiveTableData = data.slice(1);
      console.log("data",data);
      console.log("this.archiveTableHeader",this.archiveTableHeader);
      console.log("this.archiveTableData",this.archiveTableData);
      this.updateDataFromXLS();
    };
    reader.readAsBinaryString(event.target.files[0]);    
  }
  updateDataFromXLS(): void {
    let indexEstado = this.archiveTableHeader.findIndex((element) => element.toString().toLowerCase() == "aprobado")
    let indexTicket = this.archiveTableHeader.findIndex((element) => element.toString().toLowerCase() == "ticket")
    if (indexEstado >= 0 && indexTicket >= 0) {
      this.archiveTableData.forEach(element => {
        console.log('*************************');
        console.log('estado:',element[indexEstado].toLowerCase());
        console.log('estado:',element[indexEstado].toLowerCase() == "si");
        
        let stateNew: number = (element[indexEstado].toLowerCase() == "si") ? 3 : 4;
        if (stateNew == 3) this.generateFile(+element[indexTicket], stateNew, 3);
        else {
          let dataToUpdate = {
            stateReqID: 4
          }
          this.patchRequest(+element[indexTicket], dataToUpdate);
        }
      });
    }
  }
  postData(reqId:number, state: number) {
    /** */
  }
  /** TODO: fix data quemada */
  generateFile(reqId:number, state: number, indexTemplate: number): void {
    let idQR = uuidv4();
    let data = {
      "indexTemplateCert":indexTemplate,
      "idQR":idQR,
      "acronimoEscuela":"ESACE",
      "nombreEscuela":"Escuela de Armas Combinadas del Ejército".toUpperCase(),
      "estudiNombre":"David Trompa",
      "estudiDocNum":"1070999666",
      "estudiDocPlace":"Facatativa",
      "progrmaNombre":"INGENIERIA DE SISTEMAS",
      "nombreElaboro":"Sec David Trompa"
    }
    console.log('generandoArchivo');
    
    this._cloudFunctions.generateFile(data).pipe(take(1)).subscribe({
      next: data => {
        console.log('generateFile success', data);
        if (data.status == "uploaded") {
          let dataToUpdate = {
            stateReqID: state,
            uuid: idQR,
            documentLink: `/attachments/${this.nameBucketCertificateinfo}/download/${data.fileName}`
          }
          this.patchRequest(reqId, dataToUpdate);
        }
      },
      error: err => {
        console.log('generateFile fail', err);
      }
    });
  }
  patchRequest(reqId:number, data: object): void {
    this._requestService.patchRequest(reqId, data).pipe(take(1)).subscribe({
      next: data => {
        console.log('patchRequest success', data);
      },
      error: err => {
        console.log('patchRequest fail', err);
      }
    });
  }

}
