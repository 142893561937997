<div class="parentsContainer text-center" *ngIf="showParentsContainer">
  <ng-container *ngIf="ALL_MY_CHILDS.length > 0; else AcudienteNoChilds">
    <div class="parentsContainer-Childs">
      Seleccione el Estudiante para gestionar solicitudes
      <br>
      <select (change)="onChangeChildSelected()" [(ngModel)]="childSelected">
        <option disabled selected [ngValue]="undefined">Seleccione un estudiante</option>
        <option *ngFor="let child of ALL_MY_CHILDS" [ngValue]="child">
          {{ child.Name1 | uppercase }}
          {{ (child.Name2 || '') | uppercase }}
          {{ child.LastName1 | uppercase }}
          {{ (child.LastName2 || '') | uppercase }}
        </option>
      </select>
    </div>
  </ng-container>
  <ng-template #AcudienteNoChilds>
    <div class="parentsContainer-noChilds">
      No tiene estudiantes asociados.
    </div>
  </ng-template>
</div>
<div class="containerBills" *ngIf="showRequestPanels">
  <div class="btnsContainer">
    <button class="btn-navigation" (click)="goToAnotherUrl('/request')">Solicitudes</button>
  </div>
  <div class="tittleBills">Otras facturas</div>
  <div class="subtitleBills">En esta sección usted encontrará facturas pendientes que no están relacionadas con una solicitud</div>
  <div class="tableBills" *ngIf="ALL_MY_DISPATCH_ORDERS.length > 0; else noDispatchToShow">
    <table class="table table-no-border"><caption></caption>
      <thead>
        <tr>
          <th>ID</th>
          <th>Valor</th>
          <th>Concepto</th>
          <th>Fecha factura</th>
          <th>Estado</th>
          <th>Link de pago</th>
          <th>Fecha de pago</th>
          <th>Detalles</th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr *ngFor ="let dispatch of ALL_MY_DISPATCH_ORDERS" (click)="test(dispatch)"> -->
        <tr *ngFor ="let dispatch of dataToTable" (click)="test(dispatch)">
          <td>{{ dispatch.id }}</td>
          <td>COP ${{ dispatch.valor | number }}</td>
          <td>
            <div>
              {{ dispatch.concepto }}
            </div>
          </td>
          <td>{{ dispatch.fechaFactura | date:'dd/MMM/yyyy' }}</td>
          <td>{{ dispatch.estado }}</td>
          <td>
            <!-- <a class="document-box__action" [href]="'/request/'+item['id']" target="_blank" *ngIf="item['gatewayData']"> -->
            <div class="paymentBtn" (click)="goToPayment(dispatch.linkPago)" *ngIf="dispatch.showLinkPago">
              Pagar
            </div>
          </td>
          <td>{{ dispatch.fechaPago | date:'dd/MMM/yyyy HH:mm' }}</td>
          <td>
            <!-- <a class="document-box__action" [href]="'/request/'+dispatch['id']" target="_blank" *ngIf="item['gatewayData']"> -->
            <!-- <a class="document-box__action" [href]="'/request/'+dispatch['id']" target="_blank" *ngIf="dispatch['gatewayData']"> -->
            <a class="detailsView" [href]="'/request/'+dispatch['id']" target="_blank">
              Ver
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #noDispatchToShow>
    <div class="noTableBillsData">
      <span>No hay datos a su nombre</span>
    </div>
  </ng-template>
</div>
