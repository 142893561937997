import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Observable, Subscription, take } from 'rxjs';
import { NotificationQueue } from 'src/app/models/dbModels/notificationQueue';
import { ReqMembers } from 'src/app/models/dbModels/req-members';
import { Request } from 'src/app/models/dbModels/request';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';
import { StageReq } from 'src/app/models/dbModels/stageReq';
import { StageReqHistory } from 'src/app/models/dbModels/stageReqHistory';
import { User } from 'src/app/models/dbModels/user';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'masive-edition-floating-box',
  templateUrl: './masive-edition-floating-box.component.html',
  styleUrls: ['./masive-edition-floating-box.component.css']
})
export class MasiveEditionFloatingBoxComponent implements OnInit, OnDestroy {

  private subscriptionGetAllMassiveReqListSelected: Subscription;
  public ALL_MASSIVE_REQ_LIST: Request[] = [];
  public msgToSendToAllReq: string = "";
  public ID_USER_LOGUED: number = 0;

  public PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  /** ******************** *********** ******************** */
  /** ******************** POR REVISAR ******************** */
  /** ******************** *********** ******************** */

  stageReqHistoryDescription: string = '';

  public viewType: number = 3;

  public showDocumentSelector: boolean = false;

  constructor(
    private _requestWatcherUserService: RequestWatcherUserService,
    private _authService: AuthService,
    private _requestService: RequestService,
    private _sweetalertService: SweetalertService,
  ) {
    this.ID_USER_LOGUED = Number( this._authService.getCurrentUserID() );
  }

  ngOnInit(): void {
    this.subscriptionGetAllMassiveReqListSelected = this._requestWatcherUserService.ALL_MASIVE_REQS_OBSERVABLE.subscribe((data: Request[]) => {
      console.log('ALL_MASSIVE_REQ_LIST', data);
      this.ALL_MASSIVE_REQ_LIST = data;
    });
    this.validateReasigned();
  }

  ngOnDestroy() {
    this.subscriptionGetAllMassiveReqListSelected.unsubscribe();
  }

  closeFloatingBoxAdmin(): void {
    this._requestWatcherUserService.closeFloatingBoxAdmin();
  }

  sendMessageToStudent(): void {
    console.log('sendMessageToStudent');

    if (this.msgToSendToAllReq.trim() == "") return;

    let listChatMsgObserbable: Observable<RequestHistory>[] = [];

    this.ALL_MASSIVE_REQ_LIST.forEach(element => {
      const requestHistoryData: RequestHistory = {
        publicComment: this.msgToSendToAllReq,
        updateDate: new Date( Date.now() ),
        stageReqID: element.stageReqID,
        stateReqID: element.stateReqID,
        requestID: element.id,
        userID: this.ID_USER_LOGUED,
      }
      listChatMsgObserbable.push(this._requestService.createRequestHistory( requestHistoryData ).pipe( take( 1 ) ));
    });

    if (listChatMsgObserbable.length == 0) return;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listChatMsgObserbable).subscribe({
      next: ( data ) => {
        console.log( 'sendMessageToStudent success', data );
        this.msgToSendToAllReq = "";
        this._sweetalertService.swalClose();
        // this.postNotificationQueue( `El estado interno de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( 'sendMessageToStudent fail', err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }

  postNotificationQueue( text: string ): void { // TODO
    // let newDate: Date = new Date(new Date().getTime());
    // let queueNotificationQueueObserbable: any[] = [];
    // if (this.PRINCIPAL_APP_NAME == 'cedoc') {
    //   const data: NotificationQueue = {
    //     emailsTo: `["${this.requestData.requestUserRequester?.CedocEmail}"]`,
    //     namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
    //     msg: `[{"message":"${ text }"}]`,
    //     timeToSend: newDate.toISOString(),
    //     isSend: false,
    //     isSingleMessage: true,
    //     typeNotification: 'email'
    //   }
    //   queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( data ).pipe( take( 1 ) ) );
    // } else {
    //   if (this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.parentUser) {
    //     let userDataToPaymentParent = this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.parentUser;
    //     const dataParent: NotificationQueue = {
    //       emailsTo: `["${ userDataToPaymentParent.email || userDataToPaymentParent.CedocEmail }"]`,
    //       namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
    //       msg: `[{"message":"${ text }"}]`,
    //       timeToSend: newDate.toISOString(),
    //       isSend: false,
    //       isSingleMessage: true,
    //       typeNotification: 'email'
    //     }
    //     queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataParent ).pipe( take( 1 ) ) );
    //   }
    //   if (this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.attendantUser) {
    //     let userDataToPaymentAttendant = this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.attendantUser;
    //     const dataAttendant: NotificationQueue = {
    //       emailsTo: `["${ userDataToPaymentAttendant.email || userDataToPaymentAttendant.CedocEmail }"]`,
    //       namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
    //       msg: `[{"message":"${ text }"}]`,
    //       timeToSend: newDate.toISOString(),
    //       isSend: false,
    //       isSingleMessage: true,
    //       typeNotification: 'email'
    //     }
    //     queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataAttendant ).pipe( take( 1 ) ) );
    //   }
    // }
    // if (queueNotificationQueueObserbable.length == 0) return;
    // forkJoin([
    //   ...queueNotificationQueueObserbable
    // ]).subscribe( {
    //   next: data => {
    //     console.log( 'postNotificationQueue success', data );
    //   },
    //   error: err => {
    //     console.log( 'postNotificationQueue fail', err );
    //   }
    // } );
  }

  updateDataSaved(): void {
    console.log('updateDataSaved Method');
    // this.updateData.emit( true );
    // Use service watcher to update data posted
  }

  updateStateReq(stateId: number): void {
    console.log('updateStateReq');

    let listObserbableData: Observable<any>[] = [];

    this.ALL_MASSIVE_REQ_LIST.forEach(element => {
      let data: { stateReqID: number, userCurrentID: number, reqMembersIndex: number | null } = {
        stateReqID: stateId,
        userCurrentID: element.userCurrentID!,
        reqMembersIndex: null
      } /** TODO: updated date */
      if ([2, 3].includes(stateId)) data.userCurrentID = element.userOwnerID!;
      if (element.reqMembers?.length! > 0) data.reqMembersIndex = 1;
      listObserbableData.push( this._requestService.patchRequest( element.id, data ).pipe( take( 1 ) ) );
    });

    if (listObserbableData.length == 0) return;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listObserbableData).subscribe({
      next: ( data ) => {
        console.log( 'updateStateReq success', data );
        this.ALL_MASSIVE_REQ_LIST[0].stateReqID = stateId;
        this._sweetalertService.swalClose();
        // TODO: this.postNotificationQueue( `El estado interno de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
        // TODO: this.autoChatMsg( "El estado de su solicitud se ha actualizado" );
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( 'updateStateReq fail', err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }

  updateStageReq(stageData: StageReq): void {
    console.log('updateStageReq');
    if (this.stageReqHistoryDescription.trim() == '' || this.stageReqHistoryDescription.length < 10) {
      this._sweetalertService.swalError('Error','Ingrese una descripción (Obligatorio, mínimo 10 caracteres).', () => {});
      return;
    }

    let listObserbableData: Observable<any>[] = [];

    this.ALL_MASSIVE_REQ_LIST.forEach(element => {
      const data = {
        stageReqID: stageData.id, updateDate: new Date()
      } /** TODO: updated date */
      listObserbableData.push( this._requestService.patchRequest( element.id, data ).pipe( take( 1 ) ) );

      const requestHistoryData: RequestHistory = {
        // publicComment: `El estado interno de su solicitud con ticket ${element.id} se ha actualizado.`, TODO: ---> esto es para el email a enviar
        publicComment: `El estado interno de su solicitud ha cambiado (${stageData.nameStage}), con comentario: ${this.stageReqHistoryDescription.trim()}`,
        updateDate: new Date( Date.now() ),
        stageReqID: stageData.id,
        stateReqID: element.stateReqID,
        requestID: element.id,
        userID: this.ID_USER_LOGUED,
      }
      listObserbableData.push(this._requestService.createRequestHistory( requestHistoryData ).pipe( take( 1 ) ));

      let stageReqHistoryData: StageReqHistory = {
        description: this.stageReqHistoryDescription.trim(),
        stageReqID: stageData.id,
        requestID: element.id,
        createdAt: new Date().toISOString(),
        createdBy: Number( this._authService.getCurrentUserID() ),
      }
      listObserbableData.push(this._requestService.createStageReqHistories( stageReqHistoryData ).pipe( take( 1 ) ));
    });

    if (listObserbableData.length == 0) return;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listObserbableData).subscribe({
      next: ( data ) => {
        console.log( 'updateStageReq success', data );
        this.stageReqHistoryDescription = '';
        this.ALL_MASSIVE_REQ_LIST[0].stageReqID = stageData.id;
        this._sweetalertService.swalClose();
        // TODO: this.postNotificationQueue( `El estado interno de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( 'updateStageReq fail', err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }

  aproveReq(): void {
    /** TODO: review, is this usefull to celic logic? */
  }

  stateToConfirm = 0;
  protected showConfirmChangeStateModal: boolean = false;
  showModalConfirmChangeStateValue(value: number): void {
    this.stateToConfirm = value;
    this.showConfirmChangeStateModal = true;
  }
  confirmChangeStateValue(): void {
    this.updateStateReq(this.stateToConfirm);
    this.showConfirmChangeStateModal = false;
  }

  updatePayment(isPaidValue: boolean): void {
    console.log('updateStateReq');

    let listObserbableData: Observable<any>[] = [];

    this.ALL_MASSIVE_REQ_LIST.forEach(element => {
      const data = {
        isPaid: isPaidValue
      } /** TODO: updated date */
      listObserbableData.push( this._requestService.patchRequest( element.id, data ).pipe( take( 1 ) ) );
    });

    if (listObserbableData.length == 0) return;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin(listObserbableData).subscribe({
      next: ( data ) => {
        console.log( 'updateStateReq success', data );
        this.ALL_MASSIVE_REQ_LIST[0].isPaid = isPaidValue;
        this._sweetalertService.swalClose();
        // TODO: this.postNotificationQueue( `El estado interno de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
        // TODO: this.autoChatMsg( "El estado de su solicitud se ha actualizado" );
      },
      error: ( err ) => {
        console.log( 'updateStateReq fail', err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }


  /** Individual ******************************************************************* */
  idToaprveAsigned = 0;
  public showAproveBtns: boolean = true;
  public reqMemberComment: string = '';

  updateReqMemberAprove(response: boolean): void {
    if (this.idToaprveAsigned == 0) return;
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let listObservable: any = {};

    let dataUpdateMyReqMember = {
      isAproved: response,
      comment: this.reqMemberComment,
      processedAT: new Date().toISOString()
    };
    
    this.ALL_MASSIVE_REQ_LIST.forEach( (element, indexMassive) => {
      let actuallyReqMembersToReview: ReqMembers[] = element.reqMembers!.filter( member => (member.numSequence == element.reqMembersIndex) );
      let nextReqMembersToReview: ReqMembers[] = element.reqMembers!.filter( member => (member.numSequence == element.reqMembersIndex!+1) );
      let userIdLogued = Number(this._authService.getCurrentUserID());
      let allMembersInMyStepHaveARespond: boolean = true;
      let userIdPendindToResponse: number = 0;
      if (actuallyReqMembersToReview.length > 1) {
        actuallyReqMembersToReview.forEach(member => {
          if (member.isAproved == null && member.userID != userIdLogued ) {
            allMembersInMyStepHaveARespond = false;
            userIdPendindToResponse = member.userID!;
          }
        });
      }
      if (allMembersInMyStepHaveARespond && nextReqMembersToReview.length > 0) {
        let dataUpdateNextReqMember = {
          isAdmin: 1,
          assignedAT: new Date().toISOString()
        };
        nextReqMembersToReview.forEach((member, index) => {
          userIdPendindToResponse = member.userID!;
          listObservable[`UPDATE_NEXT_REQ_MEMBER_0${index}`] = this._requestService.updateReqMembers( member.id!, dataUpdateNextReqMember ).pipe( take( 1 ) );
        });
      }
  
      let actuallyNumSequence: number = element.reqMembersIndex!;
      if (!allMembersInMyStepHaveARespond) actuallyNumSequence = element.reqMembersIndex!;
      else if (nextReqMembersToReview.length > 0) actuallyNumSequence++;
      else actuallyNumSequence = 0;
      let dataToUpdateNextUserInRequest = {
        userCurrentID: ( (actuallyNumSequence == 1) ? element.userOwnerID : userIdPendindToResponse),
        reqMembersIndex: actuallyNumSequence
      };
      listObservable[`PACTCH_REQUEST_0${indexMassive}`] = this._requestService.patchRequest( element.id, dataToUpdateNextUserInRequest ).pipe( take( 1 ) );
    });
    forkJoin(listObservable).subscribe( {
      next: ( data ) => {
        console.log( 'updateReqMemberAprove success', data );
        this.showAproveBtns = false;
        this._sweetalertService.swalClose();
        this.updateDataSaved();
        this.closeFloatingBoxAdmin();
      },
      error: ( err ) => {
        console.log( "ERROR updateReqMemberAprove", err );
        this._sweetalertService.swalError('Error','Intentelo nuevamente, si el error persiste, contacte a soporte.', () => {})
      },
      complete: () => {
        console.log( 'complete updateReqMemberAprove' );
      }
    });

  }

  getNameUser( user: User ) {
    let name = "";
    name = `${ user.Name1 ? user.Name1 : '' } ${ user.Name2 ? user.Name2 : '' } ${ user.LastName1 ? user.LastName1 : '' } ${ user.LastName2 ? user.LastName2 : '' }`
    return name;
  }

  getReqMembersState(isAproved: null | boolean | undefined): string {
    let state = '';
    if (isAproved === true) state = (this.PRINCIPAL_APP_NAME == 'celic' ? 'Cumple' : 'Aprobado');
    else if (isAproved === false) state = (this.PRINCIPAL_APP_NAME == 'celic' ? 'No cumple' : 'Rechazado');
    else if (isAproved === null) state = 'Pendiente';
    return state;
  }

  getLastAdminDate(reqMember: ReqMembers): Date | string {
    let date: Date | string;
    if (reqMember.numSequence == 1) date = this.ALL_MASSIVE_REQ_LIST[0].registerDate;
    else {
      let userReqMemberFinded = this.ALL_MASSIVE_REQ_LIST[0].reqMembers?.find(userReqMember => userReqMember.numSequence == reqMember.numSequence!-1);
      date = userReqMemberFinded?.processedAT!
    }
    return date;
  }

  validateReasigned() {
    this.showAproveBtns = false;
    if (
      !this.ALL_MASSIVE_REQ_LIST[0] ||
      !this.ALL_MASSIVE_REQ_LIST[0].reqMembers ||
      this.ALL_MASSIVE_REQ_LIST[0].reqMembers.length == 0 ||
      !this.ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest ||
      !this.ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest.reqMembersTemplate ||
      this.ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest.reqMembersTemplate.length == 0
    ) return;

    let loguedAdminId = Number( this._authService.getCurrentUserID() );
    let findedPositionInReqMembers = this.ALL_MASSIVE_REQ_LIST[0].reqMembers?.findIndex( member => (member.userID == loguedAdminId && member.isAproved == null) );
    let numberSequenceInReqMembers: number | undefined = undefined;
    if (findedPositionInReqMembers == -1) return;
    this.idToaprveAsigned = this.ALL_MASSIVE_REQ_LIST[0].reqMembers![findedPositionInReqMembers!].id!;
    numberSequenceInReqMembers = this.ALL_MASSIVE_REQ_LIST[0].reqMembers![findedPositionInReqMembers!].numSequence;

    let countTotalNoAproved = 0;
    let countTotalAproved = 0;
    let countTotalNull = 0;

    for (const itemItertated of this.ALL_MASSIVE_REQ_LIST[0].reqMembers) {
      if (itemItertated.isAproved === null) countTotalNull++;
      else if (itemItertated.isAproved === false) countTotalNoAproved++;
      else if (itemItertated.isAproved === true) countTotalAproved++;
    }
    if ( numberSequenceInReqMembers == (countTotalAproved + 1) ) this.showAproveBtns = true;
  }

}
