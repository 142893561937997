<div class="modal-bill-summary">
  <div class="modal-bill-summary__header">
    <div class="modal-bill-summary__images">
      <img [src]="SCHOOL_LOGO" alt="" class="modal-bill-summary__image">
      <img [src]="SCHOOL_LOGO" alt="" class="modal-bill-summary__image">
    </div>
    <div class="modal-bill-summary__items">
      <div class="header-item">
        <div class="header-item__label">
          N¬ Convienio
        </div>
        32930
      </div>
      <div class="header-item">
        <div class="header-item__label">
          N¬ Factura
        </div>
        54368
      </div>
      <div class="header-item">
        <div class="header-item__label">
          Código de consignación
        </div>
        cemlilarr245
      </div>
    </div>
  </div>
  <div class="modal-bill-summary__info">
    <div class="modal-bill-summary__title">
      Certificados de contenidos curriculares
    </div>
    <div class="modal-bill-summary__descript">
      Certificando que usted hizo y aprobó los contenidos curriculares
    </div>
    <div class="modal-bill-summary__aplicant-info">
      <div class="modal-bill-summary__aplicant-info-title">Información del solicitante</div>
      <div class="aplicant-info-item">
        <div class="aplicant-info-item__label">
          Solitante: 
        </div>
        Eduardo Rosalío Correas Quinteras
      </div>
      <div class="aplicant-info-item">
        <div class="aplicant-info-item__label">
          Número celular: 
        </div>
        305 662 6666
      </div>
      <div class="aplicant-info-item">
        <div class="aplicant-info-item__label">
          E-mail: 
        </div>
        hydronplane@cedoc.com
      </div>
      <div class="aplicant-info-item">
        <div class="aplicant-info-item__label">
          Cédula: 
        </div>
        1062066123
      </div>
    </div>
    <div class="modal-bill-summary__separator"></div>
    <div class="modal-bill-summary__values-list">
      <div class="value-item">
        <div class="value-item__info">
          <div class="value-item__title">Valor bruto</div>
          <div class="value-item__detail">Detalle</div>
        </div>
        <div class="value-item__value">$ 470.00.00</div>
      </div>
      <div class="value-item">
        <div class="value-item__info">
          <div class="value-item__title">Valor de cobro</div>
          <div class="value-item__detail">Detalle</div>
        </div>
        <div class="value-item__value">$ 470.00.00</div>
      </div>
      <div class="value-item">
        <div class="value-item__info">
          <div class="value-item__title">Valor de IVA</div>
          <div class="value-item__detail">Detalle</div>
        </div>
        <div class="value-item__value">$ 0.00</div>
      </div>
      <div class="value-item">
        <div class="value-item__info">
          <div class="value-item__title">Valor total</div>
          <div class="value-item__detail">Detalle</div>
        </div>
        <div class="value-item__value">$ 470.00.00</div>
      </div>
    </div>
  </div>
  <div class="modal-bill-summary__actions">
    <button class="modal-bill-summary__button">Pagar por PSE</button>
    <i class="modal-bill-summary__download icon-i-tutorial"></i>
    <img src="https://qrcode.tec-it.com/API/QRCode?data=smsto%3A555-555-5555%3AGenerador+de+Códigos+QR+de+TEC-IT" alt="" class="modal-bill-summary__qr">
  </div>
</div>
