<div class="response-form" *ngIf="userIntcallAnswer?.UserIntcallFieldAnswers?.length > 0">
  <div class="response-form__left-panel">
    <div class="left-panel__header">
      <div class="header-profile__content">
        <img class="main-profile__avatar"
          src="{{ userIntcallAnswer.Userapp.Photo }}"
          alt="avatar">
        <div class="profie-info">
          <p class="text text--bold text--lg">
            Título: <span class="text--normal">{{ intCallData.nameIntCall }}</span> 
          </p>
          <p class="text text--bold text--md">Responsable 
            <span class="text--normal">{{ userIntcallAnswer.Userapp.Name1 }} {{ userIntcallAnswer.Userapp.LastName1 }}</span>
          </p>
        </div>
      </div>
      <p class="text">Fecha de creación 
        <span class="text--bold">
          {{ ( intCallData.dateStart | date: 'dd/MMM/yyyy' ) || '-' }}
        </span>
      </p>
      <p class="text">Fecha de finalización 
        <span class="text--bold">
          {{ ( intCallData.dateEnd | date: 'dd/MMM/yyyy' ) || '-' }}
        </span>
      </p>
      <a [routerLink]="['/Cv-Public', userIntcallAnswer.Userapp.email ]" class="link" target="_blank">Ver hoja de vida</a>
    </div>

    <div class="response-form__response-list" #responseList>
      <h2 class="text text--bold text--md">Lista de objetivos</h2>

      <ng-container *ngFor="let field of improvementPlanModule.intcallFields">
        <ng-container *ngFor="let answer of userIntcallAnswer.UserIntcallFieldAnswers">
          <div class="response-card" *ngIf="answer.intcallFieldID === field.id">
            <label class="checkbox-container" 
              id="checkbox-response__{{ answer.GradeIntcallFieldAnswers?.[0].id }}">
              <input type="checkbox" 
                class="checkbox-input" 
                [checked]="answer.GradeIntcallFieldAnswers?.[0]?.grade === 1"
                (change)="setAnswerState( answer.GradeIntcallFieldAnswers?.[0].id, $event )"
              >
              <span class="checkbox-checkmark"></span>
              {{ field.nameField }}
            </label>

            <h2 class="text text--bold">Acciones</h2>
            <textarea class="response-field" readonly name="" id="" rows="4">{{ answer.value }}</textarea>
          </div>
        </ng-container>
      </ng-container>
    </div>

  </div>

  <div class="response-form__right-panel">
    <div class="right-panel__header">
      <h2 class="text text--lg text--bold text--blue">Seguimiento y calificación</h2>
      <span class="state-pill text">
        Estado 
        <span class="text--bold" [ngClass]=" !userIntcallAnswer.isFinalGrade ? 'text--green' : 'text--orange' ">
          {{ !userIntcallAnswer.isFinalGrade ? 'Activo' : 'Finalizado' }} <!-- TODO: COMO SE CALCULA ESTO -->
        </span>
      </span>
    </div>

    <form class="field-list" [formGroup]="ratingForm">

      <div class="left-panel__input__control" *ngFor="let controlName of ['grade', 'effectiveness', 'efficiency']">
        <ng-container [ngTemplateOutlet]="inputLabels" [ngTemplateOutletContext]="{ field: controlName }"></ng-container>
        <input class="left-panel__input" 
          type="number" placeholder=" % " 
          [formControlName]="controlName" 
          [readOnly]="!isJudge || userIntcallAnswer.isFinalGrade"
          (change)="limitInputValue( $event )">

        <small class="invalid-input__text" *ngIf="validateForm && ratingForm.get( controlName )?.invalid" >Este campo es obligatorio</small>
      </div>

      <button class="button button--apply" 
        (click)="showConfirmModal = true" 
        [ngClass]="{ 'button--disabled': !isJudge || userIntcallAnswer.isFinalGrade }">
        Calificar
      </button>
    </form>

    <div class="actions-section">
      <div class="actions-section_header">
        <em class="icon-i-chat message-icon"></em>
        <h2 class="text text--md text--bold">Retroalimentación</h2>
      </div>

      <div class="comments-container">
        <div class="comments-control">
          <textarea class="comments-input" name="" id="" rows="1" placeholder="Escribe algo" [(ngModel)]="commentMessage"></textarea>
          <button class="button" [ngClass]="{ 'disabled': sendingMessage }" (click)="createComment()">
            <em class="icon-i-send plane-icon"></em>
          </button>
        </div>

        <div class="message-list">
          <div class="message-card" *ngFor="let comment of this.fieldAnswerForComments.CommentsIntcallFieldAnswers">
            <img class="message-profile__avatar"
              src="{{ comment?.Userapp.Photo }}"
              alt="avatar">
            <div class="message-profile__content">
              <div class="message-profile__info">
                <p class="text text--bold">{{ comment?.Userapp.Name1 }} {{ comment?.Userapp.LastName1 }}</p>
                <p class="text text--gray">{{ comment?.Userapp.email }}</p>
              </div>
              <p class="text">{{ comment.comment }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="files-container">
        <div class="section-files__header">
          <em class="icon-i-documents files-icon"></em>
          <h2 class="text text--md text--bold">Carga de archivos</h2>
        </div>
        <p class="text text--sm">
          En esta sección uste podrá subir los documentos necesearios para el plan de mejora o soporte.
        </p>

        <div class="file-box__container" *ngIf="isJudge && !userIntcallAnswer.isFinalGrade">
          <upload-file-box
            [containerName]="'internalcalldocs'"
            [getTypeDocumentsToUpload]="'application/pdf'"
            (responseDataFile)="getAndSetFilesToUpload( $event )"
          ></upload-file-box>
        </div>

        <div class="document-list" [ngStyle]="{'margin-top.px': !isJudge || userIntcallAnswer.isFinalGrade ? 20 : 0 }">
          <h2 class="text text--md">Documentos subidos ( {{ filesFromDb.length }} )</h2>
          <ng-container *ngFor="let file of filesFromDb" 
            [ngTemplateOutlet]="filesTemplate"
            [ngTemplateOutletContext]="{ item: file }"
          >
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="confirm-modal" *ngIf="showConfirmModal">
    <div class="confirm-modal__inner">
      <em class="icon-i-late-course-directive warning-icon"></em>
      <h2 class="modal__title">Calificación</h2>
      <!-- <p class="modal__paragraph">El procentaje de cumplimiento es del <span class="text--bold">80%</span> </p> -->
      <p class="modal__paragraph">Por favor, confirme que desea continuar con esta acción.</p>

      <div class="confirm-modal__actions">
        <button class="button button--cancel" (click)="showConfirmModal = false">CANCELAR</button>
        <button class="button button--confirm" [ngClass]="{ 'disabled': sendingRating }" (click)="setGrades()">CALIFICAR</button>
      </div>
    </div>
  </div>
</div>

<ng-template #filesTemplate let-item="item">
  <div class="document-item">
    <p class="text text--sm text--gray">Enviado {{ item.createdAt | date:'dd/MMM/yyyy' }}</p>
    <div class="document-item__header">
      <div class="document-item__header-container">
        <em class="icon-i-doc document-icon"></em>
        <div class="document-item__header-info">
          <h2 class="text text--bold text--md">{{ item.nameDocument }}</h2>
          <a [href]="urlBackV1 + item.documentLink" target="_blank" class="link link--white link--sm">Ver documento</a>
        </div>
      </div>

      <button class="button button--delete" (click)="removeFile( item.id )" *ngIf="isJudge && !userIntcallAnswer.isFinalGrade">
        <em class="icon-i-close delete-file__icon"></em>
      </button>
    </div>

    <div class="document-item__info" *ngIf="item.desc">
      <h2 class="text text--md text--bold">Descripción del documento</h2>
      <p class="text">{{ item.desc }}</p>
    </div>
  </div>
</ng-template>

<ng-template let-field="field" #inputLabels>
  <label class="text" for="" *ngIf="field === 'grade'">Grado de cumplimiento</label>
  <label class="text" for="" *ngIf="field === 'effectiveness'">Porcentaje de efectividad</label>
  <label class="text" for="" *ngIf="field === 'efficiency'">Porcentaje de eficiencia</label>
</ng-template>