import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { UserCourse } from '../models/dbModels/userCourse';

@Injectable({
  providedIn: 'root'
})
export class UserCourseService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getCoursesOffer(filter?: string): Observable<UserCourse[]> {
    let url: string = `/UserCourses`
    if (filter)
      url = `/UserCourses?filter=${filter}`

    return this._httpService.httpGet(url);
  } 

}


