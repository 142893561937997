
<div>
  <div class="selectContainer">
    <span>Escuela*</span><br>
    <select (change)="onChangeSchool()" [(ngModel)]="schoolSelected" required>
      <option disabled [ngValue]="undefined">SELECCIONAR ESCUELA</option>
      <option *ngFor="let school of ALL_MY_SCHOOLS" [ngValue]="school">{{school.NameTSchool}}</option>
    </select>
  </div>
  <div class="selectContainer" *ngIf="schoolSelected">
    <span>Oferta*</span><br>
    <select (change)="onChangeCourseOffer()" [(ngModel)]="courseOfferSelected" required>
      <option disabled [ngValue]="undefined">SELECCIONAR OFERTA</option>
      <option *ngFor="let courseOffer of ALL_ACTIVE_COURSES_OFFER" [ngValue]="courseOffer">{{courseOffer.NameCourseOfer }}</option>
    </select>
  </div>
  <div class="selectContainer" *ngIf="courseOfferSelected">
    <span>Sub tipo de solicitud*</span><br>
    <select (change)="onChangeSubTypeRequest()" [(ngModel)]="SubTypeRequestSelected" required>
      <option disabled [ngValue]="undefined">SELECCIONAR SUB TIPO</option>
      <option *ngFor="let subTypeRequest of ALL_SUBTYPE_REQUEST" [ngValue]="subTypeRequest">{{subTypeRequest.nameSubTypeRequest }}</option>
    </select>
  </div>
  <div *ngIf="schoolSelected && courseOfferSelected && SubTypeRequestSelected">
    Esta es la lista completa de los alumnos aceptados en esta inscripción:
    <button class="btn btn-primary" (click)="confirmarOAP()">Confirmar OAP</button>
    <div *ngFor="let student of studentlistForOAP">
      {{ student.requestUserRequester?.LastName1 }} {{ student.requestUserRequester?.LastName2 ? student.requestUserRequester?.LastName2! : '' }} {{ student.requestUserRequester?.Name1 }} {{ student.requestUserRequester?.Name2 ? student.requestUserRequester?.Name2! : '' }}
    </div>
  </div>
</div>