<div class="tag-container">
  <div class="tag" [ngStyle]="{'background-color': color}">
    <div class="tag__title">{{stageName}}</div>
    <div class="tag__date" *ngIf="date">DESDE {{date | date: 'dd/MMM/yyyy'}}</div>
  </div>
  <div class="download-button" *ngIf="file" [ngStyle]="{'background-color': color}">
    <a [href]="urlBackV1 + file" target="_blank"><em class="download-button__icon icon-i-download"></em></a>
  </div>
</div>
<a class="tag-link" *ngIf="activeChatButton">Mensaje nuevo</a>
<div class="reasigned-text" *ngIf="reasigned">
  <div class="reasigned-text__label">Reasignado por:</div>
  {{reasigned}}
</div>
<br>
<div class="summary-state" *ngIf="paymentStatus != 'SIN COSTO'">
  <div class="summary-state__label">Estado del pago</div>
  <div class="summary-state__stateContainer">
    <div class="summary-state__stateContainer-circle"
      [ngClass]="{
        'stateContainer-circle--green': paymentStatus === 'PAGADO',
        'stateContainer-circle--orange': ( paymentStatus === 'EN PROCESO' || paymentStatus === 'PENDIENTE' ),
        'stateContainer-circle--red': paymentStatus === 'RECHAZADO'
      }"
    ></div>
    <div class="summary-state__stateContainer-Text">{{ paymentStatus }}</div>
  </div>
</div>
