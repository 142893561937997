import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { SubTypeRequest } from '../models/dbModels/subTypeRequest';

@Injectable({
  providedIn: 'root'
})
export class SubTypesRequestService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getSubTypeRequest(filter?: string): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest`
    if (filter)
      url = `/SubTypesRequest?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }

  getSubTypeRequestWithTypeRequestPlaceholder(): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest?filter={
      "include": [
        "subTypeRequestPlaceholders",
        { "reqMembersTemplate":"reqMemberTemplateStaff" }
      ],
      "order": "nameSubTypeRequest ASC"
    }`;
    return this._httpService.httpGet(url);
  }

  getSubTypeRequestForOAP(schoolId: number): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest?filter={
      "where":{
        "and":[
          {"typeRequestID":1},
          {"schoolID":${schoolId}}
        ]
      }
    }`
    return this._httpService.httpGet(url);
  }
}
