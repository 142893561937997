import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'request-celic-gou',
  templateUrl: './celic-gou.component.html',
  styleUrls: ['./celic-gou.component.css']
})
export class CelicGouComponent implements OnInit {

  @Output() closeModalOutput = new EventEmitter<boolean>();

  constructor() {/** */}

  ngOnInit(): void {/** */}

  closeModal(): void {
    this.closeModalOutput.emit( true );
  }

}
