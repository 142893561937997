import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-payment-result',
  templateUrl: './payment-result.component.html',
  styleUrls: ['./payment-result.component.css']
})
export class PaymentResultComponent implements OnInit {

  private PRINCIPAL_APP_NAME: string = environment.principalAppName.toLowerCase();
  private dispatchOrderID: string = '';
  private dispatchOrderData: DispatchOrderI;

  constructor(
    private _activedRoute: ActivatedRoute,
    private _requestService: RequestService,
    private _router: Router,
    private _authService: AuthService,
    private _sweetalertService: SweetalertService,
  ) {
    this.validateUserLoggued();
  }

  ngOnInit(): void {
    this._activedRoute.params.subscribe(params => {
      if (params["id"]) {
        this.dispatchOrderID = params["id"];
        this.getDispatchOrder();
      }
    });
  }

  validateUserLoggued(): void {
    const UserID = this._authService.getCurrentUserID();
    if (UserID == null) this._router.navigate(['/landing']);
  }

  getDispatchOrder(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this._requestService.getDispatchOrdersByID( this.dispatchOrderID ).pipe( take(1) ).subscribe({
      next: resultDispatchData => {
        console.log('result: ',resultDispatchData);
        this.dispatchOrderData = resultDispatchData;
        if (this.PRINCIPAL_APP_NAME == 'celic') this.validate_Celic_DataToShow();
        else this.validate_Cedoc_DataToShow();
      },
      error: (err) => {
        console.log( 'getDispatchOrder error: ', err );
        this._router.navigate(['/request']);
      },
      complete: () => {
        this._sweetalertService.swalClose();
      },
    });
  }

  getPaymentStateSpanishString(status): string {
    let spanishState = '';
    switch (status) {
      case 'PENDING':
        spanishState = 'PENDIENTE';
        break;
      case 'APPROVED': // CELIC
      case 'SUCCESS':  // CEDOC
        spanishState = 'PAGADO';
        break;
      case 'PROCESS':
        spanishState = 'EN PROCESO';
        break;
      case 'CANCELLED':
      case 'REJECTED':
        spanishState = 'RECHAZADO';
        break;
    }
    return spanishState;
  }

  validate_Celic_DataToShow(): void {
    const dataInObject = JSON.parse(this.dispatchOrderData.gatewayData!);
    this.dataToShow.nombre = `${dataInObject?.request?.buyer?.name} ${dataInObject?.request?.buyer?.surname}`;
    this.dataToShow.status = this.getPaymentStateSpanishString( dataInObject?.status?.status );
    this.dataToShow.fecha = this.dispatchOrderData.paidDate!;
    this.dataToShow.descripcion = dataInObject?.request?.payment?.description;
    this.dataToShow.valor = this.dispatchOrderData.TotalPayment!;
    this.dataToShow.referencia = this.dispatchOrderData.id;
    this.dataToShow.msg = dataInObject?.status?.message;
    if (dataInObject?.payment?.length > 0) {
      this.dataToShow.medioDePago = dataInObject?.payment[0].paymentMethodName;
      this.dataToShow.autorizacion = this.dispatchOrderData.TrazabilityCode!;
    } else {
      this.dataToShow.showMsg = true;
    }
  }

  validate_Cedoc_DataToShow(): void {
    const dataInObject = JSON.parse(this.dispatchOrderData.gatewayData!);
    this.dataToShow.nombre = `${this.dispatchOrderData?.FirstName} ${this.dispatchOrderData?.lasName}`;
    this.dataToShow.status = this.getPaymentStateSpanishString( dataInObject?.status?.status );
    this.dataToShow.fecha = this.dispatchOrderData.paidDate!;
    this.dataToShow.descripcion = dataInObject?.status?.message; // TODO: Review
    this.dataToShow.valor = this.dispatchOrderData?.TotalPayment!;
    this.dataToShow.referencia = this.dispatchOrderData?.id;
    this.dataToShow.msg = dataInObject?.status?.message;
    if (dataInObject?.status?.data?.PaymentsArray?.length > 0) {
      this.dataToShow.medioDePago = dataInObject?.status?.data?.PaymentsArray[0].FiName;
      this.dataToShow.autorizacion = this.dispatchOrderData.TrazabilityCode!;
    } else {
      this.dataToShow.showMsg = true;
    }
  }

  dataToShow = {
    nombre: '',
    status: '',
    fecha: '',
    referencia: '',
    valor: 0,
    descripcion: '',
    medioDePago: '',
    autorizacion: '',
    msg: '',
    showMsg: false
  }

}
