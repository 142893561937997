import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'massive-details-req-box',
  templateUrl: './massive-details-req-box.component.html',
  styleUrls: ['./massive-details-req-box.component.css']
})
export class MassiveDetailsReqBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
