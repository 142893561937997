import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { User } from 'src/app/models/dbModels/user';
import { AuthService } from 'src/app/services/auth.service';
import { DispatchOrderService } from 'src/app/services/dispatch-order.service';
import { RequestService } from 'src/app/services/request.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { UserAppService } from 'src/app/services/userApp.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-all-users-bills',
  templateUrl: './all-users-bills.component.html',
  styleUrls: ['./all-users-bills.component.css']
})
export class AllUsersBillsComponent implements OnInit {

  ALL_MY_DISPATCH_ORDERS: DispatchOrderI[] = [];
  dataToTable: dispacthOrderDataInBillsTable[] = [];
  ALL_USER_DATA_TO_GET_BILLS: User;

  public PRINCIPAL_APP_NAME: string = environment.principalAppName.toLowerCase();
  private userIdLogued: number;
  private userIdChild: number;
  public showRequestPanels: boolean = false; // containerBills
  public isAcudiente: boolean = false;
  public acudienteData: User;
  public showParentsContainer: boolean = false;
  public ALL_MY_CHILDS: User[] = [];
  public childSelected: User;
  public REQUESTER_USER: User;
  private getDataFromChild: boolean = false;

  constructor(
    private _router: Router,
    private _dispatchOrderService: DispatchOrderService,
    private _sweetalertService: SweetalertService,
    private _authService: AuthService,
    private _requestService: RequestService,
    private _userAppService: UserAppService,
  ) {
    // this.getAllDataFromUserID();
    this.validateUserLoggued();
  }

  ngOnInit(): void {
    if (this.PRINCIPAL_APP_NAME == 'cedoc'.toLowerCase()) this.getAllDataFromUserID();
  }

  validateUserLoggued(): void {
    const UserID = this._authService.getCurrentUserID();
    if (UserID == null) this._router.navigate(['/landing']);
    else {
      this.userIdLogued = parseInt(UserID);
      this.validatePlatformAndRoles();
    }
  }

  validatePlatformAndRoles(): void {
    if (this.PRINCIPAL_APP_NAME == 'cedoc'.toLowerCase()) {
      this.showRequestPanels = true;
      return;
    }
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      USER_LOGGUED_INFO: this._requestService.getCurrentUser().pipe( take( 1 ) ),
      CHILDS_RELATION_INFO: this._requestService.getMyParentsToStudentsRelations(this.userIdLogued).pipe( take( 1 ) ),
      ALL_ROLES_USER_LOGUED: this._userAppService.getMyRoles().pipe( take( 1 ) ),
    }).subscribe( {
      next: ( data ) => {
        console.log( 'validatePlatformAndRoles', data );
        let { USER_LOGGUED_INFO, CHILDS_RELATION_INFO, ALL_ROLES_USER_LOGUED } = data;
        this.acudienteData = USER_LOGGUED_INFO;
        console.log( 'ALL_ROLES_USER_LOGUED', ALL_ROLES_USER_LOGUED );
        let indexAcudiente = ALL_ROLES_USER_LOGUED.findIndex( roleMapping => ( roleMapping.role?.name?.toLowerCase() == 'Acudiente'.toLowerCase() || roleMapping.role?.id == 29 ) );
        if ( indexAcudiente >= 0 ) {
          this.isAcudiente = true;
          this.showParentsContainer = true;
          console.log('SIIIII ES ACUDIENTE', indexAcudiente);
          this._sweetalertService.swalClose();
        } else {
          this.showRequestPanels = true;
          console.log('NOOOOOOOOOOOO ES ACUDIENTE', indexAcudiente);
          // this.getAllInitialData();
          this._sweetalertService.swalError('Error','El ingreso a este modulo solo esta permitido para el acudiente.', () => {
            this._router.navigate(['/user/my-account']);
          })
        }
        CHILDS_RELATION_INFO.forEach(parentToStudent => {
          if (parentToStudent.studentUser) this.ALL_MY_CHILDS.push(parentToStudent.studentUser);
        });
        console.log( '********** ********** ********** ********** ********** ');
        console.log( '********** ********** ********** ********** ********** ');
        console.log( 'ALL_MY_CHILDS', this.ALL_MY_CHILDS );
        console.log( '********** ********** ********** ********** ********** ');
        console.log( '********** ********** ********** ********** ********** ');
        // this.ALL_TYPE_REQUEST = ALL_TYPE_REQUEST;
        // this.ALL_SUBTYPE_REQUEST = ALL_SUBTYPE_REQUEST;
        // this.ALL_SCHOOLS = ALL_SCHOOLS;
        // this.ALL_MY_REQUEST = ALL_MY_REQUEST;
        // this.ALL_USER_COURSES = ALL_USER_COURSES;
        // this.ALL_STATES_REQUEST = ALL_STATES_REQUEST;
        // this.REQUESTER_USER = REQUESTER_USER;
      },
      error: ( err ) => {
        console.log( "ERROR validatePlatformAndRoles", err )
      },
      complete: () => {
        console.log( 'complete validatePlatformAndRoles' );
      }
    } )
  }

  onChangeChildSelected(): void {
    this.showRequestPanels = false;
    console.log('selected',this.childSelected);
    this.ALL_USER_DATA_TO_GET_BILLS = this.childSelected;
    this.userIdChild = this.childSelected.id!;
    this.getDataFromChild = true;
    this.getAllDistpatchOrderByUserID();
  }

  goToAnotherUrl(url: string): void {
    this._router.navigate([url]);
  }

  getAllDataFromUserID(): void {
    const UserID = this._authService.getCurrentUserID();
    console.log('UserID',UserID);
    if (UserID == null) this.goToAnotherUrl('/landing');

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      USER_LOGGUED_INFO: this._requestService.getCurrentUser().pipe( take( 1 ) ),
    }).subscribe( {
      next: ( data ) => {
        console.log( 'getAllDataFromUserID', data );
        let { USER_LOGGUED_INFO } = data;
        this. ALL_USER_DATA_TO_GET_BILLS = USER_LOGGUED_INFO;
        console.log( 'USER_LOGGUED_INFO', USER_LOGGUED_INFO );
        this._sweetalertService.swalClose();
        this.getAllDistpatchOrderByUserID();
      },
      error: ( err ) => {
        console.log( "ERROR getAllDataFromUserID", err )
        this._sweetalertService.swalError('Error','No se pudo obtener la información del usuario, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {
          this._router.navigate(['/request']);
        })
      },
      complete: () => {
        console.log( 'complete getAllDataFromUserID' );
      }
    } );
  }

  getAllDistpatchOrderByUserID(): void {
    if (!this.ALL_USER_DATA_TO_GET_BILLS?.UserDocuments?.[0].Document) {
      this._sweetalertService.swalError('Error','No se pudo obtener el documento del usuario, por favor, contacte a soporte.', () => {
        this._router.navigate(['/request']);
      })
      return
    }
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let filterDistpatch = {
      where: {
        and: [
          { requestID: null },
          { document: this.ALL_USER_DATA_TO_GET_BILLS.UserDocuments[0].Document },
          { isVisible: true }
        ]
      },
      order: "issueDate DESC"
    };
    forkJoin({
      ALL_MY_DISPATCH_ORDERS: this._dispatchOrderService.getDispatchOrder(JSON.stringify(filterDistpatch)).pipe( take( 1 ) ),
    }).subscribe({
      next: ( data ) => {
        console.log( 'getAllDistpatchOrderByUserID', data );
        let { ALL_MY_DISPATCH_ORDERS } = data;
        this.ALL_MY_DISPATCH_ORDERS = ALL_MY_DISPATCH_ORDERS;
        this.ALL_MY_DISPATCH_ORDERS.forEach(dispatchIterated => {
          let itemToTable: dispacthOrderDataInBillsTable = {
            id: dispatchIterated.id,
            valor: dispatchIterated.paymentDate1!,
            concepto: dispatchIterated.Description,
            fechaFactura: dispatchIterated.issueDate,
            estado: dispatchIterated.realStatus || 'PENDING', //TODO: Review
            showLinkPago: (dispatchIterated.sessionData != null && dispatchIterated.realStatus != 'APPROVED'),
            linkPago: '',
            fechaPago: ''
          }
          if (dispatchIterated.sessionData) {
            const btnLink = JSON.parse(dispatchIterated.sessionData);
            itemToTable.linkPago = btnLink.processUrl;
          }
          if (dispatchIterated.gatewayData) {
            const dataInObject = JSON.parse(dispatchIterated.gatewayData);
            itemToTable.fechaPago = dataInObject?.status?.date;
          }
          this.dataToTable.push(itemToTable);
        });
        console.log('this.dataToTable',this.dataToTable);
        this.showRequestPanels = true;
        this._sweetalertService.swalClose();
      },
      error: ( err ) => {
        this._sweetalertService.swalError('Error','No se pudo obtener la información del usuario, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {
          this._router.navigate(['/request']);
        })
        console.log( "ERROR getAllDistpatchOrderByUserID", err );
      },
      complete: () => {
        console.log( 'complete getAllDistpatchOrderByUserID' );
      }
    })
  }

  goToPayment_OLD(dispatch: DispatchOrderI): void {
    if (!dispatch.sessionData) {
      console.log('sessionData', dispatch.sessionData);
      return
    }
    const btnLink = JSON.parse(dispatch!.sessionData);
    console.log('btnLink', btnLink);
    window.open(btnLink.processUrl, '_blank');
  }

  goToPayment(url: string): void {
    if (url == '' || url == null || url == undefined ) return
    window.open(url, '_blank');
  }

  test(dispatch): void {
    console.log('TEST: ',dispatch)
  }

}

class dispacthOrderDataInBillsTable {
  id: string;
  valor: number;
  concepto: string;
  fechaFactura: string;
  estado: string;
  showLinkPago: boolean;
  linkPago: string;
  fechaPago: string;
}