import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, map, switchMap } from 'rxjs';
import { IntCallsService } from 'src/app/services/intCalls/int-calls.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'ci-response-form',
  templateUrl: './ci-response-form.component.html',
  styleUrls: [ './ci-response-form.component.css' ]
} )
export class CiResponseFormComponent implements OnInit {

  constructor (
    private _intCallService: IntCallsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _swal: SweetalertService
  ) { }

  @ViewChild("responseList") responseList!:ElementRef;

  //Stores the userIntcallAnswer data
  protected userIntcallAnswer: any | undefined = undefined;

  //Stores the userIntcall data
  protected intCallData: any | undefined = undefined;

  //Stores the ratingModule data
  protected ratingModule: any | undefined = undefined;

  //Stores the improvementPlanModule data
  protected improvementPlanModule: any | undefined = undefined;

  //Stores the FieldAnswer that will store the messages
  protected fieldAnswerForComments:any | undefined = undefined;

  //Stores the current user typed message to send
  protected commentMessage: string = '';

  //Stores the files coming from the database
  protected filesFromDb: any[] = [];

  //Sets whether or not to validate the rating form
  protected validateForm: boolean = false;

  //Checks it current user is a judge
  protected isJudge: boolean = true;

  //Show or hide the confirm rating modal
  protected showConfirmModal: boolean = false;

  //Sets the sending message state
  protected sendingMessage: boolean = false;

  //Sets the sending rating state
  protected sendingRating: boolean = false;

  //Rating form
  protected ratingForm: FormGroup = new FormGroup( {
    grade: new FormControl( '', [ Validators.required, Validators.pattern(/^\d+$/) ] ),
    effectiveness: new FormControl( '', [ Validators.required, Validators.pattern(/^\d+$/) ] ),
    efficiency: new FormControl( '', [ Validators.required, Validators.pattern(/^\d+$/) ] )
  } );

  public urlBackV1: any = environment.urlBackV1;

  //Stores the current logged in user
  private _currentUser: any = undefined;
  

  ngOnInit(): void {
    this._activatedRoute.params.subscribe( params => {
      this.getIntcallData( Number( params[ 'user' ] ), Number( params[ 'intCall' ] ) );
    } )
  }

  /**
   * Gets the intcall data based on the url user id
   * @param userIntcallAnswerID 
   * @param intcallID 
   */
  private getIntcallData( userIntcallAnswerID: number, intcallID: number ) {
    this._swal.swalLoading( 'Cargando...', 'Por favor, espera un nomento' );

    forkJoin( {
      currentUser: this._intCallService.getCurrentUser(),
      mainData: this.getUserIntCallAnswers( userIntcallAnswerID )
    } ).subscribe( {
      next: ( { currentUser, mainData }: any ) => {

        const userIntcallAnswer = mainData[ 0 ];

        if ( userIntcallAnswer === undefined || userIntcallAnswer.intcallID !== intcallID ) {
          this._swal.swalError('Error','No se encontraron registros relacionados', () => {
            this._router.navigate(['/request/continuous-improvement']);
          })
          return;
        }
        
        this._currentUser = currentUser;
        
        this.intCallData = userIntcallAnswer.intcall;
        
        this.userIntcallAnswer = userIntcallAnswer;

        this.isJudge = currentUser.id === this.intCallData.judgeIntcalls[0].userID;
        
        this.ratingModule = this.intCallData.intcallModules.find( e => e.nameModule === 'ratingModule' );

        this.improvementPlanModule = this.intCallData.intcallModules.find( e => e.nameModule === 'improvementPlan' );

        this.filesFromDb = this.intCallData.documentIntcalls;

        //Updates the rating form whit the values from dta base
        this.ratingForm.patchValue( {
          grade: this.getRatingFormFields( 'grade' ).value,
          effectiveness: this.getRatingFormFields( 'effectiveness' ).value,
          efficiency: this.getRatingFormFields( 'efficiency' ).value
        } );

        //Gets the message list
        const [ field ] = this.improvementPlanModule.intcallFields;
        this.fieldAnswerForComments = this.userIntcallAnswer.UserIntcallFieldAnswers.find( answer => answer.intcallFieldID === field.id );

        this._swal.swalClose();
      }, error: ( err ) => console.log( err )
    } )
  }

  /**
   * Creates comments
   */
  protected createComment() {
    if ( this.commentMessage.trim().length > 0 ) {
      this.sendingMessage = true;
      const data = {
        userIntCallFieldAnswerID: this.fieldAnswerForComments.id,
        userID: this._currentUser.id,
        comment: this.commentMessage,
        createdAt: new Date(),
      }

      this._intCallService.setCommentsIntcallFieldAnswer( data ).pipe(
        switchMap( () => {
          const filter: string = JSON.stringify( {
            where: { userIntCallFieldAnswerID: this.fieldAnswerForComments.id },
            include: [ 'Userapp' ]
          } )

          return this._intCallService.getCommentsIntcallFieldAnswer( filter )
        } ) ).subscribe( {
          next: ( resp: any ) => {
            //Updates the comment list whit the new messages
            this.fieldAnswerForComments.CommentsIntcallFieldAnswers = resp;
            this.sendingMessage = false;
            this.commentMessage = '';
          }, error: ( err ) => console.log( err )

        } )
    }
  }

  /**
   * Limits the input value 
   * 
   * @param event 
   */
  protected limitInputValue( event: any ) {
    const value = event.target.value;

    if ( value > 100 ) {
      event.target.value = 100;
    } else if ( value < 0 ) {
      event.target.value = 0;
    }
  }

  /**
   * Map the DocumentIntcall and emits the result
   * @param data 
   */
  protected getAndSetFilesToUpload( data: any ) {
    const filesToUpload = {
      intcallID: this.intCallData.id,
      nameDocument: data.nameFile,
      documentLink: data.urlFile,
      desc: data.descript,
      userID: this._currentUser.id,
      createdAt: new Date(),
    };

    this._intCallService.setDocumentIntcall( filesToUpload ).pipe(
      switchMap( () => this.getDocuments().pipe( map( documents => documents ) ) )
    ).subscribe( {
      next: ( resp ) => {
        this.filesFromDb = resp;
      }, error: ( err ) => console.log( err )
    } )
  }

  /**
   * Removes an item from the list
   * @param index 
   */
  protected removeFile( documentId: number ) {
    this._intCallService.deleteDocumentIntcall( documentId ).pipe(
      switchMap( () => this.getDocuments().pipe(
        map( documents => documents )
      ) )
    ).subscribe( {
      next: ( resp ) => {
        this.filesFromDb = resp;
      }, error: ( err ) => console.log( err )
    } )
  }

  /**
   * Sends the classification entered by the judge
   * @returns 
   */
  protected setGrades() {
    this.validateForm = true;

    //----------------------------------------------If it is already qualified
    if ( !this.ratingForm.valid || !this.isJudge || this.userIntcallAnswer.isFinalGrade ) {
      this.showConfirmModal = false;
      return;
    };

    this.sendingRating = true;
    
    const ratings = [ 'grade', 'effectiveness', 'efficiency' ].map( e => {
      const data = {
        //Gets the updated value of the rating field
        value: this.ratingForm.get( e )?.value,
      }
      return this._intCallService.patchUserIntcallFieldAnswers( this.getRatingFormFields( e ).id, data )
    })

    //Sets the ratings and get the updated the userIntCallAnswers
    forkJoin( ratings ).pipe(
      switchMap( () => {
        return this._intCallService.patchUserIntcallAnswer( this.userIntcallAnswer.id, { isFinalGrade: true } );
      }),
    ).subscribe({
      next:( resp:any )=>{
        this.userIntcallAnswer.isFinalGrade = resp.isFinalGrade;
        this.sendingRating = false;
        this.showConfirmModal = false;
      },
      error:( err ) => console.log( err )
    })
  }

  /**
   * Fills the ratingForm controls with the data from the database
   */
  private getRatingFormFields( fieldName: string ) {
    const intcallFields = this.ratingModule.intcallFields;

    const field = intcallFields.find( e => e.nameField === fieldName );
    const userIntcallFieldAnswer = this.userIntcallAnswer.UserIntcallFieldAnswers.find( uIntCall => uIntCall.intcallFieldID === field.id );
    return userIntcallFieldAnswer;
  }

  /**
   * Updates the response checkbox state
   * @param id 
   * @param event 
   */
  protected setAnswerState(id:any, event:any) {
    const isChecked = event.target.checked;

    //Gets the HTML reference of the current checkbox, to add a class that disables it until the request is made.
    const currentCheckbox = this.responseList.nativeElement.querySelector(`#checkbox-response__${ id }`);
    currentCheckbox.classList.add('disabled');
    
    this._intCallService.patchGradeIntcallFieldAnswers( id, { grade: isChecked }).subscribe( {
      complete:()=>{
        currentCheckbox.classList.remove('disabled');
      },error:(err)=> console.log(err)
    } )
  }

  /**
   * Gets userIntCallAnswers data by its id
   * @param userIntcallAnswerID 
   * @returns 
   */
  private getUserIntCallAnswers( userIntcallAnswerID: number ) {
    const filters: string = JSON.stringify( {
      where: { id: userIntcallAnswerID },
      include: [
        {
          intcall: [
            { intcallModules: 'intcallFields' },
            'documentIntcalls',
            'judgeIntcalls'
          ]
        },
        'Userapp',
        { UserIntcallFieldAnswers: [ { CommentsIntcallFieldAnswers: 'Userapp' }, 'GradeIntcallFieldAnswers' ] }
      ]
    } );

    return this._intCallService.getUserIntcallAnswer( filters );
  }
  
  /**
   * Gets the documents uploaded to the database
   * @returns 
   */
  private getDocuments() {
    const filter: string = JSON.stringify( {
      where: { intcallID: this.intCallData.id }
    } )
    return this._intCallService.getDocumentIntcall( filter );
  }
}
