import { ContactInfosI } from "../resumeModels/resume";
import { ClassificationUser } from "./calsification-user/classification-user";
import { ParentsToStudents } from "./parentsToStudents";
import { UserDocument } from "./userDocuments";

export class User {
  id?: number;
  CedocEmail?: string;
  TypeDocumentID?: number;

  Document?: string;
  Name1: string;
  Name2?: string;
  LastName1: string;
  LastName2?: string;

  CellPhone: string;
  email: string;
  Photo: string;

  TimeCreate?: string;
  IsTemporal?: boolean;

  IsMilitar?: boolean;
  IsStudent?: boolean;
  MilitarInfos?: any[];
  UserDocuments?: UserDocument[];
  CedulaMil?: string;
  Cedula?: string;

  Range?: string;
  OtanID?: number;
  ForceID?: number;
  SchoolID?: number;

  CourseOferID: number;

  UserCourses?: any[];
  ClassificationUser?: ClassificationUser[];
  ContactInfos?: ContactInfosI[];
  parentsToStudents?: ParentsToStudents[];
  roleMappings?:any[];
}
