import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-modal-bill-summary',
  templateUrl: './modal-bill-summary.component.html',
  styleUrls: ['./modal-bill-summary.component.css']
})
export class ModalBillSummaryComponent implements OnInit {

  constructor() { 
    //
  }

  public SCHOOL_LOGO = environment.SCHOOL_LOGO;

  ngOnInit(): void {
    //
  }

}
