import { Component, OnInit } from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { CourseOffer } from 'src/app/models/dbModels/courseOffer';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { User } from 'src/app/models/dbModels/user';
import { CourseOfferService } from 'src/app/services/courseOffer.service';
import { RequestService } from 'src/app/services/request.service';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'request-request-masive-oap-admin',
  templateUrl: './request-masive-oap-admin.component.html',
  styleUrls: ['./request-masive-oap-admin.component.css']
})
export class RequestMasiveOapAdminComponent implements OnInit {

  public ALL_MY_SCHOOLS: School[] = [];
  public ALL_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_ACTIVE_COURSES_OFFER: CourseOffer[] = [];
  public schoolSelected: School;
  public SubTypeRequestSelected: SubTypeRequest;
  public EMPTY_SubTypeRequest: SubTypeRequest;
  public courseOfferSelected: CourseOffer;
  public EMPTY_CourseOffer: CourseOffer;
  public studentlistForOAP: Request[] = [];;
  
  constructor(
    private _schoolService: SchoolService,
    private _requestService: RequestService,
    private _courseOfferService: CourseOfferService,
  ) { }

  ngOnInit(): void {
    this.getAllInitialData();
  }

  getAllInitialData() {
    forkJoin({
      fjSchools: this._schoolService.getSchools().pipe(take(1)),
      fjMyRoleMappings: this._requestService.getMyRoleMappings().pipe(take(1)),
    }).subscribe({
      next: ({fjSchools, fjMyRoleMappings}) => {
        console.log('***** == getAllInitialData OAP == *****');
        // console.log('fjSchools',fjSchools);
        // console.log('fjSubTypeRequest',fjSubTypeRequest);
        // console.log('fjMyRoleMappings',fjMyRoleMappings);
        console.log('***** == getAllInitialData OAP == *****');
        /** Obtengo las escuales a las que pertenece el usuario */
        let mySchoolsIds: number[] = [];
        fjMyRoleMappings.forEach(myRole => {
          if (myRole.SchoolID) mySchoolsIds.push(myRole.SchoolID);
        });
        let mySchoolsIdsCleaned = new Set(mySchoolsIds);
        let mySchoolsIdsFinal = Array.from(mySchoolsIdsCleaned);
        console.log('===',mySchoolsIdsFinal);
        mySchoolsIdsFinal.forEach(idSchool => {
          const schoolFound = fjSchools.find((schoolIterated: School) => schoolIterated?.id == idSchool);
          if (schoolFound) this.ALL_MY_SCHOOLS.push(schoolFound);
        });
      },
      error: (err) => {
        console.log('***** == ERROR getAllInitialData OAP == *****', err);
      },
      complete: () => {
        console.log('***** == complete getAllInitialData OAP == *****');
      }
    })
  }

  onChangeSchool() {
    console.log('schoolSelected',this.schoolSelected);
    this.SubTypeRequestSelected = this.EMPTY_SubTypeRequest;
    this.courseOfferSelected = this.EMPTY_CourseOffer;
    this.studentlistForOAP = [];


    forkJoin({
      fjActiveCourses: this._courseOfferService.getAllActiveCoursesBySchool(this.schoolSelected.id!).pipe(take(1)),
      fjSubTypeRequest: this._requestService.getSubTypeRequestForOAP(this.schoolSelected.id!).pipe(take(1)),
    }).subscribe({
      next: ({fjActiveCourses, fjSubTypeRequest}) => {
        console.log('***** == onChangeSchool OAP == *****');
        console.log('fjActiveCourses',fjActiveCourses);
        console.log('***** == onChangeSchool OAP == *****');
        this.ALL_ACTIVE_COURSES_OFFER = fjActiveCourses;
        this.ALL_SUBTYPE_REQUEST = fjSubTypeRequest;
        
      },
      error: (err) => {
        console.log('***** == ERROR onChangeSchool OAP == *****', err);
      },
      complete: () => {
        console.log('***** == complete onChangeSchool OAP == *****');
      }
    })



    
  }

  onChangeCourseOffer() {
    console.log('courseOfferSelected',this.courseOfferSelected);
    this.SubTypeRequestSelected = this.EMPTY_SubTypeRequest;
  }

  onChangeSubTypeRequest() {
    console.log('SubTypeRequestSelected',this.SubTypeRequestSelected);

    forkJoin({
      fjRequest: this._requestService.getAllRequestToOAP(this.SubTypeRequestSelected.id!, this.courseOfferSelected.id).pipe(take(1)),
    }).subscribe({
      next: ({fjRequest}) => {
        console.log('***** == onChangeSubTypeRequest OAP == *****');
        console.log('fjRequest',fjRequest);
        console.log('***** == onChangeSubTypeRequest OAP == *****');
        this.studentlistForOAP = fjRequest;
        
      },
      error: (err) => {
        console.log('***** == ERROR onChangeSubTypeRequest OAP == *****', err);
      },
      complete: () => {
        console.log('***** == complete onChangeSubTypeRequest OAP == *****');
      }
    })

    
  }


  confirmarOAP(){
    console.log('confirmarOAP()');
  }

}
