import { Injectable, Type } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/dbModels/school';
import { RoleMapping } from '../models//dbModels/roleMapping';
import { StageReq } from '../models/dbModels/stageReq';
import { StateReq } from '../models/dbModels/stateReq';

@Injectable({
  providedIn: 'root'
})
export class StateReqService {

  constructor(
    private _httpService: HttpService
  ) { }

  getStateReqWithStage(filter?: string): Observable<School[]> {
    let url: string = `/Schools`
    if (filter)
      url = `/Schools?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getStatesReq(filter?: string): Observable<StateReq[]> {
    let url: string = `/StatesReq`
    if (filter)
      url = `/StatesReq?filter=${filter}`
    return this._httpService.httpGet(url);
  }

}


