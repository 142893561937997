import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged,EMPTY,forkJoin, fromEvent, map, startWith, Subscription, switchMap } from 'rxjs';
import { SectionToShowEnum } from 'src/app/models/intcallModels/intcallEnums/sectionToShowEnum';
import { IntCallsService } from 'src/app/services/intCalls/int-calls.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { XlsxService } from 'src/app/services/xlsx.service';

@Component( {
  selector: 'request-continuous-improvement',
  templateUrl: './continuous-improvement.component.html',
  styleUrls: [ './continuous-improvement.component.css' ]
} )
export class ContinuousImprovementComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor ( 
    private _intcallService: IntCallsService, 
    private _xlsx: XlsxService,
    private _router: Router,
    private _swal:SweetalertService
  ) {
    //
  }

  @ViewChild( 'filterByName' ) filterByName!: ElementRef;
  @ViewChild( 'titleContainer' ) titleContainer!: ElementRef;

  //Toggles the internal call modal
  protected showSelectorsModal: boolean = false;

  //Stores the period value to filter
  protected selectedPeriod: number = 0;

  //Stores the depend schools
  protected schoolList: any[] = [];

  //Stores the program list filtered by the school id
  protected programList: any[] = [];

  //Stores the tags for the intCall
  protected finalTags: string = '';

  //Stores the value entered in the title input
  protected customTitle: string = '';

  //Stores the final title based on the selected modal options
  protected finalTitle: string = '';

  //SectionToShowEnum 
  protected sectionToShowEnum: any = SectionToShowEnum;

  //Sets the section to show
  protected sectionToShow: SectionToShowEnum = SectionToShowEnum.mainSection;

  //Stores the current user
  protected currentUser: any | undefined = undefined;

  //Stores the intcall list 
  protected intcallList: any[] = [];

  //Checks if the data is being loaded
  protected loadingData: boolean = false;

  //#region 😈 MODAL OPTIONS 

  //Sets the value of the selected option of the category field
  protected selectedCategory: any = { id: 4, value:'Docente' };

  //Stores the selected professor
  protected selectedProfessor: any | undefined = undefined;

  //Stores the selected program
  protected selectedProgram: any | undefined = undefined;

  //Stores the selected school
  protected selectedSchool: any | undefined = undefined;

  //Validates the selection inputs when user clicks on the create button
  protected validateSelections: boolean = false;
  //#endregion

  //#region 📋 FILTERS
  //Stores the end date to filter
  private _startDate: Date | undefined = undefined;

  //Stores the end date  to filter
  private _endDate: Date | undefined = undefined;

  //Stores community type to filter
  private _communityTypeToFilter: number = 4;

  //Stores intCall state type to filter
  private _filterByState: boolean | undefined = undefined;

  //Stores the filter by name input field value
  private _filterByNameValue: string = '';

  //Avoids multiple calls at the same time
  private _subscription: Subscription = new Subscription();
  //#endregion

  //#region 📖 PAGINATION

    //Stores the number of pages
    protected totalPages: number = 0;

    //Stores the selected page
    protected page: number = 1;

    //Total items per page
    protected pageItems: number = 30;

    //Stores the total receipts length
    protected totalItems: number = 0;
  //#endregion

  ngOnInit(): void {
    this._swal.swalLoading( 'Cargando...', 'Por favor, espera un nomento' );

    forkJoin( {
      currentUser: this._intcallService.getCurrentUser(),
      schools: this._intcallService.getSchools()
    } ).subscribe( {
      next: ( { currentUser, schools }: any ) => {
        if(currentUser === undefined)
          this._router.navigate(['/register'])

        this.currentUser = currentUser;
        this.schoolList = this.getDependSchools( this.currentUser.roleMappings[ 0 ].SchoolID, schools );
      }, error: ( err ) => console.log( err )
    } )
  }

  ngAfterViewInit(): void {
    let callOnce:boolean = true;

    fromEvent<Event>( this.filterByName.nativeElement, 'keyup').pipe(
      map( ( event: Event ) => ( event.target as HTMLInputElement )?.value || '' ),
      startWith( '' ),
      debounceTime( 400 ),
      distinctUntilChanged(),
      switchMap( ( value: string ) => {
        if( value.length > 3 ){
          this._filterByNameValue = `plan de mejora para: ${ value.toLowerCase() }`;
          this.loadingData = true;
          callOnce = true;
          return this.getIntcalls( 1 );
        }else{
          this._filterByNameValue = '';
          if(callOnce){
            this.loadingData = true;
            callOnce = false;
            return this.getIntcalls( 1 );
          }
        }

        return EMPTY;
      } )
    ).subscribe( {
      next:( { intCalls, intCallsCount } )=>{
        this.intcallList = intCalls;
        this.totalItems = intCallsCount;
        this.totalPages = Math.ceil( intCallsCount / this.pageItems );
        this.loadingData = false;
        this._swal.swalClose();
      },error:(err)=>console.log(err)
      
     } )
  }

  /**
   * Applies the table filters
   */
  protected applyFilters( currentPage?: number) {
    this.page = currentPage !== undefined ? currentPage : 1;
    this.loadingData = true;

    this._subscription.unsubscribe();
    this._subscription = this.getIntcalls( this.page ).subscribe( {
      next:( { intCalls, intCallsCount } )=>{
        this.intcallList = intCalls;
        this.totalItems = intCallsCount;
        this.totalPages = Math.ceil( intCallsCount / this.pageItems );
        this.loadingData = false;
      },error:( err )=>console.log( err )
    } )
  }

  private getIntcalls( currentPage: number ){
    const intCallFilter = this.setIntCallsFilter( currentPage );

    return forkJoin( {
      intCalls: this._intcallService.getIntcalls( intCallFilter ),
      intCallsCount: this._intcallService.getIntcallsCount( this.intCallsCountFilter() )
    } ).pipe(
      map( ( { intCalls, intCallsCount }:any ) => ({
        intCalls,
        intCallsCount: intCallsCount.count
      }))
    )
  }

  /**
   * Gest the depend school based on the current user school
   * @param currentSchoolId 
   * @param shoolList 
   * @returns 
   */
  private getDependSchools( currentSchoolId: number, shoolList: any[] ) {
    const currentSchool = shoolList.find( e => e.id === currentSchoolId );
    const filteredSchools = shoolList.filter( e => e.DepenSchoolID === currentSchoolId ).concat( currentSchool ).map( e => ( {
      id: e.id,
      value: e.NameTSchool,
      schoolAcronym: e.SchoolAcronim
    } ) );

    return filteredSchools;
  }

  /**
   * On school option changes
   * @param event 
   */
  protected getProgramBySchool( event: any ) {
    this.selectedSchool = event;
    this.programList = [];

    const filters: string = JSON.stringify( {
      where: { SchoolID: event.id }
    } )

    this._intcallService.getCourses( filters ).subscribe( {
      next: ( resp ) => {
        const programList = resp.map( e => ( {
          id: e.id,
          value: e.NameCourse
        } ) );

        this.programList = programList;
      }, error: ( err ) => console.log( err )

    } )
  }

  /**
   * Opens the create form section
   */
  protected onCreateButtonClick(): void {
    this.validateSelections = true;

    this.finalTags = '';
    const newTags:any[] = [];

    if ( this.selectedCategory.id === 4 ) {
      if ( this.selectedSchool === undefined || this.selectedProfessor === undefined ) {
        return;
      } else {
        newTags.push( this.selectedSchool.schoolAcronym );
      }
    } else if ( this.selectedCategory.id === 5 ) {
      if ( this.selectedSchool === undefined || this.selectedProgram === undefined ) {
        return;
      } else {
        newTags.push( this.selectedSchool.schoolAcronym, this.selectedProgram.value );
      }
    } else if ( this.selectedCategory.id === 6 ) {
      if ( this.selectedSchool === undefined ) {
        return;
      } else {
        newTags.push( this.selectedSchool.schoolAcronym );
      }
    }
    
    this.finalTitle = `${ this.finalTitle } ${ this.customTitle }`;
    this.showSelectorsModal = false
    this.finalTags = JSON.stringify( newTags );
    this.programList = [];
    this.sectionToShow = SectionToShowEnum.createFormSection;
  }

  protected downloadReport(){
    this._swal.swalLoading( 'Cargando...', 'Por favor, espera un nomento' );

    const where: any = { and: this.generateFilters() };
    const include: any[] = [ { userIntcallAnswers: [ { UserIntcallFieldAnswers: 'GradeIntcallFieldAnswers' }, 'Userapp' ] }, 'intcallFields' ];

    this._intcallService.getIntcalls( JSON.stringify( { where, include } ) ).subscribe({
      next:( resp )=>{
        const selectedFields = resp.map( ( e:any ) => ( { 
          'NOMBRE': e.nameIntCall,
          'RESPONSABLE': `${ e.userIntcallAnswers?.[0]?.Userapp.Name1 } ${ e.userIntcallAnswers?.[0]?.Userapp.LastName1 }`,
          'FECHA DE INICIO': e.dateStart,
          'FECHA DE FIN': e.dateEnd,
          'ESTADO': this.getIntCallState( e.dateStart, e.dateEnd ),
          'PORCENTAJE DE CUMPLIMIENTO': Number( this.getGrade( e ) ),
          'OBJETIVOS EN PROGRESO': this.getNonGradeAnswersCount( e.intcallFields, e.userIntcallAnswers?.[0]?.UserIntcallFieldAnswers )
         } ) );

         this._xlsx.exportToExcel( selectedFields, 'Planes de mejora' );
         this._swal.swalClose();
      },error:(err) => {
        console.log( err )
      }
    })
  }


  /**
   * Gets start date
   * @param event 
   */
  protected getStartDate( event: any ) {
    this._startDate = event === '' ? undefined : new Date( event );
    this.applyFilters();
  }

  /**
   * Gets the end date
   * @param event 
   */
  protected getEndDate( event: any ) {
    this._endDate = event === '' ? undefined : new Date( event );
    this.applyFilters();
  }

  /**
   * Gets the typeCall
   * @param event 
   */
  protected getTypeCall( event: any ) {
    this._communityTypeToFilter = event;
    this.applyFilters();
  }

  /**
   * Gets the intcall state
   * @param event 
   */
  protected getStateType( event: any ) {
    this._filterByState = event;
    this.applyFilters();
  }

  /**
   * Updates the title when selects a school
   * @param event 
   */
  protected onSelectSchool( event: any ) {
    this.selectedSchool = event;
    this.updateCustomTitle( this.selectedCategory.value, '', event.value );
  }

  /**
   * Updates the title when selects a professor
   * @param event 
   * @returns 
   */
  protected onSelectUser( event: any ) {
    if ( event === undefined )
      return;

    this.selectedProfessor = event;
    this.updateCustomTitle( this.selectedCategory.value, `${ event.Name1 } ${ event.LastName1 }` );
  }

  /**
   * Updates the title when selects a program
   * @param event 
   */
  protected onSelectProgram( event: any ) {
    this.selectedProgram = event;
    this.updateCustomTitle( this.selectedCategory.value, '', '', event.value );
  }

  /**
   * Sets filters for the API query to fetch data based on selected options
   * @returns JSON stringified object containing the filters and includes for the query
   */
  private generateFilters(): any[] {
    const and: any[] = [ { typeCall: this._communityTypeToFilter } ];

    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;

    if ( this.selectedPeriod === 0 ) {
      if ( this._startDate !== undefined ) {
        and.push( { dateStart: { gt: this._startDate } } );
      }

      if ( this._endDate !== undefined ) {
        and.push( { dateEnd: { lt: this._endDate } } );
      }
    } else if ( this.selectedPeriod === 1 ) {
      and.push( {
        dateStart: { between: [ new Date( currentYear, 0, 1 ), new Date( currentYear, 5, 30 ) ] }
      } );
    } else if ( this.selectedPeriod === 2 ) {
      and.push( {
        dateStart: { between: [ new Date( previousYear, 6, 1 ), new Date( previousYear, 11, 31 ) ] }
      } );
    }

    if ( this._filterByState !== undefined ) {
      const currentDate = new Date();
      if ( this._filterByState ) {
        and.push( { dateEnd: { gt: currentDate } }, { dateStart: { lt: currentDate } } );
      } else {
        and.push( { dateEnd: { lt: currentDate } } );
      }
    }

    if (this._filterByNameValue.length > 3) {
      and.push( { nameIntCall: { regexp: `/${this._filterByNameValue}/` } } );
    }

    return and;
  }

  /**
   * Sets the filters for fetching data.
   * @returns A JSON string containing the filters for the query.
   */
  private setIntCallsFilter( currentPage:number ): string {
    const where: any = { and: this.generateFilters() };
    const include: any[] = [ { userIntcallAnswers: [ { UserIntcallFieldAnswers: 'GradeIntcallFieldAnswers' }, 'Userapp' ] }, 'intcallFields' ];
    const skip: any = (currentPage - 1) * this.pageItems;
    const limit: any = this.pageItems;

    return JSON.stringify( { where, include, skip, limit } );
  }

  /**
   * Sets filters for the API query to fetch count of total items based on selected options
   * @returns JSON stringified object containing the filters for the query
   */
  private intCallsCountFilter(): string {
    const and = this.generateFilters();

    return JSON.stringify( { and } );
  }

  /**
   * Clears the table filters
   */
  protected clearFilters() {
    this._startDate = undefined;
    this._endDate = undefined;
    this.applyFilters();
  }

  /**
   * Clears all modal selected states
   */
  protected closeSectionModal() {
    this.programList = [];
    this.customTitle = '';
    this.finalTitle = '';
    this.finalTags = '';
    this.validateSelections = false;
    this.showSelectorsModal = false
    this.selectedProfessor = undefined;
    this.selectedProgram = undefined;
    this.selectedSchool = undefined;
    this. sectionToShow = this.sectionToShowEnum.mainSection
  }

  /**
   * Resets all the modal selects when change the category
   * @param event 
   */
  protected onChangeCategory( event: any ) {
    if ( this.titleContainer )
      this.updateCustomTitle( event.value );

    this.selectedProfessor = undefined;
    this.selectedSchool = undefined;
    this.selectedProgram = undefined;

    this.finalTags = '';
    this.programList = [];

    this.selectedCategory = event;
  }

  /**
   * Updates the custom title displayed in the modal based on the selected category, professor, school, and program.
   * @param selectedCategory The selected category for the custom title.
   * @param selectedProfessor The selected professor for the custom title.
   * @param selectedSchool The selected school for the custom title.
   * @param selectedProgram The selected program for the custom title.
   */
  private updateCustomTitle(
    selectedCategory = 'Docente',
    selectedProfessor = '',
    selectedSchool = '',
    selectedProgram = ''
  ): void {
    const newTitle = `Plan de mejora para: ${ selectedCategory } ${ selectedProfessor } ${ selectedSchool } ${ selectedProgram }`;

    const span = this.titleContainer.nativeElement.querySelector( 'span' );
    const input = this.titleContainer.nativeElement.querySelector( 'input' );

    span.textContent = newTitle;
    span.title = newTitle;

    const spanWidth = span.getBoundingClientRect().width;
    input.style.paddingLeft = `${ spanWidth + 14 }px`;

    this.finalTitle = newTitle;
  }

  /**
   * Returns status according to start and end date
   * 
   * @param startDate 
   * @param endDate 
   * @returns 
   */
  protected getIntCallState( startDate: Date, endDate: Date ) {
    const currentDate = new Date().getTime();
    if ( currentDate > new Date( endDate ).getTime() ) {
      return 'Finalizado'
    } else if ( currentDate >= new Date( startDate ).getTime() && currentDate <= new Date( endDate ).getTime() ) {
      return 'Activo'
    } else {
      return '-'
    }
  }

  /**
   * Gets the grade value
   * @param intcall 
   * @returns 
   */
  protected getGrade(intcall: any): string {
    let grade = '-';
    intcall.intcallFields.forEach((field: any) => {
      if (field.nameField === 'grade') {
        const answer = intcall.userIntcallAnswers?.[0]?.UserIntcallFieldAnswers?.find((a: any) => a.intcallFieldID === field.id);
        if (answer) {
          grade = answer.value;
        }
      }
    });
    return grade;
  }

  /**
   * Gets the total of answers qualified 
   * @param intcallFields 
   * @param userIntcallFieldAnswers 
   * @returns 
   */
  protected getNonGradeAnswersCount( intcallFields: any[], userIntcallFieldAnswers: any[] ): number {
    const qualifiedItems = userIntcallFieldAnswers.filter( answer => {
      const field = intcallFields.find( field => field.id === answer.intcallFieldID );
      if ( field && ![ 'grade', 'effectiveness', 'efficiency' ].includes( field.nameField ) ) {
        const gradeAnswer = answer.GradeIntcallFieldAnswers[ 0 ];
        return gradeAnswer && gradeAnswer.grade === 0;
      }
      return false;
    } );
    return qualifiedItems.length;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
