import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/dbModels/school';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getSchools(filter?: string): Observable<School[]> {
    let url: string = `/Schools`
    if (filter)
      url = `/Schools?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getCategory(filter?: string): Observable<School[]> {
    let url: string = `/Categories`
    if (filter)
      url = `/Categories?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getTypeCourse(filter?: string): Observable<School[]> {
    let url: string = `/TypeCourses`
    if (filter)
      url = `/TypeCourses?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getCourses(filter?: string): Observable<School[]> {
    let url: string = `/Courses`
    if (filter)
      url = `/Courses?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getCourseOfer(filter?: string): Observable<School[]> {
    let url: string = `/CoursesOfers`
    if (filter)
      url = `/CoursesOfers?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getUserCourse(filter?: string): Observable<School[]> {
    let url: string = `/UserCourses`
    if (filter)
      url = `/UserCourses?filter=${filter}`
    return this._httpService.httpGet(url);
  }

}
