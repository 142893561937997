import { Component, OnInit } from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { MultiReq } from 'src/app/models/dbModels/multi-req';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'request-request-masive-list-admin',
  templateUrl: './request-masive-list-admin.component.html',
  styleUrls: ['./request-masive-list-admin.component.css']
})
export class RequestMasiveListAdminComponent implements OnInit {

  public showModalDataSelector: boolean = false;
  public typeView: number = 1;

  constructor(
    private _schoolService: SchoolService,
    private _requestService: RequestService,
    private _authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getAllInitialData();
  }

  onChangeSelectProcess(deviceValue) {
    switch (deviceValue.target.value) {
      case "todos":
        this.typeView = 1;
        break;
      case "meHanReasignado":
        this.typeView = 2;
        break;
      case "yoHeReasignado":
        this.typeView = 1;
        break;
    }
  }
  onChangeSchool() {
    console.log('schoolSelected',this.schoolSelected);
  }
  onChangeCategory() {
    console.log('categorySelected',this.categorySelected);
    this.ALL_TYPE_COURSES_FILTERED = [];
    this.ALL_TYPE_COURSES.forEach(element => {
      if (element.CategoryID == this.categorySelected.id) this.ALL_TYPE_COURSES_FILTERED.push(element)
    });
  }
  onChangeTypeCourse() {
    console.log('typeCourseSelected',this.typeCourseSelected);
    this.getAllCourses();
  }
  onChangeCourse() {
    console.log('courseSelected',this.courseSelected);
    this.getAllCourseOfers();
  }
  onChangeCourseOfer() {
    console.log('courseOferSelected',this.courseOferSelected);
    this.getAllUserCourse();
  }
  onChangeTypeRequest() {
    console.log('typeReqSelected',this.typeReqSelected);
    this.ALL_FILTERED_SUBTYPE_REQUEST = [];
    this.ALL_SUBTYPE_REQUEST.forEach(element => {
      if (
        element.typeRequestID == this.typeReqSelected.id &&
        element.schoolID == this.schoolSelected.id
      ) this.ALL_FILTERED_SUBTYPE_REQUEST.push(element);
    });
  }
  onChangeSubTypeRequest() {
    console.log('subTypeReqSelected',this.subTypeReqSelected);
    this.showConfirmBtn = true;
    this.showTableNavBar = true;
  }

  public ALL_SCHOOLS: School[] = [];
  public ALL_CATEGORIES: any[] = [];
  public ALL_TYPE_COURSES: any[] = [];
  public ALL_TYPE_COURSES_FILTERED: any[] = [];
  public ALL_COURSES: any[] = [];
  public ALL_COURSESOFER: any[] = [];
  public ALL_USERCOUSE: any[] = [];
  public ALL_TYPE_REQUEST: TypeRequest[] = [];
  public ALL_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_FILTERED_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_MULTI_REQUEST: MultiReq[] = [];
  public schoolSelected: School;
  public categorySelected: any;
  public typeCourseSelected: any;
  public courseSelected: any;
  public courseOferSelected: any;
  public typeReqSelected: TypeRequest;
  public subTypeReqSelected: SubTypeRequest;
  /** BTN LOGIC */
  public showConfirmBtn: boolean = false;
  public showTableNavBar: boolean = false;
  public isCreated: boolean = false;
  getAllInitialData() {
    forkJoin([
      this._schoolService.getSchools().pipe(take(1)),
      this._schoolService.getCategory().pipe(take(1)),
      this._schoolService.getTypeCourse().pipe(take(1)),
      this._requestService.getTypeRequest().pipe(take(1)),
      this._requestService.getSubTypeRequest().pipe(take(1)),
      this._requestService.getMultiRequest().pipe(take(1)), /** TODO: filtrar por usuario */
    ]).subscribe({
      next: (data) => {
        console.log('getAllInitialData masive',data);
        this.ALL_SCHOOLS = data[0];
        this.ALL_CATEGORIES = data[1];
        this.ALL_TYPE_COURSES = data[2];
        this.ALL_TYPE_REQUEST = data[3];
        this.ALL_SUBTYPE_REQUEST = data[4];
        this.ALL_MULTI_REQUEST = data[5];
      },
      error: (err) => {
        console.log("ERROR getAllInitialData listAdmin",err)
      },
      complete: () => {
        console.log('complete getAllInitialData RMLA');
      }
    })
  }
  getAllCourses() {
    const filterUserCourse = JSON.stringify({
      where: {
        and: [
          {TypeCourseID: this.typeCourseSelected.id},
          {SchoolID: this.schoolSelected.id}
        ]
      }
    });
    forkJoin([
      this._schoolService.getCourses(filterUserCourse).pipe(take(1)),
    ]).subscribe({
      next: (data) => {
        console.log('getAllCourses getAllCourses',data);
        this.ALL_COURSES = data[0];
      },
      error: (err) => {
        console.log("ERROR getAllCourses",err)
      },
      complete: () => {
        console.log('complete getAllCourses');
        this.isCreated = false;
      }
    })
  }
  getAllCourseOfers() {
    const filterUserCourse = JSON.stringify({
      where: {
        and: [
          {CourseID: this.courseSelected.id},
          {SchoolID: this.schoolSelected.id}
        ]
      }
    });
    forkJoin([
      this._schoolService.getCourseOfer(filterUserCourse).pipe(take(1)),
    ]).subscribe({
      next: (data) => {
        console.log('getAllCourses getAllCourseOfers',data);
        this.ALL_COURSESOFER = data[0];
      },
      error: (err) => {
        console.log("ERROR getAllCourseOfers",err)
      },
      complete: () => {
        console.log('complete getAllCourseOfers');
        this.isCreated = false;
      }
    })
  }
  getAllUserCourse() {
    const filterUserCourse = JSON.stringify({
      where: {
        CourseOferID: this.courseOferSelected.id
      },
      include: "userapp"
    });
    forkJoin([
      this._schoolService.getUserCourse(filterUserCourse).pipe(take(1)),
    ]).subscribe({
      next: (data) => {
        console.log('getAllUserCourse masive',data);
        this.ALL_USERCOUSE = data[0];
      },
      error: (err) => {
        console.log("ERROR getAllUserCourse",err)
      },
      complete: () => {
        console.log('complete getAllUserCourse');
        this.isCreated = false;
      }
    })
  }

  createMasiveRequest(): void {
    let newMultiReq: MultiReq = {
      SchoolID: this.schoolSelected.id,
      courseID: this.courseSelected.id,
      CourseOferID: this.courseOferSelected.id,
      UserOwnerID: Number(this._authService.getCurrentUserID()),
    };
    this._requestService.createMultiReq(newMultiReq).pipe(take(1)).subscribe(
      data => {
        console.log('createMasiveRequest success', data);
        this.createRequestList(data.id);
      },
      err => {
        console.log('createMasiveRequest fail', err);
      }
    );
  }

  createRequestList(MultiReqID: number): void {
    /** TODO: (better) use map? */
    this.isCreated = true;
    let allNewRequest: Request[] = [];
    this.ALL_USERCOUSE.forEach(element => {
      let newRequest: Request = {
        registerDate: new Date(Date.now()),
        updateDate: new Date(Date.now()),
        stateReqID: 1,
        userOwnerID: this.subTypeReqSelected?.userID,
        userCurrentID: this.subTypeReqSelected?.userID,
        userRequesterID: element.UserID,
        isPaid: false,
        schoolID: this.schoolSelected.id,
        courseID: this.courseSelected.id,
        courseOfferID: this.courseOferSelected.id,
        typeRequestID: this.typeReqSelected.id,
        subTypeRequestID: this.subTypeReqSelected.id,
        MultiReqID: MultiReqID,
      };
      allNewRequest.push(newRequest);
    });
    this._requestService.createRequestMultiple(allNewRequest).pipe(take(1)).subscribe(
      data => {
        console.log('createRequestList success', data);
      },
      err => {
        console.log('createRequestList fail', err);
      }
    );
  }
  
}
