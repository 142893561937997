import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'user-request-filters-modal',
  templateUrl: './user-request-filters.component.html',
  styleUrls: ['./user-request-filters.component.css']
})
export class UserRequestFiltersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
