import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CourseOffer } from 'src/app/models/dbModels/courseOffer';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { StageReq } from 'src/app/models/dbModels/stageReq';
import { StateReq } from 'src/app/models/dbModels/stateReq';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { AuthService } from 'src/app/services/auth.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'masive-req-view-admin',
  templateUrl: './masive-req-view-admin.component.html',
  styleUrls: ['./masive-req-view-admin.component.css']
})
export class MasiveReqViewAdminComponent implements OnInit {

  @Input() allStateReq: StateReq[];
  @Input() isThereNewChats: boolean;
  @Input() set allRequestData(requestList: Request[]) {
    console.log(requestList);

    let local_typeRequest: any[] = [];
    local_typeRequest = requestList
      .map(e => e.requestTypeRequest)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
      if (local_typeRequest && local_typeRequest.length > 0) this.filtersManagement.myRequest.typeRequest = local_typeRequest;

    let local_schools: any[] = [];
    local_schools = requestList
      .map(e => e.requestSchool)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);

    if (local_schools && local_schools.length > 0) this.filtersManagement.myRequest.schoolsList = local_schools!;

    this.allRequestList = [...requestList];
    this.requestListFiltered = [];
  }

  public PRINCIPAL_APP_NAME: string = environment.principalAppName.toLowerCase();
  public userIDlogued: number;
  public idRequestSelected: number | undefined;
  public requestListFiltered: Request[];
  public allRequestList: Request[] = [];
  public filtersManagement: ManagmentFiltersSqueleton = {
    myRequest: {
      schoolsList: [],
      courseOfferList: [],
      typeRequest: [],
      subTypeRequest: [],
      subTypeReqStages: []
    }
  };
  public filtersToShow: FiltersSqueleton = {
    schoolsList: [],
    courseOfferList: [],
    typeRequest: [],
    subTypeRequest: [],
    subTypeReqStages: []
  };

  constructor(
    private _authService: AuthService,
    private _sweetalertService: SweetalertService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) { }

  ngOnInit(): void {
    this.userIDlogued = Number(this._authService.getCurrentUserID());
  }

  //On select request school filter
  onSelectSchool(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.allParamsToFilter.selectedCourseOffer = 0;
    if (Number(value) === 0) this.requestListFiltered = [...this.allRequestList];

    let local_offers: any[] = [];
    local_offers = this.allRequestList.filter(e => e.schoolID === Number(value))
      .map(e => e.requestCourseOffer)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
    this.filtersManagement.myRequest.courseOfferList = local_offers;
  }

  //On select type request filter
  onSelectType(event: Event) {
    const value: any = (event.target as HTMLInputElement).value;

    this.allParamsToFilter.selectSubtypeRequest = 0;
    if (Number(value) === 0) this.requestListFiltered = [...this.allRequestList];

    let local_subtypeRequest: any[] = [];
    local_subtypeRequest = this.allRequestList.filter(e => e.typeRequestID === Number(value))
      .map(e => e.requestSubTypeRequest)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
    this.filtersManagement.myRequest.subTypeRequest = local_subtypeRequest;
  }

  //On select subtype request filter
  onSelectSubtype(event: Event) {
    const value: any = (event.target as HTMLInputElement).value;
    let local_subTypeReq = this.filtersManagement.myRequest.subTypeRequest.find(item => item.id == this.allParamsToFilter.selectSubtypeRequest);
    if (local_subTypeReq?.subTypeReqStages) this.filtersManagement.myRequest.subTypeReqStages = local_subTypeReq!.subTypeReqStages;
  }

  //Filter by process buttons
  filterByProcess(index: number) {
    if (index == this.allParamsToFilter.filterBtnSelected) this.allParamsToFilter.filterBtnSelected = 0;
    else this.allParamsToFilter.filterBtnSelected = index;
    this.applyFilters();
  }

  //Apply all filters
  applyFilters() {
    console.log('allRequestList',this.allRequestList)
    console.log('allParamsToFilter',this.allParamsToFilter)
    this.requestListFiltered = this.allRequestList.filter(e => (
      ( 
        this.allParamsToFilter.initialDate === '' ||
        new Date(e.registerDate).getTime() >= new Date(this.allParamsToFilter.initialDate+'T00:00:00.000').getTime()
      ) &&
      ( 
        this.allParamsToFilter.finalDate === '' ||
        new Date(e.registerDate).getTime() <= new Date(this.allParamsToFilter.finalDate+'T23:59:59.999').getTime()
      ) &&
      (
        e.subTypeRequestID === this.allParamsToFilter.selectSubtypeRequest
      ) &&
      (
        e.courseOfferID === this.allParamsToFilter.selectedCourseOffer
      ) &&
      (
        this.allParamsToFilter.paidFilter === null ||
        e.isPaid === this.allParamsToFilter.paidFilter
      ) &&
      (
        e.stateReqID === this.allParamsToFilter.stateReq
      ) &&
      (
        e.stageReqID === this.allParamsToFilter.stageReq
      )
    ));
    this.allParamsToFilter.showModal = false
    this._sweetalertService.swalClose();
    console.log('requestListFiltered',this.requestListFiltered)
  }

  //Clean all filters
  cleanAllFilters() {
    this.filtersToShow.courseOfferList = [];
    this.filtersToShow.subTypeRequest = [];
    this.filtersManagement.myRequest.subTypeReqStages = [];
    this.allParamsToFilter = {
      showModal: false,
      initialDate: '',
      finalDate: '',
      paidFilter: null,
      userIdToFilter: '',
      tabSelected: null,
      filterBtnSelected: 0,
      selectedCourseOffer: 0,
      selectSubtypeRequest: 0,
      stateReq: 0,
      stageReq: 0
    }
    this.applyFilters();
  }

  /* ************************************* USEFULL *************************************  */
  allReqSelected: Request[] = [];
  // Filtros data
  allParamsToFilter: FilterParametersSqueleton = {
    showModal: true,
    initialDate: '',
    finalDate: '',
    paidFilter: null,
    userIdToFilter: '',
    tabSelected: null,
    filterBtnSelected: 0,
    selectedCourseOffer: 0,
    selectSubtypeRequest: 0,
    stateReq: 0,
    stageReq: 0
  }
  // public showFiltersModal: boolean = false;

  validarReqListClicek(itemSelected: Request): void {
    // this.removeReqToMassiveList(itemSelected);
    // this.addReqToMassiveList(itemSelected);
    console.log('itemSelected:', itemSelected);
    let indexSelected = this.allReqSelected.findIndex(req => req.id == itemSelected.id);
    console.log('indexSelected:', indexSelected);
    if (indexSelected == -1) this.allReqSelected.push(itemSelected);
    else this.allReqSelected.splice(indexSelected, 1);
    console.log('allReqSelected:', this.allReqSelected);
  }

  addReqToMassiveList(itemSelected: Request): void {
    console.log('itemSelected:', itemSelected);
    let indexSelected = this.allReqSelected.findIndex(req => req.id == itemSelected.id);
    console.log('indexSelected:', indexSelected);
    if (indexSelected == -1) this.allReqSelected.push(itemSelected);
    console.log('allReqSelected:', this.allReqSelected);
  }

  removeReqToMassiveList(itemSelected: Request): void {
    console.log('itemSelected:', itemSelected);
    let indexSelected = this.allReqSelected.findIndex(req => req.id == itemSelected.id);
    console.log('indexSelected:', indexSelected);
    if (indexSelected >= 0) this.allReqSelected.splice(indexSelected, 1);
    console.log('allReqSelected:', this.allReqSelected);
  }

  findReqInMassiveList(itemSelected: Request): boolean {
    let indexFinded = this.allReqSelected.findIndex(req => req.id == itemSelected.id);
    return indexFinded >= 0;
  }

  addDeleteAllReqs(): void {
    if (this.allReqSelected.length > 0) this.allReqSelected = [];
    else this.allReqSelected.push(...this.requestListFiltered);
  }

  manageAllReqsSelected(): void {
    if (this.allReqSelected.length == 0) return;
    let data = { showBox: true, createView: false, infoView: true, isMassive: true };
    this._requestWatcherUserService.manageFloatingBoxAdmin(data);
    this._requestWatcherUserService.updateAllMasiveReqObservable(this.allReqSelected);
  }

  navigateBtSection(indexView: number): void {
    this._requestWatcherUserService.updateSectionAdminToViewObservable(indexView);
  }

  getDaysBetweenTwoDates(date: Date): number {
    let today = new Date().toISOString().slice(0, 10);
    let startDate = date.toString().split('T')[0];
    let endDate = today;
    let diffInMs = (new Date(endDate).getTime()) - (new Date(startDate).getTime())
    let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays;
  }

}

class FiltersSqueleton {
  schoolsList: School[];
  courseOfferList: CourseOffer[];
  typeRequest: TypeRequest[];
  subTypeRequest: SubTypeRequest[];
  subTypeReqStages: StageReq[];
}
class ManagmentFiltersSqueleton {
  myRequest: FiltersSqueleton;
}

class FilterParametersSqueleton {
  showModal: boolean;
  initialDate: string;
  finalDate: string;
  paidFilter: boolean | null;
  userIdToFilter: string;
  tabSelected?: number | null;
  filterBtnSelected: number;
  selectedCourseOffer: number;
  selectSubtypeRequest: number;
  stateReq: number;
  stageReq: null | number;
}