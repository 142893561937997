import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

@Component( {
  selector: 'ci-select',
  templateUrl: './ci-select.component.html',
  styleUrls: [ './ci-select.component.css' ]
} )
export class CiSelectComponent implements OnInit {

  constructor () {
    //
  }

  @ViewChildren('dateFilter') dateFilter:QueryList<ElementRef>;

  //Emits a call when an option is selected
  @Output( 'onSelectOption' ) onSelectOption: EventEmitter<any> = new EventEmitter();

  //Emits a call when the start date is selected
  @Output( 'onSelectStartDate' ) onSelectStartDate: EventEmitter<Date> = new EventEmitter();

  //Emits a call when the end date is selected
  @Output( 'onSelectEndDate' ) onSelectEndDate: EventEmitter<Date> = new EventEmitter();

  //Emits a call when the clear filters button is clicked
  @Output( 'onClearFilters' ) onClearFilters: EventEmitter<void> = new EventEmitter();

  //Receives the option object list
  private _optionList: any[] = [];
  @Input( 'optionList' ) set optionList( value: any[] ) {
    if ( value.length > 0 ) {
      this._optionList = this._optionList.concat( value );
    }
  }

  //Stores the selected option
  private _selectedOption:any = { id:0, value:'Ninguno' };
  @Input('selectedOption') set selectedOption( value:any ){
    if(value){
      this._selectedOption = value;
    }
  } 

  get selectedOption(){
    return this._selectedOption;
  }

  get optionList() {
    return this._optionList;
  }

  //Receives an unique identifier for the select
  @Input( 'selectorId' ) selectorId: string = 'my-select';

  //Allows to define whether date inputs are displayed or not.
  @Input( 'showDate' ) showDateSection: boolean = true;

  //Allows to define whether or not to disable select
  @Input( 'disableSelect' ) disableSelect: boolean = false;

  //Allows to define whether or not the select is invalid
  @Input( 'isInvalidValid' ) isInvalidValid: boolean = false;

  //Toggles the option list
  protected showSection: boolean = false;


  ngOnInit(): void {
    //
  }


  protected onOptionClick( event: any ) {
    this.selectedOption = event;
    this.showSection = false;
    this.onSelectOption.emit( this._selectedOption );
  }

  protected showDatePicker( event: any ) {
    event.target.showPicker();
  }

  protected getStartDate( event: any ) {
    this.onSelectStartDate.emit( event.target.value );
  }

  protected getEndDate( event: any ) {
    this.onSelectEndDate.emit( event.target.value );    
  }

  protected clearFilters(){
    this._selectedOption = this._optionList[0];

    this.dateFilter.forEach( e => {
      e.nativeElement.value = '';
    })

    this.onClearFilters.emit();
  }

  @HostListener( 'document:click', [ '$event.target' ] )
  onClick( targetElement ) {
    if ( !targetElement.closest( '.ci-select__inner--' + this.selectorId ) ){
      this.showSection = false;
    }
  }
}
