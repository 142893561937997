import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseOfferService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getAllActiveCoursesBySchool(idschool: number): Observable<any> {
    let url: string = `/CoursesOfers?filter={
      "where":{
        "and": [
          {"SchoolID":${idschool}},
          {"IsActive":1} 
        ]
      },
      "include":[
        "course",
        "TypeCourseOFer",
        {
          "relation":"UserCourses",
          "scope": {
            "fields": [
              "CourseOferID"
            ]
          }
        }
      ],
      "order":"SchoolID asc"
    }`
    return this._httpService.httpGet(url);
  }
}
