<div class="bar">
  <div class="bar__container">
    <button class="mr-3" *ngIf="!isListSaved" (click)="createRequest()">Crear</button>
    <button class="mr-3" *ngIf="isListSaved" (click)="exportUserList()">Descargar listado</button>
    <!-- <button class="mr-3" *ngIf="isListSaved">Reasignar</button> -->
  </div>
  <div class="bar__container" *ngIf="isListSaved">
    <!-- <button class="ml-3">Aprobar</button>
    <button class="ml-3">Denegar</button> -->
    <button class="ml-3" (click)="fileInput.click()">
      Subir Listado
      <input #fileInput type="file" (change)="onFileInput($event)" accept=".xlsx, .xls" style="display:none;" />
    </button>





  </div>
</div>