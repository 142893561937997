<div class="request-chat">

  <div class="request-chat__header">
    <div class="header-request">
      <div class="header-request__chat-info">
        <div class="chat-info" *ngIf="requestData.requestUserOwner">
          <div class="chat-info__img" [ngStyle]="{'background-image': 'url('+requestData.requestUserOwner.Photo+')'}"></div>
          <div class="chat-info__text">
            <div class="chat-info__request-name"><strong>Gestionado por:</strong></div>
            <div class="chat-info__name">{{requestData.requestUserOwner.Name1}} {{requestData.requestUserOwner.LastName1}}</div>
            <div class="chat-info__email">{{requestData.requestUserOwner.email}}</div>
            <div class="chat-info__request-name">{{requestData.requestTypeRequest?.nameTypeRequest}} - {{requestData.requestSubTypeRequest?.nameSubTypeRequest}}</div>
            <div class="chat-info__request-time">(Solicitado el {{requestData.registerDate | date:'dd/MMM/yyyy'  }})</div>
          </div>
        </div>
      </div>
      <div class="header-request__close">
        <a (click)="closeRequestDataBox()"><em class="minimize-icon icon-i-close"></em></a>
      </div>
    </div>
  </div>

  <div class="header-request__tabs" *ngIf="viewType">
    <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 1}" (click)="viewType = 1">Chat administradores</a>
    <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 2}" (click)="viewType = 2">Chat usuario</a>
    <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 3}" (click)="viewType = 3" *ngIf="[1,2,5].includes(requestData.stateReqID!)">Documentos y estado</a>
    <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 4}" (click)="viewType = 4">Detalles de la solicitud</a>
    <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 5}" (click)="viewType = 5">Historial de pagos</a>
  </div>

  <div class="request-chat__content">
    <!-- ADMIN chat-view -->
    <div class="content-chat" *ngIf="viewType === 1">
      <div class="content-chat__messages-container">
        <request-chat-message *ngFor="let item of allRequestMessagesAdmin" [messageInfo]="item"></request-chat-message>
      </div>
    </div>
    <!-- USER chat-view -->
    <div class="content-chat" *ngIf="viewType === 2">
      <div class="content-chat__messages-container">
        <request-chat-message *ngFor="let item of allRequestMessages" [messageInfo]="item"></request-chat-message>
      </div>
    </div>
    <!-- documents-view -->
    <div class="content-documents" *ngIf="viewType === 3">
      <div class="infoBox" *ngIf="requestData.stateReqID == 2 && (isPrincipalAdmin() || showAproveBtns)">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-aprove"></em>
          <span>Asociar personal al proceso</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección usted podrá asociar personal al proceso.</span>
        </div>
        <div class="infoBox_content">
          <div class="btnContainer" *ngIf="!showReasingList">
            <button class="btnContainer__generalBtn" (click)="showReasingList = true">
              BUSCAR PERSONAL
            </button>
          </div>
          <div class="btnContainer choseNewAdmin" *ngIf="showReasingList">
            <!-- MODAL -->
            <div *ngFor="let newAdmin of newAdminBox; index as i">
              <select name="typeRequestID" [(ngModel)]="newAdminBox[i]" class="mb-3">
                <option disabled [ngValue]="undefined">Seleccione</option>
                <option *ngFor="let admin of adminsList" [ngValue]="admin">{{ admin.school?.SchoolAcronim }} - {{ admin.userapp?.CedocEmail }}</option>
              </select>
            </div>
            <div class="textAreaContainer">
              <textarea name="newAdminComment" id="newAdminComment" rows="4" placeholder="En este campo puede agregar un comentario (Opcional)."></textarea>
            </div>
            <button class="btnContainer__generalBtn mt-2" (click)="addNewAdminToList()" *ngIf="newAdminBox.length != adminsList.length">
              OTRO ADMIN
            </button>
            <button class="btnContainer__generalBtn mt-2" (click)="validateNewAdminSelected()">
              CONFIRMAR
            </button>
            <button class="btnContainer__generalBtn mt-2" (click)="showReasingList = false">
              CANCELAR
            </button>
            <!-- MODAL -->
          </div>
        </div>
      </div>
      <div class="infoBox" *ngIf="requestData.reqMembers != undefined && requestData.reqMembers!.length > 0">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-gear"></em>
          <span>Aprobaciones</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección usted podrá aprobar y/o ver las aprobaciones de terceros.</span>
        </div>

        <div class="infoBox_content" *ngIf="requestData.stateReqID == 2 && showAproveBtns">
          <div class="commentContainer">
            <span>Este comentario se guardará cuando acepte o rechace su parte del proceso (opcional).</span> <br>
            <input type="text" [(ngModel)]="reqMemberComment" placeholder="Comentario (Opcional)">
          </div>
          <div class="btnContainer" *ngIf="!showGetReqMemberByStaff; else btnsToStaff">
            <button class="btnContainer__generalBtn" (click)="showModalConfirmResponseReqMember(true, 1)">
              Cumple
            </button>
            <button class="btnContainer__generalBtn" (click)="showModalConfirmResponseReqMember(false, 1)">
              No cumple
            </button>
          </div>
          <ng-template #btnsToStaff>
            <div class="btnContainer">
              <button class="btnContainer__generalBtn" (click)="showModalConfirmResponseReqMember(true, 2)">
                Cumple
              </button>
              <button class="btnContainer__generalBtn" (click)="showModalConfirmResponseReqMember(false, 2)">
                No cumple
              </button>
            </div>
          </ng-template>
        </div>

        <div class="infoBox_content" *ngIf="requestData.stateReqID == 2 && showGetReqMemberByStaff && !showAproveBtns">
          <div class="commentContainer">
            <span>En esta sección usted podrá apoyar la atención de la solicitud seleccionada.</span>
          </div>
          <div class="btnContainer">
            <button class="btnContainer__generalBtn" (click)="showModalConfirmAssistReq()">
              Atender solicitud
            </button>
          </div>
        </div>

        <div>
          <div class="mt-3" *ngFor="let user of requestData.reqMembers">
            <span>{{ user.numSequence }} - {{ getNameUser(user.userapp!) }}</span>
            <span *ngIf="user.realName"> ({{ user.realName }})</span>
            <span>: </span>
            <strong>{{ getReqMembersState(user.isAproved) }}</strong> <br>
            <ng-container *ngFor="let staff of user.reqMemberStaff">
              <span *ngIf="staff.wasAssisted">Asistido por: {{ getNameUser(staff.userapp!) }}<br></span>
            </ng-container>
            <!-- <span *ngIf="getLastAdminDate(user)">En trámite desde: {{ getLastAdminDate(user) | date:'dd/MMM/yyyy HH:mm' }}<br></span> -->
            <span *ngIf="user.processedAT">En trámite desde: {{ user.assignedAT | date:'dd/MMM/yyyy HH:mm' }}<br></span>
            <span *ngIf="user.processedAT">Aprobado: {{ user.processedAT | date:'dd/MMM/yyyy HH:mm' }}<br></span>
            <span *ngIf="user.comment != null">Comentario: {{ (user.comment != null && user.comment != '') ? user.comment : 'Sin comentarios' }}</span>
          </div>
        </div>

      </div>
      <div class="infoBox" *ngIf="isPrincipalAdmin()">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-gear"></em>
          <span>Gestión de estados ({{ requestData.requestStateReq?.nameState }})</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección usted podrá gestionar los estados del proceso seleccionado</span>
        </div>
        <div class="infoBox_content">
          <div class="stateContainer">
            <div>
              <em class="mr-2 icon-i-ok"></em> Cambiar estado de trámite actual
            </div>

            <div class="stateContainer__sectionBtns">
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                *ngIf="[1,5].includes(requestData.stateReqID!)"
                (click)="updateStateReq(2)">EN REVISIÓN</button>
              <button *ngIf="requestData.requestSubTypeRequest?.fileUrl != null && requestData.stateReqID == 2"
                class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="aproveReq()"
              >APROBAR Y GENERAR</button>
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="updateStateReq(5)"
                *ngIf="requestData.stateReqID == 2">INCOMPLETO</button>
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="showModalConfirmChangeStateValue(3)"
                *ngIf="requestData.stateReqID == 2">APROBAR</button>
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                *ngIf="requestData.stateReqID == 2" (click)="showModalConfirmChangeStateValue(4)">NO APROBAR</button>
            </div>
          </div>

          <div class="stateContainer" *ngIf="requestData.stateReqID == 2 && requestData.requestSubTypeRequest?.subTypeReqStages && requestData.requestSubTypeRequest?.subTypeReqStages!.length > 0">
            <div>
              <em class="mr-2 icon-i-ok"></em> Cambiar estado interno <strong *ngIf="requestData.requestStageReq?.nameStage">({{requestData.requestStageReq?.nameStage}})</strong>
            </div>
            <div class="commentContainer">
              <span>Ingrese una descripción (Obligatorio, mínimo 10 caracteres).</span> <br>
              <input type="text" [(ngModel)]="stageReqHistoryDescription" placeholder="Descripción (Obligatorio)">
            </div>
            <div class="stateContainer__sectionBtns">
              <ng-container *ngFor="let option of requestData.requestSubTypeRequest?.subTypeReqStages">
                <button *ngIf="option.id != requestData.stageReqID" class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="updateStageReq(option)">{{ option.nameStage }}</button>
              </ng-container>
            </div>
          </div>

          <div class="stateContainer" *ngIf="requestData.stateReqID == 2">
            <div>
              <em class="mr-2 icon-i-ok"></em> Cambiar estado de pago actual
            </div>
            <div class="stateContainer__sectionBtns">
              <button class="stateContainer__generalBtn"
                [ngClass]="{'stateContainer__generalBtn--paid': !requestData.isPaid }"
                (click)="updatePayment(false)">FALTA PAGO</button>
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                [ngClass]="{'stateContainer__generalBtn--paid': requestData.isPaid }"
                (click)="updatePayment(true)">PAGADO</button>
            </div>
          </div>

          <div class="stateContainer" *ngIf="requestData.stateReqID == 2 && !requestData.requestSubTypeRequest?.autoGenerateLink && requestData?.dispatchOrders?.length == 0">
            <div>
              <em class="mr-2 icon-i-ok"></em> Generar link de pago por valor de ${{ requestData.TotalPayment | number }}
            </div>
            <div class="stateContainer__sectionBtns">
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                [ngClass]="{'stateContainer__generalBtn--paid': requestData.isPaid }"
                (click)="setPriceV2(requestData.TotalPayment!)">CONFIRMAR</button>
            </div>
          </div>

          <div class="stateContainer" *ngIf="requestData.stateReqID == 2 && !requestData.requestSubTypeRequest?.autoGenerateLink && requestData?.dispatchOrders?.length == 0">
            <div>
              <em class="mr-2 icon-i-ok"></em> Asignar precio (Precio actual ${{ requestData.TotalPayment | number }})
            </div>
            <div class="stateContainer__sectionBtns">
              <input type="number" class="price-input" [(ngModel)]="priceInputValue" (keyup)="setPrice( $event )">
              <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                [ngClass]="{'stateContainer__generalBtn--paid': requestData.isPaid, 'disabled': priceInputValue <= 0 || priceInputValue === undefined }"
                (click)="showConfirmPaymentModal = true">CONFIRMAR</button>
            </div>
          </div>
        </div>
      </div>
      <div class="infoBox">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-documents"></em>
          <span>Documentos enviados en la solicitud</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección ustéd podrá ver, corroborar y descargar los documentos enviados por el solicitante</span>
        </div>
        <div *ngIf="[2,5].includes(requestData.stateReqID!)">
          <ng-container *ngFor="let document of allDocumentMessagesPlaceholders">
            <div class="fileContainer mt-3" [ngClass]="{ 'fileContainer--pending': document?.documents?.[0]?.isAproved == null, 'fileContainer--pendingUpload': document.documents![0].urlFile == null }">
              <div class="descriptionFile">
                <strong>{{ document.documents![0].nameFile }}</strong> <br>
                {{ document.documents![0].descript }} <br>
                <a *ngIf="document.documents![0].urlFile != null" class="document-box__action" rel="noopener" [href]="urlBackV1 + document.documents![0].urlFile" target="_blank">Ver documento</a>
              </div>
              <div class="manageFile" *ngIf="isPrincipalAdmin() && document?.documents?.[0]?.isAproved == null && document.documents![0].urlFile != null">
                <button class="manageFile__generalBtn btn__ok" (click)="validateFile(document, true)">
                  Aprobar
                </button>
                <button class="manageFile__generalBtn btn__notok" (click)="validateFile(document, false)">
                  Rechazar
                </button>
              </div>
              <div class="status_default status_ok" *ngIf="document?.documents?.[0]?.isAproved == true">
                Aprobado
              </div>
              <div class="status_default status_notok" *ngIf="document?.documents?.[0]?.isAproved == false">
                Rechazado
              </div>
              <div class="status_default status_notuploaded" *ngIf="document.documents![0].urlFile == null">
                Documento no cargado
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="infoBox">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-documents"></em>
          <span>Documentos enviados por el chat del solicitante</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección ustéd podrá ver, corroborar y descargar los documentos enviados por el solicitante</span>
        </div>

        <div *ngIf="[2,5].includes(requestData.stateReqID!)">
          <div class="fileContainer mt-3" *ngFor="let document of allDocumentMessages" [ngClass]="{ 'fileContainer--pending': document?.documents?.[0]?.isAproved == null }">
            <div class="descriptionFile">
              <strong>{{ document.documents![0].nameFile }}</strong> <br>
              {{ document.documents![0].descript }} <br>
              <a class="document-box__action" rel="noopener" [href]="urlBackV1 + document.documents![0].urlFile" target="_blank">Ver documento</a>
            </div>
            <div class="manageFile" *ngIf="isPrincipalAdmin() && document?.documents?.[0]?.isAproved == null">
              <button class="manageFile__generalBtn btn__ok" (click)="validateFile(document, true)">
                Aprobar
              </button>
              <button class="manageFile__generalBtn btn__notok" (click)="validateFile(document, false)">
                Rechazar
              </button>
            </div>
            <div class="status_default status_ok" *ngIf="document?.documents?.[0]?.isAproved == true">
              Aprobado
            </div>
            <div class="status_default status_notok" *ngIf="document?.documents?.[0]?.isAproved == false">
              Rechazado
            </div>
          </div>
        </div>
      </div>
      <div class="infoBox">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-documents"></em>
          <span>Documentos enviados por el chat de los administradores</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección ustéd podrá ver, corroborar y descargar los documentos enviados por los
            administradores</span>
        </div>

        <div>
          <div class="fileContainer mt-3" *ngFor="let document of allDocumentMessagesAdmin">
            <div class="descriptionFile">
              <strong>{{ document.documents![0].nameFile }}</strong> <br>
              {{ document.documents![0].descript }} <br>
              <a class="document-box__action" rel="noopener" [href]="urlBackV1 + document.documents![0].urlFile" target="_blank">Ver documento</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- request-summary-view -->
    <div class="content-request-summary" *ngIf="viewType === 4">
      <div class="summary-subtitle">Su información para esta solicitud</div>
      <div class="summary-date">
        <div class="summary-date__label">Tipo de solicitud</div>
        {{ requestData.requestTypeRequest?.nameTypeRequest }}
      </div>
      <div class="summary-date">
        <div class="summary-date__label">Subtipo de solicitud</div>
        {{ requestData.requestSubTypeRequest?.nameSubTypeRequest }}
      </div>
      <div class="summary-date">
        <div class="summary-date__label">Fecha de solicitud</div>
        {{ requestData.registerDate | date:'dd/MMM/yyyy' }}
      </div>
      <div class="summary-date">
        <div class="summary-date__label">Oferta</div>
        {{ requestData.requestCourseOffer?.NameCourseOfer }}
      </div>
      <div class="summary-date">
        <div class="summary-date__label">Cupos Totales</div>
        {{ requestData.requestCourseOffer?.TotalPeople }}
      </div>

      <div class="summary-date" *ngIf="requestData.documentLink">
        <div class="summary-date__label">
          <a [href]="urlBackV1 + requestData.documentLink" target="_blank">Ver certificado generado</a>
        </div>
      </div>

      <div class="summary-date" *ngIf="[2, 3].includes(requestData.stateReqID!)">
        <a class="document-box__action" href="/request/blueFormat/{{requestData.id}}" target="_blank">
          <div class="text-item__label">
            Formato azul
          </div>
        </a>
      </div>

      <div class="linksContainer">
        <a class="document-box__action" href="/Cv-Public/{{requestData.requestUserRequester?.CedocEmail}}"
          target="_blank">
          <div class="text-item__label">
            Ver hoja de vida
          </div>
        </a>
        <a class="document-box__action" href="/academicStatus/{{requestData.requestUserRequester?.CedocEmail}}"
          target="_blank">
          <div class="text-item__label">
            Ver historial académico
          </div>
        </a>
        <a *ngIf="PRINCIPAL_APP_NAME == 'celic'" class="document-box__action" href="/academicStatus/observations/{{requestData.requestUserRequester?.id}}"
          target="_blank">
          <div class="text-item__label">
            Ver observador virtual
          </div>
        </a>
      </div>

      <div *ngIf="PRINCIPAL_APP_NAME == 'celic' && acudienteData">
        <div class="summary-subtitle">
          Información del responsable de pago
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">Nombre:</div>
            {{acudienteData.Name1}} 
            {{acudienteData.Name2}} 
            {{acudienteData.LastName1}} 
            {{acudienteData.LastName2}}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">{{ acudienteData.UserDocuments?.[0]?.TypeDocuments?.NameTypeDocument }}: </div> {{ acudienteData.UserDocuments?.[0]?.Document }}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">Número telefónico: </div> {{ acudienteData.ContactInfos?.[0]?.CellPhone }}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">E-mail</div> {{acudienteData.email}}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">Rango</div> {{ (acudienteData.IsMilitar && acudienteData.MilitarInfos && acudienteData.MilitarInfos.length > 0) ? acudienteData.MilitarInfos[0].Range.NameRange : 'Civil' }}
          </div>
        </div>
        <div class="text-inline" *ngIf="acudienteData?.IsMilitar && acudienteData.MilitarInfos && acudienteData.MilitarInfos.length > 0">
          <div class="text-item">
            <div class="text-item__label">Cédula militar</div> {{ acudienteData.MilitarInfos[0].CedulaMil }}
          </div>
        </div>
        <br><br>
        <div class="summary-subtitle">
          Información del Alumno
        </div>
      </div>

      <div class="text-item">
        <div class="text-item__label">Nombre Solicitante:</div>
        {{ requestData.requestUserRequester?.LastName1 }}
        {{ requestData.requestUserRequester?.LastName2 }}
        {{ requestData.requestUserRequester?.Name1 }}
        {{ requestData.requestUserRequester?.Name2 }}
      </div>
      <div class="text-item text-item--action" *ngIf="false">
        Ver historial de solicitudes y pagos
      </div>
      <div class="text-item">
        <div class="text-item__label">E-mail</div>
        {{ requestData.requestUserRequester?.CedocEmail }}
      </div>
      <div class="text-item">
        <div class="text-item__label">Tipo Documento</div>
        {{ requestData.requestUserRequester?.UserDocuments?.[0]?.TypeDocuments?.NameTypeDocument }}
      </div>
      <div class="text-item">
        <div class="text-item__label">Número Documento</div>
        {{ requestData.requestUserRequester?.UserDocuments?.[0]?.Document }}
      </div>
      <div class="text-item" *ngIf="requestData.requestUserRequester?.IsMilitar">
        <div class="text-item__label">Cédula militar</div>
        {{ requestData.requestUserRequester?.MilitarInfos![0]?.CedulaMil }}
      </div>
      <div class="text-item" *ngIf="false">
        <div class="text-item__label">Cédula de ciudadadanía</div>
        {{ getUserData(2) }}
      </div>
      <div class="text-item" *ngIf="false">
        <div class="text-item__label">Cédula de ciudadadanía</div>
        {{ getUserData(2) }}
      </div>
      <div class="text-inline" *ngIf="false">
        <div class="text-item">
          <div class="text-item__label">Cédula militar</div>
          {{ requestData.requestUserRequester?.IsMilitar ? 'Si' : 'No'}}
        </div>
        <div class="text-item">
          <div class="text-item__label">N¬</div>
          {{ getUserData(0) }}
        </div>
      </div>
      <div class="text-item" *ngIf="false">
        <div class="text-item__label">Rango</div>
        {{ getUserData(1) }}
      </div>

      <br><br>
      <div class="summary-subtitle">Descripción de la solicitud</div>
      <div class="text-item text-item__noFlex">
        <span [innerHTML]="requestData.requestSubTypeRequest?.descript"></span>
      </div>

    </div>
    <!-- request-summary-view -->
    <div class="content-request-summary" *ngIf="viewType === 5">
      <div class="summary-title">Historial de pagos</div>
      <div class="paymentHistory">
        <table class="table table-no-border"><caption></caption>
          <thead>
            <tr>
              <th>ID</th>
              <th>Estado</th>
              <th>Fecha</th>
              <th>Detalles</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataPayment.paymentHistory">
              <td>{{ item['id'] }}</td>
              <td>
                <div class="rowHistoryTable"
                  [ngClass]="{
                    'rowHistoryTable--green': item['state'] === 'PAGADO',
                    'rowHistoryTable--orange': ( item['state'] === 'EN PROCESO' || item['state'] === 'PENDIENTE' ),
                    'rowHistoryTable--red': item['state'] === 'RECHAZADO'
                  }"
                >
                  {{ item['state'] }}
                </div>
              </td>
              <td>{{ item['date'] | date:'dd/MMM/yyyy HH:mm' }}</td>
              <td>
                <a class="document-box__viewDetails" [href]="'/request/'+item['id']" target="_blank" *ngIf="item['gatewayData']">
                  Ver
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--  approval-view -->
    <div class="content-approval" *ngIf="viewType === null">

      <div class="content-approval__title">
        <em class="content-approval__back-icon icon-i-tutorial"></em>
        <div class="content-approval__text">Aprobación</div>
      </div>
      <div class="content-approval__descript">Requiere aprobación de:</div>
      <div class="content-approval__approvals-list">
        <div class="approval-item">
          <div class="approval-item__info">
            <div class="approval-item__info-left">
              <div class="check check--approve">
                <div class="check__filling check__filling--approve"></div>
              </div>
              <div class="approval-item__name">Bibliotecas</div>
            </div>
            <div class="approval-item__info-right">
              <button class="approval-button approval-button--approve">Aprobar</button>
              <button class="approval-button approval-button--denied">Rechazar</button>
            </div>
          </div>
          <div class="approval-item__write-description">REDACTAR DESCRIPCIÓN</div>
        </div>

        <div class="approval-item">
          <div class="approval-item__info">
            <div class="approval-item__info-left">
              <div class="check check--approve">
                <div class="check__filling"></div>
              </div>
              <div class="approval-item__name">Bibliotecas</div>
            </div>
            <div class="approval-item__info-right">
              <button class="approval-button approval-button--approve">Solicitar</button>
            </div>
          </div>
          <div class="approval-item__write-description">REDACTAR DESCRIPCIÓN</div>
        </div>

        <div class="approval-item">
          <div class="approval-item__info">
            <div class="approval-item__info-left">
              <div class="check check--denied">
                <div class="check__filling check__filling--denied"></div>
              </div>
              <div class="approval-item__name">Bibliotecas</div>
            </div>
            <div class="approval-item__info-right">
              <button class="approval-button approval-button--approve">Aprobar</button>
              <button class="approval-button approval-button--denied">Rechazar</button>
            </div>
          </div>
          <div class="approval-item__write-description">REDACTAR DESCRIPCIÓN</div>
        </div>
      </div>

    </div>
  </div>

  <div class="request-chat__sendMessage" *ngIf="viewType && requestData.stateReqID != 1 && [1, 2].includes(viewType)">
    <div *ngIf="viewType === 1">
      <div class="content-chat__input-container">
        <div class="documents-selected" *ngIf="fileToUpload.length > 0">
          <div class="document-box" *ngFor="let file of fileToUpload; index as i">
            <div class="document-box__name">{{ file.name }}</div>
            <a (click)="deleteFileBeforeUpload(i)"><em class="document-box__delete icon-i-close"></em></a>
          </div>
        </div>
        <div *ngIf="showDocumentSelector else chatOnlyText">
          <upload-file-box [containerName]="nameBucketRequestInfo"
            [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
            (responseDataFile)="getDataFromUploadFileComponent_ADMIN($event)"
            (closeView)="closeUploadFileComponent()"></upload-file-box>
        </div>
        <ng-template #chatOnlyText>
          <div class="input-container">
            <div class="input-container__input">
              <textarea [(ngModel)]="msgToSendAdmin" type="text" placeholder="Escriba su mensaje"
                class="input-container__input-msg"></textarea>
            </div>
            <div class="input-container__buttons">
              <a (click)="sendMessageToAdmin()">
                <div class="input-container__icon-button">
                  <em class="input-container__icon icon-i-send"></em>
                </div>
              </a>
              <a (click)="showDocumentSelector = !showDocumentSelector">
                <div class="input-container__icon-button input-container__icon-button--selected">
                  <em class="input-container__icon icon-i-add-file"></em>
                </div>
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="viewType === 2">
      <div class="content-chat__input-container">
        <div class="documents-selected" *ngIf="fileToUpload.length > 0">
          <div class="document-box" *ngFor="let file of fileToUpload; index as i">
            <div class="document-box__name">{{ file.name }}</div>
            <a (click)="deleteFileBeforeUpload(i)"><em class="document-box__delete icon-i-close"></em></a>
          </div>
        </div>
        <div *ngIf="showDocumentSelector else chatOnlyText">
          <upload-file-box [containerName]="nameBucketRequestInfo"
            [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
            (responseDataFile)="getDataFromUploadFileComponent_USER($event)"
            (closeView)="closeUploadFileComponent()"></upload-file-box>
        </div>
        <ng-template #chatOnlyText>
          <div class="input-container">
            <div class="input-container__input">
              <textarea [(ngModel)]="msgToSendUser" type="text" placeholder="Escriba su mensaje"
                class="input-container__input-msg"></textarea>
            </div>
            <div class="input-container__buttons">
              <a (click)="sendMessageToStudent()">
                <div class="input-container__icon-button">
                  <em class="input-container__icon icon-i-send"></em>
                </div>
              </a>
              <a (click)="showDocumentSelector = !showDocumentSelector">
                <div class="input-container__icon-button input-container__icon-button--selected">
                  <em class="input-container__icon icon-i-add-file"></em>
                </div>
              </a>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  
</div>

<div class="confirm-price__modal" *ngIf="showConfirmPaymentModal">
  <div class="confirm-price__modal-content">
    <em class="icon-i-payment payment-icon"></em>
    <h2 class="modal-content__title">Asignación de precio</h2>
    <p class="modal-content__paragraph">Al aceptar le generará un recibo al usuario con el precio asignado ( {{ priceInputValue | currency:'COP' }} ) para la solicitud de</p>
    <p class="modal-content__paragraph--italic">
      {{ requestData.requestTypeRequest?.nameTypeRequest }} - {{ requestData.requestSubTypeRequest?.nameSubTypeRequest }}: {{ requestData.requestCourseOffer?.NameCourseOfer }}
    </p>
    <div class="modal-content__actions">
      <button class="modal-content__actions-buttons modal-content__actions-buttons--red"
        (click)="showConfirmPaymentModal = false">
        CANCELAR
      </button>
      <button class="modal-content__actions-buttons modal-content__actions-buttons--green"
        (click)="confirmPayment()"
        [ngClass]="{ 'disabled': uploadingPaymentOrder }" >
        CONFIRMAR
    </button>
    </div>
  </div>
</div>

<div class="confirm-changeState__modal" *ngIf="showConfirmChangeStateModal">
  <div class="confirm-changeState__modal-content">
    <em class="icon-i-payment payment-icon"></em>
    <h2 class="modal-content__title">Cambio de estado</h2>
    <p class="modal-content__paragraph">Al aceptar el cambio de estado la solicitud se cerrará y NO podrá modificarse nuevamente ¿Está seguro de cambiar el estado?</p>
    <div class="modal-content__actions">
      <button class="modal-content__actions-buttons modal-content__actions-buttons--red"
        (click)="showConfirmChangeStateModal = false">
        CANCELAR
      </button>
      <button class="modal-content__actions-buttons modal-content__actions-buttons--green"
        (click)="confirmChangeStateValue()" >
        CONFIRMAR
    </button>
    </div>
  </div>
</div>

<div class="confirm-changeState__modal" *ngIf="dataToAssist.showModal">
  <div class="confirm-changeState__modal-content">
    <h2 class="modal-content__title">Apoyar solicitud</h2>
    <p class="modal-content__paragraph">Al confirmar el apoyo a la solicitud, nadie más la podrá responder ¿Está seguro?</p>
    <div class="modal-content__actions">
      <button class="modal-content__actions-buttons modal-content__actions-buttons--red"
        (click)="dataToAssist.showModal = false">
        CANCELAR
      </button>
      <button class="modal-content__actions-buttons modal-content__actions-buttons--green"
        (click)="confirmResponseReqMemberAssist()" >
        CONFIRMAR
    </button>
    </div>
  </div>
</div>

<div class="confirm-changeState__modal" *ngIf="dataToResponseReqMember.showModal">
  <div class="confirm-changeState__modal-content">
    <h2 class="modal-content__title">Responder</h2>
    <p class="modal-content__paragraph">Al confirmar la respuesta a la solicitud no podrá cambiarla en un futuro ¿Está seguro?</p>
    <div class="modal-content__actions">
      <button class="modal-content__actions-buttons modal-content__actions-buttons--red"
        (click)="dataToResponseReqMember.showModal = false">
        CANCELAR
      </button>
      <button class="modal-content__actions-buttons modal-content__actions-buttons--green"
        (click)="confirmResponseReqMemberAssist()" >
        CONFIRMAR
    </button>
    </div>
  </div>
</div>
