import { Component, Input, OnInit } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { FileReq } from 'src/app/models/dbModels/fileReq';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-document-message',
  templateUrl: './document-message.component.html',
  styleUrls: ['./document-message.component.css']
})
export class DocumentMessageComponent implements OnInit {

  @Input() fileReq: FileReq; 

  public nameBucketRequestInfo = environment.nameBucketRequestInfo;
  public urlBackV1: any = environment.urlBackV1;
  public fileState: string = '';
  public fileStateClass: string = '';
  public fileUploaded: boolean = false;
  public localLoaded: boolean = false;

  private subscriptionTypeView: Subscription;

  constructor(
    private _requestService: RequestService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this.fileReqTemp = {
      // descript: this.fileReq.descript,
      // userID: this.fileReq.userID,
      // nameFile: this.fileReq.nameFile,
      // updateDate: new Date(Date.now()),
      // isAdmin: false,
      // isDocumentValid: false,
      urlFile: '',
      isAproved: null
      // requestID: this.fileReq.requestID
    }
  }

  ngOnInit(): void {
    this.fileUploaded = this.fileReq.urlFile != null;
    switch (this.fileReq.isAproved) {
      case null:
        this.fileState = 'Pendiende de revisión';
        this.fileStateClass = 'status_pending';
        break;
      case true:
        this.fileState = 'Aprobado';
        this.fileStateClass = 'status_ok';
        break;
      case false:
        this.fileState = 'Rechazado';
        this.fileStateClass = 'status_notok';
        break;
    }
  }

  public fileReqTemp: FileReq;

  getDataFromUploadFileComponent(data: InfoFileData): void {
    this.fileReqTemp.urlFile = data.urlFile;
    this.fileReqTemp.descript = data.descript;
    this.fileReqTemp.updateDate = new Date(Date.now());
    this.updateFileReqByID(this.fileReqTemp);
  }
  closeUploadFileComponent(): void {
    console.log('closeUploadFileComponent');
  }
  updateFileReqByID(fileReqToUpdate: FileReq): void {
    this._requestService.updateFileReq(this.fileReq.id!, fileReqToUpdate).pipe(take(1)).subscribe(
      data => {
        console.log('updateFileReqByID success', data);
        this.localLoaded = true;
        this.updateDataSaved();
      },
      err => {
        console.log('updateFileReqByID fail', err);
      }
    );
  }
  updateDataSaved(): void {
    console.log('updateDataSaved...');
    this._requestWatcherUserService.manageUpdateDataLoadedToShow(true);
  }
}
