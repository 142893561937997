import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-request-list-tag',
  templateUrl: './request-list-tag.component.html',
  styleUrls: ['./request-list-tag.component.css']
})
export class RequestListTagComponent implements OnInit {

  @Input() stageName?: string;
  @Input() date?: Date;
  @Input() color?: string;
  @Input() file?: string;
  @Input() activeChatButton?: boolean;
  @Input() reasigned?: string;
  @Input() dispatchOrdersList?: DispatchOrderI[] = [];

  @Output() activeChat = new EventEmitter<void>();

  public urlBackV1: any = environment.urlBackV1;
  public PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  constructor() {
    this.activeChatButton = false;
  };

  ngOnInit(): void {
    this.reviewPaymentState();
  }

  chatActivation() {
    this.activeChat.emit();
  }

  requestWithPayment: boolean = false;
  paymentStatus: string = 'SIN COSTO';
  reviewPaymentState() {
    if (this.dispatchOrdersList?.length == 0) return;
    let realStatusLocal = this.dispatchOrdersList![0].realStatus;
    switch (realStatusLocal) {
      case 'PENDING':
        this.paymentStatus = 'PENDIENTE';
        break;
      case 'APPROVED':
        this.paymentStatus = 'PAGADO';
        break;
      case 'PROCESS':
        this.paymentStatus = 'EN PROCESO';
        break;
      case 'CANCELLED':
      case 'REJECTED':
        this.paymentStatus = 'RECHAZADO';
        break;
    }
  }

}
