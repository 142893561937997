import { Component, Input, OnInit } from '@angular/core';
import { Request } from 'src/app/models/dbModels/request';
import { recentRequest } from 'src/app/models/logicModels/recentRequest';

@Component({
  selector: 'app-recent-requests',
  templateUrl: './recent-requests.component.html',
  styleUrls: ['./recent-requests.component.css']
})
export class RecentRequestsComponent implements OnInit {

  @Input() recentRequestList: Request[];

  constructor() {
    /** TODO: Componente sin uso */
  }

  ngOnInit(): void {
    /** TODO: Componente sin uso */
  }

}
