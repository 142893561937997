import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { RequestService } from './services/request.service';
import { HttpService } from './services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { AttachmentService } from './services/attachment.service';
import { SharedModule } from './components/shared/shared.module';
import { RequestViewComponent } from './pages/request-view/request-view.component';
import { RecentRequestsComponent } from './components/request-view/recent-requests/recent-requests.component';
import { RequestCreationComponent } from './components/request-view/request-creation/request-creation.component';
import { RecentRequestBoxComponent } from './components/request-view/recent-request-box/recent-request-box.component';
import { RequestsListComponent } from './components/request-view/requests-list/requests-list.component';
import { RequestChatComponent } from './components/request-view/request-chat/request-chat.component';
import { RequestViewAdminComponent } from './pages/request-view-admin/request-view-admin.component';
import { RequestEditionComponent } from './components/request-view-admin/request-edition/request-edition.component';
import { RequestsListAdminComponent } from './components/request-view-admin/requests-list-admin/requests-list-admin.component';
import { RequestListBoxStudentComponent } from './components/request-view-admin/request-list-box-student/request-list-box-student.component';
import { RequestChatAdminComponent } from './components/request-view-admin/request-chat-admin/request-chat-admin.component';
import { RequestMasiveListAdminComponent } from './components/request-view-admin/request-masive-list-admin/request-masive-list-admin.component';
import { BarMasiveListAdminComponent } from './components/shared/bar-masive-list-admin/bar-masive-list-admin.component';
import { ContinuousImprovementComponent } from './pages/continuous-improvement/continuous-improvement.component';
import { CiCreateFormComponent } from './components/continuous-improvement/ci-create-form/ci-create-form.component';
import { CiResponseFormComponent } from './components/continuous-improvement/ci-response-form/ci-response-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RequestMasiveOapAdminComponent } from './components/request-view-admin/request-masive-oap-admin/request-masive-oap-admin.component';
import { PaymentResultComponent } from './components/request-view/payment-result/payment-result.component';
import { CelicGouComponent } from './components/request-view/modals/celic-gou/celic-gou.component';
import { AllUsersBillsComponent } from './components/all-users-bills/all-users-bills.component';
import { UserRequestFiltersComponent } from './components/request-view/modals/user-request-filters/user-request-filters.component';
import { RequestResumeComponent } from './pages/request-resume/request-resume.component';

import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { BlueFormatComponent } from './components/request-view/modals/blue-format/blue-format.component';
import { MasiveReqViewAdminComponent } from './components/masive-req-view-admin/masive-req-view-admin.component';
import { MassiveDetailsReqBoxComponent } from './components/massive/massive-details-req-box/massive-details-req-box.component';
import { MasiveEditionFloatingBoxComponent } from './components/massive/masive-edition-floating-box/masive-edition-floating-box.component';
import { SemaforizacionInDaysComponent } from './components/adminMiniSingleComponents/semaforizacion-in-days/semaforizacion-in-days.component';
registerLocaleData(localeEs, "es");

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    RequestViewComponent,
    RecentRequestsComponent,
    RequestCreationComponent,
    RecentRequestBoxComponent,
    RequestsListComponent,
    RequestChatComponent,
    RequestViewAdminComponent,
    RequestEditionComponent,
    RequestsListAdminComponent,
    RequestListBoxStudentComponent,
    RequestChatAdminComponent,
    RequestMasiveListAdminComponent,
    BarMasiveListAdminComponent,
    ContinuousImprovementComponent,
    CiCreateFormComponent,
    CiResponseFormComponent,
    RequestMasiveOapAdminComponent,
    PaymentResultComponent,
    CelicGouComponent,
    AllUsersBillsComponent,
    UserRequestFiltersComponent,
    RequestResumeComponent,
    BlueFormatComponent,
    MasiveReqViewAdminComponent,
    MassiveDetailsReqBoxComponent,
    MasiveEditionFloatingBoxComponent,
    SemaforizacionInDaysComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,

    NgxPaginationModule,
    ReactiveFormsModule
  ],
  providers: [
    RequestService,
    AttachmentService,
    HttpService,
    { provide: LOCALE_ID, useValue: "es" }
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule { }
