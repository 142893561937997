import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { RequestViewAdminComponent } from './pages/request-view-admin/request-view-admin.component';
import { RequestViewComponent } from './pages/request-view/request-view.component';
import { ContinuousImprovementComponent } from './pages/continuous-improvement/continuous-improvement.component';
import { CiResponseFormComponent } from './components/continuous-improvement/ci-response-form/ci-response-form.component';
import { PaymentResultComponent } from './components/request-view/payment-result/payment-result.component';
import { AllUsersBillsComponent } from './components/all-users-bills/all-users-bills.component';
import { RequestResumeComponent } from './pages/request-resume/request-resume.component';
import { BlueFormatComponent } from './components/request-view/modals/blue-format/blue-format.component';

const routes: Routes = [
  { path: 'request/blueFormat/:idToLoadData',component: BlueFormatComponent },
  { path: 'request-admin',component: RequestViewAdminComponent },
  { path: 'request-admin/:userDoc',component: RequestViewAdminComponent },
  { path: 'request/continuous-improvement',component: ContinuousImprovementComponent },
  { path: 'request/continuous-improvement/:user/:intCall',component: CiResponseFormComponent },
  { path: 'request/my-bills',component: AllUsersBillsComponent },
  { path: 'request/requestResume', component: RequestResumeComponent },
  { path: 'request', component: RequestViewComponent },
  { path: 'request/:id', component: PaymentResultComponent },

  { path: '**', component: EmptyRouteComponent },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes ) ],
  exports: [ RouterModule ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
} )
export class AppRoutingModule { }
