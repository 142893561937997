<div class="requests-list">
  <div class="requestDataContainer_btns">
    <div class="btnSection_left">
      <button class="btnNavigateSections" (click)="navigateBtSection(1)">SOLICITUDES</button>
      <button class="btnNavigateSections btnNavigateSections_selected">SOLICITUDES MASIVAS</button>
    </div>
    <div class="btnSection_right">
      <button class="btn-showModal" (click)="allParamsToFilter.showModal = true">Filtros Avanzados</button>
      <button class="btn-showModal" (click)="manageAllReqsSelected()">Editar ({{ allReqSelected.length }})</button>
      <button class="btn-showModal" (click)="addDeleteAllReqs()">{{ allReqSelected.length == 0 ? 'Agregar' : 'Borrar' }} todos</button>
      <!-- <button class="normal-button">
        <em class="normal-button__icon icon-i-tutorial"></em>
        <input type="text" placeholder="Buscar por documento" class="normal-button__input" #searchReq
          (keydown.enter)="onSearch(searchReq.value)">
      </button> -->
      <!-- <button class="btn-create" (click)="showCreateRequestInFloatingBox()">Crear Solicitud</button> -->
    </div>
  </div>
  <div class="requestSections">
    <div class="requests-list__filters">
      <!-- <button class="filter-tab" [ngClass]="{'filter-tab--selected': 0 === tabSelected}" (click)="changeFilterTabSelected(0)">
        Todas las solicitudes
      </button>
      <button class="filter-tab" *ngFor="let filterTab of allStateReq" [ngClass]="{'filter-tab--selected': filterTab.id === tabSelected}" (click)="changeFilterTabSelected(filterTab.id)">
        {{filterTab.nameState}}
      </button> -->
    </div>
    <div class="requests-list__table">
      <div class="requests-list__table-requests">
        <table class="requests-table">
          <caption></caption>
          <thead>
            <tr>
              <!-- <th scope="col" class="requests-table__title">Sel</th> -->
              <th scope="col" class="requests-table__title">Solicitudes</th>
              <th scope="col" class="requests-table__title hideOnSmallView">TICKET</th>
              <th scope="col" class="requests-table__title hideOnSmallView">Tiempo de <br> solicitud</th>
              <th scope="col" class="requests-table__title hideOnSmallView">
                <span>FECHA DE INICIO <br> DE SOLICITUD</span>
              </th>
              <th scope="col" class="requests-table__title hideOnSmallView">Estado</th>
            </tr>
          </thead>
          <tbody class="requests-table__body">
            <tr *ngFor="let request of requestListFiltered" [ngClass]="{'requests-table__tr-selected': findReqInMassiveList(request)}" (click)="validarReqListClicek(request)">
              <!-- <td>
                <ng-container *ngIf="findReqInMassiveList(request); else addSection">
                  <button (click)="removeReqToMassiveList(request)">Remover</button>
                </ng-container>
                <ng-template #addSection>
                  <button (click)="addReqToMassiveList(request)">Agregar</button>
                </ng-template>
              </td> -->
              <td class="requests-table__box-container">
                <!-- <massive-details-req-box></massive-details-req-box> -->
                <request-request-list-box
                  [title]="request.requestUserRequester?.CedocEmail"
                  [descript]="request.requestTypeRequest?.nameTypeRequest +', '+ request.requestSubTypeRequest?.nameSubTypeRequest"
                  [color]="request.requestStateReq?.color"
                  [requestHistories]="request.requestHistories!"
                  [userIDlogued]="userIDlogued"
                  [offer]="request.requestCourseOffer!.NameCourseOfer"
                  [userName]="request.requestUserRequester!.LastName1+' '+request.requestUserRequester!.Name1"
                  [userOwner]="request.requestUserOwner!.LastName1+' '+request.requestUserRequester!.Name1"
                  [showUserName]="true"></request-request-list-box>
                <div class="aditionalData showOnSmallView">
                  <div class="aditionalDataSection">Ticket: {{request.id}}</div>
                  <div class="aditionalDataSection">
                    Tiempo de solicitud: 
                    <div *ngIf="[3,4].includes(request.requestStateReq!.id!); else showDays">
                      {{ request.requestStateReq?.nameState }}
                    </div>
                    <ng-template #showDays>
                      {{ getDaysBetweenTwoDates(request.registerDate) }} DÍAS DE RADICADO
                      <ng-container *ngIf="request.requestSubTypeRequest?.statusColors">
                        <ramc-semaforizacion-in-days
                          [request]="request"
                        ></ramc-semaforizacion-in-days>
                      </ng-container>
                    </ng-template>
                  </div>
                  <div class="aditionalDataSection">Fecha: {{request.registerDate | date: 'dd/MMM/yyyy'}}</div>
                  <div class="aditionalDataSection aditionalDataSectionState">
                    ESTADO:
                    <request-request-list-tag
                      [stageName]="request.requestStateReq?.nameState"
                      [color]="request.requestStateReq?.color"
                      [file]="request.documentLink"
                      [date]="request.lastActionUpdateDate"
                      [dispatchOrdersList]="request.dispatchOrders"
                    ></request-request-list-tag>
                  </div>
                </div>
              </td>
              <td class="requests-table__text hideOnSmallView">{{ request.id }}</td>
              <td class="requests-table__text hideOnSmallView">
                <div *ngIf="[3,4].includes(request.requestStateReq!.id!); else showDays">
                  {{ request.requestStateReq?.nameState }}
                </div>
                <ng-template #showDays>
                  {{ getDaysBetweenTwoDates(request.registerDate) }} DÍAS DE RADICADO
                  <ng-container *ngIf="request.requestSubTypeRequest?.statusColors">
                    <ramc-semaforizacion-in-days
                      [request]="request"
                    ></ramc-semaforizacion-in-days>
                  </ng-container>
                </ng-template>
              </td>
              <td class="requests-table__text hideOnSmallView">{{request.registerDate | date: 'dd/MMM/yyyy'}}</td>
              <td class="requests-table__tag-container hideOnSmallView">
                <request-request-list-tag
                  [stageName]="request.requestStateReq?.nameState"
                  [color]="request.requestStateReq?.color"
                  [file]="request.documentLink"
                  [date]="request.lastActionUpdateDate"
                  [dispatchOrdersList]="request.dispatchOrders"
                ></request-request-list-tag>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modalContainer" *ngIf="allParamsToFilter.showModal">
  <div class="modalFilters">
    <div class="modalFilters__upper">
      <div class="modalFilters__upperText">
        <h1>Filtros Avanzados</h1>
        <span>Seleccione como quiere filtrar el listado</span>
      </div>
      <div class="modalFilters__upperIcon">
        <button (click)="allParamsToFilter.showModal = false">
          <em class="icon-i-close close-icon"></em>
        </button>
      </div>
    </div>
    <hr>
    <div class="list-filters">
      <div class="filter-input">
        <label for="type">Desde</label>
        <div class="select-container">
          <input type="date" name="initData" id="initData" [(ngModel)]="allParamsToFilter.initialDate">
        </div>
      </div>
      <div class="filter-input">
        <label for="type">Hasta</label>
        <div class="select-container">
          <input type="date" name="initData" id="initData" [(ngModel)]="allParamsToFilter.finalDate">
        </div>
      </div>
    </div>
    <hr>
    <div class="list-filters">

      <div class="filter-input">
        <label for="type">{{ PRINCIPAL_APP_NAME == 'celic' ? 'Liceo' : 'Escuela' }}*</label>
        <div class="select-container">
          <select name="" id="type" (change)="onSelectSchool($event)">
            <option [value]="0" disabled selected>Todas</option>
            <option [value]="item.id" *ngFor="let item of filtersManagement.myRequest.schoolsList">{{item.NameTSchool}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input">
        <label for="type">Tipo de solicitud*</label>
        <div class="select-container">
          <select name="" id="type" (change)="onSelectType($event)">
            <option [value]="0">Todas</option>
            <option [value]="item.id" *ngFor="let item of filtersManagement.myRequest.typeRequest">{{item.nameTypeRequest}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>
  
      <div class="filter-input" [ngClass]="filtersManagement.myRequest.subTypeRequest.length === 0 ? 'disabled' : ''">
        <label for="type">Subtipo de solicitud*</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="allParamsToFilter.selectSubtypeRequest" (change)="onSelectSubtype($event)">
            <option [ngValue]="0" selected disabled>Seleccionar tipo</option>
            <option [ngValue]="item.id" *ngFor="let item of filtersManagement.myRequest.subTypeRequest">{{item.nameSubTypeRequest}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>
  
      <div class="filter-input" [ngClass]="filtersManagement.myRequest.courseOfferList.length === 0 ? 'disabled' : ''">
        <label for="type">{{ PRINCIPAL_APP_NAME == 'celic' ? 'Grado' : 'Oferta' }}*</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="allParamsToFilter.selectedCourseOffer">
            <option [ngValue]="0" selected disabled>Seleccionar {{ PRINCIPAL_APP_NAME == 'celic' ? 'grado' : 'oferta' }}</option>
            <option [ngValue]="item.id" *ngFor="let item of filtersManagement.myRequest.courseOfferList">{{item.NameCourseOfer}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input">
        <label for="type">Estado de Pago*</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="allParamsToFilter.paidFilter">
            <option [ngValue]="null" selected>Mostrar todos</option>
            <option [ngValue]="false"> No Pagados </option>
            <option [ngValue]="true"> Pagados </option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input">
        <label for="type">Estado de Solicitud*</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="allParamsToFilter.stateReq">
            <option [ngValue]="0" selected >Seleccione estado</option>
            <option [ngValue]="1"> En trámite </option>
            <option [ngValue]="2"> En revisión </option>
            <option [ngValue]="5"> Incompleto </option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input" [ngClass]="filtersManagement.myRequest.subTypeReqStages.length === 0 ? 'disabled' : ''">
        <label for="type">Estado interno de solicitud*</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="allParamsToFilter.stageReq">
            <option [ngValue]="0" disabled selected>Seleccione</option>
            <option [ngValue]="null">Sin estado interno</option>
            <option [ngValue]="item.id" *ngFor="let item of filtersManagement.myRequest.subTypeReqStages">{{item.nameStage}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

    </div>
    <div class="list-filters" *ngIf="false"><!-- Se quita porque no se usa -->
      <button class="normal-button" (click)="filterByProcess(1)" [ngClass]="{'normal-button__selected': 1 == allParamsToFilter.filterBtnSelected}">
        Procesos reasignados: Reasignados a mi
      </button>
      <button class="normal-button" (click)="filterByProcess(2)" [ngClass]="{'normal-button__selected': 2 == allParamsToFilter.filterBtnSelected}">
        Procesos reasignados: Que he reasignado
      </button>
    </div>
    <div class="modalFilters__bottom">
      <button class="modalFilters__bottom btn" (click)="applyFilters()">
        APLICAR
      </button>
      <button class="modalFilters__bottom btn" (click)="cleanAllFilters()">
        RESTABLECER
      </button>
    </div>
  </div>
</div>