import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  private urlHttp: string = environment.baseUrl;
  private urlHttpBase: string = environment.base;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  private getHeader(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.authService.getToken()
    });
    return headers;
  }
  private getHeaderCloudFunctions(): HttpHeaders{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  httpGet(url: string): any {
    return this.http.get(this.urlHttp + url, {headers: this.getHeader()} );
  }
  httpPost(url: string, data: any): any {
    return this.http.post(this.urlHttp + url, data, {headers: this.getHeader()} );
  }
  httpDelete(url: string): any {
    return this.http.delete(this.urlHttp + url, {headers: this.getHeader()} );
  }
  httpPatch(url: string, data: any): any {
    return this.http.patch(this.urlHttp + url, data, {headers: this.getHeader()} );
  }
  httpPut(url: string, data: any): any {
    return this.http.put(this.urlHttp + url, data, {headers: this.getHeader()} );
  }
  urlCloudFunc = 'https://us-central1-cedoc-360c.cloudfunctions.net' /** TODO: fix data quemada */
  httpPostCloudFunctions(url: string, data: any): any {
    return this.http.post(this.urlCloudFunc + url, data, {headers: this.getHeaderCloudFunctions()} );
  }
  httpGetBase(url: string): any {
    return this.http.get(this.urlHttpBase + url, {headers: this.getHeader()} );
  }

}
