<div class="modal-all-archives">
  <div class="modal-all-archives__title">Todos los archivos</div>
  <div class="modal-all-archives__documents-selected">
    <div class="document-box">
      <div class="document-box__tooltip">
        <div class="document-box__tooltip-msg">
          Este documento es el nuevo, ya que el que había subido
          previamente lo perdí y este es el renovado
        </div>
        <div class="document-box__tooltip-delta"></div>
      </div>
      <div class="document-box__name">Recibo de pago.pdf</div>
      <i class="document-box__delete icon-i-close"></i>
    </div>
  
    <div class="document-box">
      <div class="document-box__tooltip">
        <div class="document-box__tooltip-msg">
          Este documento es el nuevo, ya que el que había subido
          previamente lo perdí y este es el renovado
        </div>
        <div class="document-box__tooltip-delta"></div>
      </div>
      <div class="document-box__name">Documento de identidad.pdf</div>
      <i class="document-box__delete icon-i-close"></i>
    </div>
  
    <div class="document-box">
      <div class="document-box__tooltip">
        <div class="document-box__tooltip-msg">
          Este documento es el nuevo, ya que el que había subido
          previamente lo perdí y este es el renovado
        </div>
        <div class="document-box__tooltip-delta"></div>
      </div>
      <div class="document-box__name">Documento de identidad.pdf</div>
      <i class="document-box__delete icon-i-close"></i>
    </div>
  
    <div class="document-box">
      <div class="document-box__tooltip">
        <div class="document-box__tooltip-msg">
          Este documento es el nuevo, ya que el que había subido
          previamente lo perdí y este es el renovado
        </div>
        <div class="document-box__tooltip-delta"></div>
      </div>
      <div class="document-box__name">Documento de identidad.pdf</div>
      <i class="document-box__delete icon-i-close"></i>
    </div>
  </div>
</div>
