<div class="box" [ngStyle]="{'background-color': recentRequest.requestStateReq?.color}">
  <div class="box__top">
    {{recentRequest.requestSubTypeRequest?.nameSubTypeRequest}}
  </div>
  <div class="box__bottom">
    <div class="box__date">{{recentRequest.updateDate | date: 'dd/MMM/yyyy'}}</div>
    <div class="box__icons">
      <i class="box__icon descargar icon-i-download"></i>
      <i class="box__icon comments icon-i-chat-line"></i>
    </div>
  </div>
</div>
