import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { NotificationQueue } from 'src/app/models/dbModels/notificationQueue';
import { IntCallsService } from 'src/app/services/intCalls/int-calls.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';

@Component( {
  selector: 'ci-create-form',
  templateUrl: './ci-create-form.component.html',
  styleUrls: [ './ci-create-form.component.css' ]
} )
export class CiCreateFormComponent implements OnInit {

  constructor (
    private _intcallService: IntCallsService,
    private _router: Router,
    private _fb: FormBuilder,
    private _swal: SweetalertService
  ) {
    //
  }

  //Sends a call when the current section is closed
  @Output( "onCloseSection" ) onCloseSection: EventEmitter<void> = new EventEmitter();

  //Receives the current logged in user
  @Input( 'currentUser' ) currentUser: any | undefined = undefined;

  //Receives the Plan de Mejora title
  @Input( 'planTitle' ) planTitle: string = '';

  //Receives the selected school id
  @Input( 'selectedSchool' ) selectedSchool: number = 0;

  //Receives the intCall tags
  @Input( 'intCallTags' ) intCallTags:string = '';

  //Gets the value of the selected option of the category field
  @Input( 'typeCall' ) typeCall: any = {};

  //Stores the manager
  protected userIntcall: any | undefined = undefined;

  //Main form group
  protected mainForm: FormGroup = new FormGroup( {} );

  //Checks if the new Plan de Mejora is being created
  protected submitting: boolean = false;


  
  ngOnInit(): void {
    this.mainForm = this._fb.group( {
      endDate: [ '', [ Validators.required ] ],
      objectives: this._fb.array( [ this.initObjective() ] )
    } )
  }


  /**
   * Sets the objectives form fields
   * @returns 
   */
  private initObjective() {
    return this._fb.group( {
      name: [ '', [ Validators.required ] ],
      actions: [ '', [ Validators.required ] ]
    } );
  }

  /**
   * Displays the date picker when the input field is clicked on
   * @param event 
   */
  protected pickDate( event: any ) {
    event.target.showPicker();
  }

  /**
   * Adds a new objective to the form objectives array
   */
  protected addItem() {
    const control = <FormArray>this.mainForm.controls[ 'objectives' ];
    control.push( this.initObjective() );
  }

  /**
   * Creates a new intCall
   * @returns 
   */
  protected createIntcall() {
    this.submitting = true;
    if ( !this.mainForm.valid || this.userIntcall === undefined )
      return;

    this._swal.swalLoading('Cargando...','Tu plan de mejora está en proceso de creación, por favor aguarda unos instantes');
    this.setItnCall().pipe(
      switchMap( ( intcall: any ) => forkJoin( {
        judge: this.setJudgeIntcall( intcall.id ),
        userIntcallAnswer: this.setUserIntcallAnswer( intcall.id )
      } ).pipe( map( users => ( { users, intcall } ) ) ) ),
      switchMap( ( { users, intcall } ) => forkJoin( {
        mainModule: this.setIntcallModule( intcall.id, users.userIntcallAnswer.id ),
        ratingModule: this.setRatingModule( intcall.id, users.userIntcallAnswer.id )
      } ).pipe( map( ( ) => ( { users, intcall } ) ) ) ) ).subscribe( {
        next: ( { intcall, users } ) => {

          const baseUrl = window.location.origin;
          const urlStr: string = `${ baseUrl }/request/continuous-improvement/${ users.userIntcallAnswer.id }/${ intcall.id }`;

          this.sendNotification( `Tienes un plan de mejora pendiente:  ${ urlStr }` ).subscribe( {
            complete:() => {
            },error:(err) => console.log(err)
          } )

          this._router.navigate( [ 'request/continuous-improvement', users.userIntcallAnswer.id, intcall.id ] );
        }, error: ( err ) => console.log( err )
      } )
  }

  /**
   * Sets the new Intcall fields an its values
   * @returns 
   */
  private setItnCall() {
    const mainFormValues = this.mainForm.getRawValue();

    const intcallData: any = {
      nameIntCall: this.planTitle.split(' ').filter( val => val !== '' ).join(' ').toLowerCase(),
      dateEnd: new Date( mainFormValues.endDate ),
      dateStart: new Date(),
      schoolID: this.selectedSchool,
      typeCall: this.typeCall.id,
      descript: 'N/A',
      tags: this.intCallTags,
      isTemplate: false
    }

    return this._intcallService.setIntcall( intcallData );
  }

  /**
   * Sets the new judge fields an its values
   * @param intcallID 
   * @returns 
   */
  private setJudgeIntcall( intcallID: number ): Observable<any> {
    const judgeIntcallData: any = {
      intcallID,
      percentageJudge: 0,
      userID: this.currentUser.id, //JUDGE
      createdAt: new Date(),
    }

    return this._intcallService.setJudgeIntcall( judgeIntcallData );
  }

  /**
   * Sets the manager fields an its values
   * @param intcallID 
   * @returns 
   */
  private setUserIntcallAnswer( intcallID: number ) {
    const userIntcallAnswerData: any = {
      intcallID,
      userID: this.userIntcall.id,
      isFinalAnswer: false,
      isFinalGrade: false,
      value: 0,
      createdAt: new Date()
    }

    return this._intcallService.setUserIntcallAnswer( userIntcallAnswerData );
  }


  /**
   * Creates the rating module where the value of the rating fields will be stored.
   * @param intcallID 
   * @param moduleID 
   * @returns 
   */
  private setRatingModule( intcallID: number, UserIntcallAnswerID: number ) {

    const intcallModuleData: any = {
      nameModule: 'ratingModule',
      descript: 'N/A',
      intcallID,
      percentageModule: 0
    }

    return this._intcallService.setIntcallModules( intcallModuleData ).pipe(
      switchMap( ( intcallModule: any ) => {

        const fields: string[] = [
          'grade',
          'effectiveness',
          'efficiency'
        ];

        //Sets rating field data
        const intcallFieldsDataObs = fields.map( e => {
          const fieldData = {
            intcallID,
            nameField: e,
            typeField: 2, //large text
            moduleID: intcallModule.id,
            percentageField: 0
          }

          //Sets intCall field answer
          const userIntcallFieldAnswerData = ( intcallFieldID: number ) => {
            return {
              intcallID,
              UserIntcallAnswerID,
              intcallFieldID,
              value: '0',
              createdAt: new Date()
            }
          }

          return this._intcallService.setIntcallFields( fieldData ).pipe(
            switchMap( ( intcallField: any ) => this._intcallService.setUserIntcallFieldAnswer( userIntcallFieldAnswerData( intcallField.id ) ) )
          );
        } );

        return forkJoin( intcallFieldsDataObs );
      } )
    )
  }


  /**
   * Creates the main module whit the questions and answers
   * @param intcallID 
   * @param UserIntcallAnswerID 
   * @returns 
   */
  private setIntcallModule( intcallID: number, UserIntcallAnswerID: number ) {
    const intcallModuleData: any = {
      nameModule: 'improvementPlan',
      descript: 'N/A',
      intcallID,
      percentageModule: 0
    }

    //Sets the question and answer fields module
    return this._intcallService.setIntcallModules( intcallModuleData ).pipe(
      switchMap( ( intcallModule: any ) => {
        const mainFormValues = this.mainForm.getRawValue();

        //Set intCall fields data 
        const intcallFieldsDataObs: any = mainFormValues.objectives.map( ( item: any ) => {
          const fieldData = {
            intcallID,
            nameField: item.name,
            typeField: 2, //large text
            moduleID: intcallModule.id,
            percentageField: 0
          }

          //Set intCall answer data
          const userIntcallFieldAnswerData = ( intcallFieldID: number ) => {
            return {
              intcallID,
              UserIntcallAnswerID,
              intcallFieldID,
              value: item.actions,
              createdAt: new Date()
            }
          }

          return this._intcallService.setIntcallFields( fieldData ).pipe(
            switchMap( ( intcallField: any ) => {
              return this._intcallService.setUserIntcallFieldAnswer( userIntcallFieldAnswerData( intcallField.id ) ).pipe(
                switchMap( ( intcallFieldAnswer: any ) => {

                  //Set checkbox data
                  const gradeIntCallFieldAnswer: any = {
                    userIntCallFieldAnswerID2: intcallFieldAnswer.id,
                    userID: this.currentUser.id,
                    grade: 0, //Default value is false
                    createdAt: new Date(),
                  }

                  return this._intcallService.setGradeIntcallFieldAnswers( gradeIntCallFieldAnswer )
                } )
              )
            } )
          )
        } )

        return forkJoin( intcallFieldsDataObs )
      } )
    )
  }

  /**
   * Sends an email notification
   * @param text 
   * @returns 
   */
  private sendNotification( text: string ){
    let date: Date = new Date();
    date.setMinutes(date.getMinutes() + 1);

    let data: NotificationQueue = {
      emailsTo: `["${ this.userIntcall.email }"]`,
      namesTO: `["${ this.userIntcall.Name1 } ${ this.userIntcall.LastName1 }"]`,
      msg: `[{"message":"${ text }"}]`,
      timeToSend: date.toISOString(),
      isSend: false,
      isSingleMessage: true,
      typeNotification: 'email'
    }

    return this._intcallService.setNotificationQueue( data );
  }
}
