<div class="localNavbar">
  <a href="/request" target="_self">
    <em class="icon-i-back"></em> REGRESAR
  </a>
</div>
<div class="principalContainer">
  <div class="centeredContainer">
    <div class="centeredContainer__upper">
      <span>¡Hola! <strong>{{dataToShow.nombre}}</strong></span><br>
      <span class="grayText">La transacción ha sido:</span><br>
      <span class="transactionStatus"
        [ngClass]="{
          'transactionStatus--green': dataToShow.status === 'PAGADO',
          'transactionStatus--orange': ( dataToShow.status === 'EN PROCESO' || dataToShow.status === 'PENDIENTE' ),
          'transactionStatus--red': dataToShow.status === 'RECHAZADO'
        }"
      >{{dataToShow.status}}</span>
    </div>
    <div class="centeredContainer__mid">
      <div class="row">
        <div class="col-4">Fecha</div>
        <div class="col-8">{{dataToShow.fecha | date:'dd/MMM/yyyy HH:mm'}}</div>
      </div>
      <div class="row">
        <div class="col-4">Referencia</div>
        <div class="col-8">{{dataToShow.referencia}}</div>
      </div>
      <div class="row">
        <div class="col-4">Valor</div>
        <div class="col-8">COP ${{dataToShow.valor | number}}</div>
      </div>
      <div class="row">
        <div class="col-4">Descripción</div>
        <div class="col-8">{{dataToShow.descripcion}}</div>
      </div>
      <ng-container *ngIf="!dataToShow.showMsg">
        <div class="row">
          <div class="col-4">Medio de pago</div>
          <div class="col-8">{{dataToShow.medioDePago}}</div>
        </div>
        <div class="row">
          <div class="col-4">Autorización</div>
          <div class="col-8">{{dataToShow.autorizacion}}</div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col-4">Mensaje</div>
        <div class="col-8">{{dataToShow.msg}}</div>
      </div>
    </div>
    <div class="centeredContainer__lower grayText">
      <span>Si tienes alguna pregunta sobre tu compra,<br>como el envío o la emisión del comprobante,<br>te recomendamos contactarte con un asesor del comercio.</span>
    </div>
  </div>
</div>