import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, take } from 'rxjs';
import { Request } from 'src/app/models/dbModels/request';
import { User } from 'src/app/models/dbModels/user';
import { RequestService } from 'src/app/services/request.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-blue-format',
  templateUrl: './blue-format.component.html',
  styleUrls: ['./blue-format.component.css']
})
export class BlueFormatComponent implements OnInit {

  public showDataToPrint: boolean = false;
  public dataToShow: Request;

  public HEADER_FORMATO_AZUL: string = environment.HEADER_FORMATO_AZUL;

  formatData = {
    fecha: new Date(),
    pin: 'EO7C5F',
    liceo: '',
    curso: ''
  };
  userData = {
    nombre: ''
  };
  userParentData = {
    nombre: '',
    grado: '',
    condicion: '',
    fuerza: '',
    documento: '',
    unidad: '',
    telefono: '',
  };
  adminsData = [{
    concepto: '',
    resultado: '',
    cargo: '',
  }];
  direccionGeneralData = {
    particular: '',
    concepto_final: '',
    respuesta: false,
    observacion_final: '',
  }
  dataToReqMemberTable = [{
    titulo: '',
    concepto: '',
    respuesta: false,
  }];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _sweetalertService: SweetalertService,
    private _requestService: RequestService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe( params => {
      this.getAllDataToBlueFormat( Number( params[ 'idToLoadData' ] ) );
    } )
  }

  printPage() {
    window.print();
  }

  private getAllDataToBlueFormat( idToLoadData: number ) {
    this._sweetalertService.swalLoading( 'Cargando...', 'Por favor, espera un momento' );
    let requestFilter = this.getRequestFilter(idToLoadData);
    forkJoin( {
      REQUEST_DATA_TO_PRINT: this._requestService.getRequest(requestFilter).pipe(take(1)),
    } ).subscribe( {
      next: ( data ) => {
        console.log('getAllDataToBlueFormat SUCCESS: ', data);
        let { REQUEST_DATA_TO_PRINT } = data;
        console.log('REQUEST_DATA_TO_PRINT: ', REQUEST_DATA_TO_PRINT);
        console.log('REQUEST_0_: ', REQUEST_DATA_TO_PRINT[0]);
        let validateRequestData: number = 0; // All OK
        if (REQUEST_DATA_TO_PRINT.length == 0) validateRequestData = 1; // Request does not exist
        // if (validateRequestData == 0 && [1,4,5].includes(REQUEST_DATA_TO_PRINT[0].stateReqID)) validateRequestData = 2; // Request is not acepted
        if (validateRequestData == 0 && REQUEST_DATA_TO_PRINT[0].reqMembers?.length == 0) validateRequestData = 3; // Request without reqMembers
        switch (validateRequestData) {
          case 0:
            this.dataToShow = REQUEST_DATA_TO_PRINT[0];
            this.validateDataToShow();
            break;
          case 1:
            this._sweetalertService.swalError('Error','La solicitud no existe.', () => {
              this._router.navigate(['/request-admin']);
            })
            break;
          case 2:
            this._sweetalertService.swalError('Error','La solicitud no esta aprobada.', () => {
              this._router.navigate(['/request-admin']);
            })
            break;
          case 3:
            this._sweetalertService.swalError('Error','La solicitud no tiene administradores asociados.', () => {
              this._router.navigate(['/request-admin']);
            })
            break;
        }
        this.dataToReqMemberTable = [];
        REQUEST_DATA_TO_PRINT[0].reqMembers?.forEach(reqMembersIterated => {
          const data = {
            titulo: reqMembersIterated.realName ?? '',
            concepto: reqMembersIterated.comment ?? '',
            respuesta: reqMembersIterated.isAproved ?? false,
          };
          this.direccionGeneralData = {
            particular: '', // TODO
            concepto_final: reqMembersIterated.comment ?? '',
            respuesta: reqMembersIterated.isAproved ?? false,
            observacion_final: '', // TODO
          }
          this.dataToReqMemberTable.push(data);
        });
      },
      error: ( err ) => {
        console.log('getAllDataToBlueFormat ERROR: ', err);
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', () => {
          this._router.navigate(['/request-admin']);
        })
      }
    } )
  }

  private getRequestFilter(idToLoadData: number): string {
    let requestFilter = JSON.stringify({
      where: { or: [
        {id: idToLoadData}
      ]},
      include: [
        "requestSchool",
        "requestCourseOffer",
        {
          "requestUserRequester":[
            {
              "parentsToStudents":{
                "parentUser":[
                  "ContactInfos",
                  "UserDocuments",
                  {
                    "MilitarInfos":[
                      "Force",
                      "Range"
                    ]
                  },
                ]
              }
            }
          ]
        },
       
        { 
          relation: "reqMembers",
          scope: {
            order: 'numSequence ASC',
            include: ["userapp"]
          }
        },
        {
          relation: "dispatchOrders",
          scope: {
            order: 'issueDate DESC'
          }
        }
      ]
    })
    return requestFilter;
  }

  private validateDataToShow() {
    let localParentUser: User = this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser!;
    this.formatData = {
      fecha: new Date(),
      pin: ''+this.dataToShow.id,
      liceo: this.dataToShow.requestSchool?.NameTSchool!,
      curso: this.dataToShow.requestCourseOffer?.NameCourseOfer!
    };
    this.userData = {
      nombre: this.getNameUserRequester()
    };
    this.userParentData = {
      nombre: this.getNameUserParent(),
      grado: this.getUserParentGrade(localParentUser),
      condicion: '', // De donde saco este dato?
      fuerza: this.getUserParentForce(localParentUser),
      documento: this.getUserParentDocument(localParentUser),
      unidad: '', // De donde saco este dato?
      telefono: this.getUserParentPhoneNumber(localParentUser),
    };
    this.adminsData = [{
      concepto: '',
      resultado: '',
      cargo: '',
    }];
    this.direccionGeneralData = {
      particular: '',
      concepto_final: '',
      respuesta: false,
      observacion_final: '',
    }
    this.showDataToPrint = true;
    this._sweetalertService.swalClose();
  }

  private getNameUserRequester(): string {
    let name = '';
    name += `${this.dataToShow.requestUserRequester?.Name1}`;
    name += this.dataToShow.requestUserRequester?.Name2 ? ` ${this.dataToShow.requestUserRequester?.Name2}` : '';
    name += ` ${this.dataToShow.requestUserRequester?.LastName1}`;
    name += this.dataToShow.requestUserRequester?.LastName2 ? ` ${this.dataToShow.requestUserRequester?.LastName2}` : '';
    return name;
  }

  private getNameUserParent(): string {
    let name = '';
    name += `${this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.Name1}`;
    name += this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.Name2 ? ` ${this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.Name2}` : '';
    name += ` ${this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.LastName1}`;
    name += this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.LastName2 ? ` ${this.dataToShow.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.LastName2}` : '';
    return name;
  }

  private getUserParentGrade(localParentUser: User): string {
    let string = '';
    if (!localParentUser.IsMilitar) string = 'Civil';
    else string = localParentUser.MilitarInfos?.[0]?.Range?.NameRange ?? '';
    return string;
  }

  private getUserParentForce(localParentUser: User): string {
    let string = '';
    if (localParentUser.IsMilitar) string = localParentUser.MilitarInfos?.[0]?.Force?.NameForce ?? '';
    return string;
  }

  private getUserParentDocument(localParentUser: User): string {
    let string = '';
    if (localParentUser.IsMilitar) string = localParentUser.UserDocuments?.[0]?.Document ?? '';
    return string;
  }

  private getUserParentPhoneNumber(localParentUser: User): string {
    let string = '';
    if (localParentUser.IsMilitar) string = ''+localParentUser.ContactInfos?.[0]?.CellPhone;
    return string;
  }

}
