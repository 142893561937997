import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-edition',
  templateUrl: './request-edition.component.html',
  styleUrls: ['./request-edition.component.css']
})
export class RequestEditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onFileInput(event: any) {
    console.log(event);
  }

}
