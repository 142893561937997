import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class Functions {

  constructor(
    private _httpService: HttpService
  ) { }

  getIP() {
    let url: string = `/functions/getIP`
    return this._httpService.httpGetBase(url);
  }

}


