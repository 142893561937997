import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RequestListBoxComponent } from './request-list-box/request-list-box.component';
import { RequestListTagComponent } from './request-list-tag/request-list-tag.component';
import { ProgressionBarComponent } from './progression-bar/progression-bar.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { DocumentMessageComponent } from './document-message/document-message.component';
import { ModalAllArchivesComponent } from './modal-all-archives/modal-all-archives.component';
import { ModalBillSummaryComponent } from './modal-bill-summary/modal-bill-summary.component';
import { UploadFileBoxComponent } from './upload-file-box/upload-file-box.component';
import { CiSelectComponent } from './ci-select/ci-select.component';
import { CiUserSearchComponent } from './ci-user-search/ci-user-search.component';

@NgModule( {
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    RequestListBoxComponent,
    RequestListTagComponent,
    ProgressionBarComponent,
    ChatMessageComponent,
    DocumentMessageComponent,
    ModalAllArchivesComponent,
    ModalBillSummaryComponent,
    UploadFileBoxComponent,
    CiSelectComponent,
    CiUserSearchComponent,
  ],
  exports: [
    RequestListBoxComponent,
    RequestListTagComponent,
    ProgressionBarComponent,
    ChatMessageComponent,
    DocumentMessageComponent,
    ModalAllArchivesComponent,
    ModalBillSummaryComponent,
    CommonModule,
    FormsModule,
    UploadFileBoxComponent,
    CiSelectComponent,
    CiUserSearchComponent
  ]
} )
export class SharedModule { }
