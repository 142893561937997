<div class="requests-creation">
  <!-- <div class="requests-creation__header">
    Solicitudes
  </div> -->
  <div class="requests-creation__header">
    <div>
      <span *ngIf="requestByAdmin">Solicitud para: {{requesterUser.LastName1}} {{requesterUser.Name1}}<br></span>
      <span *ngIf="currentPageNumber != 1">{{subTypeRequestSelected?.nameSubTypeRequest}}</span>
    </div>
    <div class="header__closeModal" (click)="closeUserFloatingBox()">
      <em class="icon-i-close close-icon"></em>
    </div>
  </div>
  <div class="requests-creation__form-container">
    <!--------------------------- modals ---------------------------->
    <!--------------------------- TODO: REVISAR ---------------------------->
    <div class="request-creation__modal-container" *ngIf="false">
      <request-modal-all-archives></request-modal-all-archives>
    </div>
    <div class="request-creation__modal-container" *ngIf="false">
      <request-modal-bill-summary></request-modal-bill-summary>
    </div>
    <!--------------------------- modals ---------------------------->
    <div class="form-view form-view--one" *ngIf="currentPageNumber == 1">
      <div class="form-view__top">
        <div class="form-view__title">Realizar solicitud</div>
      </div>
      <div class="form-view__content">
        <div class="selects-container">
          <div class="select-item">
            <select name="courseOfferID"  (change)="courseSelected()" [(ngModel)]="userCourseSelected" required #courseOfferID="ngModel">
              <option disabled [ngValue]="undefined">SELECCIONAR CURSO</option>
              <option *ngFor="let userCourse of allUserCourses" [ngValue]="userCourse">{{userCourse.coursesOfer.school?.SchoolAcronim}}-{{userCourse.coursesOfer.NameCourseOfer}}</option>
            </select>
          </div>
          <div class="select-item">
            <select name="typeRequestID" (change)="typeRequestSelected()" [(ngModel)]="requestCreationInfo.typeRequestID" required #typeRequestID="ngModel">
              <option disabled [ngValue]="undefined">TIPO DE SOLICITUD</option>
              <option *ngFor="let typeRequest of allTypeRequests" [ngValue]="typeRequest.id">{{typeRequest.nameTypeRequest}}</option>
            </select>
          </div>
          <div class="select-item" >
            <select [disabled]="subTypeRequestsSelected.length === 0" name="subTypeRequestID" [(ngModel)]="requestCreationInfo.subTypeRequestID" required #subTypeRequestID="ngModel" (change)="subTypeRequestsSelection()">
              <option disabled [ngValue]="undefined">SUB CATEGORÍA</option>
              <option *ngFor="let subTypeRequest of subTypeRequestsSelected" [ngValue]="subTypeRequest.id">{{subTypeRequest.nameSubTypeRequest}}</option>
            </select>
          </div>
        </div>
        <div class="form-view__message">Seleccione el tipo de solicitud que desea</div>
      </div>
    </div>

    <div class="form-view form-view--two" *ngIf="currentPageNumber == 2">
      <div class="form-view__top">
        <div class="form-view__title">Documentos</div>
        <div class="form-view__price">
          <div class="form-view__price-label">PRECIO:</div>
          <div class="form-view__price-value">${{ subTypeRequestSelected?.price | number }}</div>
        </div>
      </div><br>
      <div class="documents-needed__descript">
        <!-- Si no puede adjuntar documentos en este momento, lo podrá hacer mas adelante por medio del chat de la solicitud. -->
        <!-- Si los documentos no son obligatorios, los podrá adjuntar mas adelante por medio del chat de la solicitud. -->
      </div>
      <div class="form-view__content">
        <div class="documents-needed">
          <div class="documents-needed__descript documents-needed__descript__inner">
            <span [innerHTML]="subTypeRequestSelected?.descript"></span>
          </div><br><br>
        </div>


        <ng-container *ngIf="subTypeRequestSelected?.subTypeRequestPlaceholders && subTypeRequestSelected!.subTypeRequestPlaceholders!.length > 0; else nomalFlujo">
          <ng-container *ngFor="let sutTypeReqPlaceholder of subTypeRequestSelected?.subTypeRequestPlaceholders; index as i">
            <div class="dinamicRequestData">
              <span>{{ sutTypeReqPlaceholder.order}} - {{ sutTypeReqPlaceholder.placeholderString }}</span><span class="mandatory" *ngIf="sutTypeReqPlaceholder?.isMandatory">* (Obligatorio)</span> <br>

              <ng-container [ngSwitch]="sutTypeReqPlaceholder.enumPlaceholder">
                <ng-container *ngSwitchCase="'docnames'">
                  <span *ngIf="allFilesUploaded[i]">Documento cargado</span>
                  <upload-file-box
                    [containerName]="nameBucketRequestInfo"
                    [getFileName]="sutTypeReqPlaceholder.order+' ('+(sutTypeReqPlaceholder?.isMandatory ? 'Obligatorio' : 'Opcional')+') '+sutTypeReqPlaceholder.placeholderString"
                    [isPlaceholderFile]="true"
                    [showCloseBtn]="false"
                    [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
                    (responseDataFile)="getDataFromUploadFileComponent($event, i)"
                    (deleteDataFile)="deleteDataFile($event, i)"
                  ></upload-file-box>
                </ng-container>
                <ng-container *ngSwitchCase="'responcecoments'">
                  responcecoments
                </ng-container>
                <ng-container *ngSwitchCase="'members'">
                  members
                </ng-container>
              </ng-container>

            </div>
          </ng-container>
        </ng-container>
        <ng-template #nomalFlujo>
          <upload-file-box
            [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
            [containerName]="nameBucketRequestInfo"
            [showCloseBtn]="false"
            (responseDataFile)="getDataFromUploadFileComponent($event)"
          ></upload-file-box>
        </ng-template>

        <div class="requestComment">
          <span>Detalles de la solicitud:</span>
          <textarea [(ngModel)]="aditionalInformationText" type="text" placeholder="Escriba detalles pertinentes sobre su solicitud" class="textarea-selector__descript" rows="3"></textarea>
        </div>

      </div>
    </div>

    <div class="form-view form-view--three" *ngIf="currentPageNumber == 3">
      <div class="form-view__top">
        <div class="form-view__title">Confirmar solicitud</div>
      </div>
      <div class="form-view__content">
        <div class="form-view__message">Usted está solicitando:</div>
        <div class="summary-request">
          <em class="summary-request__icon icon-i-documents"></em>
          <div class="summary-request__text">
            <div class="summary-request__name">{{typeRequSelected?.nameTypeRequest}}:</div>
            <div class="summary-request__name">{{subTypeRequestSelected?.nameSubTypeRequest}}</div>
            <div class="summary-request__descript">
              <span [innerHTML]="subTypeRequestSelected?.descript"></span>
            </div>
          </div>
        </div>
        <div class="price-notice">
          <div class="price-notice__msg">Esta solicitud tiene un costo de</div>
          <div class="price-notice__value">${{subTypeRequestSelected?.price | number}}</div>
        </div>
        <div class="form-view__buttons" *ngIf="!isSavingData else savingData">
          <button class="form-view__button form-view__button--cancel" (click)="cancelCreateRequest()">CANCELAR</button>
          <button class="form-view__button form-view__button--continue" (click)="createRequest()">CONTINUAR</button>
        </div>
        <ng-template #savingData>
          <div class="form-view__buttons">
            <button class="form-view__button form-view__button--continue">CREANDO SOLICITUD</button>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="form-view form-view--four" *ngIf="currentPageNumber == 4">
      <div class="form-view__top" *ngIf="false">
        <div class="form-view__title">Para continuar con su solicitud...</div>
      </div>
      <div class="form-view__content" *ngIf="false && subTypeRequestSelected?.price! > 0; else freeRequest">
        <div class="form-view__message">
          1. Deberá pagar con
          <div class="form-view__message form-view__message--underline">este formato</div>
          o podrá pagar directamente
          <div class="form-view__message form-view__message--underline">con PSE</div>
        </div>
        <div class="form-view__message">
          2. Una vez pagado deberá envíar el comprobante por el chat
        </div>
        <div class="form-view__button-container">
          <button class="form-view__button-finish" (click)="goToRequestViewChat()">FINALIZAR</button>
        </div>
      </div>
      <ng-template #freeRequest>
        <div class="form-view__content">
          <div class="form-view__message">
            Su solicitud será revisada por un administrador quién se pondrá en contacto con usted.
          </div>
          <div class="form-view__button-container">
            <button class="form-view__button-finish" (click)="goToRequestViewChat()">FINALIZAR</button>
          </div>
        </div>
      </ng-template>
    </div>



    <div class="progression-bar-container">
      <request-progression-bar
        (changeNumberPage)="onChangePageNumber($event)"
        [pageNumber]="TOTAL_PAGE_NUMBER"
        [prevDisabled]="currentPageNumber == 1 || currentPageNumber == 4"
        [nextDisabled]="(currentPageNumber == 1 && !createRequestValidation()) || currentPageNumber == 3 || currentPageNumber == 4 || ( currentPageNumber == 2 && !allMandatoryFilesUploaded)"
        [currentPageNumber]="currentPageNumber"
      ></request-progression-bar>
    </div>

  </div>
</div>