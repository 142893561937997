<div class="chat-message" [ngClass]="{'chat-message--sent': messageInfo?.isReceived}">
  <div class="chat-message__img-container">
    <div class="user-image" [ngStyle]="{'background-image': 'url('+messageHistory?.requestHistoryUser?.Photo}"></div>
  </div>
  <div class="chat-message__chat-container">
    <div class="chat-message__box" [ngClass]="{'chat-message__box--sent': messageInfo?.isReceived}">
      <div class="chat-message__box-name">
        <!-- {{messageHistory?.requestHistoryUser?.LastName1}}
        {{messageHistory?.requestHistoryUser?.LastName2}}
        {{messageHistory?.requestHistoryUser?.Name1}}
        {{messageHistory?.requestHistoryUser?.Name2}} -->
        {{messageInfo?.userName}}
      </div>
      <!-- <div class="chat-message__box-position">
        {{messageInfo?.userPosition}}
      </div> -->
      <div class="chat-message__box-email">
        <!-- {{messageHistory?.requestHistoryUser?.CedocEmail}} -->
        {{messageInfo?.userEmail}}
      </div>
      <div class="chat-message__box-message">
        {{messageHistory?.privateComment}}
      </div>
      <div class="chat-message__box-message">
        <!-- {{messageHistory?.publicComment}} -->
        {{messageInfo?.message}}
      </div>
      <div class="chat-message__box-documents">
        <ng-container *ngFor="let doc of messageInfo?.documents">
          <div class="document-box" [ngClass]="{'document-box--sent': messageInfo?.isReceived}" >
            <div class="document-box__icon icon-i-documents"></div>
            <div class="document-box__text">
              <div class="document-box__name">{{doc.nameFile}}</div>
              <a class="document-box__action" [href]="urlBackV1 + doc.urlFile" target="_blank">Ver documento</a>
            </div>
          </div>
          <div class="chat-message__box-message">{{doc.descript}}</div>
        </ng-container>
      </div>
      <div class="chat-message__date">{{messageInfo?.messageDate | date:'dd/MMM/yyyy'}}</div>
    </div>
  </div>
</div>
