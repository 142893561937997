import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { DispatchOrderI } from '../models/dbModels/dispatchOrder';

@Injectable({
  providedIn: 'root'
})
export class DispatchOrderService {

  constructor(
    private _httpService: HttpService
  ) { }

  getDispatchOrder(filter?: string): Observable<DispatchOrderI[]> {
    let url: string = `/DispatchOrders`
    if (filter) url = `/DispatchOrders?filter=${filter}`
    return this._httpService.httpGet(url);
  }
}
