<div class="request-chat"  *ngIf="true">
  <div class="request-chat__header">
    <div class="header-request">
      <div class="header-request__chat-info">
        <div class="chat-info" *ngIf="requestData.requestUserOwner">
          <div class="chat-info__img" [ngStyle]="{'background-image': 'url('+requestData.requestUserOwner.Photo+')'}"></div>
          <div class="chat-info__text">
            <div class="chat-info__request-name"><strong>Gestionado por:</strong></div>
            <div class="chat-info__name">{{requestData.requestUserOwner.Name1}} {{requestData.requestUserOwner.LastName1}}</div>
            <div class="chat-info__email">{{requestData.requestUserOwner.email}}</div>
            <div class="chat-info__request-name">{{requestData.requestTypeRequest?.nameTypeRequest}} - {{requestData.requestSubTypeRequest?.nameSubTypeRequest}}</div>
            <div class="chat-info__request-time">(Solicitado el {{requestData.registerDate | date:'dd/MMM/yyyy'  }})</div>
          </div>
        </div>
      </div>
      <div class="header-request__close">
        <em class="minimize-icon icon-i-close" (click)="closeRequestDataBox()"></em>
      </div>
    </div>
  </div>
  <div class="content-navbar">
    <div class="button-tab button-tab--chat" [ngClass]="{'button-tab--selected': viewType === 1}" (click)="viewType = 1">Chat</div>
    <div class="button-tab button-tab--documents" [ngClass]="{'button-tab--selected': viewType === 2}" (click)="viewType = 2">Documentos</div>
    <div class="button-tab button-tab--documents" [ngClass]="{'button-tab--selected': viewType === 3}" (click)="viewType = 3">Información</div>
    <div class="button-tab button-tab--documents" [ngClass]="{'button-tab--selected': viewType === 4}" (click)="viewType = 4">Historial de pagos</div>
  </div>
  <div class="request-chat__content">
    <!-- chat-view -->
    <div class="content-chat" *ngIf="viewType === 1">
      <div class="content-chat__messages-container">
        <ng-container *ngFor="let requestMessage of allRequestMessages">
          <request-chat-message [messageInfo]="requestMessage" ></request-chat-message>
        </ng-container>
      </div>
    </div>
    <!-- documents-view -->
    <div class="content-documents" *ngIf="viewType === 2">
      <div class="documentsHeaderContainer">Documentos de la solicitud</div>
      <div class="content-documents__tag" *ngIf="allDocumentMessagesPlaceholders.length == 0">
        <request-request-list-tag
          [color]="'#8b4a4a'"
          [stageName]="'No hay documentos para mostrar'"
          [activeChatButton]="false"
        >
        </request-request-list-tag>
      </div>
      <div class="content-documents__documents-list">
        <ng-container *ngFor="let file of allDocumentMessagesPlaceholders">
          <request-document-message [fileReq]="file.documents![0]"></request-document-message>
        </ng-container>
      </div>

      <div class="documentsHeaderContainer">Documentos enviados por el chat</div>
      <div class="content-documents__tag" *ngIf="allDocumentMessages?.length == 0">
        <request-request-list-tag
          [color]="'#8b4a4a'"
          [stageName]="'No hay documentos para mostrar'"
          [activeChatButton]="false"
        >
        </request-request-list-tag>
      </div>
      <div class="content-documents__documents-list">
        <ng-container *ngFor="let file of allDocumentMessages">
          <request-document-message [fileReq]="file.documents![0]"></request-document-message>
        </ng-container>
      </div>
    </div>
    <!-- request-summary-view -->
    <div class="content-request-summary" *ngIf="viewType === 3">
      <div class="text-inline">
        <div class="summary-subtitle">SU INFORMACIÓN PARA ESTA SOLICITUD</div>
        <div class="summary-state" *ngIf="platformName == 'celic'">
          <div class="summary-state__label">Estado del pago</div>
          <div class="summary-state__stateContainer">
              <div class="summary-state__stateContainer-circle"
                [ngClass]="{
                  'stateContainer-circle--green': dataPayment.textPaymentState === 'PAGADO',
                  'stateContainer-circle--orange': ( dataPayment.textPaymentState === 'EN PROCESO' || dataPayment.textPaymentState === 'PENDIENTE' ),
                  'stateContainer-circle--red': dataPayment.textPaymentState === 'RECHAZADO'
                }"
              ></div>
              <div class="summary-state__stateContainer-Text">{{ dataPayment.textPaymentState }}</div>
          </div>
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Tipo de solicitud</div> {{ requestData.requestTypeRequest?.nameTypeRequest }}
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Subtipo de solicitud</div> {{ requestData.requestSubTypeRequest?.nameSubTypeRequest }}
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Fecha de solicitud</div> {{ requestData.updateDate | date: 'dd/MMM/yyyy HH:mm' }}
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Precio de solicitud</div> COP ${{ requestData.TotalPayment | number}}
        </div>
      </div>
      <div *ngIf="isAcudiente">
        <div class="summary-subtitle">
          Información del responsable de pago
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">Nombre:</div>
            {{acudienteData.Name1}} 
            {{acudienteData.Name2}} 
            {{acudienteData.LastName1}} 
            {{acudienteData.LastName2}}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">{{ acudienteData.UserDocuments?.[0]?.TypeDocuments?.NameTypeDocument }}: </div> {{ acudienteData.UserDocuments?.[0]?.Document }}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">E-mail</div> {{acudienteData.email}}
          </div>
        </div>
        <div class="text-inline">
          <div class="text-item">
            <div class="text-item__label">Rango</div> {{ (acudienteData.IsMilitar && acudienteData.MilitarInfos && acudienteData.MilitarInfos.length > 0) ? acudienteData.MilitarInfos[0].Range.NameRange : 'Civil' }}
          </div>
        </div>
        <div class="text-inline" *ngIf="acudienteData?.IsMilitar && acudienteData.MilitarInfos && acudienteData.MilitarInfos.length > 0">
          <div class="text-item">
            <div class="text-item__label">Cédula militar</div> {{ acudienteData.MilitarInfos[0].CedulaMil }}
          </div>
        </div>
      </div>

      <div class="summary-subtitle">
        Información del Estudiante
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Nombre:</div>
          {{requestData.requestUserRequester?.Name1}} 
          {{requestData.requestUserRequester?.Name2}} 
          {{requestData.requestUserRequester?.LastName1}} 
          {{requestData.requestUserRequester?.LastName2}}
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">Documento</div> {{userDocument}}
        </div>
      </div>
      <div class="text-inline">
        <div class="text-item">
          <div class="text-item__label">E-mail</div> {{requestData.requestUserRequester?.email}}
        </div>
      </div>

      <ng-container *ngIf="platformName == 'celic'; else cedocPaymentView">
        <div class="btnPaymentContainer" *ngIf="dataPayment.showPaymentBtn && showBtnToPayment" (click)="showBtnToPayment = false; procesPayment()">
          Pagar
        </div>
        <div class="btnPaymentContainer" *ngIf="!showBtnToPayment">
          Procesando
        </div>
        <a class="document-box__action" (click)="generateNewDispatchOrder()" *ngIf="showBtnToGenerateNewDispatch">
          <div class="btnPaymentContainer">
            Generar nuevo link de pago
          </div>
        </a>
        <div class="paymentBtnIconAndInfo" *ngIf="dataPayment.showPaymentBtn" (click)="showInfoModal = true">
          <div>
            <img class="" src="{{ urlToPaymentEntityImage }}" alt="Icono pasarela de pagos">
          </div>
          <div class="paymentBtnIconAndInfo__text">
            <span>¿Aún no nos conoces? <strong>Saber más</strong></span>
          </div>
        </div>
      </ng-container>
      <ng-template #cedocPaymentView>
        <div class="btnPaymentContainer" *ngIf="dataPayment.showPaymentBtn && showBtnToPayment" (click)="procesPayment()">
          Pagar
        </div>
      </ng-template>
    </div>
    <!-- payment-history-view -->
    <div class="content-payment-history" *ngIf="viewType === 4">
      <div class="text-inline">
        <div class="summary-title">Historial de pagos</div>
      </div>
      <div class="paymentHistory">
        <table class="table table-no-border"><caption></caption>
          <thead>
            <tr>
              <th>ID</th>
              <th>Estado</th>
              <th>Fecha</th>
              <th>Detalles</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataPayment.paymentHistory">
              <td>{{ item['id'] }}</td>
              <td>
                <div class="rowHistoryTable"
                  [ngClass]="{
                    'rowHistoryTable--green': item['state'] === 'PAGADO',
                    'rowHistoryTable--orange': ( item['state'] === 'EN PROCESO' || item['state'] === 'PENDIENTE' ),
                    'rowHistoryTable--red': item['state'] === 'RECHAZADO'
                  }"
                >
                  {{ item['state'] }}
                </div>
              </td>
              <td>{{ item['date'] | date:'dd/MMM/yyyy HH:mm' }}</td>
              <td>
                <a class="document-box__viewDetails" [href]="'/request/'+item['id']" target="_blank" *ngIf="item['gatewayData']">
                  Ver
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="platformName == 'celic'">
        <div class="btnPaymentContainer" *ngIf="dataPayment.showPaymentBtn && showBtnToPayment" (click)="showBtnToPayment = false; procesPayment()">
          Pagar
        </div>
        <div class="btnPaymentContainer" *ngIf="!showBtnToPayment">
          Procesando
        </div>
        <a class="document-box__action" (click)="generateNewDispatchOrder()" *ngIf="showBtnToGenerateNewDispatch">
          <div class="btnPaymentContainer">
            Generar nuevo link de pago
          </div>
        </a>
        <div class="paymentBtnIconAndInfo" *ngIf="dataPayment.showPaymentBtn" (click)="showInfoModal = true">
          <div>
            <img class="" src="{{ urlToPaymentEntityImage }}" alt="Icono pasarela de pagos">
          </div>
          <div class="paymentBtnIconAndInfo__text">
            <span>¿Aún no nos conoces? <strong>Saber más</strong></span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="request-chat__sendMessage" *ngIf="viewType && viewType == 1">
    <div class="content-chat__input-container">
      <div class="documents-selected" *ngIf="activeDocumentsSelector">
        <div class="document-box" *ngFor="let archive of filesReqListToUpload">
          <div class="document-box__name">{{archive.nameFile}}</div>
          <em class="document-box__delete icon-i-tutorial" (click)="deleteArrayListItem(archive)"></em>
        </div>
      </div>
      <div *ngIf="activeDocumentsSelector else chatOnlyText">
        <upload-file-box
          [containerName]="nameBucketRequestInfo"
          [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
          (responseDataFile)="getDataFromUploadFileComponent($event)"
          (closeView)="closeUploadFileComponent()"
        ></upload-file-box>
      </div>
      <ng-template #chatOnlyText>
        <div class="input-container">
          <div class="input-container__input">
            <textarea type="text" placeholder="Escriba su mensaje" class="input-container__input-msg"
              name="requestPublicComment" [(ngModel)]="requestHistoryCreation.publicComment"
              minlength="1" maxlength="70" #requestPublicComment="ngModel"
            ></textarea>
          </div>
          <div class="input-container__buttons">
            <div class="input-container__icon-button"  (click)="createRequestHistory()">
              <em class="input-container__icon icon-i-send"></em>
            </div>
            <div class="input-container__icon-button input-container__icon-button--selected" (click)="activeDocumentsSelector = !activeDocumentsSelector">
              <em class="input-container__icon icon-i-add-file"></em>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- ********************************** Modal ********************************** -->
<div *ngIf="showInfoModal">
  <request-celic-gou
    (closeModalOutput)="closeModal()"
  ></request-celic-gou>
</div>
