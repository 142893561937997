<!-- Inicio: Selector de estudiantes para el acudiente en Celic -->
<div class="parentsContainer text-center" *ngIf="showParentsContainer">
  <ng-container *ngIf="ALL_MY_CHILDS.length > 0; else AcudienteNoChilds">
    <div class="parentsContainer-Childs">
      Seleccione el Estudiante para gestionar solicitudes
      <br>
      <select (change)="onChangeChildSelected()" [(ngModel)]="childSelected">
        <option disabled selected [ngValue]="undefined">Seleccione un estudiante</option>
        <option *ngFor="let child of ALL_MY_CHILDS" [ngValue]="child">
          {{ child.Name1 | uppercase }}
          {{ (child.Name2 || '') | uppercase }}
          {{ child.LastName1 | uppercase }}
          {{ (child.LastName2 || '') | uppercase }}
        </option>
      </select>
    </div>
  </ng-container>
  <ng-template #AcudienteNoChilds>
    <div class="parentsContainer-noChilds">
      No tiene estudiantes asociados.
    </div>
  </ng-template>
</div>
<!-- Fin -->
<!-- Inicio: Contenedor info -->
<div class="requestDataContainer" *ngIf="showRequestPanels">
  <div class="requestDataContainer_btns">
    <div class="btnSection_left">
      <button class="btn-viewBills" (click)="goToAnotherUrl('/request/my-bills')">Otras Facturas ({{ ALL_MY_DISPATCH_ORDERS.length }})</button>
    </div>
    <div class="btnSection_right">
      <button class="sectionEspace btn-create" (click)="showCreateRequestInFloatingBox()">Crear Solicitud</button>
    </div>
  </div>
  <div class="requestDataContainer_description">
    <h1>Lista de solicitudes</h1>
    <span>En esta sección usted podrá encontrar todas las solicitudes, seleccione una para iniciar con su gestión</span>
  </div>
  <div class="requestDataContainer_table">
    <div class="">
      <div class="">
        <app-requests-list
          [requestListData]="ALL_MY_REQUEST"
          [stateTabs]="ALL_STATES_REQUEST"
          (requestSelected)="getRequestSelected($event)"
        ></app-requests-list>
      </div>
    </div>
  </div>
</div>
<!-- Fin -->
<!-- Caja que se sobrepone para visualizar el detalle de la solicitud -->
<div class="floatingBox" *ngIf="showFloatingBox">
  <ng-container *ngIf="!showRequestData else requestDataSection">
    <app-request-creation
      [allTypeRequests]="ALL_TYPE_REQUEST"
      [allSubTypeRequests]="ALL_SUBTYPE_REQUEST"
      [allUserCourses]="ALL_USER_COURSES"
      [requesterUser]="REQUESTER_USER"
      [allMyRequestList]="ALL_MY_REQUEST"
      (updateData)="updateRequestList()"
      (showLastRequestCreated)="showLastRequestCreated()"
    ></app-request-creation>
  </ng-container>
  <ng-template #requestDataSection>
    <request-request-chat
      [requestData]="requestSelected"
      [isAcudiente]="isAcudiente"
      [acudienteData]="acudienteData"
      (closeRequestData)="closeChatView($event)"
      (updateData)="updateRequestList()"
    ></request-request-chat>
  </ng-template>
</div>
<!-- Fin -->
