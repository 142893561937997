<div class="requests-creation">
  <div class="requests-creation__header">
    Edición de solicitudes
  </div>
  <div class="requests-creation__form-container">

    <!--------------------------- modals ---------------------------->

    <div class="request-creation__modal-container" *ngIf="false">
      <request-modal-all-archives></request-modal-all-archives>
    </div>
    
    <div class="request-creation__modal-container" *ngIf="false">
      <request-modal-bill-summary></request-modal-bill-summary>
    </div>
        
    <!--------------------------- modals ---------------------------->

    <div class="form-view form-view--one">
      <div class="form-view__top">
        <div class="form-view__title">Editar solicitud</div>
      </div>
      <div class="form-view__content">
        <div class="selects-container">
          <div class="select-item">
            <select>
              <option disabled selected>TIPO DE SOLICITUD</option>
              <option>PAZ Y SALVO</option>
              <option>CERTIFICADOS</option>
              <option>DEVOLUCIÓN</option>
              <option>CRÉDITOS ACADÉMICOS</option>
              <option>DEVOLUCIÓN</option>
            </select>
          </div>
          <div class="select-item">
            <select>
              <option disabled selected>SELECCIONAR CURSO</option>
              <option>CURSO BÁSICO DEL ARMA PARA CADETES VERSIÓN 2021-2022-1</option>
              <option>CURSO BÁSICO MIX OF Y SUB</option>
              <option>CURSO INTERMEDIO ADMINISTRATIVO - 2022</option>
            </select>
          </div>
          <div class="select-item" disabled>
            <select>
              <option disabled selected>SUB CATEGORÍA</option>
              <option disabled selected>APLICA A TODOS LOS CURSOS</option>
            </select>
          </div>
        </div>
        <div class="form-view__message">Seleccione la solicitud a la que quiera editar requisitos</div>
      </div>
    </div>

    <!-- <div class="form-view form-view--two">
      <div class="form-view__top">
        <div class="form-view__title">Documentos necesarios</div>
        <div class="form-view__price">
          <div class="form-view__price-label">PRECIO:</div>
          <div class="form-view__price-value">$0</div>
          <div class="form-view__price-edit">EDITAR PRECIO</div>
        </div>
      </div>
      <div class="form-view__content">
        <div class="documents-needed">
          <div class="documents-needed__container">
            <i class="documents-needed__icon icon-i-tutorial"></i>
            <div class="documents-needed__descript">
              Estos documentos son necesarios para realizar su
              solicitud (recuerde actualizar sus datos
              en la plataforma antes  de hacer esta solicitud)
            </div>
          </div>
          <div class="documents-needed__container">
            <i class="documents-needed__icon icon-i-tutorial"></i>
            <div class="documents-needed__list">
              <ol>
                <li>Cédula de ciudadanía</li>
                <li>Consignación de inscripción</li>
                <li>Certificado de EPS</li>
                <li>Seguro estudiantil</li>
                <li>Consignación de matrículas</li>
                <li>Referentes personales</li>
                <li>Libreta miliar</li>
                <li>Seguro estudiantil</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="progression-bar-container">
      <request-progression-bar
        [color]="'light'"
      ></request-progression-bar>
    </div>

  </div>
</div>