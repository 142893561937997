import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'request-progression-bar',
  templateUrl: './progression-bar.component.html',
  styleUrls: ['./progression-bar.component.css']
})
export class ProgressionBarComponent implements OnInit {

  @Input() color: string; 
  @Input() pageNumber: number; 
  @Input() currentPageNumber: number; 
  @Input() prevDisabled: boolean; 
  @Input() nextDisabled: boolean; 

  @Output() changeNumberPage = new EventEmitter<number>();

  public percentageBar: number;

  constructor() {
    this.color= "dark";
    this.pageNumber = 0;
    this.percentageBar = 0;
    this.currentPageNumber = 0;
  }

  ngOnInit(): void {
    this.percentageBar = (this.currentPageNumber * 100) / this.pageNumber;
  }

  backPage() {
    if(this.currentPageNumber > 1) {
      this.currentPageNumber--;
      this.changeValue();
    }
  }

  nextPage() {
    if(this.currentPageNumber < this.pageNumber) {
      this.currentPageNumber++;
      this.changeValue();
    }
  }
  
  changeValue() {    
    this.changeNumberPage.emit(this.currentPageNumber);
    this.percentageBar = (this.currentPageNumber * 100) / this.pageNumber;
  }

}
