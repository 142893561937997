import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FileReq } from 'src/app/models/dbModels/fileReq';
import { Request } from 'src/app/models/dbModels/request';
import { StateReq } from 'src/app/models/dbModels/stateReq';
import { AuthService } from 'src/app/services/auth.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.css']
})
export class RequestsListComponent implements OnInit {

  @Input() stateTabs: StateReq[];
  @Input() set requestListData(requestList: Request[]) {
    this.requestListFiltered = requestList;
    this.allRequestList = requestList;
  }
  @Output() requestSelected = new EventEmitter<Request>();
  public idRequestSelected: number | undefined;
  public requestListFiltered: Request[];
  public allRequestList: Request[];

  public tabSelected?: number | null;

  public userIDlogued: number;

  constructor (
    private _router: Router,
    private _authService: AuthService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this.tabSelected = null
    this.userIDlogued = Number(this._authService.getCurrentUserID());
  }

  ngOnInit(): void {
    this.requestListFiltered = this.allRequestList;
  }

  changeFilterTabSelected(tabIndex: any) {
    this.tabSelected = tabIndex;
    if(tabIndex) {
      this.requestListFiltered = this.allRequestList.filter(request => request.requestStateReq?.id === tabIndex);
    } else this.requestListFiltered = this.allRequestList
  }

  getLastAdminFile(requestID): FileReq | undefined { /** TODO: Revisar */
    /** let requestFiles = this.allRequestList.find(request => request.id === requestID)?.filesReq
    return requestFiles?.find(file => file.isAdmin === true); */
    return undefined;
  }

  goToRequestChat(requestID) {
    this._router.navigate([ '/request/' + requestID ], { state: { enableToLoad: true } });    
  }

  returnRequestSelected(request: Request) {
    this.idRequestSelected = request.id;
    this.requestSelected.emit(request);
    this.showRequestInfoInFloatingBox();
  }

  /** ****************************** New Code ****************************** */

  showRequestInfoInFloatingBox(): void {
    console.log('showRequestInfoInFloatingBox');
    this._requestWatcherUserService.manageFloatingBoxUserStudent({ showBox: true, createView: false, infoView: true });
  }

}
