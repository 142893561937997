<!-- Inicio: vista 1: gestion Solicitud, vista 2: generacion masiva, vista 3: OAP -->
<!-- TODO: REVISAR LOGICA -->
<div class="navSingleMassiveSection" *ngIf="false">
  <div class="navBtn" [ngClass]="{'navBtn--selected': tabSelected == 1}" (click)="tabSelected = 1">
    <span>Solicitudes</span>
  </div>
  <div class="navBtnDec"></div>
  <ng-container *ngIf="false">
    <div class="navBtn" [ngClass]="{'navBtn--selected': tabSelected == 2}" (click)="tabSelected = 2">
      <span>Generación Masiva</span>
    </div>
    <div class="navBtnDec"></div>
    <div class="navBtn" [ngClass]="{'navBtn--selected': tabSelected == 3}" (click)="tabSelected = 3">
      <span>Validación masiva OAP</span>
    </div>
    <div class="navBtnDec"></div>
  </ng-container>
</div>
<!-- Fin -->
<!-- Vista de la tabla de las solicitudes -->
<div class="panels">
  <div class="panel-left">
    <div class="headerSection">
      <div class="headerSection__icon">
        <em class="box__icon icon-i-list-icon"></em>
      </div>
      <div class="headerSection__tittle">
        <span class="tittle">Lista de solicitudes</span>
        <span>En esta sección usted podrá encontrar todas las solicitudes, seleccione una para iniciar con su gestión</span>
      </div>
    </div>
    <div class="panel-left__bottom">
      <ng-container [ngSwitch]="tabSelected">
        <ng-container *ngSwitchCase="1">
          <app-requests-list-admin
            [allStateReq]="ALL_STATES_REQUEST"
            [allRequestData]="ALL_MY_REQUEST"
            (showRequestOption)="showDataRequestSelected($event)"
          ></app-requests-list-admin>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <request-request-masive-list-admin></request-request-masive-list-admin>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <request-request-masive-oap-admin></request-request-masive-oap-admin>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <masive-req-view-admin
            [allStateReq]="ALL_STATES_REQUEST"
            [allRequestData]="ALL_MY_REQUEST"
          ></masive-req-view-admin>
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>
<!-- Fin -->
<!-- Caja que se sobrepone para visualizar el detalle de la solicitud -->
<div class="floatingBox" *ngIf="showFloatingBox">
  <ng-container [ngSwitch]="panelRightIndex">
    <ng-container *ngSwitchCase="0">
      <!-- TODO: REVISAR -->
      <div class="requestAdminFindToCreate">
        <div class="requests-creation__header">
          <div class="header__closeModal" (click)="closeUserFloatingBox()">
            <em class="icon-i-close close-icon"></em>
          </div>
        </div>
        <div class="infoSection" *ngIf="false">
          <div>
            <div class="infoSection__tittle">
              <em class="mr-3 icon-i-gear"></em>
              <span>Ir a edición de solicitudes</span>
            </div>
            <span>Esto le llevará al módulo de derechos pecuniarios dónde podrá editar los requerimientos de las solicitudes</span>
          </div>
          <em class="infoSection__icon icon-i-inclined-arrow"></em>
        </div>
        <div class="descriptSection" *ngIf="false">
          <span>Seleccione una solicitud de la lista para comenzar</span>
        </div>
        <div class="searchUserSection">
          <div>
            <div class="searchUserSection__tittle">
              <em class="mr-3 icon-i-gear"></em>
              <span>Buscar Usuario</span>
            </div>
            <span>Aquí podrá seleccionar un usuario y crear una solicitud por el.</span>
          </div>
          <div class="inputContainer">
            <input type="text" placeholder="Buscar por correo" class="normal-button__input" #searchUser (keydown.enter)="onSearchUser(searchUser.value)">
            <button class="btnToFindUserToCreateRequest" (click)="onSearchUser(searchUser.value)">Buscar</button>
          </div>
          <div *ngIf="userFound" class="containerBtnConfirmCreateRequest">
            <div class="mt-3">
              <strong>Usuario Encontrado:</strong>
              {{ dataUserFound.LastName1 }}
              {{ dataUserFound.LastName2 }}
              {{ dataUserFound.Name1 }}
              {{ dataUserFound.Name2 }}
            </div>
            <div class="mt-3">
              <button class="btnConfirmCreateRequest" (click)="getAllDataForUser()">Crear Solicitud</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <ng-container *ngIf="false">
        <app-request-edition></app-request-edition>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <request-request-chat-admin
        [requestData]="requestSelected"
        [requesterUser]="REQUESTER_USER"
        [allSubTypeRequests]="ALL_SUBTYPE_REQUEST"
        (closeRequestData)="closeChatView()"
        (updateData)="updateRequestList()"
      ></request-request-chat-admin>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <app-request-creation
        [allTypeRequests]="ALL_TYPE_REQUEST"
        [allSubTypeRequests]="ALL_SUBTYPE_REQUEST"
        [allUserCourses]="ALL_USER_COURSES"
        [requestByAdmin]="true"
        [idUserFromAdmin]="dataUserFound.id!"
        [requesterUser]="dataUserFound"
        (showLastRequestCreated)="requestUserCreated()"
      ></app-request-creation>
    </ng-container>
    <ng-container *ngSwitchCase="4">
      <masive-edition-floating-box>
      </masive-edition-floating-box>
    </ng-container>
  </ng-container>
</div>
<!-- Fin -->
