import { Component, Input, OnInit } from '@angular/core';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';
import { RequestMessage } from 'src/app/models/logicModels/requestMessage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'request-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent implements OnInit {

  @Input() messageInfo?: RequestMessage;
  @Input() messageHistory?: RequestHistory | undefined;

  public urlBackV1:any = environment.urlBackV1;

  constructor() { }

  ngOnInit(): void {
  }

}
