import { Component, Input, OnInit } from '@angular/core';
import { ReqMembers } from 'src/app/models/dbModels/req-members';
import { Request } from 'src/app/models/dbModels/request';

@Component({
  selector: 'ramc-semaforizacion-in-days',
  templateUrl: './semaforizacion-in-days.component.html',
  styleUrls: ['./semaforizacion-in-days.component.css']
})
export class SemaforizacionInDaysComponent implements OnInit {

  @Input() request: Request;

  public totalDays: number = 0;
  public colorToShow: string = "#828282";
  public showBox: boolean = false;
  public localReqMember: ReqMembers;

  constructor() { }

  ngOnInit(): void {
    if (!this.request.reqMembers || this.request.reqMembers.length == 0) return;
    let indexReqMember = this.request.reqMembers.findIndex(item => item.isStartingProcess == true);
    if (indexReqMember == -1) return;
    this.localReqMember = this.request.reqMembers[indexReqMember];
    if (!this.localReqMember.isAproved) return;
    let statusColorsInJsonData: StatusColors[] = JSON.parse(this.request.requestSubTypeRequest?.statusColors!);
    this.totalDays = this.getBusinessDays(this.localReqMember.processedAT);
    statusColorsInJsonData.forEach(statusColorIterated => {
      if (
        this.totalDays >= statusColorIterated.initDay &&
        this.totalDays <= statusColorIterated.endDate
      ) this.colorToShow = statusColorIterated.color;
    });
    this.showBox = true;
  }

  getBusinessDays(startDate) {
    const endDate = new Date();
    let count = 0;
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) count++; // 0 = Sunday, 6 = Saturday
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (count > 0) count--;
    return count;
  }

}

class StatusColors {
  initDay: number;
  endDate: number;
  color: string;
}
