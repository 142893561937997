<div class="box-student">
  <div class="box-student__student-info">
    <div class="box-student__img">
      <div class="user-image" [ngStyle]="{'background-image': 'url('+img+')'}"></div>
    </div>
    <div class="box-student__text">
        <div class="box-student__text-name">
          {{name}}
        </div>
        <div class="box-student__text-email">
          {{email}}
        </div>
    </div>
  </div>
  <div class="box-student__buttons">
    <button class="button-action button-action--selected" (click)="filterActivation()">Ver todas las solicitudes (6)</button>
    <button class="button-action" (click)="paymentHistoryActivation()">Ver historial de pagos</button>
  </div>
</div>