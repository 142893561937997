<div class="boxBody">
  <div class="whiteBox">
    <div class="whiteBox__horizontalContent">
      <div class="whiteBox__headerTittle">
        Edición masiva ({{ ALL_MASSIVE_REQ_LIST.length }})
      </div>
      <div class="whiteBox__iconClose">
        <a (click)="closeFloatingBoxAdmin()"><em class="minimize-icon icon-i-close"></em></a>
      </div>
    </div>
  </div>
  <div class="whiteBox">
    <div class="whiteBox__horizontalContent">
      <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 1}" (click)="viewType = 1">Chat usuario</a>
      <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 2}" (click)="viewType = 2">Estado</a>
      <a class="button-tab" [ngClass]="{'button-tab--selected': viewType === 3}" (click)="viewType = 3">Detalles</a>
    </div>
  </div>
  <div class="whiteBox whiteBoxAllSpace">


    
    
    
    
    <div class="request-chat__content">
      <!-- Chat-view -->
      <div class="infoBox" *ngIf="viewType === 1">
        <div class="infoBox_tittle">
          <em class="mr-2 icon-i-gear"></em>
          <span>Mensaje de chat</span>
        </div>
        <div class="infoBox_subTittle">
          <span>En esta sección usted podrá enviar un mensaje a todos los usuarios de las solicitudes seleccionadas.</span>
        </div>

        <div class="input-container">
          <div class="input-container__input">
            <textarea [(ngModel)]="msgToSendToAllReq" type="text" placeholder="Escriba su mensaje"
              class="input-container__input-msg"></textarea>
          </div>
          <div class="input-container__buttons">
            <a (click)="sendMessageToStudent()">
              <div class="input-container__icon-button">
                <em class="input-container__icon icon-i-send"></em>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- states-view -->
      <div class="content-documents" *ngIf="viewType === 2">
        <div class="infoBox" *ngIf="ALL_MASSIVE_REQ_LIST[0].reqMembers != undefined && ALL_MASSIVE_REQ_LIST[0].reqMembers!.length > 0">
          <div class="infoBox_tittle">
            <em class="mr-2 icon-i-gear"></em>
            <span>Aprobaciones</span>
          </div>
          <div class="infoBox_subTittle">
            <span>En esta sección usted podrá aprobar y/o ver las aprobaciones de terceros.</span>
          </div>
  
          <div class="infoBox_content" *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2 && showAproveBtns">
            <div class="commentContainer">
              <span>Este comentario se guardará cuando acepte o rechace su parte del proceso (opcional).</span> <br>
              <input type="text" [(ngModel)]="reqMemberComment" placeholder="Comentario (Opcional)">
            </div>
            <div class="btnContainer">
              <button class="btnContainer__generalBtn" (click)="updateReqMemberAprove(true)">
                Cumple
              </button>
              <button class="btnContainer__generalBtn" (click)="updateReqMemberAprove(false)">
                No cumple
              </button>
            </div>
          </div>
  
  
          <div>
            <div class="mt-3" *ngFor="let user of ALL_MASSIVE_REQ_LIST[0].reqMembers">
              <span>{{ user.numSequence }} - {{ getNameUser(user.userapp!) }}</span> <span *ngIf="user.realName"> ({{ user.realName }})</span>
            </div>
          </div>
  
        </div>
        <div class="infoBox">
          <div class="infoBox_tittle">
            <em class="mr-2 icon-i-gear"></em>
            <span>Gestión de estados ({{ ALL_MASSIVE_REQ_LIST[0].requestStateReq?.nameState }})</span>
          </div>
          <div class="infoBox_subTittle">
            <span>En esta sección usted podrá gestionar los estados de los procesos seleccionados</span>
          </div>
          <div class="infoBox_content">
            <div class="stateContainer">
              <div>
                <em class="mr-2 icon-i-ok"></em> Cambiar estado de trámite actual
              </div>
  
              <div class="stateContainer__sectionBtns">
                <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                  *ngIf="[1,5].includes(ALL_MASSIVE_REQ_LIST[0].stateReqID!)"
                  (click)="updateStateReq(2)">EN REVISIÓN</button>
                <button *ngIf="ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.fileUrl != null && ALL_MASSIVE_REQ_LIST[0].stateReqID == 2"
                  class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="aproveReq()"
                >APROBAR Y GENERAR</button>
                <button class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="updateStateReq(5)"
                  *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2">INCOMPLETO</button>
                <button class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="showModalConfirmChangeStateValue(3)"
                  *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2">APROBAR</button>
                <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                  *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2" (click)="showModalConfirmChangeStateValue(4)">NO APROBAR</button>
              </div>
            </div>
  
            <div class="stateContainer" *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2 && ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.subTypeReqStages && ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.subTypeReqStages!.length > 0">
              <div>
                <em class="mr-2 icon-i-ok"></em> Cambiar estado interno <strong *ngIf="ALL_MASSIVE_REQ_LIST[0].requestStageReq?.nameStage">({{ALL_MASSIVE_REQ_LIST[0].requestStageReq?.nameStage}})</strong>
              </div>
              <div class="commentContainer">
                <span>Ingrese una descripción (Obligatorio, mínimo 10 caracteres).</span> <br>
                <input type="text" [(ngModel)]="stageReqHistoryDescription" placeholder="Descripción (Obligatorio)">
              </div>
              <div class="stateContainer__sectionBtns">
                <ng-container *ngFor="let option of ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.subTypeReqStages">
                  <button *ngIf="option.id != ALL_MASSIVE_REQ_LIST[0].stageReqID" class="stateContainer__generalBtn stateContainer__generalBtn--separator" (click)="updateStageReq(option)">{{ option.nameStage }}</button>
                </ng-container>
              </div>
            </div>
  
            <div class="stateContainer" *ngIf="ALL_MASSIVE_REQ_LIST[0].stateReqID == 2">
              <div>
                <em class="mr-2 icon-i-ok"></em> Cambiar estado de pago actual
              </div>
              <div class="stateContainer__sectionBtns">
                <button class="stateContainer__generalBtn"
                  [ngClass]="{'stateContainer__generalBtn--paid': !ALL_MASSIVE_REQ_LIST[0].isPaid }"
                  (click)="updatePayment(false)">FALTA PAGO</button>
                <button class="stateContainer__generalBtn stateContainer__generalBtn--separator"
                  [ngClass]="{'stateContainer__generalBtn--paid': ALL_MASSIVE_REQ_LIST[0].isPaid }"
                  (click)="updatePayment(true)">PAGADO</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- resume-view -->
      <div class="content-request-summary" *ngIf="viewType === 3">
        <div class="summary-subtitle">Su información para esta solicitud</div>
        <div class="summary-date">
          <div class="summary-date__label">Tipo de solicitud</div>
          {{ ALL_MASSIVE_REQ_LIST[0].requestTypeRequest?.nameTypeRequest }}
        </div>
        <div class="summary-date">
          <div class="summary-date__label">Subtipo de solicitud</div>
          {{ ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.nameSubTypeRequest }}
        </div>
        <div class="summary-date">
          <div class="summary-date__label">Oferta</div>
          {{ ALL_MASSIVE_REQ_LIST[0].requestCourseOffer?.NameCourseOfer }}
        </div>
        <div class="summary-date">
          <div class="summary-date__label">Cupos Totales</div>
          {{ ALL_MASSIVE_REQ_LIST[0].requestCourseOffer?.TotalPeople }}
        </div>
  
        <br><br>
        <div class="summary-subtitle">Descripción de la solicitud</div>
        <div class="text-item text-item__noFlex">
          <span [innerHTML]="ALL_MASSIVE_REQ_LIST[0].requestSubTypeRequest?.descript"></span>
        </div>
  
      </div>
    </div>

  </div>
</div>

<div class="confirm-changeState__modal" *ngIf="showConfirmChangeStateModal">
  <div class="confirm-changeState__modal-content">
    <em class="icon-i-payment payment-icon"></em>
    <h2 class="modal-content__title">Cambio de estado</h2>
    <p class="modal-content__paragraph">Al aceptar el cambio de estado la solicitud se cerrará y NO podrá modificarse nuevamente ¿Está seguro de cambiar el estado?</p>
    <div class="modal-content__actions">
      <button class="modal-content__actions-buttons modal-content__actions-buttons--red"
        (click)="showConfirmChangeStateModal = false">
        CANCELAR
      </button>
      <button class="modal-content__actions-buttons modal-content__actions-buttons--green"
        (click)="confirmChangeStateValue()" >
        CONFIRMAR
    </button>
    </div>
  </div>
</div>
