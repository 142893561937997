import { Component, Input, OnInit } from '@angular/core';
import { Request } from 'src/app/models/dbModels/request';
import { recentRequest } from 'src/app/models/logicModels/recentRequest';

@Component({
  selector: 'app-recent-request-box',
  templateUrl: './recent-request-box.component.html',
  styleUrls: ['./recent-request-box.component.css']
})
export class RecentRequestBoxComponent implements OnInit {

  @Input() recentRequest: Request;

  constructor() {  }

  ngOnInit(): void {
  }

}
