import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { AttachmentService } from 'src/app/services/attachment.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'upload-file-box',
  templateUrl: './upload-file-box.component.html',
  styleUrls: ['./upload-file-box.component.css']
})
export class UploadFileBoxComponent implements OnInit {

  @Input() isPlaceholderFile: boolean = false;
  @Input() showCloseBtn: boolean = true;
  @Input() containerName: string;
  @Input() maxFileSizeInMB: number = 5;
  @Input() set getFileName(fileName: string) {
    if (fileName && fileName != '') this.dataFile.nameFile = fileName;
  }
  @Input() set getTypeDocumentsToUpload(fileTypes: string) {
    if (fileTypes && fileTypes != '') this.acceptedFileTypes = fileTypes;
    else this.acceptedFileTypes = 'image/jpeg,image/png,application/pdf';
    this.fileTypesToShow = '';

    let eachTypesReceived = this.acceptedFileTypes.replace(' ','').split(',');
    console.log('eachTypesReceived: ', eachTypesReceived);

    let dataTemp: string[] = [];
    eachTypesReceived.forEach(typeFile => {
      dataTemp.push(TypesFiles[typeFile]);
    });
    this.fileTypesToShow = dataTemp.join(', ');
    console.log('fileTypesToShow: ', this.fileTypesToShow);
  }
  @Output() responseDataFile = new EventEmitter<InfoFileData>();
  @Output() closeView = new EventEmitter<void>();
  @Output() deleteDataFile = new EventEmitter<InfoFileData>();

  public dataFile: InfoFileData = {
    nameFile: "",
    descript: "",
    urlFile: "",
    isPlaceholderFile: false
  };
  public fileToUpload: File;
  private emptyFile: File;
  public errorNoFileName: boolean = false;
  // public isArchiveError: boolean = false;
  public isFileUploaded: boolean = false;
  // public uploadArchiveText: string = 'Elija el archivo';
  public acceptedFileTypes: string = '';
  public fileTypesToShow: string = '';
  public urlBackV1: string = environment.urlBackV1;

  constructor (
    private _attachmentService: AttachmentService,
    private _sweetalertService: SweetalertService,
  ) { }
  
  ngOnInit(): void { /** */}

  onFileInput(event: any) {
    this.isFileUploaded = false;
    let isFileOk = true;
    if (!<File>event.target.files[0]) return;
    this.fileToUpload = <File>event.target.files[0];
    if (this.fileToUpload.size > (this.maxFileSizeInMB*1000000)) {
      isFileOk = false;
      this._sweetalertService.swalError('Error', 'El documento supera el tamaño limite permitido', ()=>{});
    }
    let documentNameSplited = this.fileToUpload.name.split('.');
    let documentType = documentNameSplited[documentNameSplited.length-1].toLowerCase();
    let result = this.fileTypesToShow.includes(documentType);
    if (!this.fileTypesToShow.includes(documentType)) {
      isFileOk = false;
      this._sweetalertService.swalError('Error', 'Tipo de documento no permitido', ()=>{});
    }
    if (!isFileOk) this.fileToUpload = this.emptyFile;
  }

  uploadAttachment() {
    this.errorNoFileName = false;
    if (this.dataFile.nameFile == '') {
      this.errorNoFileName = true;
      this._sweetalertService.swalError('Error', 'Debe ingresar un titulo para el documento', ()=>{});
      return;
    }
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this._attachmentService.storageFile(this.containerName, this.fileToUpload).then((result: any) => {
      this.dataFile.urlFile = `/attachments/${this.containerName}/download/${result['result']['files']['file'][0]['name']}`;
      this.dataFile.isPlaceholderFile = this.isPlaceholderFile;
      this.isFileUploaded = true;
      this._sweetalertService.swalClose();
      this.createFileReq();
    });
  }

  eraseFileSelected(): void {
    this.isFileUploaded = false;
    this.fileToUpload = this.emptyFile;
    this.deleteFile();
  }

  editFileSelected(): void {
    this.isFileUploaded = false;
    this.deleteFile();
  }

  createFileReq(): void {
    this.responseDataFile.emit(this.dataFile);
  }

  deleteFile(): void {
    this.deleteDataFile.emit(this.dataFile);
  }

  closeComponent(): void {
    this.closeView.emit();
  }

}





export enum TypesFiles {
  'image/jpeg' = '.jpg, .jpeg',
  'image/png' = '.png',
  'application/pdf' = '.pdf',
  'application/vnd.ms-excel' = '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = '.xlsx',
}