<div class="general-view">
  <div class="general-view__container" *ngIf="sectionToShow === sectionToShowEnum.mainSection">

    <div class="general-view__header">
      <div class="header__column-l">
        <div class="select-container">
          <span class="label label--white">Elegir comunidad</span>
          <ci-select 
            [optionList]="[ { id: 4, value:'Docente'}, { id: 5, value:'Programa' }, { id: 6, value:'Institución' } ]"
            [selectedOption]="{ id: 4, value:'Docente'}"
            [showDate]="false"
            [selectorId]="'community'"
            (onSelectOption)="getTypeCall( $event.id )">
          </ci-select>
        </div>

        <div class="select-container">
          <span class="label label--white">Elegir periodo de reporte</span>
          <ci-select 
            [optionList]="[ { id:0, value:'Personalizado'}, { id:1, value:'Periodo actual'}, { id: 2, value:'Periodo pasado' } ]"
            [selectedOption]="{ id:0, value:'Personalizado'}"
            [selectorId]="'report'"
            [showDate]="selectedPeriod === 0"
            (onSelectOption)="selectedPeriod = $event.id; applyFilters()"
            (onSelectStartDate)="getStartDate( $event )"
            (onSelectEndDate)="getEndDate( $event )"
            (onClearFilters)="clearFilters()">
          </ci-select>
        </div>

        <div>
          <span class="label label--white">Estado del plan</span>
          <div class="state-button__list">
            <label class="button button--state" for="all">
              <input class="input-state" type="radio" id="all" name="state" (change)="getStateType( undefined )" checked hidden #stateInput>
              <span>Todos</span>
            </label>

            <label class="button button--state" for="active">
              <input class="input-state" type="radio" id="active" name="state" (change)="getStateType( true )" hidden #stateInput>
              <span>Activo</span>
            </label>

            <label class="button button--state" for="ended">
              <input class="input-state" type="radio" id="ended" name="state" (change)="getStateType( false )" hidden #stateInput>
              <span>Finalizado</span>
            </label>
          </div>
        </div>

        <button class="button button--create" 
          [ngClass]="{ 'disabled': currentUser === undefined }" 
          (click)="showSelectorsModal = true">
          Crear plan de mejora
          <em class="icon-i-add add-icon"></em>
        </button>
      </div>

      <div class="header__column-r">
        <div class="search-input__container">
          <em class="icon-i-search search-icon"></em>
          <input type="text" class="search-input" placeholder="Buscar por nombre" #filterByName>
        </div>

        <button class="button button--export" (click)="downloadReport()">Exportar</button>
      </div>

    </div>

    <div class="table-container" [ngClass]="{'disabled':loadingData}" *ngIf="intcallList.length > 0">
      <div class="table-content">
        <table class="table-content__main" aria-describedby="internal calls table" *ngIf="intcallList.length > 0">
          <thead>
            <tr class="table-content__main-tr">
              <th class="table-content__main-th">NOMBRE</th>
              <th class="table-content__main-th">Responsable</th>
              <th class="table-content__main-th">Fecha de inicio</th>
              <th class="table-content__main-th">Fecha de fin</th>
              <th class="table-content__main-th">Estado</th>
              <th class="table-content__main-th">Porcentaje de cumplimiento</th>
              <th class="table-content__main-th">Objetivos en progreso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let intcall of intcallList | paginate: { itemsPerPage: pageItems, currentPage: page, totalItems: totalItems } ">
              <td class="table-content__main-td"> 
                <a [routerLink]="[ intcall.userIntcallAnswers?.[0]?.id, intcall.id]">{{ intcall.nameIntCall | titlecase }}</a>
              </td>
              <td class="table-content__main-td" (click)="true  ">
                <div class="table-content__main-td--profile">
                  <img class="data-content__profile-picture" src="{{ intcall.userIntcallAnswers?.[0]?.Userapp.Photo }}" alt="avatar">
                  <div class="data-content__profile-info">
                    <span class="text text--bold">
                      {{ intcall.userIntcallAnswers?.[0]?.Userapp.Name1 | titlecase }}
                      {{ intcall.userIntcallAnswers?.[0]?.Userapp.LastName1 | titlecase }}
                    </span>
                    <span class="text text--sm">{{ intcall.userIntcallAnswers?.[0]?.Userapp.email }}</span>
                  </div>
                </div>
              </td>
              <td class="table-content__main-td">{{ (intcall.dateStart | date:'dd/MMM/yyyy') || '-' }}</td>
              <td class="table-content__main-td">{{ (intcall.dateEnd | date:'dd/MMM/yyyy') || '-' }}</td>
              <td class="table-content__main-td">{{ getIntCallState(intcall.dateStart, intcall.dateEnd) }}</td>
              <td class="table-content__main-td"> {{ getGrade( intcall ) }} </td>
              <td class="table-content__main-td">
                {{ getNonGradeAnswersCount(intcall.intcallFields, intcall.userIntcallAnswers?.[0]?.UserIntcallFieldAnswers) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <pagination-controls 
      (pageChange)="applyFilters( $event )"
      [autoHide]="true"
      previousLabel="Anterior"
      nextLabel="Siguiente">
    </pagination-controls>
  </div>
  
  <div class="general-view__content" *ngIf="sectionToShow === sectionToShowEnum.createFormSection">
    <ci-create-form 
      [currentUser]="currentUser" 
      [typeCall]="selectedCategory"
      [planTitle]="finalTitle"
      [intCallTags]="finalTags"
      [selectedSchool]="selectedSchool.id"
      (onCloseSection)="closeSectionModal()">
    </ci-create-form>
  </div>
</div>

<!-- MODAL -->
<div class="modal-container" *ngIf="showSelectorsModal">
  <div class="modal-container__body">
    <div class="modal-body__tittle">Creación de plan de mejora</div>

    <div class="modal-control__title" #titleContainer>
      <span class="control-input__main-title">Plan de mejora para: docente</span>
      <input class="control-input" [(ngModel)]="customTitle" type="text">
    </div>

    <div class="modal-body__selectors">
      <div class="modal-body__content">

        <!-- SELECT THE MAIN CATEGORY -->
        <div class="modal-body__content-item">
          <div class="select-container">
            <span class="label label--dark">Seleccionar categoría</span>
            <ci-select 
              [optionList]="[ { id: 4, value:'Docente' }, { id: 5, value:'Programa' }, { id: 6, value:'Institución' } ]"
              [selectedOption]="{ id: 4, value:'Docente' }"
              [showDate]="false"
              [selectorId]="'category'"
              (onSelectOption)="onChangeCategory( $event )">
            </ci-select>
          </div>
        </div>

        <!-- SELECT SCHOOL WHEN THE CATEGORY IS "DOCENTE" -->
        <div class="modal-body__content-item" *ngIf="selectedCategory.id === 4">
          <div class="select-container">
            <span class="label label--dark">Seleccionar escuela</span>
            <ci-select 
              [optionList]="schoolList"
              [showDate]="false"
              [selectorId]="'school'"
              [isInvalidValid]="validateSelections && selectedSchool === undefined"
              (onSelectOption)="selectedSchool = $event"
            >
            </ci-select>
          </div>
        </div>

        <!-- SELECT USER WHEN THE CATEGORY IS "DOCENTE" -->
        <div class="modal-body__content-item" *ngIf="selectedCategory.id === 4">
          <ci-user-search (onSelectUser)="onSelectUser( $event )" [isSubmitting]="validateSelections"> </ci-user-search>
        </div>

        <!-- SELECT SCHOOL WHEN THE CATEGORY IS "PROGRAMA" -->
        <div class="modal-body__content-item" *ngIf="selectedCategory.id === 5">
          <div class="select-container">
            <span class="label label--dark">Seleccionar escuela</span>
            <ci-select 
              [optionList]="schoolList"
              [showDate]="false"
              [selectorId]="'school-2'"
              [isInvalidValid]="validateSelections && selectedSchool === undefined"
              (onSelectOption)="getProgramBySchool( $event )"
            >
            </ci-select>
          </div>
        </div>

        <!-- SELECT PROGRAM WHEN THE CATEGORY IS "PROGRAMA" -->
        <div class="modal-body__content-item" *ngIf="selectedCategory.id === 5">
          <div class="select-container">
            <span class="label label--dark">Seleccionar programa</span>
            <ci-select 
              [optionList]="programList"
              [showDate]="false"
              [selectorId]="'program'"
              [disableSelect]="programList.length === 0"
              [isInvalidValid]="validateSelections && selectedProgram === undefined"
              (onSelectOption)="onSelectProgram( $event )">
            </ci-select>
          </div>
        </div>

        <!-- SELECT SCHOOL WHEN THE CATEGORY IS "INSTITUCIÓN" -->
        <div class="modal-body__content-item" *ngIf="selectedCategory.id === 6">
          <div class="select-container">
            <span class="label label--dark">Seleccionar escuela</span>
            <ci-select 
              [optionList]="schoolList"
              [showDate]="false"
              [selectorId]="'school-3'"
              [isInvalidValid]="validateSelections && selectedSchool === undefined"
              (onSelectOption)="onSelectSchool( $event )">
            </ci-select>
          </div>
        </div>        
      </div>
    </div>

    <div class="modalBody__btns">
      <button type="button" class="btn modalBtn" (click)="onCreateButtonClick()">
        Crear plan de mejora
      </button>
      <button type="button" class="btn modalBtn modalBtnCancel" (click)="closeSectionModal()">
        x
      </button>
    </div>
  </div>
</div>