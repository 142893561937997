import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { RoleMapping } from '../models/dbModels/roleMapping';

@Injectable({
  providedIn: 'root'
})
export class UserAppService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getMyRoles(): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings?filter={"include": ["role"]}`
    return this._httpService.httpGet(url);
  }

}


