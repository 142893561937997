<div class="masiveRequests">
  <div class="masiveRequests__btnsSection">
    <select (change)="onChangeSelectProcess($event)">
      <option disabled selected>Procesos:</option>
      <option value="todos">Todos</option>
      <option value="meHanReasignado">Reasignados a mi</option>
      <option value="yoHeReasignado">Que he reasignado</option>
    </select>
    <div class="masiveRequests__btnsSection__modalBtn" (click)="showModalDataSelector = !showModalDataSelector">
      Configurar selección
    </div>
  </div>
  <div class="modalOptionMasive">
    <div class="modalOptionMasive__optionsContainer">
    </div>
    <div class="modalOptionMasive__"></div>
  </div>
  <ng-container *ngIf="typeView == 1 else viewMyAssigment">
    <div class="masiveRequests__listSection">
      <div class="masiveReqListTittle">
        <div class="masiveReqListTittle__miniSection">
          <strong>Listado desde: </strong> {{ schoolSelected.NameTSchool }}
        </div>
        <div class="masiveReqListTittle__miniSection">
          <strong>CURSO: </strong> {{ courseOferSelected?.NameCourseOfer }}
        </div>
        <div class="masiveReqListTittle__miniSection">
          <strong>DESCUENTO DE: </strong> {{ subTypeReqSelected.nameSubTypeRequest }}
        </div>
      </div>
      <div class="masiveRequestTable">
        <request-bar-masive-list-admin
          *ngIf="showTableNavBar"
          [isListSaved]="isCreated"
          (createMasiveRequest)="createMasiveRequest()"
        ></request-bar-masive-list-admin>
        <table><caption></caption>
          <thead>
            <tr>
              <th scope="col">NOMBRE</th>
              <th scope="col">FECHA</th>
              <th scope="col">ESTADO</th>
              <th scope="col">PAGO</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of ALL_USERCOUSE">
              <td>
                {{ user.userapp.LastName1 }}
                {{ user.userapp.LastName2 }}
                {{ user.userapp.Name1 }}
                {{ user.userapp.Name2 }}
              </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>        
          </tbody>
        </table>
        <request-bar-masive-list-admin
          *ngIf="showTableNavBar"
          [isListSaved]="isCreated"
          (createMasiveRequest)="createMasiveRequest()"
        ></request-bar-masive-list-admin>
      </div>
    </div>
  </ng-container>
  <ng-template #viewMyAssigment>
    <div class="masiveRequests__multiReqSection">
      <ng-container *ngFor="let multiReq of ALL_MULTI_REQUEST">
        <div class="multiReq__header">
          <div class="multiReq__miniSection">
            <strong>Listado desde: </strong>
            {{ multiReq.schools?.SchoolAcronim }} - 
            {{ multiReq.schools?.NameTSchool }}
          </div>
          <div class="multiReq__miniSection">
            {{ multiReq.courseOfer?.NameCourseOfer }}
          </div>
          <div class="multiReq__miniSection">
            {{ multiReq.requests![0].requestTypeRequest?.nameTypeRequest }} - 
            {{ multiReq.requests![0].requestSubTypeRequest?.nameSubTypeRequest }}
          </div>
          <div class="multiReq__miniSection multiReq__miniSection--lastSection">
            <div>
              Reasignado desde: <br>
              <strong>{{ multiReq.userOwner?.CedocEmail }}</strong>
            </div>
            <em class="icon-i-plus" (click)="multiReq.showListData = !multiReq.showListData"></em>
          </div>
        </div>
        <ng-container *ngIf="multiReq.showListData && multiReq!.requests!.length > 0">
          <request-bar-masive-list-admin
            [isListSaved]="true"
            [allDataList]="multiReq"
          ></request-bar-masive-list-admin>
          <table><caption></caption>
            <thead>
              <tr>
                <th scope="col">NOMBRE</th>
                <th scope="col">FECHA</th>
                <th scope="col">ESTADO</th>
                <!-- <th scope="col">PAGO</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let reqIterated of multiReq!.requests">
                <td>
                  {{ reqIterated?.requestUserRequester?.LastName1 }}
                  {{ reqIterated?.requestUserRequester?.LastName2 }}
                  {{ reqIterated?.requestUserRequester?.Name1 }}
                  {{ reqIterated?.requestUserRequester?.Name2 }}
                </td>
                <td>{{ reqIterated?.registerDate | date: 'dd/MMM/yyyy' }}</td>
                <td>{{ reqIterated?.requestStateReq?.nameState }}</td>
                <!-- <td>{{ reqIterated?.isPaid ? 'Pagado' : 'Pendiente' }}</td> -->
              </tr>        
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
<!-- ********************************** Modal ********************************** -->
<div class="modalContainer" *ngIf="showModalDataSelector">
  <div class="modalContainer__body modalBody">
    <div class="modalBody__upperBar modalBodyLateralPadding">
      <div class="columnsSelector__tittle">
        <span class="columnsSelector__tittle--principal">Configurar selección en dónde se gestionarán las solicitudes</span><br>
        <span class="columnsSelector__tittle--secondary">Desde esta selección se extraerá el listado de estudiantes filtrando el tipo de oferta</span>
      </div>
      <em class="icon-i-cancel-details-box changeCursor" (click)="showModalDataSelector = !showModalDataSelector"></em>
    </div>
    <div class="modalBody__content modalBodyLateralPadding">
      <div class="modalBodyContent">
        <div class="modalBodyContent__item">
          <span>Escuela*</span><br>
          <select (change)="onChangeSchool()"
            [(ngModel)]="schoolSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR ESCUELA</option>
            <option *ngFor="let school of ALL_SCHOOLS" [ngValue]="school">{{school.NameTSchool}}</option>
          </select>
        </div>
        <div class="modalBodyContent__item">
          <span>Tipo de educación*</span><br>
          <select (change)="onChangeCategory()"
            [(ngModel)]="categorySelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR TIPO DE EDICACIÓN</option>
            <option *ngFor="let school of ALL_CATEGORIES" [ngValue]="school">{{school.NameCategoryCourse }}</option>
          </select>
        </div>
        <div class="modalBodyContent__item">
          <span>Tipo de curso*</span><br>
          <select (change)="onChangeTypeCourse()"
            [(ngModel)]="typeCourseSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR TIPO DE CURSO</option>
            <option *ngFor="let school of ALL_TYPE_COURSES_FILTERED" [ngValue]="school">{{school.NameTypeCourse }}</option>
          </select>
        </div>
        <div class="modalBodyContent__item">
          <span>Curso*</span><br>
          <select (change)="onChangeCourse()"
            [(ngModel)]="courseSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR CURSO</option>
            <option *ngFor="let school of ALL_COURSES" [ngValue]="school">{{school.NameCourse }}</option>
          </select>
        </div>
        <div class="modalBodyContent__item">
          <span>SELECCIONE OFERTA*</span><br>
          <select (change)="onChangeCourseOfer()"
            [(ngModel)]="courseOferSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR OFERTA</option>
            <option *ngFor="let school of ALL_COURSESOFER" [ngValue]="school">{{school.NameCourseOfer }}</option>
          </select>
        </div>
        <div class="modalBodyContent__item"></div>
        <div class="modalBodyContent__item"></div>
        <div class="modalBodyContent__item"></div>
        <div class="modalBodyContent__item">
          <span>Tipo de Solicitud*</span><br>
          <select (change)="onChangeTypeRequest()"
            [(ngModel)]="typeReqSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR TIPO DE SOLICITUD</option>
            <option *ngFor="let school of ALL_TYPE_REQUEST" [ngValue]="school">{{school.nameTypeRequest }}</option>
          </select>
        </div>
        <div class="modalBodyContent__item">
          <span>Subtipo de Solicitud*</span><br>
          <select (change)="onChangeSubTypeRequest()"
            [(ngModel)]="subTypeReqSelected" required>
            <option disabled [ngValue]="undefined">SELECCIONAR SUBTIPO DE SOLICITUD</option>
            <option *ngFor="let school of ALL_FILTERED_SUBTYPE_REQUEST" [ngValue]="school">{{school.nameSubTypeRequest }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="modalBody__btns">
      <button type="button" class="btn modalBtn" (click)="showModalDataSelector = false">
        APLICAR
        <em class="icon ml-2 icon-i-check-modal"></em>
      </button>
      <button type="button" class="btn modalBtn" (click)="showModalDataSelector = false">
        REESTABLECER
        <em class="icon ml-2 icon-i-reasign"></em>
      </button>
    </div>
  </div>
</div>
