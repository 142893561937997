import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { concat, forkJoin, map, Observable, switchMap, take, tap, toArray } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { FileReq } from 'src/app/models/dbModels/fileReq';
import { NotificationQueue } from 'src/app/models/dbModels/notificationQueue';
import { ReqMembers } from 'src/app/models/dbModels/req-members';
import { ReqMembersStaff } from 'src/app/models/dbModels/ReqMembersStaff';
import { Request } from 'src/app/models/dbModels/request';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { User } from 'src/app/models/dbModels/user';
import { UserCourse } from 'src/app/models/dbModels/userCourse';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { AuthService } from 'src/app/services/auth.service';
import { Functions } from 'src/app/services/functions';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'app-request-creation',
  templateUrl: './request-creation.component.html',
  styleUrls: [ './request-creation.component.css' ]
} )
export class RequestCreationComponent implements OnInit {


  @Input() allTypeRequests: TypeRequest[];
  @Input() allSubTypeRequests: SubTypeRequest[];
  @Input() allUserCourses: UserCourse[];
  @Input() requestByAdmin: boolean = false;
  @Input() idUserFromAdmin: number = 0;
  @Input( 'requesterUser' ) requesterUser!: User;
  @Input() allMyRequestList: Request[] = [];
  @Output() updateData = new EventEmitter<boolean>();
  @Output() showLastRequestCreated = new EventEmitter<boolean>();

  public nameBucketRequestInfo = environment.nameBucketRequestInfo;
  public PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  public userCourseSelected: UserCourse;
  public typeRequestFilteredBySchool: TypeRequest[] = [];

  public subTypeRequestsSelected: SubTypeRequest[] = [];
  public TOTAL_PAGE_NUMBER: number = 4;

  public currentPageNumber: number;
  public filesReqList: FileReq[] = [];
  public filesReqListToUpload: any[] = [];

  public uploadArchiveText: string = 'Elija el archivo';
  public isArchiveError: boolean = false;

  public fileToUpload: File;

  public requestCreationInfo: Request;
  public subTypeRequestSelected?: SubTypeRequest;
  public typeRequSelected?: TypeRequest;

  public aditionalInformationText: string = '';

  private GATEWAY_NAME = environment.dispatchOrder_gatewayName;

  constructor (
    private _requestService: RequestService,
    private _authService: AuthService,
    private _functions: Functions,
    public _router: Router,
    private _sweetalertService: SweetalertService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this.validateUserLoggued();
    this.requestCreationInfo = {
      registerDate: new Date( Date.now() ),
      updateDate: new Date( Date.now() ),
      stateReqID: 1,
      userOwnerID: undefined,
      userCurrentID: undefined,
      userRequesterID: 0,
    };
  }

  myIP = '';
  getMyIp(): void {
    this._functions.getIP().pipe(take(1)).subscribe({
      next: data => {
        console.log('this.myIP', this.myIP);
        console.log('getMyIp success', data);
        let ipList = data.clientIp.split(',');
        console.log('ipList', ipList);
        if (ipList[0].includes(':')) this.myIP = ipList[1].trim();
        else this.myIP = ipList[0].trim();
        console.log('this.myIP', this.myIP);
      },
      error: err => {
        console.log('getMyIp fail', err);
      }
    });
  }

  ngOnInit(): void {
    this.currentPageNumber = 1;
  }

  validateUserLoggued(): void {
    const UserID = this._authService.getCurrentUserID();
    if (UserID == null) this._router.navigate(['/landing']);
    else this.getMyIp();
  }

  typeRequestSelected(): void {
    if (
      this.requestCreationInfo.typeRequestID == undefined ||
      this.requestCreationInfo.schoolID == undefined
    ) return
    this.subTypeRequestsSelected = [];
    this.requestCreationInfo.subTypeRequestID = undefined;
    this.allSubTypeRequests.forEach( element => {
      if (
        element.typeRequestID == this.requestCreationInfo.typeRequestID &&
        element.schoolID == this.requestCreationInfo.schoolID
      ) this.subTypeRequestsSelected.push( element );
    } );
  }

  courseSelected(): void {
    this.requestCreationInfo.schoolID = this.userCourseSelected.coursesOfer.SchoolID;
    this.requestCreationInfo.courseOfferID = this.userCourseSelected.coursesOfer.id;
    this.requestCreationInfo.typeRequestID = undefined;
    this.requestCreationInfo.subTypeRequestID = undefined;
  }

  subTypeRequestsSelection() {
    this.myDinamicFilesUploaded = [];
    this.myDinamicDataFilesToUpload = [];
    this.filesReqListToUpload = [];
    let requestDUplicatedInProcess = false;
    this.allMyRequestList.forEach(myRequestIterated => {
      if (
        myRequestIterated.courseOfferID == this.requestCreationInfo.courseOfferID &&
        myRequestIterated.subTypeRequestID == this.requestCreationInfo.subTypeRequestID &&
        ( myRequestIterated.stateReqID == 1 || myRequestIterated.stateReqID == 2 )
      ) requestDUplicatedInProcess = true;
    });
    if (requestDUplicatedInProcess) {
      this.requestCreationInfo.subTypeRequestID = undefined;
      this._sweetalertService.swalError('Error','Ya hay una solicitud de este tipo en proceso.', () => {})
      return
    };
    this.subTypeRequestSelected = this.subTypeRequestsSelected?.find( item => item.id === this.requestCreationInfo.subTypeRequestID );
    this.typeRequSelected = this.allTypeRequests?.find( item => item.id === this.subTypeRequestSelected?.typeRequestID );
    this.requestCreationInfo.userOwnerID = this.subTypeRequestSelected?.userID;
    this.requestCreationInfo.userCurrentID = this.subTypeRequestSelected?.userID;
    if (this.subTypeRequestSelected!.subTypeRequestPlaceholders!.length > 0) {
      this.myDinamicFilesUploaded = new Array( this.subTypeRequestSelected!.subTypeRequestPlaceholders!.length ).fill(false);
      this.myDinamicDataFilesToUpload = [];
      this.allFilesUploaded = new Array( this.subTypeRequestSelected!.subTypeRequestPlaceholders!.length ).fill(false);
      this.subTypeRequestSelected!.subTypeRequestPlaceholders!.forEach((element, index) => {
        if (!element.isMandatory) this.myDinamicFilesUploaded[index] = true;
        const dataToFiles = {
          nameFile: `${element.order} (${element?.isMandatory ? 'Obligatorio' : 'Opcional'}) ${element.placeholderString}`,
          descript: '',
          urlFile: null,
          userID: this.getUserID(),
          updateDate: new Date( Date.now() ),
          isAdmin: false,
          isDocumentValid: false,
          requestID: '',
          isPlaceholderFile: true
        }
        this.myDinamicDataFilesToUpload.push(dataToFiles);
      });
    } else {
      this.allMandatoryFilesUploaded = true;
    }
  }

  coursesOfferSelection() { /** TODO: Revisar */
    this.requestCreationInfo.courseID = this.allUserCourses?.find( item => item.coursesOfer.id === this.requestCreationInfo.courseOfferID )?.coursesOfer.CourseID;
  }

  onChangePageNumber( e: any ) {
    this.currentPageNumber = e;
  }

  onChangePageNext() {
    this.currentPageNumber = this.currentPageNumber + 1;
  }
  public isSavingData: boolean = false;

  createRequest() {
    this.isSavingData = true;
    this.requestCreationInfo.userRequesterID = this.getUserID();
    
    this.requestCreationInfo.registerDate = new Date(),
    this.requestCreationInfo.updateDate = new Date(),

    this.requestCreationInfo.TotalPayment = this.subTypeRequestSelected?.price;
    this.requestCreationInfo.dueDate = new Date();

    this._requestService.createRequest( this.requestCreationInfo ).subscribe( {
      next: ( data:any ) => {
        // TODO: review code, to many functions... refactor to switchMap or forkjoin
        this.autoAssingToDefaultRequestMembers(data.id);
        if ( this.isThereFileToUpload ) this.manageFileReqListToCreate( data.id );
        if ( this.aditionalInformationText.trim() != '' ) this.createRequestHistory( data.id );
        this.isSavingData = false;
        this.currentPageNumber = 4;
        if ( this.subTypeRequestSelected?.autoGenerateLink && this.subTypeRequestSelected?.price && this.subTypeRequestSelected?.price > 0 ) this.setReceipt( data.id );
        this.updateDataSaved();
      },
      error: ( err ) => console.log( err )
    } )
  }

  autoAssingToDefaultRequestMembers(idRequest: number): void {
    if (
      !this.subTypeRequestSelected ||
      !this.subTypeRequestSelected.reqMembersTemplate ||
      this.subTypeRequestSelected.reqMembersTemplate.length <= 0
    ) return;
    
    // let dataToUpdateRequest = {
    //   userCurrentID: this.subTypeRequestSelected.reqMembersTemplate[0].userID
    // }
    let dataToRequestMembersObserbable: any[] = [];
    
    this.subTypeRequestSelected.reqMembersTemplate.forEach(reqMemberTemplate => {
      let dataToRequestMembers: ReqMembers = {
        userID: reqMemberTemplate.userID,
        requestID: idRequest,
        isAproved: null,
        numSequence: reqMemberTemplate.numSequence,
        isAdmin: true,
        // assignedAT: new Date().toISOString(),
        realName: reqMemberTemplate.realName,
        isStartingProcess: reqMemberTemplate.isStartingProcess
      };
      if (reqMemberTemplate.reqMemberTemplateStaff && reqMemberTemplate.reqMemberTemplateStaff.length > 0) {
        dataToRequestMembersObserbable.push(
          this._requestService.createReqMembers( dataToRequestMembers ).pipe(
            switchMap ( (responseReqMembers: ReqMembers) => {
              let observableReqMembersStaffTemplate: Observable<any>[] = [];
              reqMemberTemplate.reqMemberTemplateStaff.forEach(staffItertaed => {
                let dataReqMembersStaff: ReqMembersStaff = {
                  userID: staffItertaed.userID,
                  reqMembersID: responseReqMembers.id!
                }
                observableReqMembersStaffTemplate.push(
                  this._requestService.createReqMembersStaff( dataReqMembersStaff ).pipe(
                    tap( resp => console.log(resp) ),
                    map( (createReqMembersStaff) => ({ createReqMembersStaff, responseReqMembers }) ) 
                  )
                );
              });
              return concat(...observableReqMembersStaffTemplate).pipe(toArray());;
            }),
          )
        );
      } else dataToRequestMembersObserbable.push( this._requestService.createReqMembers( dataToRequestMembers ).pipe( take(1) ) );
    });

    forkJoin([
      ...dataToRequestMembersObserbable
    ]).subscribe( {
      next: ( data ) => {
        console.log( 'autoAssingToDefaultRequestMembers response', data );
      },
      error: ( err ) => {
        console.log( "ERROR autoAssingToDefaultRequestMembers", err )
      },
      complete: () => {
        console.log( 'complete autoAssingToDefaultRequestMembers' );
      }
    } )
  }

  private setReceipt( requestId: number ) {
    if (this.PRINCIPAL_APP_NAME == 'celic' && !this.requesterUser.parentsToStudents?.[0]?.parentUser) {
      this._sweetalertService.swalError('Error','No hay datos del responsable de pago.', () => {})
      return;
    }
    let navigatorName = navigator.userAgent;

    let todayDate = new Date();
    todayDate.setDate(new Date().getDate() + 30);
    let monthDate = todayDate.toISOString();

    let userDataToPayment: User;
    if (this.PRINCIPAL_APP_NAME == 'cedoc') userDataToPayment = this.requesterUser;
    else {
      userDataToPayment = this.requesterUser.parentsToStudents?.[0]?.parentUser!;
    }

    console.log('userDataToPayment', userDataToPayment);
    if (!userDataToPayment) return;

    const data: DispatchOrderI = {
      id: Number(this.requesterUser.id!.toString() + new Date().valueOf().toString()).toString(),
      DueDate: monthDate,
      issueDate: new Date().toISOString(),
      DispatchCode: requestId,
      Code: this.requestCreationInfo.schoolID!,
      document: userDataToPayment.UserDocuments?.[ 0 ].Document,
      Address: userDataToPayment?.ContactInfos?.[ 0 ]?.Address,
      UserId: userDataToPayment.id!, // TODO: Preguntar a Luis, si es el id del padre?
      Telephone: userDataToPayment?.ContactInfos?.[ 0 ]?.CellPhone ?? userDataToPayment?.ContactInfos?.[ 0 ]?.Phone ?? 0,
      firsEmail: userDataToPayment.CedocEmail,
      lastEmail: userDataToPayment.email || userDataToPayment.CedocEmail!,
      FirstName: `${ userDataToPayment.Name1 } ${ userDataToPayment.Name2 ?? '' }`,
      lasName: `${ userDataToPayment.LastName1 } ${ userDataToPayment.LastName2 ?? '' }`,
      AssetsAndServicesCatalog: 'N/A',
      Description: this.subTypeRequestSelected?.nameSubTypeRequest!,
      PaymentState: false,
      paymentDate1: this.subTypeRequestSelected?.price!,
      fileAdminName: this.subTypeRequestSelected?.nameSubTypeRequest!,
      realStatus: "PENDING",
      gatewayName: this.GATEWAY_NAME,
      requestID: requestId,
      userAgent: navigatorName,
      ipAddress: this.myIP,
      paidTo: `["${this.requesterUser.UserDocuments?.[ 0 ].Document!}"]`,
    }
    console.log('DispatchOrderI',data);

    this._requestService.setDispatchOrders( data ).pipe(
      take(1)
    ).subscribe({
      next: resultDispatchOrder => {
        console.log( 'setReceipt success', resultDispatchOrder );
        this.patchRequestByID(requestId, resultDispatchOrder.id);
        // this.postDispatchOrdersPaymentRequest(resultDispatchOrder.id);
        this.postNotificationQueue( `Tienes un recibo nuevo asociado a tu solicitud con ticket ${resultDispatchOrder.requestID}, con un valor de $ ${resultDispatchOrder.TotalPayment} COP` );
        this.updateDataSaved();
      },
      error: (err) => console.log( 'setReceipt error', err ),
      complete: () => {
        console.log( 'setReceipt complete' );
      }
    })
  }

  patchRequestByID(idReq: number, dispOrdID: string): void {
    let data = { dispatchOrderID: dispOrdID }
    this._requestService.patchRequest(idReq, data).pipe(take(1)).subscribe({
      next: data => { console.log('patchRequestByID success', data ); },
      error: err => { console.log('patchRequestByID fail', err ); }
    });
  }

  postDispatchOrdersPaymentRequest(dispOrdID: number): void {
    console.log('postDispatchOrdersPaymentRequest',dispOrdID);
    let navigatorName = navigator.userAgent;
    let data = {
      userAgent: navigatorName,
      ipAddress: this.myIP,
      reference: dispOrdID
    }
    console.log('DATA::::: ',data);
    this._requestService.postDispatchOrdersPaymentRequest(data).pipe(take(1)).subscribe({
      next: data => {
        console.log('postDispatchOrdersPaymentRequest success', data );
        this.updateDataSaved();
      },
      error: err => { console.log('postDispatchOrdersPaymentRequest fail', err ); }
    });
  }

  createRequestValidation(): boolean {
    let validation = true;
    if (
      !this.requestCreationInfo.typeRequestID ||
      !this.requestCreationInfo.subTypeRequestID ||
      !this.requestCreationInfo.courseOfferID ||
      !this.requestCreationInfo.schoolID
    )
      validation = false
    return validation;
  }

  manageFileReqListToCreate(requestID: number) {
    let allFilesSavedToUpdated: any[] = [];

    if (this.myDinamicDataFilesToUpload.length == 0) { // Adjuntar archivos no obligatorios por DB
      this.filesReqListToUpload.forEach(fileIterated => {
        fileIterated.requestID = requestID;
        const dataToSend = fileIterated;
        allFilesSavedToUpdated.push( this._requestService.createFileReq( dataToSend ).pipe( take( 1 ) ) );
      });
    }
    if (this.myDinamicDataFilesToUpload.length > 0) { // Adjuntar archivos OBLIGATORIOS por DB (placeHolders)
      this.myDinamicDataFilesToUpload.forEach(fileIterated => {
        fileIterated['requestID'] = requestID;
        const dataToSend = fileIterated;
        allFilesSavedToUpdated.push( this._requestService.createFileReq( dataToSend ).pipe( take( 1 ) ) );
      });
    }

    // Ejecutar peticiones
    forkJoin([...allFilesSavedToUpdated]).subscribe( {
      next: ( data ) => {
        console.log( 'manageFileReqListToCreate', data );
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( "ERROR manageFileReqListToCreate", err )
      },
      complete: () => {
        console.log( 'complete manageFileReqListToCreate' );
      }
    } )
  }

  createRequestHistory( requestID ) {
    let requestHistoryCreation: RequestHistory = {
      publicComment: this.aditionalInformationText,
      updateDate: new Date( Date.now() ),
      stageReqID: 1,
      stateReqID: 1,
      requestID: requestID,
      userID: this.getUserID(),
    }
    if ( requestHistoryCreation.requestID ) {
      this._requestService.createRequestHistory( requestHistoryCreation ).pipe( take( 1 ) ).subscribe(
        data => {
          console.log( 'createRequestHistory success', data );
          this.aditionalInformationText = '';
          this.updateDataSaved();
        },
        err => {
          console.log( 'createRequestHistory fail', err );
        }
      );
    }
  }

  goToRequestViewChat() {
    this.showLastRequestCreated.emit( true );
  }

  public isThereFileToUpload: boolean = false;
  getDataFromUploadFileComponent( data: InfoFileData, index: number | null = null ): void {
    console.log('uploadFileResponse: ',data);
    console.log('indexFile: ',index);
    let dataFile = {
      nameFile: data.nameFile,
      descript: data.descript,
      urlFile: data.urlFile,
      userID: this.getUserID(),
      updateDate: new Date( Date.now() ),
      isAdmin: false,
      isDocumentValid: false,
      requestID: '',
      isPlaceholderFile: data.isPlaceholderFile
    };
    this.isThereFileToUpload = true;
    if (index != null && index >= 0) {
      this.filesReqListToUpload.push(dataFile);
      this.validateMandatoryFiles(index, dataFile);
    } else {
      this.filesReqListToUpload = [];
      this.filesReqListToUpload.push(dataFile);
    }
  }
  
  public myDinamicFilesUploaded: boolean[] = [];
  public myDinamicDataFilesToUpload: object[] = [];
  public allFilesUploaded: boolean[] = [];
  public allMandatoryFilesUploaded = false;
  validateMandatoryFiles(index: number, dataFile: object) {
    this.myDinamicFilesUploaded[index] = true;
    this.myDinamicDataFilesToUpload[index] = dataFile;
    this.allFilesUploaded[index] = true;
    this.allMandatoryFilesUploaded = true;
    this.myDinamicFilesUploaded.forEach(element => {
      if (!element) this.allMandatoryFilesUploaded = false;
    });
  }

  deleteDataFile(dataFile: object, index: number) {
    if (this.subTypeRequestSelected!.subTypeRequestPlaceholders![index].isMandatory) this.myDinamicFilesUploaded[index] = false;
    let urlFileToFind = this.myDinamicDataFilesToUpload[index]['urlFile'];
    let indexToFind = this.filesReqListToUpload.findIndex(x => x.urlFile == urlFileToFind);
    if (indexToFind >= 0) this.filesReqListToUpload.splice(indexToFind, 1);
    this.myDinamicDataFilesToUpload[index]['urlFile'] = null;
    this.allFilesUploaded[index] = false;
    this.allMandatoryFilesUploaded = true;
    this.myDinamicFilesUploaded.forEach(element => {
      if (!element) this.allMandatoryFilesUploaded = false;
    });
  }

  updateDataSaved(): void {
    this.updateData.emit( true );
  }

  cancelCreateRequest(): void {
    this.currentPageNumber = 1;
    this.requestCreationInfo.typeRequestID = undefined;
    this.requestCreationInfo.subTypeRequestID = undefined;
    this.requestCreationInfo.courseOfferID = undefined;
    this.requestCreationInfo.schoolID = undefined;
  }

  deleteFile() { /** TODO: Revisar */
  }

  getUserID(): number {
    console.log('getUserID',this.requesterUser);
    return this.requestByAdmin ? this.idUserFromAdmin : this.requesterUser.id!;
  }

  postNotificationQueue( text: string ): void {
    let newDate: Date = new Date(new Date().getTime());
    let queueNotificationQueueObserbable: any[] = [];
    if (this.PRINCIPAL_APP_NAME == 'cedoc') {
      const data: NotificationQueue = {
        emailsTo: `["${ this.requesterUser.email || this.requesterUser.CedocEmail }"]`,
        namesTO: `["${ this.requesterUser.Name1 }  ${ this.requesterUser.LastName1 }"]`,
        msg: `[{"message":"${ text }"}]`,
        timeToSend: newDate.toISOString(),
        isSend: false,
        isSingleMessage: true,
        typeNotification: 'email'
      }
      queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( data ).pipe( take( 1 ) ) );
    } else {
      if (this.requesterUser.parentsToStudents?.[0]?.parentUser) {
        let userDataToPaymentParent = this.requesterUser.parentsToStudents?.[0]?.parentUser;
        const dataParent: NotificationQueue = {
          emailsTo: `["${ userDataToPaymentParent.email || userDataToPaymentParent.CedocEmail }"]`,
          namesTO: `["${ this.requesterUser.Name1 }  ${ this.requesterUser.LastName1 }"]`,
          msg: `[{"message":"${ text }"}]`,
          timeToSend: newDate.toISOString(),
          isSend: false,
          isSingleMessage: true,
          typeNotification: 'email'
        }
        queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataParent ).pipe( take( 1 ) ) );
      }
      if (this.requesterUser.parentsToStudents?.[0]?.parentUser) {
        let userDataToPaymentAttendant = this.requesterUser.parentsToStudents?.[0]?.attendantUser;
        const dataAttendant: NotificationQueue = {
          emailsTo: `["${ userDataToPaymentAttendant.email || userDataToPaymentAttendant.CedocEmail }"]`,
          namesTO: `["${ this.requesterUser.Name1 }  ${ this.requesterUser.LastName1 }"]`,
          msg: `[{"message":"${ text }"}]`,
          timeToSend: newDate.toISOString(),
          isSend: false,
          isSingleMessage: true,
          typeNotification: 'email'
        }
        queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataAttendant ).pipe( take( 1 ) ) );
      }
    }
    if (queueNotificationQueueObserbable.length == 0) return;
    forkJoin([
      ...queueNotificationQueueObserbable
    ]).subscribe( {
      next: data => {
        console.log( 'postNotificationQueue success', data );
      },
      error: err => {
        console.log( 'postNotificationQueue fail', err );
      }
    } );
  }

  /** ****************************** New Code ****************************** */

  closeUserFloatingBox(): void {
    this._requestWatcherUserService.closeFloatingBoxUserStudent();
    this._requestWatcherUserService.closeFloatingBoxAdmin();
  }
}
