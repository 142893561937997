<div class="create-form">

  <div class="create-form__header">
    <button class="button button--back" (click)="onCloseSection.emit()">
      <em class="icon-i-back back-icon"></em>
    </button>
    <span class="text text--bold text--lg">{{ planTitle }}</span>
  </div>

  <form action="" [formGroup]="mainForm" (ngSubmit)="createIntcall()">
    <div class="create-form__main">
      <ci-user-search 
        (onSelectUser)="userIntcall = $event"
        [isSubmitting]="submitting"
        [title]="'Responsable'">
      </ci-user-search>

      <div class="create-form__control">
        <span class="create-form__label">Elija la fecha de finalización</span>
        <input class="create-form__input create-form__input--date" 
          type="date" 
          [ngClass]="{ 'error-input': !mainForm.get('endDate')?.valid && submitting }" 
          [formControlName]="'endDate'" 
          (click)="pickDate( $event )"
        >
        <small class="error-message"
          *ngIf="!mainForm.get('endDate')?.valid && submitting">Este campo es obligatorio
        </small>
      </div>
    </div>

    <div class="objective-list">
      <h2 class="text text--bold">Lista de objetivos</h2>

      <div [formArrayName]="'objectives'">
        <div class="objective-list__item" *ngFor="let objective of mainForm.controls['objectives']['controls']; let i = index" 
          [formGroupName]="i">
          <div class="objective-list__item-header">
            <input class="item-header__title" 
              type="text" 
              placeholder="Nombre de objetivo" 
              [formControlName]="'name'"
            >
            <button class="button button--close" (click)="mainForm.get('objectives')?.['removeAt'](i)">
              <em class="icon-i-close close-icon"></em>
            </button>
          </div>
          <small *ngIf="!mainForm.get('objectives')?.['controls'][i].get('name').valid && submitting" 
            class="error-message">
            Este campo es requerido
          </small>

          <h2 class="text" [ngStyle]="{'margin-top.px': 10}">Acciones</h2>
          <textarea class="objective-list__item-actions" rows="4"
            placeholder="Escriba aquí"
            [ngClass]="{ 'error-input': !mainForm.get('objectives')?.['controls'][i].get('actions').valid && submitting }"
            [formControlName]="'actions'">
          </textarea>
          <small *ngIf="!mainForm.get('objectives')?.['controls'][i].get('actions').valid && submitting" 
            class="error-message">
            Este campo es requerido
          </small>
        </div>
      </div>

      <div class="add-objetcive__container">
        <button class="button button--add" type="button" (click)="addItem()">
          AGREGAR OBJETIVO
          <em class="icon-i-plus add-icon"></em>
        </button>
      </div>
    </div>

    <button class="button button--create" [ngClass]="{ 'disabled': mainForm.getRawValue().objectives.length === 0 }">Crear plan de mejora</button>
  </form>
</div>