<div class="recent-requests">
  <div class="recent-requests__header">
    Solicitudes recientes
  </div>
  <div class="recent-requests__list">
    <div class="recent-requests-list">
      <div class="recent-requests-list__item" *ngFor="let recentRequest of recentRequestList">
        <app-recent-request-box
          [recentRequest]="recentRequest"
        ></app-recent-request-box>
      </div>
    </div>
  </div>
</div>