import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs'
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { IntCallsService } from 'src/app/services/intCalls/int-calls.service';

@Component( {
  selector: 'ci-user-search',
  templateUrl: './ci-user-search.component.html',
  styleUrls: [ './ci-user-search.component.css' ]
} )
export class CiUserSearchComponent implements OnInit, AfterViewInit {

  constructor ( private _intCalls:IntCallsService ) { }

  @ViewChild( 'userSearchInput' ) userSearchInput!: ElementRef;

  //Sends the selected user
  @Output('onSelectUser') onSelectUser: EventEmitter<any> = new EventEmitter();

  //Check if the parent form is submitted
  @Input('isSubmitting') isSubmitting: boolean = false;

  //Receives the label title
  @Input('title') title:string = 'Docente';

  //Toggles the div containing the list of users
  protected showUserList: boolean = false;

  //Stores the user to add
  protected userToAdd:any = undefined;

  //Stores the filtered user list from database.
  protected userList: any[] = [];

  //Shows a preview of the selected user
  protected showSelectedUser: boolean = false;

  ngOnInit(): void {
    //
  }

  ngAfterViewInit(): void {

    //Creates an observable from the userSearchInput nativeElement
    fromEvent<Event>( this.userSearchInput.nativeElement, 'keyup' ).pipe(
      map( ( event: Event ) => ( event.target as HTMLInputElement ).value.toLowerCase() ),
      filter( ( value: string ) => value.length > 3 ),
      debounceTime( 300 ),
      switchMap( ( value:string ) => this.getUserList( value ) )
    ).subscribe( {
      next:( resp:any )=>{
        this.userList = resp;
        this.showUserList = this.userList.length > 0;
      },
      error:(err)=> console.log(err)
    })
  }

  /**
    * This function is used to get a list of users from the self-assessment-service.
    * @param value 
    * @returns 
    */
  private getUserList( value: string ) {
    const filter = JSON.stringify( {
      where: { email: { regexp: `/${ value }/` } },
      limit: 4
    } )

    return this._intCalls.getUserList( filter );
  }

  /**
   * Sets some properties such as the user, and emits an event with the current value of userToAdd
   * @param user 
   */
  protected addUser( user:any ){
    this.userToAdd = user;
    this.showSelectedUser = true;
    this.userSearchInput.nativeElement.value = '';
    this.userSearchInput.nativeElement.placeholder = '';
    this.onSelectUser.emit( this.userToAdd );
  }

  /**
   * Resets some properties and emits an event with the current value of userToAdd
   */
  protected clearUser(){
    this.userToAdd = undefined;
    this.showSelectedUser = false;
    this.userSearchInput.nativeElement.placeholder = 'Buscar por correo';

    this.onSelectUser.emit( this.userToAdd );
  }

  /**
   * This function is used to detect when a user clicks outside of an input container and a button with the class "button--add-partner"
   * @param event 
   */
  @HostListener( 'document:click', [ '$event' ] )
  clickOutside( event ): void {
    const target = event.target;
    const inputContainer = target.closest( '.user-select__content' );
    const addPartnerButton = target.classList.contains( 'button--add-user' );

    if ( !inputContainer && !addPartnerButton ) {
      this.showUserList = false;
    } else {
      if ( this.userSearchInput.nativeElement.value.length > 0 ) {
        this.showUserList = true;
      }
    }
  }

}
