<div class="box" [ngStyle]="{'background-color': color}">
  <!-- <div class="box__img">
    <div class="user-img" [ngStyle]="{'background-image': 'url('+img+')'}"></div>
  </div> -->
  <div class="box__text">
    <div class="box__title">{{title}}</div>
    <div class="box__description">
      {{descript}} <br *ngIf="isThereNewChats"><br *ngIf="isThereNewChats"><span *ngIf="isThereNewChats" class="newMessages">Nuevo mensaje</span>
    </div>
    <br>
    <div class="box__description" *ngIf="showUserName">
      <span class="box__bold">Solicitante: </span>{{ userName }}
    </div>
    <div class="box__description" *ngIf="showUserName">
      <span class="box__bold">Dueño: </span> {{ userOwner }}
    </div>
    <div class="box__description" *ngIf="showSchoolData">
      {{ SchoolData }}
    </div>
    <div class="box__description">
      {{ offer }}
    </div>
  </div>
</div>