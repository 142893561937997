<div class="requests-list">
  <div class="requests-list__filters">
    <div class="filter-tab" [ngClass]="{'filter-tab--selected': null === tabSelected}" (click)="changeFilterTabSelected(null)">
      Todas las solicitudes
    </div>
    <div class="filter-tab" *ngFor="let filterTab of stateTabs" [ngClass]="{'filter-tab--selected': filterTab.id === tabSelected}" (click)="changeFilterTabSelected(filterTab.id)">
      {{filterTab.nameState}}
    </div>
  </div>
  <div class="requests-list__table">
    <table class="requests-table"><caption></caption>
      <thead>
        <tr>
          <th scope="col" class="requests-table__title">SOLICITUDES</th>
          <th scope="col" class="requests-table__title hideOnSmallView">TICKET</th>
          <th scope="col" class="requests-table__title hideOnSmallView">FECHA</th>
          <!-- <th scope="col" class="requests-table__title">RECIBO DE PAGO</th> -->
          <th scope="col" class="requests-table__title hideOnSmallView">ESTADO</th>
        </tr>
      </thead>
      <tbody class="requests-table__body">
        <tr *ngFor="let request of requestListFiltered" (click)="returnRequestSelected(request)" [ngClass]="{'requests-table__tr-selected': request.id == idRequestSelected}">
          <td class="requests-table__box-container">
            <request-request-list-box 
              [title]="request.requestTypeRequest?.nameTypeRequest" 
              [descript]="request.requestSubTypeRequest?.nameSubTypeRequest" 
              [color]="request.requestStateReq?.color" 
              [img]="request.requestUserOwner?.Photo"
              [requestHistories]="request.requestHistories!"
              [userIDlogued]="userIDlogued"
              [offer]="request.requestCourseOffer!.NameCourseOfer"
              [userName]="request.requestUserRequester!.LastName1+' '+request.requestUserRequester!.Name1"
            ></request-request-list-box>
            <div class="aditionalData showOnSmallView">
              <div class="aditionalDataSection">TICKET: {{request.id}}</div>
              <div class="aditionalDataSection">FECHA: {{request.registerDate | date: 'dd/MMM/yyyy'}}</div>
              <div class="aditionalDataSection aditionalDataSectionState">
                ESTADO:
                <request-request-list-tag
                  [stageName]="request.requestStateReq?.nameState"
                  [color]="request.requestStateReq?.color"
                  [file]="request.documentLink"
                  [dispatchOrdersList]="request.dispatchOrders"
                >
                </request-request-list-tag>
              </div>
            </div>
          </td>
          <td class="requests-table__text hideOnSmallView">{{request.id}}</td>
          <td class="requests-table__text hideOnSmallView">{{request.registerDate | date: 'dd/MMM/yyyy'}}</td>
          <!-- <td class="requests-table__text">
            {{request.isPaid ? 'Si' : 'No' }}
            <br>
            link
          </td> -->
          <td class="requests-table__tag-container hideOnSmallView">
            <request-request-list-tag
              [stageName]="request.requestStateReq?.nameState"
              [color]="request.requestStateReq?.color"
              [file]="request.documentLink"
              [dispatchOrdersList]="request.dispatchOrders"
            >
            </request-request-list-tag>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>