import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, findIndex, forkJoin, take } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { StateReq } from 'src/app/models/dbModels/stateReq';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { User } from 'src/app/models/dbModels/user';
import { UserCourse } from 'src/app/models/dbModels/userCourse';
import { ShowRequestFloatingBox } from 'src/app/models/requestWatcher/showRequestFloatingBox';
import { AuthService } from 'src/app/services/auth.service';
import { DispatchOrderService } from 'src/app/services/dispatch-order.service';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SchoolService } from 'src/app/services/school.service';
import { StateReqService } from 'src/app/services/stateReq.service';
import { SubTypesRequestService } from 'src/app/services/subTypeRequest.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { UserAppService } from 'src/app/services/userApp.service';
import { UserCourseService } from 'src/app/services/userCourse.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'request-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: [ './request-view.component.css' ]
} )
export class RequestViewComponent implements OnInit, OnDestroy {

  public showRequestData: boolean = false;
  public requestSelected: Request;
  public ALL_MY_REQUEST: Request[] = [];
  public ALL_TYPE_REQUEST: TypeRequest[] = [];
  public ALL_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_USER_COURSES: UserCourse[] = [];
  public ALL_SCHOOLS: School[] = [];
  public ALL_STATES_REQUEST: StateReq[] = [];
  public REQUESTER_USER: User;
  public ALL_MY_CHILDS: User[] = [];
  public childSelected: User;
  private indexRequestSelected: number;
  private userIdLogued: number;
  private userIdChild: number;
  private getDataFromChild: boolean = false;
  public showParentsContainer: boolean = false;
  public showRequestPanels: boolean = false;
  public PRINCIPAL_APP_NAME: string = environment.principalAppName.toLowerCase();

  public isAcudiente: boolean = false;
  public acudienteData: User;

  ALL_MY_DISPATCH_ORDERS: DispatchOrderI[] = [];

  constructor (
    private _authService: AuthService,
    private _requestService: RequestService,
    private _userCourseService: UserCourseService,
    private _schoolService: SchoolService,
    private _stateReqService: StateReqService,
    private _subTypesRequestService: SubTypesRequestService,
    private _userAppService: UserAppService,
    private _sweetalertService: SweetalertService,
    private _router: Router,
    private _dispatchOrderService: DispatchOrderService,
    private _requestWatcherUserService: RequestWatcherUserService,
  ) {
    this.validateUserLoggued();
  }

  ngOnInit(): void {
    if (this.PRINCIPAL_APP_NAME == 'cedoc'.toLowerCase()) this.getAllInitialData();
    this.subscriptionShowFloatingBox = this._requestWatcherUserService.userStudentShowFloatingBox.subscribe((data: ShowRequestFloatingBox) => {
      console.log('ngOnInit userStudentShowFloatingBox: ',data);
      this.showFloatingBox = data.showBox;
      this.showRequestData = data.infoView;
    });
    this.subscriptionUpdateDataLoaded = this._requestWatcherUserService.updateDataLoadedToShowObservable.subscribe((updateData: boolean) => {
      console.log('ngOnInit subscriptionUpdateDataLoaded: ',updateData);
      if (updateData) this.updateRequestList();
    });
  }

  validateUserLoggued(): void {
    const UserID = this._authService.getCurrentUserID();
    if (UserID == null) this._router.navigate(['/landing']);
    else {
      this.userIdLogued = parseInt(UserID);
      this.validatePlatformAndRoles();
    }
  }

  validatePlatformAndRoles(): void {
    if (this.PRINCIPAL_APP_NAME == 'cedoc'.toLowerCase()) {
      this.showRequestPanels = true;
      return;
    }
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      USER_LOGGUED_INFO: this._requestService.getCurrentUser().pipe( take( 1 ) ),
      CHILDS_RELATION_INFO: this._requestService.getMyParentsToStudentsRelations(this.userIdLogued).pipe( take( 1 ) ),
      ALL_ROLES_USER_LOGUED: this._userAppService.getMyRoles().pipe( take( 1 ) ),
    }).subscribe( {
      next: ( data ) => {
        console.log( 'validatePlatformAndRoles', data );
        let { USER_LOGGUED_INFO, CHILDS_RELATION_INFO, ALL_ROLES_USER_LOGUED } = data;
        this.acudienteData = USER_LOGGUED_INFO;
        console.log( 'ALL_ROLES_USER_LOGUED', ALL_ROLES_USER_LOGUED );
        let indexAcudiente = ALL_ROLES_USER_LOGUED.findIndex( roleMapping => ( roleMapping.role?.name?.toLowerCase() == 'Acudiente'.toLowerCase() || roleMapping.role?.id == 29 ) );
        if ( indexAcudiente >= 0 ) {
          this.isAcudiente = true;
          this.showParentsContainer = true;
          console.log('SIIIII ES ACUDIENTE', indexAcudiente);
          this._sweetalertService.swalClose();
        } else {
          this.showRequestPanels = true;
          console.log('NOOOOOOOOOOOO ES ACUDIENTE', indexAcudiente);
          // this.getAllInitialData();
          this._sweetalertService.swalError('Error','El ingreso a este modulo solo esta permitido para el acudiente.', () => {
            this._router.navigate(['/user/my-account']);
          })
        }
        CHILDS_RELATION_INFO.forEach(parentToStudent => {
          if (parentToStudent.studentUser) this.ALL_MY_CHILDS.push(parentToStudent.studentUser);
        });
        console.log( '********** ********** ********** ********** ********** ');
        console.log( '********** ********** ********** ********** ********** ');
        console.log( 'ALL_MY_CHILDS', this.ALL_MY_CHILDS );
        console.log( '********** ********** ********** ********** ********** ');
        console.log( '********** ********** ********** ********** ********** ');
        // this.ALL_TYPE_REQUEST = ALL_TYPE_REQUEST;
        // this.ALL_SUBTYPE_REQUEST = ALL_SUBTYPE_REQUEST;
        // this.ALL_SCHOOLS = ALL_SCHOOLS;
        // this.ALL_MY_REQUEST = ALL_MY_REQUEST;
        // this.ALL_USER_COURSES = ALL_USER_COURSES;
        // this.ALL_STATES_REQUEST = ALL_STATES_REQUEST;
        // this.REQUESTER_USER = REQUESTER_USER;
      },
      error: ( err ) => {
        console.log( "ERROR validatePlatformAndRoles", err )
      },
      complete: () => {
        console.log( 'complete validatePlatformAndRoles' );
      }
    } )
  }

  onChangeChildSelected(): void {
    this.showRequestPanels = false;
    console.log('selected',this.childSelected);
    this.REQUESTER_USER = this.childSelected;
    this.userIdChild = this.childSelected.id!;
    this.getDataFromChild = true;
    this.getAllInitialData();
  }

  getAllInitialData() {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const filterUserCourse = this.getFilterToUserCourse();
    const filterRequest = this.getFilterToRequest();
    forkJoin({
      ALL_TYPE_REQUEST: this._requestService.getTypeRequest().pipe( take( 1 ) ),
      ALL_SUBTYPE_REQUEST: this._subTypesRequestService.getSubTypeRequestWithTypeRequestPlaceholder().pipe( take( 1 ) ),
      ALL_SCHOOLS: this._schoolService.getSchools().pipe( take( 1 ) ),
      ALL_MY_REQUEST: this._requestService.getRequest( filterRequest ).pipe( take( 1 ) ),
      ALL_USER_COURSES: this.getDataFromChild ? this._requestService.getMyChildsUserCourses( this.getUserIdToGetData() ).pipe( take( 1 ) ) : this._userCourseService.getCoursesOffer( filterUserCourse ).pipe( take( 1 ) ),
      ALL_STATES_REQUEST: this._stateReqService.getStatesReq().pipe( take( 1 ) ),
      // REQUESTER_USER: this._requestService.getCurrentUser().pipe( take( 1 ) ),
      REQUESTER_USER: this._requestService.getUserById(this.getUserIdToGetData()).pipe( take( 1 ) ),
      ALL_USER_LOGUED_ROLES: this._userAppService.getMyRoles().pipe( take( 1 ) ),
    }).subscribe( {
      next: ( data ) => {
        console.log( 'getAllInitialData', data );
        let { ALL_TYPE_REQUEST, ALL_SUBTYPE_REQUEST, ALL_SCHOOLS, ALL_MY_REQUEST, ALL_USER_COURSES, ALL_STATES_REQUEST, REQUESTER_USER } = data;
        this.ALL_TYPE_REQUEST = ALL_TYPE_REQUEST;
        this.ALL_SUBTYPE_REQUEST = ALL_SUBTYPE_REQUEST;
        this.ALL_SCHOOLS = ALL_SCHOOLS;
        this.ALL_MY_REQUEST = ALL_MY_REQUEST;
        this.ALL_USER_COURSES = this.getDataFromChild ? ALL_USER_COURSES['result'] : ALL_USER_COURSES;
        this.ALL_STATES_REQUEST = ALL_STATES_REQUEST;
        this.REQUESTER_USER = REQUESTER_USER;
        this.showRequestPanels = true;
        this.getAllDistpatchOrderByUserID();
      },
      error: ( err ) => {
        console.log( "ERROR getAllInitialData", err )
      },
      complete: () => {
        console.log( 'complete getAllInitialData RV' );
        this._sweetalertService.swalClose();
      }
    } )
  }

  getAllDistpatchOrderByUserID(): void {
    if (!this.REQUESTER_USER?.UserDocuments?.[0].Document) {
      this._sweetalertService.swalError('Error','No se pudo obtener el documento del usuario, por favor, contacte a soporte.', () => {
        // this._router.navigate(['/request']);
      })
      return
    }
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let filterDistpatch = {
      where: {
        and: [
          { requestID: null },
          { document: this.REQUESTER_USER.UserDocuments[0].Document },
          { isVisible: true },
          { or: [
            { realStatus: 'PENDING' },
            { realStatus: 'PROCESS' }
          ]}
        ]
      },
      order: "issueDate DESC"
    };
    forkJoin({
      ALL_MY_DISPATCH_ORDERS: this._dispatchOrderService.getDispatchOrder(JSON.stringify(filterDistpatch)).pipe( take( 1 ) ),
    }).subscribe({
      next: ( data ) => {
        let { ALL_MY_DISPATCH_ORDERS } = data;
        this.ALL_MY_DISPATCH_ORDERS = ALL_MY_DISPATCH_ORDERS;
        this._sweetalertService.swalClose();
      },
      error: ( err ) => {
        this._sweetalertService.swalError('Error','No se pudo obtener la información del usuario, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {
          this._router.navigate(['/request']);
        })
        console.log( "ERROR getAllDistpatchOrderByUserID", err );
      },
      complete: () => {
        console.log( 'complete getAllDistpatchOrderByUserID' );
      }
    })
  }

  updateRequestList() {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const filterRequest = this.getFilterToRequest();
    forkJoin({
      ALL_MY_REQUEST: this._requestService.getRequest( filterRequest ).pipe( take( 1 ) ),
    }).subscribe( {
      next: ( data ) => {
        let { ALL_MY_REQUEST } = data;
        this.ALL_MY_REQUEST = ALL_MY_REQUEST;
        if ( this.indexRequestSelected >= 0 ) this.requestSelected = this.ALL_MY_REQUEST[ this.indexRequestSelected ];
      },
      error: ( err ) => {
        console.log( "ERROR updateRequestList", err )
      },
      complete: () => {
        console.log( 'complete updateRequestList RV' );
        this._sweetalertService.swalClose();
      }
    } )
  }

  getRequestSelected( requesSelected: Request ): void {
    this.showRequestData = false;
    this.requestSelected = requesSelected;
    this.showRequestData = true;
    let index = this.ALL_MY_REQUEST.findIndex( element => element.id == requesSelected.id );
    if ( index >= 0 ) this.indexRequestSelected = index;
  }

  closeChatView( response ): void {
    this.showRequestData = response;
  }

  showLastRequestCreated(): void {
    this.showRequestData = false;
    this.requestSelected = this.ALL_MY_REQUEST[ 0 ];
    this.showRequestData = true;
  }

  getFilterToRequest(): string {
    let filter = JSON.stringify( {
      order: 'updateDate DESC',
      where: {
        userRequesterID: this.getUserIdToGetData()
      },
      include: [
        "requestSubTypeRequest",
        "requestTypeRequest",
        "requestUserOwner",
        "requestStageReq",
        "requestStateReq",
        "requestCourseOffer",
        { "requestUserRequester": [ "UserDocuments", "MilitarInfos", {roleMappings:"school"} ] },
        { "requestHistories": "requestHistoryUser" },
        {
          relation: "filesReq",
          scope: {
            order: 'updateDate DESC',
            include: [ "filesUserID" ]
          }
        },
        {
          relation: "dispatchOrders",
          scope: {
            order: 'issueDate DESC'
          }
        }
      ]
    } );
    return filter;
  }

  getFilterToUserCourse(): string {
    let filter = JSON.stringify( {
      where: {
        UserID: this.getUserIdToGetData()
      },
      include: [ {'coursesOfer':"school"} ]
    } );
    return filter;
  }

  getUserIdToGetData(): number {
    console.log('getUserIdToGetData: ',this.getDataFromChild ? this.userIdChild : this.userIdLogued);
    return this.getDataFromChild ? this.userIdChild : this.userIdLogued;
  }

  goToAnotherUrl(url: string): void {
    this._router.navigate([url]);
  }

  /** ****************************** New Code ****************************** */

  public showFloatingBox: boolean = false;
  private subscriptionShowFloatingBox: Subscription;
  private subscriptionUpdateDataLoaded: Subscription;
  
  ngOnDestroy() {
    this.subscriptionShowFloatingBox.unsubscribe();
    this.subscriptionUpdateDataLoaded.unsubscribe();
  }

  showCreateRequestInFloatingBox(): void {
    console.log('showCreateRequestInFloatingBox');
    this._requestWatcherUserService.manageFloatingBoxUserStudent({ showBox: true, createView: true, infoView: false });
  }

  showRequestInfoInFloatingBox(): void {
    console.log('showRequestInfoInFloatingBox');
    this._requestWatcherUserService.manageFloatingBoxUserStudent({ showBox: true, createView: false, infoView: true });
  }

}
