import { Injectable, Type } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { School } from '../models/dbModels/school';
import { RoleMapping } from '../models/dbModels/roleMapping';

@Injectable({
  providedIn: 'root'
})
export class DirectivasService {

  constructor(
    private _httpService: HttpService
  ) { }

  getMyRoleMappings(filter?: string): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings`
    if (filter)
      url = `/Userapps/me/roleMappings?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getUsersByEmail(userEmail:string): Observable<any> {
    const filter: string = JSON.stringify({
      where: {
        "CedocEmail": { "like" : `${userEmail}`}
      },
      include:[
        'UserDocuments',
        'ContactInfos',
        {'parentsToStudents':[
          {'parentUser':["UserDocuments","ContactInfos"]},
          'attendantUser'
        ]}
      ]
    })
    return this._httpService.httpGet(`/Userapps?filter=${ filter }`);
  }

  getSchoolInfo(filter?: string): Observable<School[]> {
    let url: string = `/Schools`
    if (filter)
      url = `/Schools?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getSchoolInfoByID(SchoolID: number, filter?: string): Observable<School> {
    let url: string = `/Schools/${SchoolID}`
    if (filter)
      url = `/Schools/${SchoolID}?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  

}


