import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CourseOffer } from 'src/app/models/dbModels/courseOffer';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { StateReq } from 'src/app/models/dbModels/stateReq';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { AuthService } from 'src/app/services/auth.service';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-requests-list-admin',
  templateUrl: './requests-list-admin.component.html',
  styleUrls: ['./requests-list-admin.component.css']
})
export class RequestsListAdminComponent implements OnInit, OnDestroy {

  @Input() allStateReq: StateReq[];
  @Input() isThereNewChats: boolean;
  @Input() set allRequestData(requestList: Request[]) {
    console.log(requestList);

    let local_typeRequest: any[] = [];
    local_typeRequest = requestList
      .map(e => e.requestTypeRequest)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
      if (local_typeRequest && local_typeRequest.length > 0) this.filtersManagement.myRequest.typeRequest = local_typeRequest;

    let local_schools: any[] = [];
    local_schools = requestList
      .map(e => e.requestSchool)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);

    if (local_schools && local_schools.length > 0) this.filtersManagement.myRequest.schoolsList = local_schools!;

    this.allRequestList = [...requestList];

    this.validateFiltersToShow();
    this._getParams();
  }
  @Output() showRequestOption = new EventEmitter<{ index: number, request: Request }>();

  /** Variables con datos fijos */
  public allRequestList: Request[] = [];
  // public myRequestSchoolList: School[] = [];
  // public typeRequest: TypeRequest[] = [];
  // public subtypeRequest: any[] = [];
  // public offers: any[] = [];
  
  /** Otras variables */
  public idRequestSelected: number | undefined;
  public tabSelected?: number | null;
  public filterBtnSelected: number = 0;
  public requestListFiltered: Request[];

  public selectedCourseOffer: number = 0;
  public selectSubtypeRequest: number = 0;
  public paidFilter: boolean | null = null;

  public userDocumentToFilter: string = '';
  public userIdToFilter: string = '';
  public userIDlogued: number;

  public showFiltersModal: boolean = false;
  public PRINCIPAL_APP_NAME: string = environment.principalAppName.toLowerCase();

  public filtersToShow: FiltersSqueleton = {
    schoolsList: [],
    courseOfferList: [],
    typeRequest: [],
    subTypeRequest: []
  };
  public filtersManagement: ManagmentFiltersSqueleton = {
    myRequest: {
      schoolsList: [],
      courseOfferList: [],
      typeRequest: [],
      subTypeRequest: []
    },
    schoolRequest: {
      schoolsList: [],
      courseOfferList: [],
      typeRequest: [],
      subTypeRequest: []
    }
  };

  constructor(
    private _authService: AuthService,
    private _activeRoute: ActivatedRoute,
    private _requestService: RequestService,
    private _requestWatcherUserService: RequestWatcherUserService,
    private _sweetalertService: SweetalertService,
  ) {
    this.tabSelected = 0;
    this.userIDlogued = Number(this._authService.getCurrentUserID());
  }

  ngOnInit(): void {
    this.subscriptionGetAllSchools = this._requestWatcherUserService.ALL_SCHOOLS_OBSERVABLE.subscribe((data: School[]) => {
      this.filtersManagement.schoolRequest.schoolsList = data;
    });
    this.subscriptionGetAllTypeRequest = this._requestWatcherUserService.ALL_TYPE_REQUEST_OBSERVABLE.subscribe((data: TypeRequest[]) => {
      this.filtersManagement.schoolRequest.typeRequest = data;
    });
    this.subscriptionGetAllSubTypeRequest = this._requestWatcherUserService.ALL_SUB_TYPE_REQUEST_OBSERVABLE.subscribe((data: SubTypeRequest[]) => {
      this.filtersManagement.schoolRequest.subTypeRequest = data;
    });
  }

  //Get url document param
  private _getParams(){
    this._activeRoute.params.subscribe(params => {
      const userDoc = params["userDoc"];
      if (!userDoc){
        this.requestListFiltered = [...this.allRequestList];
        return;
      }

      this.userDocumentToFilter = params["userDoc"];
      this.onSearch(this.userDocumentToFilter);
    });
  }

  //On select type request filter
  onSelectType(event: Event) {
    const value: any = (event.target as HTMLInputElement).value;

    this.selectSubtypeRequest = 0;
    if (Number(value) === 0) this.requestListFiltered = [...this.allRequestList];

    let local_subtypeRequest: any[] = [];
    local_subtypeRequest = this.allRequestList.filter(e => e.typeRequestID === Number(value))
      .map(e => e.requestSubTypeRequest)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
    this.filtersToShow.subTypeRequest = local_subtypeRequest;
  }

  //On select request school filter
  onSelectSchool(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.selectedCourseOffer = 0;
    if (Number(value) === 0) this.requestListFiltered = [...this.allRequestList];

    let local_offers: any[] = [];
    local_offers = this.allRequestList.filter(e => e.schoolID === Number(value))
      .map(e => e.requestCourseOffer)
      .filter((value, index, self) => self.findIndex(t => t!.id === value!.id) === index);
    this.filtersToShow.courseOfferList = local_offers;
  }

  //Filter by process buttons
  filterByProcess(index: number) {
    if (index == this.filterBtnSelected) this.filterBtnSelected = 0;
    else this.filterBtnSelected = index;
    this.applyFilters();
  }

  //Apply all filters
  applyFilters() {
    this.requestListFiltered = this.allRequestList.filter(e => (
      (this.selectSubtypeRequest === 0 || e.subTypeRequestID === this.selectSubtypeRequest) &&
      (this.selectedCourseOffer === 0 || e.courseOfferID === this.selectedCourseOffer) &&
      (
        (this.filterBtnSelected === 1 && e.userOwnerID !== Number(this._authService.getCurrentUserID()) &&
          e.userCurrentID === Number(this._authService.getCurrentUserID())) ||
        (this.filterBtnSelected === 2 && e.userOwnerID === Number(this._authService.getCurrentUserID()) &&
          e.userCurrentID !== Number(this._authService.getCurrentUserID())) ||
        (this.filterBtnSelected === 0)
      ) &&
      ( this.tabSelected ===  0 || this.tabSelected === e.stateReqID ) &&
      (
        this.userIdToFilter === '' || 
        this.userIdToFilter === e.requestUserRequester?.UserDocuments?.[0]?.Document ||
        this.userIdToFilter === e.requestUserRequester?.parentsToStudents?.[0]?.parentUser?.UserDocuments?.[0]?.Document ||
        this.userIdToFilter === e.requestUserRequester?.parentsToStudents?.[0]?.attendantUser?.UserDocuments?.[0]?.Document
      ) &&
      ( this.paidFilter === null || e.isPaid === this.paidFilter ) &&
      ( 
        this.initialDate === '' ||
        new Date(e.registerDate).getTime() >= new Date(this.initialDate+'T00:00:00.000').getTime()
      ) &&
      ( 
        this.finalDate === '' ||
        new Date(e.registerDate).getTime() <= new Date(this.finalDate+'T23:59:59.999').getTime()
      )
    ));
    this.showFiltersModal = false
    this._sweetalertService.swalClose();
    console.log('requestListFiltered',this.requestListFiltered)
  }

  //Clean all filters
  cleanAllFilters() {
    this.filtersToShow.courseOfferList = [];
    this.filtersToShow.subTypeRequest = [];
    this.userIdToFilter = '';
    this.selectSubtypeRequest = 0;
    this.selectedCourseOffer = 0;
    this.filterBtnSelected = 0;
    this.tabSelected = 0;
    this.showFiltersModal = false;
    this.paidFilter = null;
    this.applyFilters();
  }


  //Tabs filters
  changeFilterTabSelected(tabIndex: number | undefined) {
    this.tabSelected = tabIndex;
    if (tabIndex === 0)
      this.requestListFiltered = [...this.allRequestList];

    this.applyFilters();
  }

  //On input type in document input
  onSearch(query: string = '') {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this.userIdToFilter = '';
    if(query.length > 0){
      this.userIdToFilter = query;
      this.applyFilters();
      return;
    }
    this.requestListFiltered = [...this.allRequestList];
    this.userIdToFilter = '';
    this.applyFilters();
  }

  //Search for user document in db
  findUser(doc: string): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    this._requestService.getAllUserRequestByUserDocument(+doc).subscribe({
      next: (data: any) => {
        console.log("response findUser", data)
        this._sweetalertService.swalClose();
        if (data.length == 0) this._sweetalertService.swalError('Error','No hay solicitudes para el documento digitado.', () => {});
        this.applyFilters();
      },
      error: (err) => {
        console.log("Error in findUser", err);
        this._sweetalertService.swalError('Error','Algo no salió bien, intentalo nuevamnete.', () => {});
      }
    })
  }

  getDaysBetweenTwoDates(date: Date): number {
    let today = new Date().toISOString().slice(0, 10);
    let startDate = date.toString().split('T')[0];
    let endDate = today;
    let diffInMs = (new Date(endDate).getTime()) - (new Date(startDate).getTime())
    let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays;
  }

  returnRequestSelected(request: Request) {
    this.idRequestSelected = request.id;
    let data = {
      index: 2,
      request: request
    }
    this.showRequestOption.emit(data);
    this.showRequestInfoInFloatingBox();
  }

  /** ****************************** New Code ****************************** */

  columnToOrder = 0;
  numberOrderView = 0;
  private subscriptionGetAllSchools: Subscription;
  private subscriptionGetAllTypeRequest: Subscription;
  private subscriptionGetAllSubTypeRequest: Subscription;
  public initialDate: string = '';
  public finalDate: string = '';

  ngOnDestroy() {
    this.subscriptionGetAllSchools.unsubscribe();
    this.subscriptionGetAllTypeRequest.unsubscribe();
    this.subscriptionGetAllSubTypeRequest.unsubscribe();
  }

  showRequestInfoInFloatingBox(): void {
    this._requestWatcherUserService.manageFloatingBoxAdmin({ showBox: true, createView: false, infoView: true });
  }

  showCreateRequestInFloatingBox(): void {
    this._requestWatcherUserService.manageFloatingBoxAdmin({ showBox: true, createView: true, infoView: false });
  }

  requestToShow = 0;
  changeRequestLoadedToShow(optionSelected: number): void {
    if (this.requestToShow == optionSelected) return;
    this.requestToShow = optionSelected;
    this._requestWatcherUserService.updateLoadRequestToShowObservable(optionSelected);
    this.validateFiltersToShow();
  }

  changeViewAndOrder(option: number): void {
    if (this.columnToOrder == option) this.numberOrderView = ((this.numberOrderView+1)%2);
    else {
      this.columnToOrder = option;
      this.numberOrderView = 1;
    }

    this.requestListFiltered.sort((a, b) => {
      if (this.numberOrderView == 1) return a.id! - b.id!;
      else return b.id! - a.id!;
    });
  }

  validateFiltersToShow(): void {
    if ([0, 2].includes(this.requestToShow)) this.filtersToShow = this.filtersManagement.myRequest;
    if (this.requestToShow == 1) this.filtersToShow = this.filtersManagement.schoolRequest;
  }

  navigateBtSection(indexView: number): void {
    this._requestWatcherUserService.updateSectionAdminToViewObservable(indexView);
  }

}

class FiltersSqueleton {
  schoolsList: School[];
  courseOfferList: CourseOffer[];
  typeRequest: TypeRequest[];
  subTypeRequest: SubTypeRequest[];
}
class ManagmentFiltersSqueleton {
  myRequest: FiltersSqueleton;
  schoolRequest: FiltersSqueleton;
}
