<div class="requests-list">
  <div class="requestDataContainer_btns">
    <div class="btnSection_left">
      <button class="btnNavigateSections btnNavigateSections_selected">SOLICITUDES</button>
      <button class="btnNavigateSections" (click)="navigateBtSection(4)">SOLICITUDES MASIVAS</button>
    </div>
    <div class="btnSection_right">
      <button class="btn-showModal" (click)="showFiltersModal = true">Filtros Avanzados</button>
      <button class="normal-button">
        <em class="normal-button__icon icon-i-tutorial"></em>
        <input type="text" placeholder="Buscar por documento" class="normal-button__input" #searchReq
          (keydown.enter)="onSearch(searchReq.value)">
      </button>
      <button class="btn-create" (click)="showCreateRequestInFloatingBox()">Crear Solicitud</button>
    </div>
  </div>
  <div class="requestSections">
    <div class="requests-list__filters">
      <button class="filter-tab" [ngClass]="{'filter-tab--selected': 0 === tabSelected}" (click)="changeFilterTabSelected(0)">
        Todas las solicitudes
      </button>
      <button class="filter-tab" *ngFor="let filterTab of allStateReq" [ngClass]="{'filter-tab--selected': filterTab.id === tabSelected}" (click)="changeFilterTabSelected(filterTab.id)">
        {{filterTab.nameState}}
      </button>
    </div>
    <div class="requests-list__table">
      <div class="requests-list__table-requests">
        <table class="requests-table">
          <caption></caption>
          <thead>
            <tr>
              <th scope="col" class="requests-table__title">Solicitudes</th>
              <th scope="col" class="requests-table__title hideOnSmallView">
                <button class="containerNameIcon" (click)="changeViewAndOrder(1)">
                  TICKET 
                  <div class="iconContainerToOrder" [ngClass]="{'iconContainerToOrderDesc': columnToOrder == 1 && numberOrderView == 0, 'iconContainerToOrderAsc': columnToOrder == 1 && numberOrderView == 1}">
                    <em class="icon-i-next close-icon" *ngIf="columnToOrder == 1"></em>
                  </div>
                </button>
              </th>
              <th scope="col" class="requests-table__title hideOnSmallView">Tiempo de <br> solicitud</th>
              <th scope="col" class="requests-table__title hideOnSmallView">
                <button class="containerNameIcon" (click)="changeViewAndOrder(2)">
                  <span>FECHA DE INICIO <br> DE SOLICITUD</span>
                  <div class="iconContainerToOrder" [ngClass]="{'iconContainerToOrderDesc': columnToOrder == 2 && numberOrderView == 0, 'iconContainerToOrderAsc': columnToOrder == 2 && numberOrderView == 1}">
                    <em class="icon-i-next close-icon" *ngIf="columnToOrder == 2"></em>
                  </div>
                </button>
              </th>
              <th scope="col" class="requests-table__title hideOnSmallView">Estado</th>
            </tr>
          </thead>
          <tbody class="requests-table__body">
            <tr *ngFor="let request of requestListFiltered" (click)="returnRequestSelected(request)" onKeyDown="false"
              [ngClass]="{'requests-table__tr-selected': request.id == idRequestSelected}">
              <td class="requests-table__box-container">
                <request-request-list-box [title]="request.requestUserRequester?.CedocEmail"
                  [descript]="request.requestTypeRequest?.nameTypeRequest +', '+ request.requestSubTypeRequest?.nameSubTypeRequest"
                  [color]="request.requestStateReq?.color"
                  [img]="'https://finde.latercera.com/wp-content/uploads/2020/06/Dark-temporada-3-ok-700x450.jpg'"
                  [requestHistories]="request.requestHistories!" [userIDlogued]="userIDlogued"
                  [offer]="request.requestCourseOffer!.NameCourseOfer"
                  [userName]="request.requestUserRequester!.LastName1+' '+request.requestUserRequester!.Name1"
                  [userOwner]="request.requestUserOwner!.LastName1+' '+request.requestUserOwner!.Name1"
                  [showUserName]="true"
                  [SchoolData]="request.requestSchool!.NameTSchool!"
                  [showSchoolData]="true"
                ></request-request-list-box>
                <div class="aditionalData showOnSmallView">
                  <div class="aditionalDataSection">Ticket: {{request.id}}</div>
                  <div class="aditionalDataSection">
                    Tiempo de solicitud: 
                    <div *ngIf="[3,4].includes(request.requestStateReq!.id!); else showDays">
                      {{ request.requestStateReq?.nameState }}
                    </div>
                    <ng-template #showDays>
                      {{ getDaysBetweenTwoDates(request.registerDate) }} DÍAS DE RADICADO
                      <ng-container *ngIf="request.requestSubTypeRequest?.statusColors">
                        <ramc-semaforizacion-in-days
                          [request]="request"
                        ></ramc-semaforizacion-in-days>
                      </ng-container>
                    </ng-template>
                  </div>
                  <div class="aditionalDataSection">Fecha: {{request.registerDate | date: 'dd/MMM/yyyy'}}</div>
                  <div class="aditionalDataSection aditionalDataSectionState">
                    ESTADO:
                    <request-request-list-tag
                      [stageName]="request.requestStateReq?.nameState"
                      [color]="request.requestStateReq?.color"
                      [file]="request.documentLink"
                      [date]="request.lastActionUpdateDate"
                      [dispatchOrdersList]="request.dispatchOrders"
                    ></request-request-list-tag>
                  </div>
                </div>
              </td>
              <td class="requests-table__text hideOnSmallView">{{ request.id }}</td>
              <td class="requests-table__text hideOnSmallView">
                <div *ngIf="[3,4].includes(request.requestStateReq!.id!); else showDays">
                  {{ request.requestStateReq?.nameState }}
                </div>
                <ng-template #showDays>
                  {{ getDaysBetweenTwoDates(request.registerDate) }} DÍAS DE RADICADO
                  <ng-container *ngIf="request.requestSubTypeRequest?.statusColors">
                    <ramc-semaforizacion-in-days
                      [request]="request"
                    ></ramc-semaforizacion-in-days>
                  </ng-container>
                </ng-template>
              </td>
              <td class="requests-table__text hideOnSmallView">{{request.registerDate | date: 'dd/MMM/yyyy'}}</td>
              <td class="requests-table__tag-container hideOnSmallView">
                <request-request-list-tag
                  [stageName]="request.requestStateReq?.nameState"
                  [color]="request.requestStateReq?.color"
                  [file]="request.documentLink"
                  [date]="request.lastActionUpdateDate"
                  [dispatchOrdersList]="request.dispatchOrders"
                ></request-request-list-tag>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modalContainer" *ngIf="showFiltersModal">
  <div class="modalFilters">
    <div class="modalFilters__upper">
      <div class="modalFilters__upperText">
        <h1>Filtros Avanzados</h1>
        <span>Seleccione como quiere filtrar el listado</span>
      </div>
      <div class="modalFilters__upperIcon">
        <button (click)="showFiltersModal = false">
          <em class="icon-i-close close-icon"></em>
        </button>
      </div>
    </div>
    <div class="list-filters">

      <button class="normal-button" (click)="changeRequestLoadedToShow(0)" [ngClass]="{'normal-button__selected': 0 == requestToShow}">
        Ver mis solicitudes
      </button>
      <button class="normal-button" (click)="changeRequestLoadedToShow(1)" [ngClass]="{'normal-button__selected': 1 == requestToShow}">
        Ver solicitudes {{ PRINCIPAL_APP_NAME == 'celic' ? 'del liceo' : 'de la escuela' }}
      </button>
      <ng-container *ngIf="PRINCIPAL_APP_NAME == 'celic'">
        <button class="normal-button" (click)="changeRequestLoadedToShow(2)" [ngClass]="{'normal-button__selected': 2 == requestToShow}">
          Ver solicitudes por atender
        </button>
      </ng-container>

    </div>
    <hr>
    <div class="list-filters">
      <div class="filter-input">
        <label for="type">Desde</label>
        <div class="select-container">
          <input type="date" name="initData" id="initData" [(ngModel)]="initialDate">
        </div>
      </div>
      <div class="filter-input">
        <label for="type">Hasta</label>
        <div class="select-container">
          <input type="date" name="initData" id="initData" [(ngModel)]="finalDate">
        </div>
      </div>
    </div>
    <hr>
    <div class="list-filters">

      <div class="filter-input">
        <label for="type">{{ PRINCIPAL_APP_NAME == 'celic' ? 'Liceo' : 'Escuela' }}</label>
        <div class="select-container">
          <select name="" id="type" (change)="onSelectSchool($event)">
            <option [value]="0">Todo</option>
            <option [value]="item.id" *ngFor="let item of filtersToShow.schoolsList">{{item.NameTSchool}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input">
        <label for="type">Tipo de solicitud</label>
        <div class="select-container">
          <select name="" id="type" (change)="onSelectType($event)">
            <option [value]="0">Todas</option>
            <option [value]="item.id" *ngFor="let item of filtersToShow.typeRequest">{{item.nameTypeRequest}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>
  
      <div class="filter-input" [ngClass]="filtersToShow.subTypeRequest.length === 0 ? 'disabled' : ''">
        <label for="type">Subtipo de solicitud</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="selectSubtypeRequest">
            <option [ngValue]="0" selected disabled>Seleccionar tipo</option>
            <option [ngValue]="item.id" *ngFor="let item of filtersToShow.subTypeRequest">{{item.nameSubTypeRequest}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>
  
      <div class="filter-input" [ngClass]="filtersToShow.courseOfferList.length === 0 ? 'disabled' : ''">
        <label for="type">{{ PRINCIPAL_APP_NAME == 'celic' ? 'Grado' : 'Oferta' }}</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="selectedCourseOffer">
            <option [ngValue]="0" selected disabled>Seleccionar {{ PRINCIPAL_APP_NAME == 'celic' ? 'grado' : 'oferta' }}</option>
            <option [ngValue]="item.id" *ngFor="let item of filtersToShow.courseOfferList">{{item.NameCourseOfer}}</option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

      <div class="filter-input">
        <label for="type">Estado de Pago</label>
        <div class="select-container">
          <select name="" id="type" [(ngModel)]="paidFilter">
            <option [ngValue]="null" selected>Mostrar todos</option>
            <option [ngValue]="false"> No Pagados </option>
            <option [ngValue]="true"> Pagados </option>
          </select>
          <em class="icon-i-angle-right"></em>
        </div>
      </div>

    </div>
    <div class="list-filters" *ngIf="false"><!-- Se quita porque no se usa -->
      <button class="normal-button" (click)="filterByProcess(1)" [ngClass]="{'normal-button__selected': 1 == filterBtnSelected}">
        Procesos reasignados: Reasignados a mi
      </button>
      <button class="normal-button" (click)="filterByProcess(2)" [ngClass]="{'normal-button__selected': 2 == filterBtnSelected}">
        Procesos reasignados: Que he reasignado
      </button>
    </div>
    <div class="modalFilters__bottom">
      <button class="modalFilters__bottom btn" (click)="applyFilters()">
        APLICAR
      </button>
      <button class="modalFilters__bottom btn" (click)="cleanAllFilters()">
        RESTABLECER
      </button>
    </div>
  </div>
</div>