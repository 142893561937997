
<div class="user-select">
  <span class="user-select__label">{{ title }}</span>
  <div class="user-select__content" 
    [ngClass]="{ 'error-input' : isSubmitting && userToAdd === undefined  }">
    <div class="search-input__container">
      <ng-container [ngTemplateOutlet]="selectedUser" *ngIf="showSelectedUser"></ng-container>
      <input type="text" 
        class="search-input" 
        placeholder="Buscar por correo" 
        #userSearchInput 
        (click)="clearUser()"
      >
      <em class="icon-i-search search-icon"></em>
    </div>
  
    <div class="user-list" *ngIf="showUserList && userList.length > 0 && userSearchInput.value.length > 3">
      <div class="user-list__item" *ngFor="let user of this.userList">
        <img class="user-picture" src="{{ user.photo }}"  alt="">
         <button class="button button--add-user" (click)="addUser( user )">
          <span class="user-email">{{ user.email }}</span>
        </button>
      </div>
    </div>
  </div>

  <small class="error-message" *ngIf="isSubmitting && userToAdd === undefined">Este campo es obligatorio</small>
</div>

<ng-template #selectedUser>
  <div class="selected-user">
    <img class="user-picture user-picture--sm" src="{{ userToAdd.photo }}" alt="">
    <span class="user-email user-email--sm">{{ userToAdd.email }}</span>
  </div>
</ng-template>