import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, switchMap, take } from 'rxjs';
import { DispatchOrderI } from 'src/app/models/dbModels/dispatchOrder';
import { FileReq } from 'src/app/models/dbModels/fileReq';
import { NotificationQueue } from 'src/app/models/dbModels/notificationQueue';
import { ReqMembers } from 'src/app/models/dbModels/req-members';
import { ReqMembersStaff } from 'src/app/models/dbModels/ReqMembersStaff';
import { Request } from 'src/app/models/dbModels/request';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';
import { RoleMapping } from 'src/app/models/dbModels/roleMapping';
import { StageReq } from 'src/app/models/dbModels/stageReq';
import { StageReqHistory } from 'src/app/models/dbModels/stageReqHistory';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { User } from 'src/app/models/dbModels/user';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { RequestMessage } from 'src/app/models/logicModels/requestMessage';
import { AuthService } from 'src/app/services/auth.service';
import { CloudFunctions } from 'src/app/services/cloud-functions';
import { Functions } from 'src/app/services/functions';
import { RequestService } from 'src/app/services/request.service';
import { RequestWatcherUserService } from 'src/app/services/request/request-watcher-user.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Component( {
  selector: 'request-request-chat-admin',
  templateUrl: './request-chat-admin.component.html',
  styleUrls: [ './request-chat-admin.component.css' ]
} )
export class RequestChatAdminComponent implements OnInit {

  @Input() allSubTypeRequests: SubTypeRequest[];
  @Input() requestData: Request;
  @Input( 'requesterUser' ) requesterUser!: User;
  @Output() closeRequestData = new EventEmitter<boolean>();
  @Output() updateData = new EventEmitter<boolean>();

  public nameBucketRequestInfo = environment.nameBucketRequestInfo;
  public nameBucketCertificateinfo = environment.nameBucketCertificateinfo;
  public PRINCIPAL_APP_NAME = environment.principalAppName.toLowerCase();

  showDocumentSelector: boolean = false;
  public fileToUpload: File[] = [];
  fileToUploadName: string = "";
  allNameFilesToUpload: string[] = [];
  msgToSendAdmin: string = "";
  msgToSendUser: string = "";
  showReasingList: boolean = false;

  public adminsList: RoleMapping[] = [];
  public adminsSelected: RoleMapping;

  public viewType?: number | null;
  public tagStateActive: boolean;
  public payStateActive: boolean;
  public allRequestMessages: RequestMessage[] | undefined;
  public allRequestMessagesAdmin: RequestMessage[] | undefined;

  protected priceInputValue:number = 0;
  protected showConfirmPaymentModal: boolean = false;
  protected showConfirmChangeStateModal: boolean = false;
  protected uploadingPaymentOrder: boolean = false;

  public urlBackV1:any = environment.urlBackV1;

  private GATEWAY_NAME = environment.dispatchOrder_gatewayName;

  constructor (
    private _authService: AuthService,
    private _requestService: RequestService,
    private _cloudFunctions: CloudFunctions,
    private _requestWatcherUserService: RequestWatcherUserService,
    private _sweetalertService: SweetalertService,
    private _functions: Functions,
  ) {
    this.viewType = 4
    this.tagStateActive = false;
    this.payStateActive = false;
    this.newAdminBox.push( this.adminsSelected );
  }

  private reqMembersDataToReply: {
    idReq: number,
    isReqMember: boolean,
    idReqStaff: number,
    isReqMemberStaff: boolean
  } = {
    idReq: 0,
    isReqMember: false,
    idReqStaff: 0,
    isReqMemberStaff: false
  };
  ngOnInit(): void {
    this.dataChatMsgsOrder();
    this.getAdmins();
    this.getMyIp();
    this.calculateDataToPayment();
    if (
      this.requestData?.requestUserRequester?.parentsToStudents &&
      this.requestData.requestUserRequester.parentsToStudents.length > 0 &&
      this.requestData.requestUserRequester.parentsToStudents[0].parentUser
    ) this.acudienteData = this.requestData.requestUserRequester.parentsToStudents[0].parentUser;
  }

  acudienteData: User;

  ngOnChanges( changes: SimpleChanges ) {
    this.initAllData();
    if ( !changes[ 'firstChange' ] ) {
      this.dataChatMsgsOrder();
      this.validateReasigned();
      if (
        this.requestData?.requestUserRequester?.parentsToStudents &&
        this.requestData.requestUserRequester.parentsToStudents.length > 0 &&
        this.requestData.requestUserRequester.parentsToStudents[0].parentUser
      ) this.acudienteData = this.requestData.requestUserRequester.parentsToStudents[0].parentUser;
      else {
        this.acudienteData = new User();
      }
      this.calculateDataToPayment();
    }
    if ( this.requestData.stateReqID == 3 ) this.viewType = 4
  }

  initAllData() {
    this.reqMembersDataToReply = {
      idReq: 0,
      isReqMember: false,
      idReqStaff: 0,
      isReqMemberStaff: false
    };
  }

  allDocumentMessages: RequestMessage[];
  allDocumentMessagesPlaceholders: RequestMessage[];
  allDocumentMessagesAdmin: RequestMessage[];
  dataChatMsgsOrder() {
    console.log( 'requestData', this.requestData );
    let requestHistoriesUser: RequestHistory[] = [];
    let requestHistoriesAdmin: RequestHistory[] = [];
    this.requestData.requestHistories?.forEach( element => {
      if ( element.privateComment ) requestHistoriesAdmin.push( element );
      else requestHistoriesUser.push( element );
    } );
    let fileRequestUser: FileReq[] = [];
    let fileRequestAdmin: FileReq[] = [];
    this.requestData.filesReq?.forEach( element => {
      if ( element.isAdmin ) fileRequestAdmin.push( element );
      else fileRequestUser.push( element );
    } );
    /** *************************** USER *************************** */
    let textMessages: RequestMessage[] | undefined;
    textMessages = requestHistoriesUser?.map( history => {
      let historyItem: RequestMessage = {
        userName: history.requestHistoryUser?.Name1 + ' ' + history.requestHistoryUser?.LastName1,
        userEmail: history.requestHistoryUser?.email,
        message: history.publicComment,
        messageDate: history.updateDate,
        userImg: history.requestHistoryUser?.Photo,
        isReceived: history.userID?.toString() === this._authService.getCurrentUserID(),
      }
      return ( historyItem )
    } );
    let documentMessages: RequestMessage[] = [];
    documentMessages = fileRequestUser.map( file => {
      let FileItem: RequestMessage = {
        userName: file.filesUserID?.Name1 + ' ' + file.filesUserID?.LastName1,
        userEmail: file.filesUserID?.email,
        documents: [ file ],
        messageDate: file.updateDate,
        userImg: file.filesUserID?.Photo,
        isReceived: file.filesUserID?.id?.toString() === this._authService.getCurrentUserID(),
      }
      return ( FileItem )
    } );
    this.allDocumentMessagesPlaceholders = documentMessages.filter(fileToFilter => fileToFilter.documents?.[0]?.isPlaceholderFile);
    this.allDocumentMessages = documentMessages.filter(fileToFilter => !fileToFilter.documents?.[0]?.isPlaceholderFile);
    this.allRequestMessages = textMessages?.concat( documentMessages );
    this.allRequestMessages?.sort( function ( a, b ) {
      return new Date( a.messageDate! ).getTime() - new Date( b.messageDate! ).getTime();
    } );
    this.allDocumentMessagesPlaceholders?.sort( function ( a, b ): number {
      const nameA = a?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
      const nameB = b?.documents?.[0]?.nameFile?.toLowerCase() ?? '';
      const numA = parseInt(nameA, 10);
      const numB = parseInt(nameB, 10);
      if (!isNaN(numA) && !isNaN(numB)) return numA - numB;
      return nameA.localeCompare(nameB);
    } );
    /** *************************** ADMIN *************************** */
    let textMessagesAdmin: RequestMessage[] | undefined;
    textMessagesAdmin = requestHistoriesAdmin?.map( history => {
      let historyItem: RequestMessage = {
        userName: history.requestHistoryUser?.Name1 + ' ' + history.requestHistoryUser?.LastName1,
        userEmail: history.requestHistoryUser?.email,
        message: history.privateComment,
        messageDate: history.updateDate,
        userImg: history.requestHistoryUser?.Photo,
        isReceived: history.userID?.toString() === this._authService.getCurrentUserID(),
      }
      return ( historyItem )
    } );
    let documentMessagesAdmin: RequestMessage[] | undefined;
    documentMessagesAdmin = fileRequestAdmin?.map( file => {
      let FileItem: RequestMessage = {
        userName: file.filesUserID?.Name1 + ' ' + file.filesUserID?.LastName1,
        // userPosition: ,
        userEmail: file.filesUserID?.email,
        documents: [ file ],
        messageDate: file.updateDate,
        userImg: file.filesUserID?.Photo,
        isReceived: file.filesUserID?.id?.toString() === this._authService.getCurrentUserID(),
      }
      return ( FileItem )
    } );
    this.allDocumentMessagesAdmin = documentMessagesAdmin;
    this.allRequestMessagesAdmin = textMessagesAdmin?.concat( documentMessagesAdmin ? documentMessagesAdmin : [] );
    this.allRequestMessagesAdmin?.sort( function ( a, b ) {
      return new Date( a.messageDate! ).getTime() - new Date( b.messageDate! ).getTime();
    } );
  }

  deleteFileBeforeUpload( index: number ): void { /** TODO: Revisar */
    this.fileToUpload.splice( index, 1 );
    this.allNameFilesToUpload.splice( index, 1 );
  }

  sendMessageToAdmin(): void {
    let requestHistoryData: RequestHistory = {
      privateComment: this.msgToSendAdmin,
      updateDate: new Date( Date.now() ),
      stageReqID: this.requestData.stageReqID,
      stateReqID: this.requestData.stateReqID,
      requestID: this.requestData.id,
      userID: Number( this._authService.getCurrentUserID() ),
    }
    this.createRequestHistory( requestHistoryData );
  }

  sendMessageToStudent(): void {
    let requestHistoryData: RequestHistory = {
      publicComment: this.msgToSendUser,
      updateDate: new Date( Date.now() ),
      stageReqID: this.requestData.stageReqID,
      stateReqID: this.requestData.stateReqID,
      requestID: this.requestData.id,
      userID: Number( this._authService.getCurrentUserID() ),
    }
    this.createRequestHistory( requestHistoryData );
  }

  createRequestHistory( req: RequestHistory ) {
    this._requestService.createRequestHistory( req ).pipe( take( 1 ) ).subscribe(
      data => {
        console.log( 'createRequestHistory success', data );
        this.msgToSendAdmin = '';
        this.msgToSendUser = '';
        this.requestData?.requestHistories?.push( data );
        this.dataChatMsgsOrder();
        this.updateDataSaved();
      },
      err => {
        console.log( 'createRequestHistory fail', err );
      }
    );
  }

  createFileReq( fileReq: FileReq ): void {
    this._requestService.createFileReq( fileReq ).pipe( take( 1 ) ).subscribe(
      data => {
        console.log( 'createFileReq success', data );
        this.updateDataSaved();
        this.requestData?.filesReq?.push( data );
        this.dataChatMsgsOrder();
        this.showDocumentSelector = false;
      },
      err => {
        console.log( 'createFileReq fail', err );
      }
    );
  }

  aproveReq(): void {
    if ( this.requestData.requestSubTypeRequest?.fileUrl != null ) this.generateFile( this.requestData.requestSubTypeRequest.fileUrl );
    else this.updateStateReq( 3 );
  }
  getNameUser( user: User ) {
    let name = "";
    name = `${ user.Name1 ? user.Name1 : '' } ${ user.Name2 ? user.Name2 : '' } ${ user.LastName1 ? user.LastName1 : '' } ${ user.LastName2 ? user.LastName2 : '' }`
    return name.trim();
  }
  generateFile( indexTemplate: string ): void {
    let idQR = uuidv4();
    let data = {
      "indexTemplateCert": indexTemplate,
      "idQR": idQR,
      "idEscuela": this.requestData.schoolID,
      "acronimoEscuela": this.requestData.requestSchool?.SchoolAcronim,
      "nombreEscuela": this.requestData.requestSchool?.NameTSchool!.toUpperCase(),
      "estudiNombre": this.getNameUser( this.requestData.requestUserRequester! ),
      "estudiDocNum": this.requestData.requestUserRequester?.UserDocuments![ 0 ]?.Document,
      "estudiDocPlace": this.requestData.requestUserRequester?.UserDocuments![ 0 ]?.TypeDocuments?.NameTypeDocument,
      "progrmaNombre": this.requestData.requestCourseOffer?.course?.NameCourse,
      "nombreElaboro": this.getNameUser( this.requestData.requestUserOwner! ),
      "cursoNombre": this.requestData.requestCourseOffer?.course?.NameCourse,
      "courseTeachHoursmin": this.requestData.requestCourseOffer?.course?.TeachingHoursmin,
      "CousesOferSince": this.requestData.requestCourseOffer?.Since.toString().split( 'T' )[ 0 ],
      "nombreFirma1": "",
      "cargoFirma1": "Comandante del comando educación y doctrina",
      "nombreFirma2": "",
      "cargoFirma2": "Secretaria General",
      "nombreFirma3": "",
      "cargoFirma3": "Rector"
    }

    console.log( 'generandoArchivo', data );
    console.log( 'requestData', this.requestData );

    this._cloudFunctions.generateFile( data ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        console.log( 'generateFile success', data );
        if ( data.status == "uploaded" ) {
          let dataToUpdate = {
            stateReqID: 3,
            uuid: idQR,
            documentLink: `/attachments/${this.nameBucketCertificateinfo}/download/${ data.fileName }`
          }
          let autoChatText = "El estado de su solicitud se ha actualizado y su certificado ha sido generado";
          this.patchRequest( dataToUpdate, autoChatText );
          this.postNotificationQueue( 'Se ha generado un certificado para su solicitud' );
        }
      },
      error: err => {
        console.log( 'generateFile fail', err );
      }
    } );
  }
  patchRequest( data: object, chatText: string ): void {
    this._requestService.patchRequest( this.requestData.id, data ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        console.log( 'patchRequest success', data );
        this.requestData.stateReqID = 3;
        this.autoChatMsg( chatText );
        this.postNotificationQueue( `Su solicitud con ticket ${this.requestData.id} se ha actualizado` );
      },
      error: err => {
        console.log( 'patchRequest fail', err );
      }
    } );
  }
  public reqMemberComment: string = '';
  public showAproveBtns: boolean = false;
  public showGetReqMemberByStaff: boolean = false;
  updateReqMemberAprove(response: boolean): void {
    if (!this.reqMembersDataToReply.isReqMember) return;
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let listObservable: any = {};

    let dataUpdateMyReqMember = {
      isAproved: response,
      comment: this.reqMemberComment,
      processedAT: new Date().toISOString()
    };
    listObservable['UPDATE_REQ_MEMBER'] = this._requestService.updateReqMembers( this.reqMembersDataToReply.idReq, dataUpdateMyReqMember ).pipe( take( 1 ) );

    let actuallyReqMembersToReview: ReqMembers[] = this.requestData.reqMembers!.filter( member => (member.numSequence == this.requestData.reqMembersIndex) );
    let nextReqMembersToReview: ReqMembers[] = this.requestData.reqMembers!.filter( member => (member.numSequence == this.requestData.reqMembersIndex!+1) );
    let userIdLogued = Number(this._authService.getCurrentUserID());
    let allMembersInMyStepHaveARespond: boolean = true;
    let userIdPendindToResponse: number = 0;
    if (actuallyReqMembersToReview.length > 1) {
      actuallyReqMembersToReview.forEach(member => {
        if (member.isAproved == null && member.userID != userIdLogued ) {
          allMembersInMyStepHaveARespond = false;
          userIdPendindToResponse = member.userID!;
        }
      });
    }
    if (allMembersInMyStepHaveARespond && nextReqMembersToReview.length > 0) {
      let dataUpdateNextReqMember = {
        isAdmin: 1,
        assignedAT: new Date().toISOString()
      };
      nextReqMembersToReview.forEach((member, index) => {
        userIdPendindToResponse = member.userID!;
        listObservable[`UPDATE_NEXT_REQ_MEMBER_0${index}`] = this._requestService.updateReqMembers( member.id!, dataUpdateNextReqMember ).pipe( take( 1 ) );
      });
    }

    let actuallyNumSequence: number = this.requestData.reqMembersIndex!;
    if (!allMembersInMyStepHaveARespond) actuallyNumSequence = this.requestData.reqMembersIndex!;
    else if (nextReqMembersToReview.length > 0) actuallyNumSequence++;
    else actuallyNumSequence = 0;
    let dataToUpdateNextUserInRequest = {
      userCurrentID: ( (actuallyNumSequence == 0) ? this.requestData.userOwnerID : userIdPendindToResponse),
      reqMembersIndex: actuallyNumSequence
    };
    listObservable[`PACTCH_REQUEST`] = this._requestService.patchRequest( this.requestData.id, dataToUpdateNextUserInRequest ).pipe( take( 1 ) );

    forkJoin(listObservable).subscribe( {
      next: ( data ) => {
        console.log( 'updateReqMemberAprove success', data );
        this.showAproveBtns = false;
        this._sweetalertService.swalClose();
        this.updateDataSaved();
        this.closeFloatingBoxAdmin();
      },
      error: ( err ) => {
        console.log( "ERROR updateReqMemberAprove", err );
        this._sweetalertService.swalError('Error','Intentelo nuevamente, si el error persiste, contacte a soporte.', () => {})
      },
      complete: () => {
        console.log( 'complete updateReqMemberAprove' );
      }
    });
  }

  updateReqMemberAproveFromStaff(response: boolean): void {
    if (!this.reqMembersDataToReply.isReqMemberStaff) return;
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let listObservable: any = {};

    let dataUpdateMyReqMember = {
      isAproved: response,
      comment: this.reqMemberComment,
      processedAT: new Date().toISOString()
    };
    listObservable['UPDATE_REQ_MEMBER'] = this._requestService.updateReqMembers( this.reqMembersDataToReply.idReq, dataUpdateMyReqMember ).pipe( take( 1 ) );

    let actuallyReqMembersToReview: ReqMembers[] = this.requestData.reqMembers!.filter( member => (member.numSequence == this.requestData.reqMembersIndex) );
    let nextReqMembersToReview: ReqMembers[] = this.requestData.reqMembers!.filter( member => (member.numSequence == this.requestData.reqMembersIndex!+1) );
    let userIdLogued = Number(this._authService.getCurrentUserID());
    let allMembersInMyStepHaveARespond: boolean = true;
    let userIdPendindToResponse: number = 0;
    if (actuallyReqMembersToReview.length > 1) {
      actuallyReqMembersToReview.forEach(member => {
        if (member.isAproved == null && member.userID != userIdLogued && this.reqMembersDataToReply.idReq != member.id ) {
          allMembersInMyStepHaveARespond = false;
          userIdPendindToResponse = member.userID!;
        }
      });
    }
    if (allMembersInMyStepHaveARespond && nextReqMembersToReview.length > 0) {
      let dataUpdateNextReqMember = {
        isAdmin: 1,
        assignedAT: new Date().toISOString()
      };
      nextReqMembersToReview.forEach((member, index) => {
        userIdPendindToResponse = member.userID!;
        listObservable[`UPDATE_NEXT_REQ_MEMBER_0${index}`] = this._requestService.updateReqMembers( member.id!, dataUpdateNextReqMember ).pipe( take( 1 ) );
      });
    }

    let actuallyNumSequence: number = this.requestData.reqMembersIndex!;
    if (!allMembersInMyStepHaveARespond) actuallyNumSequence = this.requestData.reqMembersIndex!;
    else if (nextReqMembersToReview.length > 0) actuallyNumSequence++;
    else actuallyNumSequence = 0;
    let dataToUpdateNextUserInRequest = {
      userCurrentID: ( (actuallyNumSequence == 0) ? this.requestData.userOwnerID : userIdPendindToResponse),
      reqMembersIndex: actuallyNumSequence
    };
    listObservable[`PACTCH_REQUEST`] = this._requestService.patchRequest( this.requestData.id, dataToUpdateNextUserInRequest ).pipe( take( 1 ) );

    let dataUpdateReqMemberStaff: ReqMembersStaff = {
      isAproved: response,
      processedAT: new Date().toISOString(),
    };
    listObservable[`PACTCH_REQ_MEMBER_ESTAFF`] = this._requestService.updateReqMembersStaff( this.reqMembersDataToReply.idReqStaff!, dataUpdateReqMemberStaff ).pipe( take( 1 ) );
    
    forkJoin(listObservable).subscribe( {
      next: ( data ) => {
        console.log( 'updateReqMemberAproveFromStaff success', data );
        this.showAproveBtns = false;
        this._sweetalertService.swalClose();
        this.updateDataSaved();
        this.closeFloatingBoxAdmin();
      },
      error: ( err ) => {
        console.log( "ERROR updateReqMemberAproveFromStaff", err );
        this._sweetalertService.swalError('Error','Intentelo nuevamente, si el error persiste, contacte a soporte.', () => {})
      },
      complete: () => {
        console.log( 'complete updateReqMemberAproveFromStaff' );
      }
    });

  }

  confirmAssistReqmember(): void {
    let dataUpdateReqMemberStaff: ReqMembersStaff = {
      wasAssisted: true,
      assistedAt: new Date().toISOString(),
      canTake: false
    };
    if (!this.reqMembersDataToReply.isReqMemberStaff) return;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    let observableList: Observable<any>[] = [];
    observableList.push( this._requestService.updateReqMembersStaff( this.reqMembersDataToReply.idReqStaff!, dataUpdateReqMemberStaff ).pipe( take( 1 ) ), );
    this.requestData.reqMembers?.forEach(reqMember => {
      if (reqMember.id == this.reqMembersDataToReply.idReq) {
        reqMember.reqMemberStaff?.forEach(staff => {
          let otherDataUpdateReqMemberStaff: ReqMembersStaff = {
            wasAssisted: false,
            canTake: false
          };
          if (staff.id != this.reqMembersDataToReply.idReqStaff) observableList.push( this._requestService.updateReqMembersStaff( staff.id!, otherDataUpdateReqMemberStaff ).pipe( take( 1 ) ), );
        });
      }
    });

    forkJoin(observableList).subscribe( {
      next: ( data ) => {
        console.log( 'confirmAssistReqmember success', data );
        this.showGetReqMemberByStaff = false;
        this._sweetalertService.swalClose();
        this.closeFloatingBoxAdmin();
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( "ERROR confirmAssistReqmember", err );
        this._sweetalertService.swalError('Error','Intentelo nuevamente, si el error persiste, contacte a soporte.', () => {})
      },
      complete: () => {
        console.log( 'complete confirmAssistReqmember' );
      }
    });

  }

  updateStateReq( stateId: number ): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let data: { stateReqID: number, userCurrentID: number, reqMembersIndex: number | null } = { stateReqID: stateId, userCurrentID: this.requestData.userCurrentID!, reqMembersIndex: null };
    if ([2, 3].includes(stateId)) data.userCurrentID = this.requestData.userOwnerID!;
    if (this.requestData.reqMembers?.length! > 0) data.reqMembersIndex = 1;
    this._requestService.patchRequest( this.requestData.id, data ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        console.log( 'updateStateReq success', data );
        this.requestData.stateReqID = stateId;
        this._sweetalertService.swalClose();
        if (stateId == 2 && this.requestData.reqMembers?.length! > 0 ) this.validateFirtStateChange();
        this.autoChatMsg( "El estado de su solicitud se ha actualizado" );
        this.postNotificationQueue( `El estado de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
      },
      error: err => {
        console.log( 'updateStateReq fail', err );
      }
    } );
  }

  validateFirtStateChange(): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let firsReqMember: ReqMembers = this.requestData.reqMembers?.find(member => member.numSequence == 1)!;
    let data = { assignedAT: new Date().toISOString() };
    this._requestService.updateReqMembers( firsReqMember.id!, data ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        console.log( 'validateFirtStateChange success', data );
        this._sweetalertService.swalClose();
      },
      error: err => {
        console.log( 'validateFirtStateChange fail', err );
      }
    } );
  }

  stageReqHistoryDescription: string = '';
  updateStageReq( stageData: StageReq ): void {
    if (this.stageReqHistoryDescription.trim() == '' || this.stageReqHistoryDescription.length < 10) {
      this._sweetalertService.swalError('Error','Ingrese una descripción (Obligatorio, mínimo 10 caracteres).', () => {});
      return;
    }
    console.log('stage update', stageData);
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let listObserbable: any[] = [];

    let dataToReq = { stageReqID: stageData.id, updateDate: new Date() };
    listObserbable.push(this._requestService.patchRequest( this.requestData.id, dataToReq ).pipe( take( 1 ) ));

    let requestHistoryData: RequestHistory = {
      // publicComment: `El estado interno de su solicitud con ticket ${element.id} se ha actualizado.`, TODO: ---> esto es para el email a enviar
      publicComment: `El estado interno de su solicitud ha cambiado (${stageData.nameStage}), con comentario: ${this.stageReqHistoryDescription.trim()}`,
      updateDate: new Date( Date.now() ),
      stageReqID: stageData.id,
      stateReqID: this.requestData.stateReqID,
      requestID: this.requestData.id,
      userID: Number( this._authService.getCurrentUserID() ),
    }
    listObserbable.push(this._requestService.createRequestHistory( requestHistoryData ).pipe( take( 1 ) ));

    let stageReqHistoryData: StageReqHistory = {
      description: this.stageReqHistoryDescription.trim(),
      stageReqID: stageData.id,
      requestID: this.requestData.id,
      createdAt: new Date().toISOString(),
      createdBy: Number( this._authService.getCurrentUserID() ),
    }
    listObserbable.push(this._requestService.createStageReqHistories( stageReqHistoryData ).pipe( take( 1 ) ));

    forkJoin(listObserbable).subscribe({
      next: ( data ) => {
        console.log( 'updateStageReq success', data );
        this.requestData.stageReqID = stageData.id;
        this.stageReqHistoryDescription = '';
        this.postNotificationQueue( `El estado interno de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
        this._sweetalertService.swalClose();
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( 'updateStageReq fail', err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }

  updatePayment( isPaidValue: boolean ): void {
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let data = { isPaid: isPaidValue }
    this._requestService.patchRequest( this.requestData.id, data ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        console.log( 'updatePayment success', data );
        this.requestData.isPaid = isPaidValue;
        this._sweetalertService.swalClose();
        this.autoChatMsg( "El pago de su solicitud se ha actualizado" );
        this.postNotificationQueue( `El estado de pago de su solicitud con ticket ${this.requestData.id} se ha actualizado` );
      },
      error: err => {
        console.log( 'updatePayment fail', err );
      }
    } );
  }

  getUserData( index: number ): string | undefined { /** TODO: REVISAR LOGICA */
    let data: string | undefined = '';
    if ( index == 0 )
      data = (
        this.requestData?.requestUserRequester?.MilitarInfos != undefined &&
        this.requestData?.requestUserRequester?.MilitarInfos[ 0 ]?.CedulaMil != undefined
      ) ? this.requestData?.requestUserRequester?.MilitarInfos[ 0 ]?.CedulaMil : '';
    if ( index == 1 )
      data = (
        this.requestData?.requestUserRequester?.IsMilitar &&
        this.requestData?.requestUserRequester?.MilitarInfos != undefined &&
        this.requestData?.requestUserRequester?.MilitarInfos[ 0 ]?.Range?.NameRang != undefined
      ) ? this.requestData?.requestUserRequester?.MilitarInfos[ 0 ]?.Range?.NameRang : 'Civil';
    if ( index == 2 )
      data = (
        this.requestData?.requestUserRequester?.UserDocuments != undefined &&
        this.requestData?.requestUserRequester?.UserDocuments[ 0 ]?.Document != undefined
      ) ? this.requestData?.requestUserRequester?.UserDocuments[ 0 ]?.Document : '';
    return data;
  }

  getAdmins() {
    let filter = JSON.stringify( {
      where: {
        and: [
          { SchoolID: this.requestData?.schoolID},
          { roleId: 25 }
        ]
      },
      include: [ "userapp", "school", "role" ]
    } );
    this._requestService.getRequestAdmins( filter ).pipe( take( 1 ) ).subscribe( {
      next: data => {
        this.adminsList = data;
      },
      error: err => {
        console.log( 'getAdmins fail', err );
      },
      complete: () => {
        this.validateReasigned();
      }
    } );
  }

  validateReasigned() {
    this.showAproveBtns = false;
    if (
      !this.requestData ||
      this.requestData.reqMembersIndex == null ||
      !this.requestData.reqMembers ||
      this.requestData.reqMembers.length == 0 ||
      !this.requestData.requestSubTypeRequest ||
      !this.requestData.requestSubTypeRequest.reqMembersTemplate ||
      this.requestData.requestSubTypeRequest.reqMembersTemplate.length == 0
    ) return;

    let loguedAdminId = Number( this._authService.getCurrentUserID() );
    let amIReqMember: boolean = false;
    let amIReqMemberStaff: boolean = false;

    let actuallyReqMembersToReview: ReqMembers[] = this.requestData.reqMembers?.filter( member => (member.numSequence == this.requestData.reqMembersIndex) );
    let actuallyReqMembersToReviewWithStaff: ReqMembers[] = actuallyReqMembersToReview.filter( member => ( member.reqMemberStaff?.length! > 0 ) );
    let actuallyReqMembersStaffToReview: ReqMembersStaff[] = [];
    actuallyReqMembersToReviewWithStaff.forEach(members => {
      members.reqMemberStaff?.forEach(staff => {
        actuallyReqMembersStaffToReview.push(staff);
      });
    });

    // let findedPositionInReqMembers = this.requestData.reqMembers?.findIndex( member => (member.userID == loguedAdminId && member.isAproved == null) );
    let findedPositionInReqMembers = actuallyReqMembersToReview.findIndex( member => (member.userID == loguedAdminId && member.isAproved == null) );
    // if (findedPositionInReqMembers == -1) return;
    
    // let findedPositionInReqMembersStaff = this.requestData.reqMembers?.findIndex( member => (member.userID == loguedAdminId && member.isAproved == null) );
    // let findedPositionInReqMembersStaff = actuallyReqMembersStaffToReview.findIndex( staff => (staff.userID == loguedAdminId && ( staff.wasAssisted == null || staff.isAproved == null)) );
    let findedPositionInReqMembersStaff = actuallyReqMembersStaffToReview.findIndex( staff => ( staff.userID == loguedAdminId && staff.isAproved == null) );
    // if (findedPositionInReqMembers == -1) return;
    
    console.log('************************************************************');
    console.log('************************************************************');
    console.log('actuallyReqMembersToReview: ', actuallyReqMembersToReview);
    console.log('findedPositionInReqMembers: ', findedPositionInReqMembers);
    console.log('findedPositionInReqMembersStaff: ', findedPositionInReqMembersStaff);
    console.log('actuallyReqMembersStaffToReview: ', actuallyReqMembersStaffToReview);
    console.log('************************************************************');
    console.log('************************************************************');

    if (findedPositionInReqMembers == -1 && findedPositionInReqMembersStaff == -1) return;
    if (findedPositionInReqMembers >= 0) {
      this.reqMembersDataToReply.idReq = actuallyReqMembersToReview[findedPositionInReqMembers].id!;
      this.reqMembersDataToReply.isReqMember = true;
      this.showAproveBtns = true;
    }
    if (findedPositionInReqMembersStaff >= 0) {
      this.reqMembersDataToReply.idReq = actuallyReqMembersStaffToReview[findedPositionInReqMembersStaff].reqMembersID!;
      this.reqMembersDataToReply.idReqStaff = actuallyReqMembersStaffToReview[findedPositionInReqMembersStaff].id!;
      this.reqMembersDataToReply.isReqMemberStaff = true;
      this.showGetReqMemberByStaff = true;
      if (actuallyReqMembersStaffToReview[findedPositionInReqMembersStaff].wasAssisted) this.showAproveBtns = true;
    }
  }

  public newAdminBox: RoleMapping[] = [];
  addNewAdminToList(): void {
    let addNewAdminSpace = true;
    this.newAdminBox.forEach(element => {
      if (!element || element === undefined || element === null) addNewAdminSpace = false;
    });
    if (addNewAdminSpace) this.newAdminBox.push( this.adminsSelected );
  }

  validateNewAdminSelected(): void {
    if (
      this.newAdminBox.length == 0 ||
      !this.newAdminBox?.[0]?.userapp?.id ||
      !this.requestData.reqMembers
    ) return;
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let firstMemberWithoutCalification = this.requestData.reqMembers?.find(x => x.isAproved === null);
    let numSequenceActual = 1;
    if (this.requestData.reqMembers.length == 0  && !firstMemberWithoutCalification) numSequenceActual = 1; // Es el primer ReqMember
    if (firstMemberWithoutCalification?.numSequence) numSequenceActual = firstMemberWithoutCalification.numSequence; // Ya hay reqMembers y se insertan entre medios
    if (this.requestData.reqMembers.length > 0 && !firstMemberWithoutCalification) numSequenceActual = this.requestData.reqMembers.length + 1; // Todos los ReqMembers actuales ya aprobaron y se agrega al final
    let listObserbable: any[] = [];
    listObserbable.push(this._requestService.patchRequest( this.requestData.id, { userCurrentID: this.newAdminBox[0].userapp.id } ));
    this.newAdminBox.forEach( (newAdminIterated, index) => {
      if (!newAdminIterated?.userapp) return;
      const data: ReqMembers = {
        userID: newAdminIterated.userapp.id,
        requestID: this.requestData.id,
        isAproved: null,
        isAdmin: true,
        assignedAT: new Date().toISOString(),
        numSequence: numSequenceActual + index
      }
      listObserbable.push(this._requestService.createReqMembers( data ));
    } );
    this.requestData.reqMembers.forEach(reqMemberIterated => {
      if (reqMemberIterated.id && reqMemberIterated.numSequence && reqMemberIterated.numSequence >= numSequenceActual) {
        const data = { numSequence: reqMemberIterated.numSequence + this.newAdminBox.length }
        listObserbable.push(this._requestService.updateReqMembers( reqMemberIterated.id, data ));
      }
    });
    forkJoin(listObserbable).subscribe({
      next: ( data ) => {
        this._sweetalertService.swalClose();
        this.showReasingList = !this.showReasingList;
        this.newAdminBox = [];
        this.updateDataSaved();
      },
      error: ( err ) => {
        console.log( "ERROR validateNewAdminSelected", err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
      }
    });
  }

  getDataFromUploadFileComponent_ADMIN( data: InfoFileData ): void {
    let newFileReq: FileReq = {
      descript: data.descript,
      userID: Number( this._authService.getCurrentUserID() ),
      nameFile: data.nameFile,
      updateDate: new Date( Date.now() ),
      isAdmin: true,
      isDocumentValid: false,
      urlFile: data.urlFile,
      requestID: this.requestData.id
    }
    this.createFileReq( newFileReq );
  }

  getDataFromUploadFileComponent_USER( data: InfoFileData ): void {
    let newFileReq: FileReq = {
      descript: data.descript,
      userID: Number( this._authService.getCurrentUserID() ),
      nameFile: data.nameFile,
      updateDate: new Date( Date.now() ),
      isAdmin: false,
      isDocumentValid: false,
      urlFile: data.urlFile,
      requestID: this.requestData.id
    }
    this.createFileReq( newFileReq );
  }
  closeUploadFileComponent(): void {
    this.showDocumentSelector = false;
  }

  closeRequestDataBox() {
    this.closeRequestData.emit( false );
    this.closeFloatingBoxAdmin();
  }

  updateDataSaved(): void {
    this.updateData.emit( true );
  }

  isPrincipalAdmin(): boolean {
    let result = false;
    if ( Number( this._authService.getCurrentUserID() ) == this.requestData.userOwnerID ) result = true;
    return result;
  }

  postNotificationQueue( text: string ): void {
    let newDate: Date = new Date(new Date().getTime());
    let queueNotificationQueueObserbable: any[] = [];
    if (this.PRINCIPAL_APP_NAME == 'cedoc') {
      const data: NotificationQueue = {
        emailsTo: `["${this.requestData.requestUserRequester?.CedocEmail}"]`,
        namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
        msg: `[{"message":"${ text }"}]`,
        timeToSend: newDate.toISOString(),
        isSend: false,
        isSingleMessage: true,
        typeNotification: 'email'
      }
      queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( data ).pipe( take( 1 ) ) );
    } else {
      if (this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.parentUser) {
        let userDataToPaymentParent = this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.parentUser;
        const dataParent: NotificationQueue = {
          emailsTo: `["${ userDataToPaymentParent.email || userDataToPaymentParent.CedocEmail }"]`,
          namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
          msg: `[{"message":"${ text }"}]`,
          timeToSend: newDate.toISOString(),
          isSend: false,
          isSingleMessage: true,
          typeNotification: 'email'
        }
        queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataParent ).pipe( take( 1 ) ) );
      }
      if (this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.attendantUser) {
        let userDataToPaymentAttendant = this.requestData?.requestUserRequester?.parentsToStudents?.[0]?.attendantUser;
        const dataAttendant: NotificationQueue = {
          emailsTo: `["${ userDataToPaymentAttendant.email || userDataToPaymentAttendant.CedocEmail }"]`,
          namesTO: `["${this.requestData.requestUserRequester?.Name1} ${this.requestData.requestUserRequester?.LastName1}"]`,
          msg: `[{"message":"${ text }"}]`,
          timeToSend: newDate.toISOString(),
          isSend: false,
          isSingleMessage: true,
          typeNotification: 'email'
        }
        queueNotificationQueueObserbable.push( this._requestService.createNotificationQueue( dataAttendant ).pipe( take( 1 ) ) );
      }
    }
    if (queueNotificationQueueObserbable.length == 0) return;
    forkJoin([
      ...queueNotificationQueueObserbable
    ]).subscribe( {
      next: data => {
        console.log( 'postNotificationQueue success', data );
      },
      error: err => {
        console.log( 'postNotificationQueue fail', err );
      }
    } );
  }
  autoChatMsg( msg: string ): void {
    let requestHistoryData: RequestHistory = {
      publicComment: msg,
      updateDate: new Date( Date.now() ),
      stageReqID: this.requestData.stageReqID,
      stateReqID: this.requestData.stateReqID,
      requestID: this.requestData.id,
      userID: Number( this._authService.getCurrentUserID() ),
    }
    this.postNotificationQueue( `Ha recibido un nuevo mensaje en el chat de su solicitud con ticket ${this.requestData.id}` );
    this.createRequestHistory( requestHistoryData );
  }

  myIP = '';
  getMyIp(): void {
    this._functions.getIP().pipe(take(1)).subscribe({
      next: data => {
        let ipList = data.clientIp.split(',');
        if (ipList[0].includes(':')) this.myIP = ipList[1].trim();
        else this.myIP = ipList[0].trim();
      },
      error: err => {
        console.log('getMyIp fail', err);
      }
    });
  }


  /**
   * Creates a new receipt when a new price is set by the administrator
   * @param event 
   * @returns 
   */
  protected confirmPayment( ) {
    const subTypeRequest = this.allSubTypeRequests.find( e => e.id === this.requestData.subTypeRequestID );
    if ( this.priceInputValue === 0 || !subTypeRequest ) {
      this._sweetalertService.swalError('Error','El precio no es válido.', () => {});
      return;
    }
    this.uploadingPaymentOrder = true;
    let navigatorName = navigator.userAgent;
    let todayDate = new Date();
    todayDate.setDate(new Date().getDate() + 30);
    let monthDate = todayDate.toISOString();

    if (
      !this.requestData.requestUserRequester ||
      !this.requestData.requestUserRequester.UserDocuments ||
      this.requestData.requestUserRequester.UserDocuments.length == 0 ||
      !this.requestData.requestUserRequester.UserDocuments[0].Document ||
      !this.requestData.requestUserRequester.ContactInfos ||
      this.requestData.requestUserRequester.ContactInfos.length == 0 ||
      !this.requestData.requestUserRequester.ContactInfos[0].Address
    ) {
      this._sweetalertService.swalError('Error','El estudiante debe actualizar los datos en la plataforma (documento y dirección).', () => {});
      this.uploadingPaymentOrder = false;
      return;
    }

    if (
      this.PRINCIPAL_APP_NAME == 'celic' && (
        !this.requestData.requestUserRequester ||
        !this.requestData.requestUserRequester.parentsToStudents ||
        this.requestData.requestUserRequester.parentsToStudents.length == 0 ||
        !this.requestData.requestUserRequester.parentsToStudents[0].parentUser ||
        !this.requestData.requestUserRequester.parentsToStudents[0].parentUser.UserDocuments ||
        this.requestData.requestUserRequester.parentsToStudents[0].parentUser.UserDocuments.length == 0 ||
        !this.requestData.requestUserRequester.parentsToStudents[0].parentUser.UserDocuments[0].Document ||
        !this.requestData.requestUserRequester.parentsToStudents[0].parentUser.ContactInfos ||
        this.requestData.requestUserRequester.parentsToStudents[0].parentUser.ContactInfos.length == 0 ||
        !this.requestData.requestUserRequester.parentsToStudents[0].parentUser.ContactInfos[0].Address
      )
    ) {
      this._sweetalertService.swalError('Error','El responsable de pago del estudiante debe actualizar los datos en la plataforma (documento y dirección).', () => {});
      this.uploadingPaymentOrder = false;
      return;
    }

    let userToGeneratePayment: User;
    if (this.PRINCIPAL_APP_NAME == 'celic') userToGeneratePayment = this.requestData.requestUserRequester?.parentsToStudents![0].parentUser!;
    else userToGeneratePayment = this.requestData.requestUserRequester!;

    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    const data: DispatchOrderI = {
      id: Number(userToGeneratePayment.id!.toString() + new Date().valueOf().toString()).toString(),
      DueDate: monthDate,
      issueDate: new Date().toISOString(),
      DispatchCode: this.requestData.id,
      Code: this.requestData.schoolID!,
      document: userToGeneratePayment.UserDocuments![0].Document,
      Address: userToGeneratePayment.ContactInfos![0].Address, 
      UserId: userToGeneratePayment.id!,
      Telephone: userToGeneratePayment.ContactInfos?.[0]?.CellPhone || userToGeneratePayment?.ContactInfos?.[0]?.Phone || 0,
      firsEmail: userToGeneratePayment.CedocEmail, 
      lastEmail: userToGeneratePayment.email! || userToGeneratePayment.CedocEmail!, 
      FirstName: `${ userToGeneratePayment.Name1 } ${ userToGeneratePayment.Name2 || '' }`,
      lasName: `${ userToGeneratePayment.LastName1 || '' } ${ userToGeneratePayment.LastName2 }`,
      AssetsAndServicesCatalog: 'N/A',
      Description: subTypeRequest?.nameSubTypeRequest || 'N/A',
      gatewayName: this.GATEWAY_NAME,
      realStatus: "PENDING",
      // EcollectValue: this.requestData.requestSchool?.entityCode,
      PaymentState: false,
      paymentDate1: this.priceInputValue,
      // paymentDate2: this.priceInputValue,
      fileAdminName: subTypeRequest?.nameSubTypeRequest || 'N/A', //Receipt name
      requestID: this.requestData.id,
      userAgent: navigatorName,
      ipAddress: this.myIP,
      paidTo: `["${userToGeneratePayment.UserDocuments![0].Document}"]`,
    }

    this._requestService.setDispatchOrders( data ).pipe(
      switchMap( ( dispatchOrder: any ) => {
        const newRequestData: any = {
          TotalPayment: this.priceInputValue,
          dueDate: new Date(),
          dispatchOrderID: dispatchOrder.id
        }
        return this._requestService.patchRequest( this.requestData.id, newRequestData )
      } )
    ).subscribe( {
      next: (value) => {
        this.postNotificationQueue( `Tienes un recibo nuevo en tu solicitud con ticket ${this.requestData.id}` );
        this._sweetalertService.swalClose();
      }, complete: () => {
        this.autoChatMsg( "El precio de su solicitud se ha actualizado" );
        this.showConfirmPaymentModal = false;
        this.uploadingPaymentOrder = false;
        this.priceInputValue = 0;
      }, error: ( err ) => {
        console.log( err );
        this._sweetalertService.swalError('Error','Algo no salió bien, intentelo nuevamente, si el problema persiste, contacte a soporte.', () => {});
        this.showConfirmPaymentModal = false;
        this.uploadingPaymentOrder = false;
        this.priceInputValue = 0;
      }
    } );
  }

/**
 * Set the price and prevent the value from being less than 0
 * @param event 
 */
  protected setPrice(event: any){
    const eventValue = event.target.value;

    if(eventValue < 0 )
      event.target.value = 0;
    
    this.priceInputValue = event.target.value;
  }

  protected setPriceV2(value: number){
    this.priceInputValue = value;
    this.showConfirmPaymentModal = true
  }

  stateToConfirm = 0;
  showModalConfirmChangeStateValue(value: number): void {
    this.stateToConfirm = value;
    this.showConfirmChangeStateModal = true;
  }
  confirmChangeStateValue(): void {
    this.updateStateReq(this.stateToConfirm);
    this.showConfirmChangeStateModal = false;
  }

  dataToAssist = {
    showModal: false
  }
  showModalConfirmAssistReq(): void {
    this.dataToAssist.showModal = true;
  }
  confirmResponseReqMemberAssist(): void {
    this.dataToAssist.showModal = false;
    // this.confirmAssistReqmember(); /** TODO: Revisar asistir reqMember */
    this.confirmResponseReqMember();
  }

  dataToResponseReqMember = {
    showModal: false,
    dataToResponse: false,
    isReqMember: false,
    isReqMemberStaff: false
  }
  showModalConfirmResponseReqMember(value: boolean, type: number): void {
    // this.dataToResponseReqMember.dataToResponse = value;
    // this.dataToResponseReqMember.showModal = true;
    this.dataToResponseReqMember = {
      showModal: true,
      dataToResponse: value,
      isReqMember: false,
      isReqMemberStaff: false
    };
    if (type == 1) this.dataToResponseReqMember.isReqMember = true;
    if (type == 2) this.dataToResponseReqMember.isReqMemberStaff = true;
  }
  confirmResponseReqMember(): void {
    this.dataToResponseReqMember.showModal = false;
    if (this.dataToResponseReqMember.isReqMember) this.updateReqMemberAprove(this.dataToResponseReqMember.dataToResponse);
    if (this.dataToResponseReqMember.isReqMemberStaff) this.updateReqMemberAproveFromStaff(this.dataToResponseReqMember.dataToResponse);
  }

  /** ****************************** New Code ****************************** */

  closeFloatingBoxAdmin(): void {
    this._requestWatcherUserService.closeFloatingBoxAdmin();
  }

  getReqMembersState(isAproved: null | boolean | undefined): string {
    let state = '';
    if (isAproved === true) state = (this.PRINCIPAL_APP_NAME == 'celic' ? 'Cumple' : 'Aprobado');
    else if (isAproved === false) state = (this.PRINCIPAL_APP_NAME == 'celic' ? 'No cumple' : 'Rechazado');
    else if (isAproved === null) state = 'Pendiente';
    return state;
  }

  getLastAdminDate(reqMember: ReqMembers): Date | string {
    let date: Date | string;
    if (reqMember.numSequence == 1) date = this.requestData.registerDate;
    else {
      let userReqMemberFinded = this.requestData.reqMembers?.find(userReqMember => userReqMember.numSequence == reqMember.numSequence!-1);
      date = userReqMemberFinded?.processedAT!
    }
    return date;
  }

  validateFile(documentData: RequestMessage, response: boolean): void {
    if (
      !documentData ||
      !documentData.documents ||
      documentData.documents.length == 0 ||
      !documentData.documents[0].id
    ) return;
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    let dataToUpdateFileReq = {
      isAproved: response
    }
    this._requestService.updateFileReq(documentData.documents[0].id, dataToUpdateFileReq).pipe( take( 1 ) ).subscribe(
      data => {
        this.updateDataSaved();
      },
      err => {
        console.log( 'createRequestHistory fail', err );
        this._sweetalertService.swalError('Error','Intentelo nuevamente, si el error persiste, contacte a soporte.', () => {})
      }
    );
  }

  dataPayment = {
    requestWithPayment: false,
    // showPaymentBtn: false,
    classPaymentColor: '',
    textPaymentState: 'SIN COSTO',
    btnLink: '',
    paymentHistory: [{}]
  };
  calculateDataToPayment(): void {
    this.dataPayment = {
      requestWithPayment: this.requestData.dispatchOrders!.length > 0,
      // showPaymentBtn: false,
      classPaymentColor: '',
      textPaymentState: (this.requestData?.TotalPayment && this.requestData.TotalPayment > 0 && this.requestData.dispatchOrders!.length == 0) ? 'SIN ASIGNAR' : 'SIN COSTO',
      btnLink: '',
      paymentHistory: []
    }
    if (this.requestData.dispatchOrders!.length == 0) return;
    if (this.requestData.dispatchOrders![0].sessionData == null) return;
    const btnLink = JSON.parse(this.requestData.dispatchOrders![0].sessionData);
    this.dataPayment.btnLink = btnLink.processUrl;
    const paymentState = this.requestData.dispatchOrders![0].PaymentState;
    const realStatus = this.requestData.dispatchOrders![0].realStatus;
    if ( !paymentState && realStatus == "PENDING" ) {
      this.dataPayment.textPaymentState = 'PENDIENTE';
    }
    if ( realStatus == "APPROVED" ) {
      this.dataPayment.textPaymentState = 'PAGADO';
    }
    if ( realStatus == "PROCESS" ) {
      this.dataPayment.textPaymentState = 'EN PROCESO';
    }
    if ( realStatus == "CANCELLED" || realStatus == "REJECTED" ) {
      this.dataPayment.textPaymentState = 'RECHAZADO';
    }
    this.calculatePaymentHistory();
  }
  calculatePaymentHistory(): void {
    let paymentHistory = {
      id: '',
      date: '',
      state: '',
      gatewayData: false
    }
    this.requestData.dispatchOrders!.forEach((dispatch, index) => {
      paymentHistory = {
        id: dispatch.id,
        date: dispatch.issueDate,
        state: '',
        gatewayData: !!dispatch.gatewayData
      }
      const realStatus = dispatch.realStatus;
      switch (realStatus) {
        case 'PENDING':
          paymentHistory.state = 'PENDIENTE';
          break;
        case 'APPROVED':
          paymentHistory.state = 'PAGADO';
          break;
        case 'PROCESS':
          paymentHistory.state = 'EN PROCESO';
          break;
        case 'CANCELLED':
        case 'REJECTED':
          paymentHistory.state = 'RECHAZADO';
          break;
      }
      this.dataPayment.paymentHistory.push(paymentHistory);
    });
  }
}
