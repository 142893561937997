import { Course } from "./course";
import { CourseOffer } from "./courseOffer";
import { Request } from "./request";
import { School } from "./school";
import { User } from "./user";

export class MultiReq {
  public id?: number;
  public SchoolID?: number;
  public courseID?: number;
  public CourseOferID?: number;
  public UserOwnerID?: number;

  public requests?: Request[];
  public schools?: School;
  public course?: Course;
  public courseOfer?: CourseOffer;
  public userOwner?: User;

  public showListData?: boolean = false; // Para visualizacion
}
