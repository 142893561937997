import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationQueue } from 'src/app/models/dbModels/notificationQueue';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class IntCallsService {

  constructor(private _httpService: HttpService) { }

  public getCurrentUser(){
    const filter = JSON.stringify({
      include:['roleMappings','roles']
    });
    return this._httpService.httpGet(`/Userapps/me?filter=${ filter }`);
  }

  public getUserList(filter?:string): Observable<any>{
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ViewUserInfos${ newFilter }`);
  }

  public getSchools(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Schools${ newFilter }`);
  }

  public getCourses(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Courses${ newFilter }`);
  }

  public getIntcalls(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Intcalls${ newFilter }`);
  }

  public getIntcallsCount( filters?:string ){
    return this._httpService.httpGet(`/Intcalls/count?where=${ filters }`);
  }

  public getUserIntcallAnswer(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/UserIntcallAnswers${ newFilter }`);
  }

  public getCommentsIntcallFieldAnswer(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/CommentsIntcallFieldAnswers${ newFilter }`);
  }

  public getDocumentIntcall(filter?:string){
    let newFilter = "";
    if(filter) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet(`/DocumentIntcalls${ newFilter }`);
  }

  public setIntcall(data:any){
    return this._httpService.httpPost('/Intcalls',data);
  }

  public setJudgeIntcall(data:any){
    return this._httpService.httpPost('/JudgeIntcalls',data);
  }

  public setUserIntcallAnswer(data:any){
    return this._httpService.httpPost('/UserIntcallAnswers',data);
  }

  public setUserIntcallFieldAnswer(data:any){
    return this._httpService.httpPost('/UserIntcallFieldAnswers',data);
  }

  public setCommentsIntcallFieldAnswer(data:any){
    return this._httpService.httpPost('/CommentsIntcallFieldAnswers',data);
  }

  public setIntcallModules(data:any){
    return this._httpService.httpPost('/IntcallModules',data);
  }

  public setGradeIntcallFieldAnswers(data:any){
    return this._httpService.httpPost('/GradeIntcallFieldAnswers',data);
  }

  public setIntcallFields(data:any){
    return this._httpService.httpPost('/IntcallFields',data);
  }

  public setDocumentIntcall(data:any){
    return this._httpService.httpPost('/DocumentIntcalls',data);
  }


  public setNotificationQueue(data: NotificationQueue) {
    return this._httpService.httpPost( '/NotificationQueues', data );
  }

  public deleteDocumentIntcall(id:number){
    return this._httpService.httpDelete(`/DocumentIntcalls/${ id }`);
  }

  public patchGradeIntcallFieldAnswers( id:number, data:any ){
    return this._httpService.httpPatch(`/GradeIntcallFieldAnswers/${ id }`,data);
  }

  public patchUserIntcallAnswer( id:number, data:any ){
    return this._httpService.httpPatch(`/UserIntcallAnswers/${ id }`,data);
  }

  public patchUserIntcallFieldAnswers( id:number, data:any ){
    return this._httpService.httpPatch(`/UserIntcallFieldAnswers/${ id }`,data);
  }
}
