<div class="containerDocuemtsSelector">
  <div class="closeDocumentsContainer" *ngIf="showCloseBtn">
    <a (click)="closeComponent()" class="closeDocumentsContainerBtn">
      <em class="icon-i-close"></em>
    </a>
  </div>
  <div class="documentEmpty" *ngIf="!fileToUpload">
    <a (click)="fileInput.click()" class="documentEmptyToClick">
      <em class="icon-i-filled-upload iconsDefaultStyle iconsDefaultStyle__lightBlue"></em>
      <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" [accept]="acceptedFileTypes"/>
      <span>Elija el archivo que desee cargar</span>
      <span>({{ fileTypesToShow }})</span>
      <span>Tamaño máximo {{maxFileSizeInMB}}MB</span>
    </a>
  </div>
  <div class="documentSelected" *ngIf="fileToUpload && !isFileUploaded">
    <div class="documentSelected__fileInfo">
      <em class="documentSelected__fileInfoAutoWith icon-i-documents iconsDefaultStyle iconsDefaultStyle__darkBlue"></em>
      <div class="documentSelected__fileInfoData">
        <span>{{ fileToUpload.name }}</span>
      </div>
      <a class="documentSelected__fileInfoAutoWith documentSelected__fileInfoChangeFile" (click)="eraseFileSelected()">
        <span>Cambiar archivo</span>
      </a>
    </div>
    <div class="documentSelected__fileName">
      <input type="text" placeholder="Escriba el nombre del archivo (Obligatorio)" class="documentSelected__fileNameInput"
        name="FileName" [(ngModel)]="dataFile.nameFile" minlength="1" maxlength="70" #FileName="ngModel"
      >
      <div class="documentSelected__errorMessage" *ngIf="dataFile.nameFile.trim()==''">
        <span>Nombre del archivo obligatorio</span>
      </div>
    </div>
    <div class="documentSelected__fileDescript">
      <input type="text" placeholder="Descripción (Opcional)" class="documentSelected__fileNameInput" name="FileDescript" [(ngModel)]="dataFile.descript" minlength="1" maxlength="70" #FileDescript="ngModel">
    </div>
    <button 
      (click)="uploadAttachment()"
      [disabled]="!fileToUpload || dataFile.nameFile.trim()==''"
      class="documentSelected__btn"
      [ngClass]="{'documentSelected__btn--disabled': dataFile.nameFile.trim()=='' || !fileToUpload}"
    >Enviar Archivo</button>
  </div>
  <div class="documentUploaded" *ngIf="fileToUpload && isFileUploaded">
    <em class="documentSelected__fileInfoAutoWith icon-i-documents iconsDefaultStyle iconsDefaultStyle__darkBlue"></em>
    <div class="documentUploaded__infoFile">
      <a class="document-box__action" [href]="urlBackV1 + dataFile.urlFile" target="_blank">
        <span class="fileNameStyle">{{ fileToUpload.name }}</span>
      </a>
      <a class="document-box__action" [href]="urlBackV1 + dataFile.urlFile" target="_blank">
        <span>{{ dataFile.nameFile.trim() }}</span>
      </a>
    </div>
    <a class="document-box__action" (click)="editFileSelected()">
      <em class="documentUploaded__fileInfoAutoWith icon-i-pencil iconsDefaultStyle iconsDefaultStyle__lightBlue"></em>
    </a>
    <a class="document-box__action" (click)="eraseFileSelected()">
      <em class="documentUploaded__fileInfoAutoWith icon-i-trash iconsDefaultStyle iconsDefaultStyle__lightBlue"></em>
    </a>
  </div>
</div>
