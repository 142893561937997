import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'request-request-list-box-student',
  templateUrl: './request-list-box-student.component.html',
  styleUrls: ['./request-list-box-student.component.css']
})
export class RequestListBoxStudentComponent implements OnInit {

  @Input() name: string;
  @Input() email: string;
  @Input() img: string;

  @Output() activeFilters = new EventEmitter<void>();
  @Output() activePaymentHistory = new EventEmitter<void>();


  constructor() { 
    this.name = ''; 
    this.email = ''; 
    this.img = '';
  };

  ngOnInit(): void {
  }

  filterActivation() {    
    this.activeFilters.emit();
  }

  paymentHistoryActivation() {    
    this.activePaymentHistory.emit();
  }

}
