<div class="filtersContainer">

  <div class="filter-input">
    <label for="type">Liceo</label>
    <div class="select-container">
      <select (change)="onChangeSelected()" [(ngModel)]="allFiltersOptionsSelected.schools">
        <option disabled selected [ngValue]="undefined">Seleccione liceo</option>
        <option *ngFor="let school of ALL_SCHOOLS_LIST" [ngValue]="school">{{ school.NameTSchool }}</option>
      </select>
      <em class="icon-i-angle-right"></em>
    </div>
  </div>

  <div class="filter-input">
    <label for="type">Tipo de solicitud</label>
    <div class="select-container">
      <select (change)="onChangeSelected()" [(ngModel)]="allFiltersOptionsSelected.typeRequest">
        <option disabled selected [ngValue]="undefined">Seleccione tipo de solicitud</option>
        <option *ngFor="let typeReq of ALL_TYPE_REQUEST" [ngValue]="typeReq">{{ typeReq.nameTypeRequest }}</option>
      </select>
      <em class="icon-i-angle-right"></em>
    </div>
  </div>

  <div class="filter-input">
    <label for="type">Subtipo de solicitud</label>
    <div class="select-container">
      <select (change)="onChangeSelected()" [(ngModel)]="allFiltersOptionsSelected.subTypeRequest">
        <option disabled selected [ngValue]="undefined">Seleccione subtipo de solicitud</option>
        <option *ngFor="let subTypeReq of ALL_SUBTYPE_REQUEST" [ngValue]="subTypeReq">{{ subTypeReq.nameSubTypeRequest }}</option>
      </select>
      <em class="icon-i-angle-right"></em>
    </div>
  </div>

  <button (click)="applyFiltersSelected()">Buscar</button>

</div>
<div class="tableRequest">
  <table class="table table-no-border"><caption></caption>
    <thead>
      <tr>
        <th>ID</th>
        <th>Estudiante</th>
        <th>Solicitud</th>
        <th>Estado</th>
        <th *ngFor="let header of allReqMembersHeaders">{{ header.numSequence }} - {{ header.realName }}<br>{{ header.userName }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let reqIterated of allReqDataToShow">
        <td>{{ reqIterated.id }}</td>
        <td>{{ reqIterated.nombreEstudiante }}</td>
        <td>{{ reqIterated.typeReqNombre }}</td>
        <td>{{ reqIterated.estado }}</td>
        <!-- <td>{{ reqIterated.reqMembersData.length }}</td> -->
        <td *ngFor="let item of reqIterated.reqMembersData">
          {{ item.aprobado }} {{ item.aprobado=='Si' ? '-' : '' }} {{ item.fecha | date:'dd/MMM/yyyy' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>