<div class="ci-select">
  <div class="ci-select__inner ci-select__inner--{{ selectorId }}"  
    [ngClass]="{ 'ci-select__inner--visible': showSection, 'ci-select__inner--invalid': isInvalidValid , 'disabled': disableSelect }">

    <!-- SELECT -->
    <div class="ci-select__selector" 
      [ngStyle]="{ 'cursor': optionList.length > 0 ? 'pointer' : '' }" 
      (click)="showSection = !showSection">
      <span class="ci-select__selected">{{ selectedOption.value }}</span>
    </div>

    <!-- SELECT CONTAINER -->
    <div class="ci-select__options-container" 
      [ngClass]="{ 'ci-select__options-container--visible': showSection, 'ci-select__options-container--invalid': isInvalidValid }"
      *ngIf="optionList.length > 0">
      <span class="separator separator--top"></span>
      <div class="ci-select__options-list">
        <!-- OPTION LIST -->
        <ng-container *ngFor="let option of optionList">
          <span class="select-option" 
            [ngClass]="{ 'select-option--bold' : selectedOption.id === option.id }"
            (click)="onOptionClick( option )" title="{{ option.value }}"
          >
            {{ option.value }}
          </span>
        </ng-container>
        
      </div>
      <span class="separator separator--bottom"></span>

      <!-- DATE INPUTS SECTION -->
      <div class="ci-select__options-dates" *ngIf="showDateSection">
        <div class="date-input__container">
          <span class="ci-select__label">Fecha de inicio de reporte</span>
          <input type="date" class="date-input" (click)="showDatePicker( $event )" (change)="getStartDate( $event )" #dateFilter>
        </div>

        <div class="date-input__container">
          <span class="ci-select__label">Fecha de finalización fe reporte</span>
          <input type="date" class="date-input" (click)="showDatePicker( $event )" (change)="getEndDate( $event )" #dateFilter>
        </div>

        <button class="button button--clear" (click)="clearFilters()">Limpiar</button>
      </div>
    </div>
  </div>

  <small class="error-message" *ngIf="isInvalidValid"> Este campo es obligatorio </small>
</div>