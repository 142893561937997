import { schoolToLoad } from "./environment.common";

export const environment = {
  production: false,
  base: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/',
  baseUrl: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',
  urlBackV1: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  nameBucketRequestInfo: 'requestinfo',
  nameBucketCertificateinfo: 'certificateinfo',
  dispatchOrder_gatewayName: 'ECOLLECT',
  principalAppName: 'cedoc',
  URL_IMAGEN_PASARELA_DE_PAGOS: '',
  TIME_IN_MINUTES_TO_GENERATE_NEW_PAYMENT_LINK: 20,
  HEADER_FORMATO_AZUL: 'https://storage.googleapis.com/celic_assets/modules/pages/common/img/header_formato_azul.png',

  ...schoolToLoad()
};
