import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CloudFunctions {

  constructor(
    private _httpService: HttpService
  ) { }

  generateFile(data: object) {
    let url: string = `/signFile/singFile`
    return this._httpService.httpPostCloudFunctions(url, data);
  }

}


