<div class="modalContainer">
  <div class="modalBody">
    <div class="modalBody__upperBar">
      <span>GOU Pagos</span>
      <em class="icon-i-cancel-details-box changeCursor" (click)="closeModal()"></em>
    </div>
    <div class="modalBody__content">
      <div class="QuestionData">
        <span>¿Qué es GUO Pagos?</span>
        <p>GUO PAGOS es la plataforma de pagos electrónicos que usa Liceos Del Ejército para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.</p>
      </div>
      <div class="QuestionData">
        <span>¿Cómo puedo pagar?</span>
        <p>En la plataforma edicativa de los Liceos del Ejercito usted podrá realizar su pago con los medios habilitados para tal fin. Usted, de acuerdo a las opciones de pago escogidas por el comercio, podrá pagar a través de tarjetas de crédito Visa, y MasterCard y Cuentas debito ahorro y corriente PSE.</p>
      </div>
      <div class="QuestionData">
        <span>¿Es seguro ingresar mis datos bancarios en este sitio web?</span>
        <p>Para proteger tus datos los Liceos del Ejercito delega en GOU pagos la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera te podrás sentir seguro a la hora de ingresar la información de su tarjeta. <br><br> Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de dirección cambia a color verde. Estas características son visibles de inmediato y dan garantía y confianza para completar la transacción en GOU Pagos. <br><br> GOU Pagos también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.</p>
      </div>
      <div class="QuestionData">
        <span>¿Puedo realizar el pago cualquier día y a cualquier hora?</span>
        <p>Sí, en Liceos del Ejercito podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.</p>
      </div>
      <div class="QuestionData">
        <span>¿Puedo cambiar la forma de pago?</span>
        <p>Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es posible cambiar la forma de pago.</p>
      </div>
      <div class="QuestionData">
        <span>¿Pagar electrónicamente tiene algún valor para mí como comprador?</span>
        <p>No, los pagos electrónicos realizados a través de GOU Pagos no generan costos adicionales para el comprador.</p>
      </div>
      <div class="QuestionData">
        <span>¿Qué debo hacer si mi transacción no concluyó?</span>
        <p>En primera instancia, revisar si llegó un email de confirmación de la transacción a la cuenta de correo electrónico inscrita en el momento de realizar el pago, en caso de no haberlo recibido, deberás contactar a Maria Victoria Cantor Martinez para confirmar el estado de la transacción.</p>
      </div>
      <div class="QuestionData">
        <span>¿Qué debo hacer si no recibí el comprobante de pago?</span>
        <p>Por cada transacción aprobada a través de GOU Pagos, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar. <br><br> Si no lo recibes, podrás contactar a Maria Victoria Cantor Martinez o a la línea 601 9157054 ext 2004 o al correo electrónico mavictoriac@liceosejercito.edu.co, para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar.</p>
      </div>
    </div>
  </div>
</div>
