import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { TypeRequest } from '../models/dbModels/typeRequest';
import { SubTypeRequest } from '../models/dbModels/subTypeRequest';
import { Request } from '../models/dbModels/request';
import { FileReq } from '../models/dbModels/fileReq';
import { RequestHistory } from '../models/dbModels/requestHistory';
import { ReqMembers } from '../models/dbModels/req-members';
import { MultiReq } from '../models/dbModels/multi-req';
import { NotificationQueue } from '../models/dbModels/notificationQueue';
import { RoleMapping } from '../models/dbModels/roleMapping';
import { DispatchOrderI } from '../models/dbModels/dispatchOrder';
import { ParentsToStudents } from '../models/dbModels/parentsToStudents';
import { User } from '../models/dbModels/user';
import { UserCourse } from '../models/dbModels/userCourse';
import { StageReqHistory } from '../models/dbModels/stageReqHistory';
import { ReqMembersStaff } from '../models/dbModels/ReqMembersStaff';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getTypeRequest(filter?: string): Observable<TypeRequest[]> {
    let url: string = `/TypesRequest`
    if (filter)
      url = `/TypesRequest?filter=${filter}`

    return this._httpService.httpGet(url);
  }

  getSubTypeRequest(filter?: string): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest`
    if (filter) url = `/SubTypesRequest?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  getUsers(filter?: string): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest`
    if (filter)
      url = `/SubTypesRequest?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }


  getSubTypeRequestForOAP(schoolId: number): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest?filter={
      "where":{
        "and":[
          {"typeRequestID":1},
          {"schoolID":${schoolId}}
        ]
      }
    }`
    return this._httpService.httpGet(url);
  }

  getRequest(filter?: string): Observable<Request[]> {
    let url: string = `/Requests`
    if (filter)
      url = `/Requests?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }

  getRequestToTake(userId: number, filter: object): Observable<Request[]> {
    let url: string = `/Requests/getRequestToTake`;
    let dataToSend = {
      userID: userId,
      filterReq: filter
    };
    return this._httpService.httpPost(url, dataToSend);
  }

  getAllMyRequest(userId: number, filter: object): Observable<Request[]> {
    let url: string = `/Requests/getAllMyRequest`;
    let dataToSend = {
      userID: userId,
      filterReq: filter
    };
    return this._httpService.httpPost(url, dataToSend);
  }

  getAllRequestToOAP(subTypeRequestID: number, courseOfferID: number): Observable<Request[]> {
    let url: string = `/Requests?filter={
      "where":{
        "and":[
          {"subTypeRequestID":${subTypeRequestID}},
          {"courseOfferID":${courseOfferID}},
          {"stateReqID":3}
        ]
      },
      "include":"requestUserRequester"
    }`
    console.log('url',url);
    return this._httpService.httpGet(url);
  }

  getAllUserRequestByUserDocument(userDocReceived: number): Observable<Request> {
    let filter = JSON.stringify({
      where: { 
        Document: userDocReceived
      },
      include: {
        User: {
          relation: "myRequest",
          scope: {
            order: 'updateDate DESC',
            include: [
              "requestSchool",
              "requestSubTypeRequest",
              "requestTypeRequest",
              "requestUserOwner",
              "requestStageReq",
              "requestStateReq",
              {"reqMembers":"userapp"},
              {"requestCourseOffer":"course"},
              {"requestUserRequester": [{"UserDocuments":"TypeDocuments"}, "MilitarInfos"]},
              {"requestHistories": "requestHistoryUser"},
              { relation: "filesReq",
                scope: {
                  order: 'updateDate DESC',
                  include: ["filesUserID"]
                }
              }
            ]
          }
        }
      }
    })
    let url: string = `/UserDocuments?filter=${filter}`
    return this._httpService.httpGet(url);
  }

  getMultiRequest(): Observable<MultiReq[]> {
    let url: string = `/MultiReqs?filter={"order":"id DESC","include":[{"requests":["requestSubTypeRequest","requestTypeRequest","requestUserRequester","requestStateReq"]},"schools","course","courseOfer","userOwner"]}`
    return this._httpService.httpGet(url);
  }

  getRequestAdmins(filter?: string): Observable<Request[]> {
    let url: string = `/roleMappings`
    if (filter) url = `/roleMappings?filter=${filter}`;
    return this._httpService.httpGet(url);
  }
  getMyRoleMappings(filter?: string): Observable<RoleMapping[]> {
    let url: string = `/Userapps/me/roleMappings?filter={"include": ["school","role"]}`
    if (filter)
      url = `/Userapps/me/roleMappings?filter=${filter}`
    return this._httpService.httpGet(url);
  }
  getMyChildsUserCourses(userappID: number): Observable<UserCourse[]> {
    let url: string = `/Userapps/getChildDataFromAcudiente`
    return this._httpService.httpPost(url,{userappID});
  }

  createRequest(data?: Request) {
    let url: string = `/Requests`
    return this._httpService.httpPost(url, data);
  }
  createRequestMultiple(data?: Request[]) {
    let url: string = `/Requests`
    return this._httpService.httpPost(url, data);
  }

  createMultiReq(data?: MultiReq) {
    let url: string = `/MultiReqs`
    return this._httpService.httpPost(url, data);
  }

  createRequestHistory(data?: RequestHistory) {
    let url: string = `/RequestHistories`
    return this._httpService.httpPost(url, data);
  }

  createStageReqHistories(data: StageReqHistory) {
    let url: string = `/StageReqHistories`
    return this._httpService.httpPost(url, data);
  }
  
  createFileReq(data?: FileReq) {
    let url: string = `/FilesReq`
    return this._httpService.httpPost(url, data);
  }

  updateFileReq(id: number, data: object) {
    let url: string = `/FilesReq/${id}`
    return this._httpService.httpPatch(url, data);
  }

  getFilesReq(filter?: string): Observable<FileReq[]> {
    let url: string = `/FilesReq`
    if (filter)
      url = `/FilesReq?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }

  patchRequest(id: number | undefined, data: object): Observable<Request[]> {
    let url: string = `/Requests/${id}`
    return this._httpService.httpPatch(url, data);
  }

  createReqMembers(data?: ReqMembers) {
    let url: string = `/ReqMembers`
    return this._httpService.httpPost(url, data);
  }

  updateReqMembers(id: number, data: object) {
    let url: string = `/ReqMembers/${id}`
    return this._httpService.httpPatch(url, data);
  }

  getReqMembersStaff(filter?: string): Observable<ReqMembersStaff[]> {
    let url: string = `/ReqMembersStaffs`;
    if (filter) url = `/ReqMembersStaffs?filter=${filter}`;
    return this._httpService.httpGet(url);
  }

  createReqMembersStaff(data: ReqMembersStaff): Observable<ReqMembersStaff> {
    let url: string = `/ReqMembersStaffs`
    return this._httpService.httpPost(url, data);
  }

  updateReqMembersStaff(id: number, data: ReqMembersStaff): Observable<ReqMembersStaff> {
    let url: string = `/ReqMembersStaffs/${id}`
    return this._httpService.httpPatch(url, data);
  }

  createNotificationQueue(data?: NotificationQueue) {
    let url: string = `/NotificationQueues`
    return this._httpService.httpPost(url, data);
  }

  public setDispatchOrders(data: any): Observable<DispatchOrderI> {
    return this._httpService.httpPost('/DispatchOrders',data);
  }

  public getDispatchOrdersByID(id: string): Observable<DispatchOrderI> {
    return this._httpService.httpGet(`/DispatchOrders/${id}`);
  }

  public postDispatchOrdersPaymentRequest(data: any): Observable<any> {
    return this._httpService.httpPost('/DispatchOrders/paymentRequest',data);
  }

  public patchDispatchOrdersByID(id: string, data) {
    let url: string = `/DispatchOrders/${id}`
    return this._httpService.httpPatch(url, data);
  }


  public getCurrentUser(): Observable<User>{
    const filter:string = JSON.stringify({
      include:[{'UserDocuments':'TypeDocuments'},'ContactInfos', {'MilitarInfos':'Range'}]
    })
    return this._httpService.httpGet(`/Userapps/me?filter=${ filter }` );
  }

  public getUserById(userId: number): Observable<User>{
    const filter: string = JSON.stringify({
      include:[
        'UserDocuments',
        'ContactInfos',
        {roleMappings:"school"},
        {'parentsToStudents':[
          {'parentUser':["UserDocuments","ContactInfos"]},
          'attendantUser'
        ]}
      ]
    })
    return this._httpService.httpGet(`/Userapps/${userId}?filter=${ filter }` );
  }

  public getCurrentUserWithChilds(): Observable<User>{
    const filter: string = JSON.stringify({
      include:['UserDocuments','ContactInfos',{'parentsToStudents':{roleMappings:"school"}}]
    })
    return this._httpService.httpGet(`/Userapps/me?filter=${ filter }` );
  }

  public getMyParentsToStudentsRelations(parentId: number): Observable<ParentsToStudents[]> {
    const filter: string = JSON.stringify({
      where: { ParentUserId: parentId },
      include:['parentUser',{studentUser: ['UserDocuments','ContactInfos']}]
    });
    return this._httpService.httpGet(`/ParentsToStudents?filter=${ filter }` );
  }
  
}
