<div class="allViewContainer" *ngIf="showDataToPrint">
  <div class="btnsSection">
    <button (click)="printPage()">Imprimir</button>
  </div>
  <div id="sectionToPrint" class="sectionToPrint">
    <div class="to-print">
      <div class="bluFormat_header">
        <img class="" src="{{ HEADER_FORMATO_AZUL }}" alt="Icono pasarela de pagos">
      </div>
      <div class="bluFormat_section001">
        <span><strong>FORMATO ESTUDIO CUPO DE INGRESO</strong></span>
        <div class="tableSection001">
          <table class="table table-bordered"><caption></caption>
            <thead><tr><th></th></tr></thead>
            <tbody>
              <tr>
                <td><strong>FECHA</strong></td>
                <td>{{ formatData.fecha | date:'dd/MMM/yyyy' }}</td>
                <td><strong>PIN</strong></td>
                <td>{{ formatData.pin }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bluFormat_section002">
        <span><strong>I. INFORMACIÓN BÁSICA</strong></span>
        <div class="tableSection002">
          <table class="table table-bordered"><caption></caption>
            <thead><tr><th></th></tr></thead>
            <tbody>
              <tr>
                <td colspan="3"><strong>LICEO:</strong> {{ formatData.liceo }}</td>
                <td colspan="3"><strong>CURSO:</strong> {{ formatData.curso }}</td>
              </tr>
              <tr>
                <td colspan="6"><strong>NOMBRE DEL ASPIRANTE:</strong> {{ userData.nombre }}</td>
              </tr>
              <tr>
                <td colspan="4"><strong>NOMBRE DEL PADRE:</strong> {{ userParentData.nombre }}</td>
                <td colspan="2"><strong>GRADO:</strong> {{ userParentData.grado }}</td>
              </tr>
              <tr>
                <td colspan="2"><strong>CONDICIÓN:</strong> {{ userParentData.condicion }}</td>
                <td colspan="2"><strong>FUERZA:</strong> {{ userParentData.fuerza }}</td>
                <td colspan="2"><strong>IDENTIFICACIÓN:</strong> {{ userParentData.documento }}</td>
              </tr>
              <tr>
                <td colspan="4"><strong>DESCUENTO POR NOMINA:</strong> SI__ NO__</td>
                <td colspan="2"><strong>UNIDAD:</strong> {{ userParentData.unidad }}</td>
              </tr>
              <tr>
                <td colspan="3"><strong>PAGO EN BANCO:</strong> SI__ NO__</td>
                <td colspan="3"><strong>TELEFONO:</strong> {{ userParentData.telefono }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="bluFormat_section003">
        <span><strong>II. VERIFICACIÓN ACADÉMICA</strong></span><br>
        <span><strong>CERTIFICADO ACADÉMICO ANTERIOR:</strong></span>
        <div class="tableSection003">
          <table class="table table-bordered"><caption></caption>
            <thead><tr><th></th></tr></thead>
            <tbody>
              <tr>
                <td><strong>ADECUADO</strong></td>
                <td>SI</td>
                <td></td>
                <td>NO</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
  
          <div *ngFor="let reqMemberIterated of dataToReqMemberTable">
            <span><strong>CONCEPTO {{ reqMemberIterated.titulo | uppercase }}:</strong></span>
            <table class="table table-bordered tableTextBottom"><caption></caption>
              <thead><tr><th></th></tr></thead>
              <tbody>
                <tr>
                  <td class="tableTd25"><strong>CONCEPTO</strong></td>
                  <td class="tableTdTextBottom tableTd10">{{ reqMemberIterated.respuesta ? 'SI' : 'NO'}}</td>
                  <td class="tableTdTextBottom"><span>{{ reqMemberIterated.concepto }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="bluFormat_section004">
        <span><strong>III. DIRECCIÓN GENERAL</strong></span>
        <div class="tableSection003">
          <table class="table table-bordered"><caption></caption>
            <thead><tr><th></th></tr></thead>
            <tbody>
              <tr>
                <td><strong>PARTICULAR</strong></td>
                <td>{{ direccionGeneralData.particular }}</td>
              </tr>
            </tbody>
          </table>
  
          <table class="table table-bordered"><caption></caption>
            <thead><tr><th></th></tr></thead>
            <tbody>
              <tr>
                <td class="tableTd25">
                  <strong>CONCEPTO FINAL</strong> <br>
                  {{ direccionGeneralData.concepto_final }}
                </td>
                <td class="tableTdTextBottom tableTd10">{{ direccionGeneralData.respuesta ? 'SI' : 'NO'}}</td>
                <td class="tableTdTextBottom">DIRECTOR GENERAL LICEOS DEL EJÉRCITO</td>
              </tr>
            </tbody>
          </table>
  
        </div>
      </div>
      <div class="bluFormat_section005">
        <span><strong>OBSERVACIONES FINALES:</strong></span>
        <div class="section005_comments">{{ direccionGeneralData.observacion_final }}</div>
      </div>
    </div>
  </div>
</div>