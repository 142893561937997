<div class="progression-bar">
  <div class="progression-bar__bar">
    <div class="bar">
      <div class="bar__filling" [ngStyle]="{'width': percentageBar + '%'}" [ngClass]="{'bar__filling--light': color === 'light'}"></div>
    </div>
  </div>
  <div class="progression-bar__buttons">
    <button class="button-navigation" [ngClass]="{'button-navigation--disabled': prevDisabled}" [disabled]="prevDisabled" (click)="backPage()">
      <em class="button-navigation-icon icon-i-back"></em>
    </button>
    <button class="button-navigation" [ngClass]="{'button-navigation--disabled': nextDisabled}"  [disabled]="nextDisabled" (click)="nextPage()">
      <em class="button-navigation-icon icon-i-next"></em>
    </button>
  </div>
</div>
