import { Component, Input, OnInit } from '@angular/core';
import { RequestHistory } from 'src/app/models/dbModels/requestHistory';

@Component({
  selector: 'request-request-list-box',
  templateUrl: './request-list-box.component.html',
  styleUrls: ['./request-list-box.component.css']
})
export class RequestListBoxComponent implements OnInit {

  @Input() title?: string;
  @Input() descript?: string;
  @Input() color?: string;
  @Input() img?: string;
  @Input() requestHistories: RequestHistory[] = [];
  @Input() userIDlogued: number;
  @Input() offer: string;
  @Input() userName: string;
  @Input() userOwner: string;
  @Input() SchoolData: string = '';
  @Input() showUserName: boolean = false;
  @Input() showSchoolData: boolean = false;
  isThereNewChats = false;

  constructor() { 
    this.title = ''; 
    this.descript = ''; 
    this.color = '';
    this.img = '';
  };

  ngOnInit(): void {
    let lastIndex = this.requestHistories.length;
    //console.log('requestHistories',this.requestHistories);
    if (
      lastIndex > 0 &&
      this.requestHistories[lastIndex-1].userID != this.userIDlogued
    ) this.isThereNewChats = true;
  }

}
