import { Component, OnInit } from '@angular/core';
import { forkJoin, take } from 'rxjs';
import { Request } from 'src/app/models/dbModels/request';
import { School } from 'src/app/models/dbModels/school';
import { SubTypeRequest } from 'src/app/models/dbModels/subTypeRequest';
import { TypeRequest } from 'src/app/models/dbModels/typeRequest';
import { UserCourse } from 'src/app/models/dbModels/userCourse';
import { RequestService } from 'src/app/services/request.service';
import { SchoolService } from 'src/app/services/school.service';

@Component({
  selector: 'request-request-resume',
  templateUrl: './request-resume.component.html',
  styleUrls: ['./request-resume.component.css']
})
export class RequestResumeComponent implements OnInit {

  public ALL_USER_COURSES: UserCourse[] = [];
  public ALL_SUBTYPE_REQUEST: SubTypeRequest[] = [];
  public ALL_TYPE_REQUEST: TypeRequest[] = [];
  public ALL_SCHOOLS_LIST: School[] = [];
  public ALL_REQUEST_FINDED: Request[] = [];
  public allReqDataToShow: ReqDataToTable[] = [];
  public allReqMembersHeaders: HeadersReqMembersDataToTable[] = [];

  public allFiltersOptionsSelected: FiltersOptionsSqueleton = {
    schools: undefined,
    typeRequest: undefined,
    subTypeRequest: undefined,
  };

  constructor(
    private _requestService: RequestService,
    private _schoolService: SchoolService,
  ) {
    this.getAllInitialDataReqResume();
  }

  ngOnInit(): void {
    this.onChangeSelected();
  }

  getAllInitialDataReqResume(): void {
    let subTypeReqFilter = JSON.stringify({
      include: {
        relation: 'reqMembersTemplate',
        scope: {
          order: 'numSequence ASC',
          include: ["user"]
        }
      }
    });
    forkJoin({
      ALL_SCHOOLS_LIST: this._schoolService.getSchools().pipe(take(1)),
      ALL_SUBTYPE_REQUEST: this._requestService.getSubTypeRequest(subTypeReqFilter).pipe(take(1)),
      ALL_TYPE_REQUEST: this._requestService.getTypeRequest().pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        console.log('response getAllInitialDataReqResume',data);
        let {ALL_SUBTYPE_REQUEST, ALL_TYPE_REQUEST, ALL_SCHOOLS_LIST} = data;
        this.ALL_SUBTYPE_REQUEST = ALL_SUBTYPE_REQUEST;
        this.ALL_TYPE_REQUEST = ALL_TYPE_REQUEST;
        this.ALL_SCHOOLS_LIST = ALL_SCHOOLS_LIST;
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err)
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }

  onChangeSelected(): void {
    console.log('allFiltersOptionsSelected: ',this.allFiltersOptionsSelected);
    if (!this.allFiltersOptionsSelected.subTypeRequest?.reqMembersTemplate) return;
    this.allReqMembersHeaders = [];
    this.allReqMembersHeaders = this.allFiltersOptionsSelected.subTypeRequest.reqMembersTemplate.map(item => {
      const data: HeadersReqMembersDataToTable = {
        numSequence: item.numSequence,
        realName: item.realName,
        userId: item.userID,
        userName: `${item.user.LastName1} ${item.user.Name1}`
      }
      return data
    });
  }

  applyFiltersSelected(): void {
    console.log('allFiltersOptionsSelected: ',this.allFiltersOptionsSelected);
    let requestFilter = JSON.stringify({
      order: 'id DESC',
      where: {
        and: [
          { schoolID: this.allFiltersOptionsSelected.schools?.id },
          { subTypeRequestID: this.allFiltersOptionsSelected.subTypeRequest?.id },
          { typeRequestID: this.allFiltersOptionsSelected.typeRequest?.id }
        ]
      },
      include: [
        'requestUserRequester',
        'requestTypeRequest',
        'requestSubTypeRequest',
        'requestStateReq',
        {
          relation: 'reqMembers',
            scope: {
              order: 'numSequence ASC',
              include: ["userapp"]
            }
        },
      ]
    });
    console.log('requestFilter: ', requestFilter);
    forkJoin({
      ALL_REQUEST_FINDED: this._requestService.getRequest(requestFilter).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        this.allReqDataToShow = [];
        console.log('response applyFiltersSelected',data);
        let { ALL_REQUEST_FINDED } = data;
        this.ALL_REQUEST_FINDED = ALL_REQUEST_FINDED;
        this.ALL_REQUEST_FINDED.forEach(reqFindedIterated => {

          let nombreEstudiante = '';
          nombreEstudiante += `${reqFindedIterated.requestUserRequester?.Name1}`;
          nombreEstudiante += reqFindedIterated.requestUserRequester?.Name2 ? ` ${reqFindedIterated.requestUserRequester?.Name2}` : '';
          nombreEstudiante += ` ${reqFindedIterated.requestUserRequester?.LastName1}`;
          nombreEstudiante += reqFindedIterated.requestUserRequester?.LastName2 ? ` ${reqFindedIterated.requestUserRequester?.LastName2}` : '';
          const data: ReqDataToTable = {
            id: reqFindedIterated.id!,
            nombreEstudiante: nombreEstudiante,
            typeReqNombre: `${reqFindedIterated.requestTypeRequest?.nameTypeRequest} - ${reqFindedIterated.requestSubTypeRequest?.nameSubTypeRequest}`,
            estado: reqFindedIterated.requestStateReq?.nameState!,
            reqMembersData: reqFindedIterated.reqMembers!.filter(x => x.realName != null).map(reqMemberIterated => {
              const test: ReqMembersDataToTable = {
                nombre: `${reqMemberIterated.numSequence} ${reqMemberIterated.userapp?.Name1} ${reqMemberIterated.userapp?.LastName1}`,
                aprobado: reqMemberIterated.isAproved ? 'Si' : 'No',
                fecha: reqMemberIterated.processedAT!
              }
              return test;
            })
          }
          this.allReqDataToShow.push(data);
        });
      },
      error: (err) => {
        console.log("ERROR applyFiltersSelected",err)
      },
      complete: () => {
        console.log('complete applyFiltersSelected');
      }
    });
  }

}

class FiltersOptionsSqueleton {
  schools: School | undefined;
  typeRequest: TypeRequest | undefined;
  subTypeRequest: SubTypeRequest | undefined;
}
class ReqDataToTable {
  id: number;
  nombreEstudiante: string;
  typeReqNombre: string;
  estado: string;
  reqMembersData: ReqMembersDataToTable[];
}
class ReqMembersDataToTable {
  nombre: string;
  aprobado: string;
  fecha: string;
}
class HeadersReqMembersDataToTable {
  numSequence: number;
  realName: string;
  userId: number;
  userName: string;
}
