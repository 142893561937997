<div class="document-message">
  <div class="document-message__message">
    <div class="document-box">
      <div class="document-box__text">
        <div class="document-box__text-name">{{fileReq.nameFile}}</div>
        <div class="document-box__text-action" *ngIf="fileReq.urlFile != null">
          <a [href]="urlBackV1 + fileReq.urlFile" target="_blank">Ver documento</a>
        </div>
        <div class="document-box__text-name">{{fileReq.descript}}</div>
        <div class="document-box__statusFile" [ngClass]="fileStateClass" *ngIf="fileUploaded; else noFileUploadedDiv">
          {{ fileState }}
        </div>
        <ng-template #noFileUploadedDiv>
          <div class="document-box__statusFile" [ngClass]="fileStateClass">
            Documento no cargado
          </div>
        </ng-template>
        <div class="document-message__date">Enviado {{fileReq.updateDate | date: 'dd/MMM/yyyy'}}</div>
        <div class="loadNewDocument" *ngIf="(!fileUploaded || fileReq.isAproved === false) && !localLoaded">
          <span>Cargar</span>
          <upload-file-box
            [containerName]="nameBucketRequestInfo"
            [getTypeDocumentsToUpload]="'image/jpeg,image/png,application/pdf'"
            [getFileName]="fileReq.nameFile!"
            [showCloseBtn]="false"
            (responseDataFile)="getDataFromUploadFileComponent($event)"
            (closeView)="closeUploadFileComponent()"
          ></upload-file-box>
        </div>
      </div>
    </div>
  </div>
</div>
